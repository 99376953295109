import React, { Component, useState, useEffect } from 'react';
import takenextstep from '../../../assets/images/mfdregscreen/takenextstep-img-2.jpg'
import takenextstep1 from '../../../assets/images/mfdregscreen/takenextstep-img-3.png'
import downarrow from '../../../assets/images/regscreen/down_arrow.svg'
import { isMobile } from 'react-device-detect';
import $ from "jquery";


const MFDSection18 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    useEffect(() => {
        $('.faqblockcom .whitebx').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('.formbox').offset().top
            }, 1000);
            setTimeout(function () {
                $('.formbox .form-control.focus-field').focus();
            }, 1000);
        });

        $(document).ready(function () {
            $(".accordion-head").click(function () {
                // Toggle the current section
                $('.accordion-head').removeClass('active');
                $(this).addClass("active").next(".accordion-content").slideToggle();

                // Toggle the rotate class on the image
                $(this).find("img").toggleClass("rotate");

                // Hide other sections and remove the rotate class from their images
                $(".accordion-content").not($(this).next()).slideUp();
                $(".accordion-head img").not($(this).find("img")).removeClass("rotate");
            });
        });
    }, [])

    const phoneNumber = "02240548383"; // Replace with the phone number you want


    const handleButtonClick = () => {
        if (isMobile) {
            window.location.href = `tel:${phoneNumber}`;
        }
    };

    return (
        <>
            <section className="sectionpad ptop0">
                <h2 className="f32 mobf24 semibold mobf20 text-center">
                    Take your next step
                </h2>
                <div className="mtop40" />
                <div className="faqblockcom steps">
                    <div className="dflex spacebetween mwraps">
                        <div className="d48">
                            <div className={isMobile ? "whitebx1" : "whitebx"} onClick={handleButtonClick}>
                                <div className="stepbx">
                                    <picture>
                                        {/* WebP source */}
                                        <source
                                            src={takenextstep}
                                            type="image/webp"
                                        />
                                        {/* Fallback for browsers that do not support WebP */}
                                        <img
                                            src={takenextstep}
                                            alt=""
                                        />
                                    </picture>
                                </div>
                                <div className="mtop10 faq-min-height">
                                    <h3 className="f20 semibold mobf14">
                                        Talk to our investment advisor for stock recommendations <br />
                                    </h3>
                                </div>
                                <div className="text-right mtop20">
                                    <svg
                                        width={28}
                                        height={28}
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_4243_171381"
                                            style={{ maskType: "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x={0}
                                            y={0}
                                            width={28}
                                            height={28}
                                        >
                                            <rect width={28} height={28} fill="#C4C4C4" />
                                        </mask>
                                        <g mask="url(#mask0_4243_171381)">
                                            <path
                                                d="M16.3332 21L14.6998 19.3083L18.8415 15.1667H4.6665V12.8333H18.8415L14.6998 8.69167L16.3332 7L23.3332 14L16.3332 21Z"
                                                fill="#5B6471"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="d48 mobmtop20">
                            <div className="whitebx">
                                <div className="stepbx">
                                    <picture>
                                        {/* WebP source */}
                                        <source
                                            src={takenextstep1}
                                            type="image/webp"
                                        />
                                        {/* Fallback for browsers that do not support WebP */}
                                        <img
                                            src={takenextstep1}
                                            alt=""
                                        />
                                    </picture>
                                </div>
                                <div className="mtop10">
                                    <h3 className="f20 semibold mobf14">
                                        Get expert assistance for portfolio management
                                    </h3>
                                </div>
                                <div className="text-right mtop20">
                                    <svg
                                        width={28}
                                        height={28}
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_4243_171381"
                                            style={{ maskType: "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x={0}
                                            y={0}
                                            width={28}
                                            height={28}
                                        >
                                            <rect width={28} height={28} fill="#C4C4C4" />
                                        </mask>
                                        <g mask="url(#mask0_4243_171381)">
                                            <path
                                                d="M16.3332 21L14.6998 19.3083L18.8415 15.1667H4.6665V12.8333H18.8415L14.6998 8.69167L16.3332 7L23.3332 14L16.3332 21Z"
                                                fill="#5B6471"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mtop40" />
                <div id="accordion">
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How to create a mandate for an SIP?
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content accordion-mfd">
                        <div>
                            <p className="seoptag">
                                To create a autopay mandate for an SIP on Rise app, you will have to follow the given steps:-
                            </p>
                            <div className="mtop10">
                                <ol className="">
                                    <li> Select the mutual fund you wish to invest in and choose SIP as the mode of investment</li>
                                    <li> Click on Invest Now and choose your bank account on the next page</li>
                                    <li> Select Pay Via UPI as your payment type</li>
                                    <li> Complete the payment on the UPI app by entering your UPI pin.</li>
                                </ol>
                            </div>
                            {/* <div className="mtop10">
                                Repatriable Demat Account: Intended for Non-Resident Indians (NRIs)
                                who wish to transfer funds abroad. It requires an associated NRE bank
                                account.
                            </div>
                            <div className="mtop10">
                                Non-Repatriable Demat Account: Also for NRIs, but funds cannot be
                                transferred abroad. It requires an associated NRO bank account.
                            </div> */}
                            <p />
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            {" "}
                            How do I start my SIP? <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                To start your SIP, select your desired fund from available collections in the Mutual Fund section of Rise app, select SIP as the mode of investment on the order page and provide order details like the SIP frequency, SIP amount and start date. Set up a mandate by completing the payment through either netbanking or UPI and your SIP will be automatically created.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            {" "}
                            How to invest in mutual funds?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                To invest in mutual funds with expert recommendations, login to your account on Rise app, click on Mutual Fund option at the bottom of your screen and select a fund of your choice from available collections such as most popular funds, consistent compounders, highest rated funds etc. Click on Invest Now and enter your mode of investment (SIP/<span><a className='tradAccLink' href="https://www.motilaloswal.com/calculators/lumpsum-calculator" target="_blank">Lumpsum</a></span>) and amount. Complete the payment process and your investment is complete.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            What are different types of mutual funds?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                To cater to diverse investor preferences and financial goals, there are different types of mutual funds which include equity funds, debt funds, hybrid funds, sectoral (theme-based) funds, index funds, tax-saver <span><a className='tradAccLink' href="https://www.motilaloswal.com/calculators/elss-calculator" target="_blank">(ELSS)</a></span> funds etc. Each type of mutual fund serves a different purpose, enabling investors to make an investment decision based on their risk tolerance and return objectives.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            {" "}
                            What happens if I miss an SIP payment?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                Typically, mutual fund firms do not impose penalties for missing a few SIP (Systematic Investment Plan) installments. However, if you fail to make payments for three consecutive months, your SIP will be automatically terminated.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            What are the different modes of payment for mutual funds?
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                Netbanking and UPI are the available modes of payment for investing in mutual funds.
                            </p>
                            {/* <ul className="ul1 pl0 calculator-buulet">
                                <li>
                                    Download and install the MO Rise application on your mobile phone.
                                </li>
                                <li>Register using your mobile number and verify it with an OTP.</li>
                                <li>Register using your email ID and verify it with an OTP.</li>
                                <li>Enter your PAN Card details.</li>
                                <li>Provide your Bank details.</li>
                                <li>Add your Aadhaar details.</li>
                                <li>
                                    Electronically sign the application through Aadhaar-based OTP
                                    verification.
                                </li>
                                <li>Complete the DEMAT Account Activation process.</li>
                            </ul>
                            <div className="mtop10">
                                Alternatively, you can also log in through the web by visiting
                                www.motilaloswal.com/open-demat-account/ and following the same steps
                                as mentioned above.
                            </div>
                            <div className="mtop10">
                                Once your account is successfully activated, your Demat Account
                                credentials will be sent to your registered email ID.
                            </div> */}
                            <p />
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            What is the minimum amount required for an SIP?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                You can start investing in mutual funds through SIP mode with a monthly installment of as low as Rs. 500.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            What is the minimum amount required for a lumpsum investment?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                You can make a lumpsum investment of as low as Rs. 100 post your initial investment.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            What are the different modes of payment for SIPs?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                You can set up an autopay for your SIPs through UPI and netbanking both.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How to add mutual funds to my watchlist on Rise App?
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                To add mutual funds to your watchlist in Rise app, click on the search bar at the top of the screen, enter the fund name you wish to add to watchlist, click on the fund name to open the fund details page and click on the + icon next to the fund name to add your desired fund to your watchlist.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How to buy mutual funds on RISE app?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content accordion-mfd">
                        <div>
                            <p className="seoptag">
                                To buy Mutual Funds on Rise, follow the below givens steps:-
                            </p>
                            <div className="mtop10">
                                <ol className="">
                                    <li> Log in to your account</li>
                                    <li> Select 'Mutual Funds'</li>
                                    <li> Select from the wide variety of options available</li>
                                    <li> Select your type of investment - Lumpsum or SIP - and investment amount</li>
                                    <li> Confirm your choice and click on submit.</li>
                                    <li> Complete the payment through netbanking or UPI and the units will be credited to your account within 2 days.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How to redeem or sell mutual funds on MO App?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                To redeem or sell mutual funds unit, visit the Portfolio section on your app, select the mutual fund you wish to redeem, click on redeem and enter the number of units or amount. Confirm your order to complete your redemption process.
                            </p>
                        </div>
                    </div>

                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How can I track my mutual funds portfolio on MO App?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content accordion-mfd">
                        <div>
                            <p className="seoptag">
                                To track your mutual fund portfolio on the Rise app, follow the below given steps
                            </p>
                            <div className="mtop10">
                                <ol className="">
                                    <li> Log in to your account</li>
                                    <li> Go to 'Portfolio'</li>
                                    <li> Select 'Mutual Funds'</li>
                                    <li> All your investments in Mutual Funds Portfolio will be displayed.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-head">
                        <div className="dflex spacebetween">
                            How can I save taxes by investing in mutual funds?{" "}
                            <img src={downarrow} alt="" />
                        </div>
                    </div>
                    <div className="accordion-content">
                        <div>
                            <p className="seoptag">
                                You can save up to Rs. 46,800 in taxes every year by investing in ELSS Funds. To invest in ELSS Funds, visit the Mutual Funds section on your Rise app and select a fund from "Tax Saver" category.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSection18;

import Api from '.';
import { endpoints } from './endpoints';
import { getUniqueId, getTime } from '../helper/method';
import Cookies from '../lib/cookies';

export default class RegistrationApi extends Api {
    constructor() {
        super();
        this.cookie = new Cookies();
    }

    getCities(data) {//bind cities
        let url = this.buildUrl(endpoints.registration.getCities)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    openAccount(data) {//open Account
        let timeStamp = getTime();
        sessionStorage.tId = getUniqueId() + "_" + timeStamp;//Math.floor(1000 + Math.random() * 9000).toString();

        console.log('generated ekycSession', sessionStorage.ekycSession);

        this.cookie.removeCookie('silentLogin');

        let url = this.buildUrl(endpoints.registration.openAccount)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    checkMobileNumberStatus(data) {//check Mobile Status
        let url = this.buildUrl(endpoints.registration.checkMobileNumberStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validatePartnerCode(data) {//validate PartnerCode
        let url = this.buildUrl(endpoints.registration.validatePartnerCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    resumeApplication(data) {//resume Application
        let timeStamp = getTime();
        sessionStorage.tId = getUniqueId() + "_" + timeStamp;//Math.floor(1000 + Math.random() * 9000).toString();

        console.log('generated ekycSession', sessionStorage.ekycSession);

        this.cookie.removeCookie('silentLogin');

        let url = this.buildUrl(endpoints.registration.resumeApplication)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAuRmDetails = (data) => {
        let url = this.buildUrl(endpoints.registration.getAuRmDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getBaDiyDetails = (data) => {
        let url = this.buildUrl(endpoints.registration.getBaDiyDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getSchemeDetailsSTPMobile = (data) => {     // for mobile app as schemes are on registration page
        let url = this.buildUrl(endpoints.registration.getSchemeDetailsSTPMobile)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getCityFromIP = (data) => {
        // let url = this.buildUrl(endpoints.registration.getSchemeDetailsSTPMobile)
        let url = "https://api.ipstack.com/" + data + "?access_key=3921b8d9dc76b45efbad0ffc34002727&format=1";

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getTruecallerProfile = (data) => {
        let url = this.buildUrl(endpoints.registration.getTruecallerProfile)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    validateReferralCode = (data) => {
        let url = this.buildUrl(endpoints.registration.validateReferralCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    loadLeadTypes = (data) => {
        let url = this.buildUrl(endpoints.registration.loadLeadTypes)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    generateRedirectionUrl = (data) => {
        let url = this.buildUrl(endpoints.registration.generateRedirectionUrl)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    subBroker = (data) => {
        let url = this.buildUrl(endpoints.registration.subBroker)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    exportToExcel = (data) => {
        let url = this.buildUrl(endpoints.registration.exportToExcel)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    ekycApproveReject = (data) => {
        let url = this.buildUrl(endpoints.registration.ekycApproveReject)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getToken = (data) => {
        let timeStamp = getTime();
        sessionStorage.tId = getUniqueId() + "_" + timeStamp;//Math.floor(1000 + Math.random() * 9000).toString();

        console.log('generated ekycSession', sessionStorage.ekycSession);

        this.cookie.removeCookie('silentLogin');

        let url = this.buildUrl(endpoints.registration.getToken)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAuthForBlogs = (data) => {
        let url = this.buildUrl(endpoints.registration.getAuthForBlogs)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    encryptionForBlogs = (data) => {
        let url = this.buildUrl(endpoints.registration.encryptionForBlogs)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getBlogsApi = (data) => {
        let url = this.buildUrl(endpoints.registration.getBlogsApi)

        return this.fetch(url, "GET").then(response => response); // no body part is allowed in GET method
    }
}
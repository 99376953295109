import ProofApi from "../services/proofApi";
import { put, call } from 'redux-saga/effects';
import { saveProofList, updateAfterDeleteProof, saveBase64ProofContent, saveUploadProofData, showPanTextBox, gotoVerificationPage, saveValidationPan, saveValidateAccountAndIfsc, updateBankProof, clearPanTextBox, updateIsAdharXML, saveValidateAdharNo, updateAfterPanSiteRejection, setMicr } from "../redux/actions/proof";
import { setLoading, clearLoading } from "../redux/actions/loader";
import { apiErrorResponse, documentKeyForProof, userType } from '../helper/constant'
import { push } from 'connected-react-router';
import Snackbar from 'cogo-toast';
import { showSnackBar } from "../lib/snackBar";
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'
import { GET_PROOF_LIST, SET_MICR } from "../redux/types/proof";
import { GET_BANK_CHOICE_CHIPS } from '../redux/types/bank';
import { GET_INCOME_CHOICE_CHIPS } from '../redux/types/income'
import { isMobile } from 'react-device-detect';

let api = new ProofApi();

export function* uploadProof({ type, payload, resolve, reject }) {

    let responsePayload = {};

    try {
        let { data, applicationType } = payload;
        payload = data;

        if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.PAN) {
            // yield put(setLoading({ message: 'Validating your PAN Card' }));
            yield put(setLoading({ message: '' }));
        } else if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.BANKPROOFEQ) {
            // yield put(setLoading({ message: 'Validating cancelled cheque copy and retrieving your signature' }));
            yield put(setLoading({ message: '' }));
        } else {
            yield put(setLoading());
        }

        let response = yield api.uploadProof(payload)

        // if (isLoadingRequired) {
        yield put(clearLoading());
        // }

        if (response.data && response.data.Status) {

            resolve && resolve(response.data.Data);

        } else {
            resolve && resolve(response.data.Data);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}

export function* uploadProofUsEquity({ type, payload, resolve, reject }) {

    let responsePayload = {};

    try {
        let { data, applicationType } = payload;
        payload = data;

        if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.PAN) {
            // yield put(setLoading({ message: 'Validating your PAN Card' }));
            yield put(setLoading({ message: '' }));
        } else if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.BANKPROOFEQ) {
            // yield put(setLoading({ message: 'Validating cancelled cheque copy and retrieving your signature' }));
            yield put(setLoading({ message: '' }));
        } else {
            yield put(setLoading());
        }

        let response = yield api.uploadProofUsEquity(payload)

        // if (isLoadingRequired) {
        yield put(clearLoading());
        // }

        if (response.data && response.data.Status) {

            resolve && resolve(response.data.Data);

        } else {
            resolve && resolve(response.data.Data);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}

export function* uploadMultiPart({ type, payload, resolve, reject }) {

    let responsePayload = {};

    try {
        let { data, applicationType } = payload;
        payload = data;

        yield put(setLoading());

        let response = yield api.pdfUpload(payload)

        yield put(clearLoading());

        if (response.data && response.data.Status) {

            if (response.data.Data.Status === "2") {//success uploaded
                showSnackBar({
                    type: (response.data.Data.DocumentStatus === '0' || response.data.Data.DocumentStatus === '2') ? 'success' : 'error',
                    message: response.data.Data.Message
                })
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }

            resolve && resolve(response.data);

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}

export function* getProofList({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getProofList(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            if (Array.isArray(response.data.Data)) {
                // yield put(saveProofList(response.data.UPLOAD_PROOF_DETAILS));
                let documentsWithoutAdditionalProof = response.data.Data.filter(obj => obj.DOCUMENTKEY.indexOf('ADDITIONALPROOF') < 0)
                yield put(saveProofList(documentsWithoutAdditionalProof));
                resolve && resolve(documentsWithoutAdditionalProof);
            } else {
                responsePayload = { type: "error", message: apiErrorResponse }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            yield put(clearLoading());
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* deleteProof({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;
        payload = data;

        yield put(setLoading());

        let response = yield api.deleteProof(payload)

        yield put(clearLoading());

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* deleteProofDetailsUsEquity({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;
        payload = data;

        yield put(setLoading());

        let response = yield api.deleteProofDetailsUsEquity(payload)

        yield put(clearLoading());

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* viewProof({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;
        payload = data;

        let response = yield api.viewProof(payload)

        if (response.data && response.data.Status) {

            console.log('viewProof response', response.data)

            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            reject && reject({ message: apiErrorResponse });
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        // reject && reject(e)
    }
}

export function* viewUploadedImageUsEquity({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;
        payload = data;

        let response = yield api.viewUploadedImageUsEquity(payload)

        if (response.data && response.data.Status) {

            console.log('viewUploadedImageUsEquity response', response.data)

            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            reject && reject({ message: apiErrorResponse });
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        // reject && reject(e)
    }
}

export function* getProofVerificationStatus({ type, payload, resolve, reject }) {

}

export function* submitProof({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { type: applicationType, employeeId, tokenKey, isAllianceUser = false, ...reqPayload } = payload;
        let loadingMessageObj = isAllianceUser ? null : { message: "We are preparing the document for e-Signature with your data" };
        yield put(setLoading(loadingMessageObj));
        let response = yield api.submitProof(reqPayload);
        console.log("RESPONSE", response);
        yield put(clearLoading());

        if (response.data && response.data.Status) {

            if (response.data.Data[0].STATUS === '0') {
            }
            resolve && resolve(response.data.Data[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* submitOCRProof({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { type: applicationType, employeeId, tokenKey, isAllianceUser = false, ...reqPayload } = payload;
        let loadingMessageObj = isAllianceUser ? null : { message: "We are preparing the document for e-Signature with your data" };
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.submitOCRProof(reqPayload);
        console.log("RESPONSE", response);
        yield put(clearLoading());

        if (response.data && response.data.Status) {

            // if (response.data.Data[0].STATUS === '0') {
            // }
            resolve && resolve(response.data.Data[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* gotoVerificationPageApi({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.gotoVerificationPageApi(payload);

        yield put(clearLoading());

        if (response.data && response.data.Status) {//successful
            resolve && resolve(response.data.Data)
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }

    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}


export function* captureAdharXML({ type, payload }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.captureAdharXML(payload)
        console.log("Adhar", response.data);
        yield put(clearLoading());
        console.log('captureAdharXML response', response);
        if (response.data && response.data.Status) {
            window.location.href = response.data.Data.Capture_link;//existing tab with back option
            // window.replace(response.data.Capture_link);//existing tab with no back button
            // window.open(response.data.Capture_link, '_blank');//new tab
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* fetchAdharXML({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.fetchAdharXML(payload)
        console.log('fetchAdharXML response', response);

        if (response.data && response.data.Status) {

            resolve && resolve(response);
        } else {
            resolve && resolve(response);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* checkUncheckMobileAdharLink({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading());
        let response = yield api.checkUncheckMobileAdharLink(payload)
        // yield put(clearLoading());
        console.log('checkUncheckMobileAdharLink response', response);
        if (response.data && response.data.Status) {
            if (payload.ISAADHAARXML == "2") {
                yield put(updateIsAdharXML({ isAdharXML: payload.ISAADHAARXML }));
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* validateAccountAndIfsc({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading());
        // yield put(setLoading({ message: 'We are depositing ₹ 1 in your bank account to verify your account details.' }));
        yield put(setLoading({ message: '' }));
        let { callType, ...requiredPayload } = payload;//callType can be BANKPROOFEQ or  MARGINCHEQUEUPLOAD

        let response = yield api.validateAccountAndIfsc(requiredPayload)
        console.log("response", response.data);
        yield put(clearLoading());

        console.log('validateAccountAndIfsc response', response);
        if (response.data && response.data.Status) {

            // if (response.data.Data.BANKDETAILS[0].STATUS === "1" && response.data.Data.BANKDETAILS[0].MESSAGE !== "We could not verify your Bank Details. Please upload Bank Proof to proceed") {
            //     showSnackBar({
            //         type: 'success',
            //         message: response.data.Data.BANKDETAILS[0].MESSAGE
            //     })
            // } if (response.data.Data.BANKDETAILS[0].STATUS === "1" && response.data.Data.BANKDETAILS[0].MESSAGE === "We could not verify your Bank Details. Please upload Bank Proof to proceed") {

            //     showSnackBar({
            //         type: 'error',
            //         message: response.data.Data.BANKDETAILS[0].MESSAGE
            //     })
            // }
            if (response.data.Data.BANKDETAILS[0].STATUS === "1") {
                showSnackBar({
                    type: 'success',
                    message: response.data.Data.BANKDETAILS[0].MESSAGE
                })
            } else if (response.data.Data.BANKDETAILS[0].STATUS === "0") {

                showSnackBar({
                    type: 'error',
                    message: response.data.Data.BANKDETAILS[0].MESSAGE
                })
            }

            resolve && resolve(response.data.Data.BANKDETAILS[0]);

        } else {
            resolve && resolve(response.data.Data.BANKDETAILS[0]);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {

        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}

export function* validatePAN({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: 'Verifying your PAN Details' }));
        yield put(setLoading({ message: '' }));

        let response = yield api.validatePAN(payload)
        console.log("object", response);
        yield put(clearLoading());

        console.log('validatePAN response', response);

        // if (response.data.Data.Status === "1") {//success
        // } else if (response.data.Data.Status === "0") {//error
        //     showSnackBar({
        //         type: 'error',
        //         message: response.data.Data.Message
        //     })
        // }

        // resolve && resolve(response.data.Data);
        if (response.data.Status) {//success
            resolve && resolve(response.data.Data)
            // if (response.data.Data.Status === "1") {
            //     showSnackBar({
            //         type: 'success',
            //         message: response.data.Message,
            //         hideAfterSec: 8
            //     })
            // }
        } else {
            if (!response.data.Status) {//fail
                resolve && resolve(response.data.Data)
                if (response.data.Data.Status === "0") {
                    // alert("inside else if(err)")
                    showSnackBar({
                        type: 'error',
                        message: response.data.Message
                    })
                }
                return;
            }
        }



    } catch (e) {

        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        console.log('Something went wrong');
    }
}

export function* validatePanDob({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: 'Verifying your PAN Details' }));
        yield put(setLoading({ message: '' }));

        let response = yield api.validatePanDob(payload)
        console.log("object", response);
        yield put(clearLoading());

        console.log('validatePanDob response', response);

        if (response.data.Data.Status === "1") {//success
        } else if (response.data.Data.Status === "0") {//error
        }

        // resolve && resolve(response.data.Data);  

        //snackBar added 
        if (response.data.Status) {//success
            resolve && resolve(response.data.Data)
            console.log("Response--asdasd>", response.data.Data.IsKraCustomer)
            if (response.data.Data.IsKraCustomer === "1") {
                showSnackBar({
                    type: 'success',
                    message: response.data.Data.Message
                })
            }
            // if (response.data.Data.Status === "1" && response.data.Message !== "KYC not verified") {
            //     showSnackBar({
            //         type: 'success',
            //         message: response.data.Data.Message
            //     })
            // }
            // if (response.data.Message === "KYC not verified") {
            //     showSnackBar({
            //         type: 'error',
            //         message: response.data.Message
            //     })
            // }
        } else {//error
            resolve && resolve(response.data.Data)
            showSnackBar({
                type: 'error',
                message: response.data.Data.Message
            })
            return;
        }

    } catch (e) {

        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        console.log('Something went wrong');
    }
}

export function* verifyPanOnName({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: 'Verifying your PAN Details' }));
        yield put(setLoading({ message: '' }));

        let response = yield api.verifyPanOnName(payload)
        console.log("object", response);
        yield put(clearLoading());

        console.log('verifyPanOnName response', response);

        if (response.data.Data.Status === "1") {//success
        } else if (response.data.Data.Status === "0") {//error
        }

        // resolve && resolve(response.data.Data);  

        //snackBar added 
        if (response.data.Status) {//success
            resolve && resolve(response.data.Data)
            console.log("Response--asdasd>", response.data.Data.IsKraCustomer)
            if (response.data.Data.IsKraCustomer === "1") {
                showSnackBar({
                    type: 'success',
                    message: response.data.Data.Message
                })
            }
            // if (response.data.Data.Status === "1" && response.data.Message !== "KYC not verified") {
            //     showSnackBar({
            //         type: 'success',
            //         message: response.data.Data.Message
            //     })
            // }
            // if (response.data.Message === "KYC not verified") {
            //     showSnackBar({
            //         type: 'error',
            //         message: response.data.Message
            //     })
            // }
        } else {//error
            resolve && resolve(response.data.Data)
            showSnackBar({
                type: 'error',
                message: response.data.Data.Message
            })
            return;
        }

    } catch (e) {

        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        console.log('Something went wrong');
    }
}

export function* validateAadhaarApi({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.validateAadhaarApi(payload)
        console.log("object", response);
        yield put(clearLoading());

        console.log('validateAadhaarApi response', response);
        if (response.data && response.data.Status) {


            if (response.data.Data[0].STATUS === "1") {//success
                //yield put(saveValidateAdharNo({ adharNo: payload.AadhaarNo }))
                resolve && resolve(response.data.Data[0]);
            } else if (response.data.Data[0].STATUS === "0") {//error
                resolve && resolve(response.data.Data[0]);
                showSnackBar({
                    type: 'error',
                    message: response.data.Data[0].MESSAGE
                })
            }
        } else {

            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        }
    } catch (e) {

        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        console.log('Something went wrong');
    }
}

export function* validateEmailOTP({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));

        let response = yield api.validateEmailOTP(payload)
        yield put(clearLoading());

        console.log('validateEmailOTP response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            resolve && resolve(response.data.Data);
            // responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* resendEmailOTP({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.resendEmailOTP(payload)
        yield put(clearLoading());

        console.log('resendEmailOTP response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* addRemovePOA({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());

        let response = yield api.addRemovePOA(payload)
        yield put(clearLoading());

        console.log('addRemovePOA response in saga', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getMICR({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getMICR(payload)
        yield put(clearLoading());
        console.log('getMICR response in saga', response);
        if (response.data && response.data.Status) {
            yield put({ type: SET_MICR, payload: response.data.Data })
            resolve && resolve(response);
        } else {
            // responsePayload = { type: "error", message: response.data.Message }
            // yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(response);
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* deleteNominee({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.deleteNominee(payload)
        yield put(clearLoading());
        console.log('deleteNominee response in saga', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data[0]);
        } else {
            resolve && resolve(response.data.Data[0]);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}
export function* validateNomineePAN({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: 'We are verifying your PAN' }))
        yield put(setLoading({ message: '' }));
        let response = yield api.validateNomineePAN(payload)
        console.log("object", response);
        yield put(clearLoading());
        console.log('validateNomineePAN response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            resolve && resolve(response.data.Data);
            responsePayload = { type: "error", message: response.data.Message }
            if (!isMobile) {
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* captureLivePhoto({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.captureLivePhoto(payload)
        console.log("object", response);
        console.log('captureLivePhoto response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* saveLivePhoto({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.saveLivePhoto(payload)
        console.log("object", response);
        console.log('saveLivePhoto response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            resolve && resolve(response);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* digiLockerRequest({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.digiLockerRequest(payload)
        console.log("object", response);
        console.log('digiLockerRequest response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* fairXpayKycRequestRedirectionSuperApp({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.fairXpayKycRequestRedirectionSuperApp(payload)
        console.log("object", response);
        console.log('fairXpayKycRequestRedirectionSuperApp response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* digiLockerResponse({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.digiLockerResponse(payload)
        console.log("object", response);
        console.log('digiLockerResponse response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            resolve && resolve(response);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* shareLink({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.shareLink(payload)
        console.log("object", response);
        console.log('shareLink response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getPanDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getPanDetails(payload)
        console.log("object", response);
        console.log('getPanDetails response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data.PANDETAILS[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}
export function* getBankList({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getBankList(payload)
        console.log("object", response);
        console.log('getBankList response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            console.log("getBankList-->", response.data.Data)
            resolve && resolve(response.data.Data);
            // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong-->', e);
    }
}

export function* getBankListByIfsc({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getBankListByIfsc(payload)
        console.log("object", response);
        console.log('getBankListByIfsc response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            console.log("getBankListByIfsc-->", response.data.Data)
            resolve && resolve(response.data.Data);
            // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong-->', e);
    }
}

export function* getMicrBasedOnBankBranch({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getMicrBasedOnBankBranch(payload)
        console.log("object", response);
        console.log('getMicrBasedOnBankBranch response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            console.log("getMicrBasedOnBankBranch-->", response.data.Data)
            resolve && resolve(response.data.Data);

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong-->', e);
    }
}

export function* getCmrCopyDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getCmrCopyDetails(payload)
        console.log("object", response);
        console.log('getCmrCopyDetails response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            console.log("getCmrCopyDetails-->", response.data.Data)
            resolve && resolve(response.data.Data);
            // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong-->', e);
    }
}

export function* getPANBankDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getPANBankDetails(payload)
        console.log("object", response);
        console.log('getPANBankDetails response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            console.log("getpanBankDetails-->", response.data.Data)
            resolve && resolve(response.data.Data);
            yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong-->', e);
    }
}

export function* getAadharDetails({ type, payload, resolve, reject }) {
    console.log(type, ' ', payload, ' sagas/proof.js getAadharDetails');
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAadharDetails(payload)
        console.log(response, ' sagas/proof.js/getAadharDetails response');
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getClientPhoto({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getClientPhoto(payload)
        console.log("object", response);
        console.log('getClientPhoto response', response.data.Data);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data.USERPHOTO[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* updateProofStage({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.updateProofStage(payload)
        console.log("object", response);
        console.log('updateProofStage response', response.data.Data);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data.STAGEDETAILS[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* insertUpdateStageUsEquity({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.insertUpdateStageUsEquity(payload)
        console.log("object", response);
        console.log('insertUpdateStageUsEquity response', response.data.Data);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getClientSignature({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getClientSignature(payload)
        console.log('getClientSignature response', response.data.Data);
        yield put(clearLoading());
        resolve && resolve(response.data.Data);
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getBankDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getBankDetails(payload)
        console.log('getBankDetails response', response.data.Data);
        yield put(clearLoading());
        // replace this part with line 865 to 871 an rename
        resolve && resolve(response.data.Data);
        yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })
        // until here
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getOneMoneyBankDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getOneMoneyBankDetails(payload)
        console.log('getOneMoneyBankDetails response', response.data.Data);
        yield put(clearLoading());
        // replace this part with line 865 to 871 an rename
        resolve && resolve(response.data.Data);
        // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })
        // until here
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* updateMobileNumber({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.updateMobileNumber(payload)
        console.log('updateMobileNumber response', response.data.Data);
        yield put(clearLoading());
        // replace this part with line 865 to 871 an rename
        resolve && resolve(response.data.Data);
        // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })
        // until here
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* OneMoneyRedirection({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.OneMoneyRedirection(payload)
        console.log('OneMoneyRedirection response', response.data.Data);
        yield put(clearLoading());
        // replace this part with line 865 to 871 an rename
        resolve && resolve(response.data.Data);
        // yield put({ type: GET_BANK_CHOICE_CHIPS, payload: response.data.Data.BANKPROOFDETAILS })
        // until here
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* deleteBankDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.deleteBankDetails(payload)
        console.log('deleteBankDetails response', response.data.Data);
        yield put(clearLoading());

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* insertNriFlag({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.insertNriFlag(payload)
        console.log('insertNriFlag response', response.data.Data[0]);
        yield put(clearLoading());
        // resolve && resolve(response.data.Data);
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* moveBackwordStatus({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.moveBackwordStatus(payload)
        console.log('moveBackwordStatus response', response.data.Data);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data.USERMOVEBACKWARDSTATUS[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getAadhaarConsent({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAadhaarConsent(payload)
        console.log('getAadhaarConsent response', response.data.Data);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data.USERMOVEBACKWARDSTATUS[0]);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* validateDpIdApi({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.validateDpIdApi(payload)
        console.log("object", response);
        yield put(clearLoading());

        console.log('validateDpIdApi response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data.Status === "1") {//success
                // yield put(saveValidateAdharNo({ adharNo: payload.AadhaarNo }))
                resolve && resolve(response.data.Data);
            } else if (response.data.Data.Status === "0") {//error
                resolve && resolve(response.data.Data);
                showSnackBar({
                    type: 'error',
                    message: response.data.Data.Message
                })
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* hypervergeLivePhotoRequest({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.hypervergeLivePhotoRequest(payload)
        console.log('hypervergeLivePhotoRequest response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* ainxtLivePhotoRequest({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.ainxtLivePhotoRequest(payload)
        console.log('ainxtLivePhotoRequest response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* ainxtLivePhotoResponse({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.ainxtLivePhotoResponse(payload)
        console.log('ainxtLivePhotoResponse response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* hypervergeLivePhotoResponse({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.hypervergeLivePhotoResponse(payload)
        console.log('hypervergeLivePhotoResponse response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            resolve && resolve(response);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* insertUpdateSmallCasesConsent({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.insertUpdateSmallCasesConsent(payload)
        console.log('insertUpdateSmallCasesConsent response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            resolve && resolve(response);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}
export function* livePhotoLocationDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.livePhotoLocationDetails(payload)
        console.log("object", response);
        console.log('livePhotoLocationDetails response', response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getProofDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getProofDetails(payload)
        console.log("object", response);
        console.log('getProofDetails response', response.data.Data.INCOMEPROOFDROPDOWNVALUES);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
            yield put({ type: GET_INCOME_CHOICE_CHIPS, payload: response.data.Data.INCOMEPROOFDROPDOWNVALUES })
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getProofDetailsUsEquity({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getProofDetailsUsEquity(payload)
        console.log("object", response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* getAadhaarDetailSuperApp({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAadhaarDetailSuperApp(payload)
        console.log("object", response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* aadhaarLinksUsEquity({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.aadhaarLinksUsEquity(payload)
        console.log("object", response);
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* ainxtDigiLockerRequest({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.ainxtDigiLockerRequest(payload)
        console.log('ainxtDigiLockerRequest response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* ainxtDigilockerResponse({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.ainxtDigilockerResponse(payload)
        console.log('ainxtDigilockerResponse response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(response);
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* validatePansign({ type, payload, resolve, reject }) {

    let responsePayload = {};

    try {
        let { data, applicationType } = payload;
        payload = data;

        if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.PAN) {
            // yield put(setLoading({ message: 'Validating your PAN Card' }));
            yield put(setLoading({ message: '' }));
        } else if (applicationType !== userType.alliance && payload.FileType === documentKeyForProof.BANKPROOFEQ) {
            // yield put(setLoading({ message: 'Validating cancelled cheque copy and retrieving your signature' }));
            yield put(setLoading({ message: '' }));
        } else {
            yield put(setLoading());
        }

        let response = yield api.validatePansign(payload)

        // if (isLoadingRequired) {
        yield put(clearLoading());
        // }

        if (response.data && response.data.Status) {

            resolve && resolve(response.data.Data);

        } else {
            resolve && resolve(response.data.Data);
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}


export function* updateOneMoneyStatus({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;


        let response = yield api.updateOneMoneyStatus(payload)
        console.log('updateOneMoneyStatus response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(response);
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* saveRevPennyDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;


        let response = yield api.saveRevPennyDetails(payload)
        console.log('saveRevPennyDetails response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(response);
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}

export function* updateMFDFlag({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let { data, applicationType } = payload;


        let response = yield api.updateMFDFlag(payload)
        console.log('updateMFDFlag response', response);
        if (response.data && response.data.Status) {
            resolve && resolve(response);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            resolve && resolve(response);
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log('Something went wrong');
    }
}


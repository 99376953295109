import CongratulationApi from "../services/congratulationApi";
import { put } from 'redux-saga/effects';
import { DOWNLOAD_PDF_FORM } from '../redux/types/congratulation';
import { setLoading, clearLoading } from "../redux/actions/loader";
import { showSnackBar } from "../lib/snackBar";

let api = new CongratulationApi();

export function* downloadPdfForm({ type, payload, resolve, reject }) {
    try {

        yield put(setLoading());
        let { data } = yield api.downloadPdfForm(payload)
        console.log(data);
        yield put(clearLoading());
        resolve(data);
        if (data) {
            console.log(" API response=>", data);
            var popup_window = window.open(data, '_blank');
            try {
                popup_window.focus();
            } catch (e) {
                showSnackBar({
                    message: 'Pop-up Blocker is enabled! Please add this site to your exception list.'
                })

            }
            //window.open(data, '_blank');//new tab
        } else {

            reject && reject({
                message: 'Something went wrong'
            });
        }
    } catch (e) {
        yield put(clearLoading());
        // console.log(e, 'error in getEsignDetails');
        reject && reject(e);
    }
}

export function* addRefral({ type, payload, resolve, reject }) {
    try {

        yield put(setLoading());
        let data = yield api.addRefral(payload)
        console.log(data);
        yield put(clearLoading());
        if (data) {

            resolve(data);

        } else {

            yield put(clearLoading());
            showSnackBar({
                message: 'Invalid Entry'
            })
            reject && reject({
                message: 'Something went wrong'
            });
        }
    } catch (e) {
        yield put(clearLoading());
        // console.log(e, 'error in getEsignDetails');
        reject && reject(e);
    }
}



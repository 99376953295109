import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'

class RegSection12 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="whatisproduct-sec">
          <div className="cms">
            <h2>
              <div className="text-center line1">
                <span className="lightgrey f32 font-semi-bold mobf24">Who can open a</span>
              </div>
              <div className="text-center line1">
                <span className="f32 font-semi-bold mobf24"> Demat Account in India?
                </span>
              </div>
            </h2>
            <div className="mtop30" />
            <p>
              <b className='boldfaq'>1. Individual Resident: </b> An individual who has been physically present in India
              for 182 days or more in the taxing year, or stays in India for at least 365 days
              in the immediately preceding four years and 60 days or more in the relevant financial year.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>2. Hindu Undivided Family (HUF): </b>A family comprising all individuals descended linearly
               from a common ancestor, including the male ancestors' wives and daughters. The Karta, typically 
               the oldest male family member,
               is the signatory authority for HUF Demat Accounts.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>3. Domestic Corporation: </b>A business operating in its home country,
               subject to specific regulations and tariffs.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>4. Overseas Indians:</b> 
              {" "}
              <a
                href="https://www.motilaloswal.com/nri-demat-account"
                className="seohreflink"
                target="_blank"
              >
                Non-Resident Indians
              </a>{" "}(NRIs) can also open Demat Accounts
               by mentioning their NRI status in the account opening form.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>5. Clearing Member: </b>A broker's portfolio where client's securities are kept, 
              as well as where securities from central depositories are collected by the broker.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default RegSection12;
import React from 'react';
import calender from '../../../assets/images/calender.svg';
import classNames from 'classnames';
import { accountMinLength } from '../../../helper/constant';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { isMobile } from 'react-device-detect';

const Input = (props) => {
    let {
        onChange,
        onBlur,
        onKeyPress,
        onKeyUp,
        nomineeType,
        placeholder,
        showPanCalenderIcon,
        customClaenderOuterClass,
        handleCalender,
        otpTimer,
        showOtp,
        validPan,
        customIfsClass,
        customDobClass,
        PanProofStatus,
        searchLabel,
        handleIfscSearchClick,
        accountNumber,
        showChoiceOptions,
        choiceLabel,
        handleClickInside,
        handleCloseToggle,
        showCalender,
        dob,
        bankProofStatus,
        IncomeProofStatus,
        panNumber,
        isDobNonEditable,
        isAllianceUser,
        handlePanNameVerify,
        ...requiredProps
    } = props;

    console.log("nomineeType in Input", props.name, nomineeType);

    const ref = useDetectClickOutside({
        onTriggered: handleCloseToggle,
        // disableClick: true
    })

    const handleChange = (e) => {
        // e.preventDefault();
        let target = e.currentTarget;
        console.log("target=>", target);
        let { name, value: valueToPass } = target
        if (target.type === 'checkbox') {
            valueToPass = target.checked;
        }
        onChange && onChange({ name, value: valueToPass, nomineeType })
    }

    const handleBlur = (e) => {
        let target = e.currentTarget;
        let { name, value: valueToPass, isRequired = true } = target
        if (target.type === 'checkbox') {
            valueToPass = target.checked;
        }

        onBlur && onBlur({ name, value: valueToPass, isRequired, nomineeType })
    }

    const handleKeyUp = (e) => {
        onKeyUp && onKeyUp(e);
    }

    const handleKeyPress = (e) => {
        onKeyPress && onKeyPress(e);
    }

    const handleKeyDown = (e) => {
        console.log('handle Key down', e.key)
        let key = e.key;
        if (key === 'ArrowUp' || key === 'ArrowDown') {
            e.preventDefault();
        }
        // onKeyPress && onKeyPress({ event: e, key: key });  
    }
    const handleCalenderIconClick = () => {
        handleClickInside()
    }
    const handleIfscClick = () => {
        handleIfscSearchClick()
    }

    return (
        <>
            {customClaenderOuterClass ?
                <div className={customClaenderOuterClass ? customClaenderOuterClass : ""}>
                    <input
                        {...requiredProps} autoComplete={'off'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        placeholder={placeholder}
                        // disabled={bankProofStatus === "2" ? true : IncomeProofStatus === "2" ? true : false}
                        // className="date-input form-control" 
                        className={classNames('date-input form-control', { [customDobClass]: customDobClass })}
                    />
                    {showPanCalenderIcon && <img ref={isMobile ? null : ref} src={calender} onClick={(validPan === "1" && isDobNonEditable !== "1" && panNumber.length === 10) && handleCalenderIconClick} alt="" />}
                    {showChoiceOptions && <span>{choiceLabel}</span>}
                </div> :
                otpTimer ?
                    <div className="datebox">
                        <input
                            {...requiredProps} autoComplete={'off'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            onKeyDown={handleKeyDown}
                            onKeyUp={handleKeyUp}
                            placeholder={placeholder}
                            className="date-input form-control"
                        />
                        {showOtp.length === 10 && <small className='otptext' >Resend OTP in {otpTimer} seconds</small>}
                    </div>
                    :
                    searchLabel ?
                        <>
                            <div class="ifscbox">
                                <input
                                    {...requiredProps} autoComplete={'off'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyPress={handleKeyPress}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    placeholder={placeholder}
                                    // className="date-input form-control"
                                    className={classNames(`date-input form-control ${customIfsClass ? customIfsClass : customDobClass} `)}
                                />
                                <a href={accountNumber && accountNumber.length < accountMinLength ? "#" : "#" + "findIfsc"} onClick={handleIfscClick} ><span>{searchLabel}</span></a>
                            </div>
                        </>
                        :
                        props.showPanNameVerify ?
                            <div className='NewPanName'>
                                <input
                                    {...requiredProps} autoComplete={'off'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyPress={handleKeyPress}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    placeholder={placeholder}
                                    // className="date-input form-control"
                                    className={classNames(`date-input form-control ${customIfsClass ? customIfsClass : customDobClass} `)}
                                />
                                {props.showPanNameVerify ? <p onClick={handlePanNameVerify}>Verify</p> : ""}
                            </div>
                            :
                            <input
                                {...requiredProps} autoComplete={'off'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                                placeholder={placeholder}
                                // className="date-input form-control"
                                className={classNames(`date-input form-control ${customIfsClass ? customIfsClass : customDobClass} `)}
                            />

            }
        </>

    )
}
export default Input
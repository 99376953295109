export const PAYMENT_INIT = "PAYMENT_INIT";
export const PAYMENT_INIT_RESPONSE = "PAYMENT_INIT_RESPONSE";
export const PAYMENT_INIT_RESPONSE_FAIL = "PAYMENT_INIT_RESPONSE_FAIL";

export const PAYMENT_STATUS = "PAYMENT_STATUS";
export const PAYMENT_STATUS_RESPONSE = "PAYMENT_STATUS_RESPONSE";
export const PAYMENT_STATUS_FAIL = "PAYMENT_STATUS_FAIL";

export const SEND_CHEQUE_APPROVAL = "SEND_CHEQUE_APPROVAL";
export const SEND_CHEQUE_APPROVAL_RESPONSE = "SEND_CHEQUE_APPROVAL_RESPONSE";
export const SEND_CHEQUE_APPROVAL_FAIL = "SEND_CHEQUE_APPROVAL_FAIL";

export const REQUEST_CHEQUE_STATUS = "REQUEST_CHEQUE_STATUS";
export const REQUEST_CHEQUE_STATUS_RESPONSE = "REQUEST_CHEQUE_STATUS_RESPONSE";
export const REQUEST_CHEQUE_STATUS_FAIL = "REQUEST_CHEQUE_STATUS_FAIL";

export const SKIP_OR_CHEQUE_PAYMENT = "SKIP_OR_CHEQUE_PAYMENT";
export const SKIP_OR_CHEQUE_PAYMENT_RESPONSE = "SKIP_OR_CHEQUE_PAYMENT_RESPONSE";
export const SKIP_OR_CHEQUE_PAYMENT_FAIL = "SKIP_OR_CHEQUE_PAYMENT_FAIL";

export const GET_FUND_TRANSFER_DETAILS = "GET_FUND_TRANSFER_DETAILS";

export const GET_SCHEME_DETAILS_DIRECT = "GET_SCHEME_DETAILS_DIRECT"

export const VALIDATE_DIRECT_LOGIN = "VALIDATE_DIRECT_LOGIN";

export const INSERT_UPDATE_SELECTED_SEGMENT = "INSERT_UPDATE_SELECTED_SEGMENT";

import React, { Component } from 'react'
import icon1 from '../../../assets/images/openDemat/icon-1.svg';
import icon2 from '../../../assets/images/openDemat/icon-2.svg';
import icon3 from '../../../assets/images/openDemat/icon-3.svg';
import icon4 from '../../../assets/images/openDemat/icon-4.svg';
import icon5 from '../../../assets/images/openDemat/icon-5.svg';
import icon6 from '../../../assets/images/openDemat/icon-6.svg';
import icon7 from '../../../assets/images/openDemat/icon-7.svg';
import Carousel from "react-elastic-carousel";
import Item from './Item'

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
]
class RegProcedureList extends Component {
    render() {
        return (
            <section class="section-spac top-p-none" id="demos">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8">
                            <div class="top-title text-center conbox">
                                {/* <h2 className="title text-center"><span> Make Better Investments for Life with Motilal Oswal</span></h2> */}
                                <h2> Make Better Investments for Life with Motilal Oswal</h2>
                                <p className="mar-tp-10 text-center para">It is one demat account with multiple benefits.<br /> Investors can invest in a variety of asset classes through Motilal Oswal Demat Account</p>
                            </div>
                            <div class="large-12 columns">
                                <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={3000}>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon1} width="65" height="65" alt="" />
                                            </div>
                                            <h3 className="common-header">Demat Account </h3>
                                            <p className="common-para">An Online DEMAT Account to safeguard all your financial securities. Get a chance to avail exciting offers on the go, only with Motilal Oswal.</p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/open-demat-account" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon2} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">Trading Account </h3>
                                            <p className="common-para">Trade or invest easily in 5000+ stocks in 1-click with online
                                                Trading Account. Download specialized apps MO Investor & MO Trader for better experience.</p>
                                            <p className="mtop10">
                                                <a href="https://invest.motilaloswal.com/" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon3} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">Upcoming IPO </h3>
                                            <p className="common-para">Invest in exciting upcoming IPOs for FREE with online Motilal Oswal DEMAT Account.
                                                Invest in IPOs in 1-click through easy UPI option with MO Investor.  </p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/invest-in-upcoming-ipo" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon4} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">IAP </h3>
                                            <p className="common-para">Intelligent Advisory Portfolios are expert curated ready to invest portfolios created with intelligent algorithms and expert stock market investment ideas.</p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/intelligent-advisory-portfolio" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon5} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">Improve My Portfolio </h3>
                                            <p className="common-para">Does your portfolio hold the right stocks?
                                                Get Stock Market Investment Ideas for perfect investment portfolio with expert selected stocks.</p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/improve-your-investment-portfolio" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon6} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">Start SIP </h3>
                                            <p className="common-para">Don't have a lump sum amount to invest? Start a SIP. Easy investment in mutual funds SIP and equities. SIP investment to make better portfolio. </p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/invest-in-sip" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <div className="item">
                                            <div>
                                                <img className="img-box" src={icon7} width="60" height="60" alt="" />
                                            </div>
                                            <h3 className="common-header">Suggest Me Tool </h3>
                                            <p className="common-para">Confused with so many options of investments? Ask the expert. Get clarified investment objectives, investment advice and more, in a click.</p>
                                            <p className="mtop10">
                                                <a href="https://www.motilaloswal.com/tell-us-your-investment-objective" className="readmore common-para">Know More</a>
                                            </p>
                                        </div>
                                    </Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RegProcedureList;
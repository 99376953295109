import { produce } from 'immer';
import { SET_HOME_PAGE_QUERY_PARAMS } from '../types/homePageQueryParams';
import { regFormType } from '../../helper/constant';

const initialState = {
    formTypeCommingFromParams: regFormType.openAccount
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_HOME_PAGE_QUERY_PARAMS:
            return { ...state, ...payload };

        default:
            return state
    }
})

export default reducer;

export const GET_ESIGN_DETAILS = "GET_ESIGN_DETAILS";
export const GET_ESIGN_DETAILS_KRA = "GET_ESIGN_DETAILS_KRA";
export const GET_ESIGN_DETAILS_DDPI = "GET_ESIGN_DETAILS_DDPI";
export const GET_ESIGN_DETAILS_RESPONSE = "GET_ESIGN_DETAILS_RESPONSE";
export const GET_ESIGN_DETAILS_FAIL = "GET_ESIGN_DETAILS_FAIL";

export const GET_AUTH_MODE = "GET_AUTH_MODE";
export const GET_AUTH_MODE_KRA = "GET_AUTH_MODE_KRA";
export const GET_AUTH_MODE_DDPI = "GET_AUTH_MODE_DDPI";
export const GET_AUTH_MODE_RESPONSE = "GET_AUTH_MODE_RESPONSE";
export const GET_AUTH_MODE_FAIL = "GET_AUTH_MODE_FAIL";

export const FAIR_X_PAY_REQUEST = "FAIR_X_PAY_REQUEST";
export const FAIR_X_PAY_RESPONSE = "FAIR_X_PAY_RESPONSE";


export const GET_AVAILABLE_CLIENTCODE = "GET_AVAILABLE_CLIENTCODE";
export const GET_AVAILABLE_CLIENTCODE_RESPONSE = "GET_AVAILABLE_CLIENTCODE_RESPONSE";
export const GET_AVAILABLE_CLIENTCODE_FAIL = "GET_AVAILABLE_CLIENTCODE_FAIL";

export const GENERATE_CLIENTCODE = "GENERATE_CLIENTCODE";
export const GENERATE_CLIENTCODE_RESPONSE = "GENERATE_CLIENTCODE_RESPONSE";
export const GENERATE_CLIENTCODE_FAIL = "GENERATE_CLIENTCODE_FAIL";

export const EMUDRA = "EMUDRA";
export const EMUDRA_KRA = "EMUDRA_KRA";
export const EMUDRA_DDPI = "EMUDRA_DDPI";
export const EMUDRA_RESPONSE = "EMUDRA_RESPONSE";
export const EMUDRA_FAIL = "EMUDRA_FAIL";

export const GET_EMUDRA = "GET_EMUDRA";
export const GET_EMUDRA_KRA = "GET_EMUDRA_KRA";
export const GET_EMUDRA_DDPI = "GET_EMUDRA_DDPI";
export const GET_EMUDRA_RESPONSE = "GET_EMUDRA_RESPONSE";
export const GET_EMUDRA_FAIL = "GET_EMUDRA_FAIL";

export const PUSH_DATA_IN_DRISHTI = "PUSH_DATA_IN_DRISHTI";
export const PUSH_DATA_IN_DRISHTI_RESPONSE = "PUSH_DATA_IN_DRISHTI_RESPONSE";
export const PUSH_DATA_IN_DRISHTI_FAIL = "PUSH_DATA_IN_DRISHTI_FAIL";

export const CHECK_EMUDHRA = "CHECK_EMUDHRA";
export const CHECK_EMUDHRA_RESPONSE = "CHECK_EMUDHRA_RESPONSE";
export const CHECK_EMUDHRA_FAIL = "CHECK_EMUDHRA_FAIL";

export const CHECK_VERIFIED = "CHECK_VERIFIED";
export const CHECK_VERIFIED_RESPONSE = "CHECK_VERIFIED_RESPONSE";
export const CHECK_VERIFIED_FAIL = "CHECK_VERIFIED_FAIL";
export const GENERATECLIENTCODE_MF = "GENERATECLIENTCODE_MF";



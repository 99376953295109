import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import verifyOtpEmail from './verifyOtpEmail';
import user from './user';
import esign from './esign'
import loader from './loader';
import proof from './proof';
import personalInfo from './personalInfo';
import scheme from './scheme';
import payment from './payment';
import auRmDetails from './auRmDetails';
import homePageQueryParams from './homePageQueryParams';
import responseError from './responseError'
import history from '../../lib/history';
import { enableES5 } from 'immer';
import nominee from './nominee'
import feedback from './feedback'
import analytics from './analytics';
import bank from './bank';
import income from './income';

enableES5();
let historyR = !process.env.IS_SERVER ? { router: connectRouter(history) } : {};

export default combineReducers({
  ...historyR,
  verifyOtpEmail,
  user,
  esign,
  proof,
  personalInfo,
  loader,
  scheme,
  payment,
  auRmDetails,
  homePageQueryParams,
  responseError,
  nominee,
  feedback,
  analytics,
  bank,
  income
})

export const OFFER_REDEEM = "OFFER_REDEEM";
export const INIT_OFFER = "INIT_OFFER";
export const INIT_SKIP = "INIT_SKIP";

export const OFFER_PAYMENT_STATUS = "OFFER_PAYMENT_STATUS";
export const PAYMENT_STATUS_RESPONSE = "PAYMENT_STATUS_RESPONSE";
export const PAYMENT_STATUS_FAIL = "PAYMENT_STATUS_FAIL";

export const SAVE_OFFER_REDEEM = "SAVE_OFFER_REDEEM";
export const SAVE_INIT_OFFER = "SAVE_INIT_OFFER";
export const SAVE_INIT_SKIP = "SAVE_INIT_SKIP";

export const DOWNLOAD_ESIGN_PDF_FORM = "DOWNLOAD_ESIGN_PDF_FORM";

export const GET_OFFER_LIST = "GET_OFFER_LIST";
export const REGISTER_WALLET_OFFER = "REGISTER_WALLET_OFFER";

export const GET_VALUEPACK_DETAILS = "GET_VALUEPACK_DETAILS";

export const INITIATE_VALUEPACK = "INITIATE_VALUEPACK";

export const UPDATE_VALUEPACK = "UPDATE_VALUEPACK";
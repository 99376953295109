import Api from '.';
import { endpoints } from './endpoints';

export default class PersonalApi extends Api {

    getPersonalInfo(data) {//get Personal Info for tell me about yourself
        let url = this.buildUrl(endpoints.personalInfo.getPersonalInfo)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getIncomeProofDetails(data) {
        let url = this.buildUrl(endpoints.personalInfo.getIncomeProofDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updatePersonalInfo(data) {//update Personal Info for tell me about yourself
        let url = this.buildUrl(endpoints.personalInfo.updateDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updatePersonalOtherDetails(data) {//update Personal Info for aadhar other details
        let url = this.buildUrl(endpoints.personalInfo.updatePersonalOtherDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getPersonalDetailsUsEquity(data) {
        let url = this.buildUrl(endpoints.personalInfo.getPersonalDetailsUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updatePersonalDetailsUsEquity(data) {//update Personal Info for tell me about yourself
        let url = this.buildUrl(endpoints.personalInfo.updatePersonalDetailsUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

}
import Api from '.';
import { endpoints } from './endpoints';

export default class SchemeApi extends Api {

    getSchemeDetailsBranch(data) {//terms and consition data
        let url = this.buildUrl(endpoints.verification.schemeDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getUserPaymentDetails(data) {
        let url = this.buildUrl(endpoints.verification.getUserPaymentDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    onSchemeChangeBrach(data) {//terms and consition data
        let url = this.buildUrl(endpoints.verification.onSchemeChange)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveSchemeDetailsBrach(data) {//terms and consition data
        let url = this.buildUrl(endpoints.verification.saveSchemeDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    saveSchemeDetailsDirect(data) {//terms and consition data
        let url = this.buildUrl(endpoints.verification.saveSchemeDetailsDirect)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

}
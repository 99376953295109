import { produce } from 'immer';
import {
    SET_VERIFY_OTP, SET_VERIFY_EMAIL,
    SET_VERIFY_OTP_ERROR,
    SET_VERIFY_EMAIL_ERROR
} from '../types/verifyOtpEmail';
import { INIT_SET_USER_RESPONSE } from '../types/user';

const initialState = {
    otp: '',
    otpError: '',
    email: '',
    emailError: ''
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_VERIFY_OTP:
            state.otp = payload;
            break;
        case SET_VERIFY_OTP_ERROR:
            state.otpError = payload;
            break;
        case SET_VERIFY_EMAIL:
            state.email = payload;
            break;
        case SET_VERIFY_EMAIL_ERROR:
            state.emailError = payload;
            break;
        case INIT_SET_USER_RESPONSE:
            state.email = payload.email;
            break;
        default:
            return state
    }
})

export default reducer;

import React, { Component, useState } from 'react';
import Slider from "react-slick";
import $ from "jquery";

const MFDSection2 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });
        
    const settings = {
        infinite: true,
        slidesToShow: 2,
        dots: true,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024, // 1024px or larger
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768, // 768px or larger
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480, // 480px or larger
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        
        <>
          <section className="sectionpad">
                <div class="text-center">
                    <h2 class="textlabel"><span class="f32 semibold line1 mobf24">
                        Did you know?
                        </span>
                    </h2>
                </div>
                <div class="mtop60"></div>
                {/* <div className="mtop60" /> */}
                <div className="slider">
                    <Slider {...settings}>
                        <div>
                            <div class="whiteslide text-center">
                                <p class="f18 mobf16">India is the world's
                                    2nd largest mutual fund industry with an AUM of Rs. 50 Lakh Crores
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="whiteslide text-center">
                                <div>
                                    <p class="f18 mob16">
                                        There are more than 45 AMCs in India with 1500+ funds
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="whiteslide text-center">
                                <div>
                                    <p class="f18 mob16">
                                        Over 46 million Indian households have invested in mutual funds
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="whiteslide text-center">
                                <div>
                                    <p class="f18 mob16">
                                        There are over 62 Million active SIPs in India
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>

    );
}

export default MFDSection2;

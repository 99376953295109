import React, { Component } from 'react';
import RegInput from '../../molecules/regInput';
import RegMobileNo from '../../molecules/regMobileNo';
import { isValidAlphaNumeric, isValidMobileNo, isValidName, isValidOtp, fetchWithWait } from "../../../helper/method"
import produce from 'immer';
import { connect } from 'react-redux';
import RegistrationApi from '../../../services/registrationApi';
import { apiErrorResponse, userType } from '../../../helper/constant';
import { resendOtp, setUser } from '../../../redux/actions/user';
import { setLoading, clearLoading } from '../../../redux/actions/loader';
import { setResponseErrorSnack } from '../../../redux/actions/responseError';
import { capitalCase } from 'change-case';
import { isMobile } from 'react-device-detect';
import $ from 'jquery';
import history from "../../../lib/history";
import firebase from "../../molecules/firebase";


const otpLength = 4;
let api = new RegistrationApi();
let TRACK_URL = process.env.REACT_APP_TRACK_URL;
let TRADE_URL = process.env.REACT_APP_FINISH_URL;

class RegOpenAccFormMiddle extends Component {
    constructor(props) {
        super()
        this.state = {
            values: {
                fullName: '',
                mobileNo: '',
                city: '',
                partnerCode: '',
                showPartner: false,
                isPolicyAccepted: true, // checkbox state
                auRm: '',
                fetchedCity: '',
                fetchedState: '',
                isSouthIndian: false,
                referralCode: '',
                otp: '',
                referedVia: "",
                TnC: true
            },
            error: {
                // fullName: '',
                // mobileNo: '',
                // city: '',
                // partnerCode: '',
                // isPolicyAccepted: '' // terms and condition (policy)
                // apiResponse: ''
            },
            availableCities: [],
            isShowErrorPopup: false,
            baDetails: {},
            openPartnerDetailDialog: false,
            isBADetailShow: true,
            isDIY: false,
            schemPlanId: null,
            disableFullNameForDiy: true,
            disableMobileForDiy: true,
            disablePartnerCodeForDiy: true,
            showCity: false,
            IsTrueCallerUser: false,
            timer: 60,
            showTrueCallerPopup: true,
            selectedLanguage: "English",
            // showMobileOtpPopup: false,
            otpTimer: 60,
            showTimer: false,
            disableResendButton: false,
            isValidReferralCode: false,
            disableReferralCode: false,
            showCheckBoxes: true,
        }
    }
    componentDidMount() {
        // this.checkDiyBa()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.diyId !== this.props.user.diyId) {
            console.log(this.props.user.diyId, 'diyId on update');
            this.checkDiyBa();
        }
    }

    //#region diy user logic.
    checkDiyBa = () => {
        // alert("called")

        if (!this.props.user.diyId) {
            return;
        }

        let data = {
            _guid: this.props.user.diyId
        }

        // this.props.setLoading({ message: "We are fetching your details..." })
        this.props.setLoading({ message: "" });

        api.getBaDiyDetails(data).then(response => {
            // alert("!")
            console.log('diy ba response-->', response);
            this.props.clearLoading()

            if (response.data) { //set user field for diy

                this.props.setUser({
                    applicationType: response.data[0].APPLICATIONTYPE,
                    strPSource: response.data[0].PSOURCE
                })

                this.setState(prevState => produce(prevState, draft => {
                    draft.values.fullName = response.data[0].NAME;
                    draft.values.mobileNo = response.data[0].MOBILE;
                    draft.values.partnerCode = response.data[0].PARTNERCODE;
                    draft.schemPlanId = response.data[0].SCHEMEPLANID;
                    draft.isDIY = true;
                }))

                if (response.data[0].NAME === null || response.data[0].NAME === '') {
                    this.setState({ disableFullNameForDiy: false })
                }

                if (response.data[0].MOBILE === null || response.data[0].MOBILE === '') {
                    this.setState({ disableMobileForDiy: false })
                }

                if (response.data[0].PARTNERCODE === null || response.data[0].PARTNERCODE === '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = false;
                        draft.disablePartnerCodeForDiy = false;
                    }))
                } else if (response.data[0].PARTNERCODE !== null || response.data[0].PARTNERCODE !== '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.data.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
                // draft.isShowErrorPopup = true;
            }))
        })

    }
    //#endregion


    clearError = (name) => {
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error[name]
        }), () => {
            console.log('error cleared');
        })
    }

    //#region change in any input field is handled here
    handleInputChange = ({ name, value }) => {
        console.log('handleInputChange name,value-->', name, value)
        if (name === "mobileNo" && value.length != 0) {
            let validMob = isValidMobileNo(value);

            if (!validMob) {
                return false;
            }
        } else if (name === "fullName" && value.length != 0) {
            if (value.length > 60) {    // for mobile, not to accept 60+ chars
                return false;
            }
            // if (value[0] == ".") {  // dont allow . in first position
            //     return false;
            // }
            let validName = isValidName(value);
            if (!validName) {
                return false;
            }
            if (value.includes('.')) {
                // value = value.replace('..', '.')        // allow only single dot(.)
                // value = value.replace(/ +/g, ' ', '');  //Remove extra white spaces
                return false;   // dont allow .
            }
            else {
                value = value.replace(/ +(?= )/g, '');  //allow only single space after every one word
            }
        } else if (name === "partnerCode") {
            if (!isValidAlphaNumeric(value)) {
                return;
            }
            if (value.length == 0) {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = true
                }))
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = false
                }))
            }
            value = value.toUpperCase();
        }
        else if (name === "referralCode") {
            if (!isValidAlphaNumeric(value) || value.length > 8) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === 'otp') {
            if (!isValidOtp(value)) {
                return;
            }
        }
        this.clearError(name);
        if (name === 'otp' && value.length > otpLength) {
            return false;
        }
        this.setState(prevState => produce(prevState, draft => {
            draft.values[name] = value;
        }), () => {
            if (this.state.values.city) {// this is work around for city to unset city error in mobile
                this.clearError(name);
            }
        })
    }
    //#endregion

    //#region any input field blur is handled here.
    handleInputBlur = ({ name, value, isRequired }) => {
        console.log('Input blur', name, value, isRequired);
        this.validateField({ name, value, isRequired });
        if (name === 'partnerCode' && value) {
            let data = {
                _BACODE: value,
                ApplicationType: 'PP'
            }
            // this.validatePartnerCode(data);
        }
    }
    //#endregion

    //#region validation of field 
    validateField = ({ name, value, isRequired, callback = () => { } }) => {
        console.log('validate', name, value, isRequired)
        if (isRequired && !value) {// value is reqd but left blank
            callback();
            this.setState(prevState => produce(prevState, draft => {
                if (name === "fullName") {
                    draft.error[name] = 'Please enter your Full Name';
                } else if (name === "mobileNo") {
                    draft.error[name] = 'Please enter your Mobile Number';
                }
            }), () => {
                console.log('error set')
            })
        } else if (name === "mobileNo") {
            this.validateMobileNumber({ name, value, callback })
        } else if (name === "fullName") {
            this.validateFullName({ name, value, callback })
        } else if (name === "partnerCode") {
            if (this.state.error.partnerCode) {//if field error is set on blue of partner code.
                this.setState(prevState => produce(prevState, draft => {
                    draft.values.showPartner = true;
                }))
                callback();
            }
        }
        // else if (name === "partnerCode") {
        //     if (this.state.error.partnerCode) {//if field error is set on blue of partner code.
        //         callback();
        //         this.setState(prevState => produce(prevState, draft => {
        //             draft.error[name] = 'Please enter Code';
        //         }))
        //     }
        // }
        else if (name === "otp") {
            this.validateOtp({ name, value, callback })
        }
    }
    //#endregion

    validateMobileNumber = ({ name, value, callback }) => {
        let vailid = isValidMobileNo(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 10) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Mobile Number';
            }))
        }
    }

    validateFullName = ({ name, value, callback }) => {
        let vailid = isValidName(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 2) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Full Name';
            }))
        }
    }
    //#region validation of whole form
    validateForm = () => {
        const { fullName, mobileNo, city, partnerCode, isPolicyAccepted, auRm, referralCode } = this.state.values
        let decision = true;

        const makeDecision = () => {
            decision = false;
        }

        let fieldsToValidate = [
            (this.props.showFullName) && { name: 'fullName', value: fullName, isRequired: true },
            (this.props.showMobile) && { name: 'mobileNo', value: mobileNo, isRequired: true },

        ]
        fieldsToValidate = fieldsToValidate.filter(field => field);
        fieldsToValidate.forEach(field => this.validateField({ ...field, callback: makeDecision }))
        console.log('fieldsToValidate', fieldsToValidate);
        return decision;
    }
    //#endregion

    //#region open account button clicked. Send req to backend
    handleOpenAccount = () => {
        // alert("1")
        // if (this.state.values.referralCode !== '' && !this.state.values.isValidReferralCode) {
        //     this.validateReferralCode(this.validateForm);
        //     return;
        // }
        let valid = this.validateForm();
        console.log('handleOpenAccount valid', valid);
        if (valid) {
            // alert('send req')
            this.openAccount();
        }
    }
    //#endregion


    //#region Open Account Request data
    getOpenAccountReqData = () => {
        let data;
        let applicationType = this.props.user.applicationType;
        if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {// PP user = BA user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _strSource: 'Online',
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: 'OAO_middle',
                _interestedin: 'All',
                ApplicationType: 'PP',
                AppLoginId: this.state.values.partnerCode || this.props.user.appLoginId,
                _applicationFrom: 'Web',
                isOcrUser: '1',
                BranchEmpCode: this.props.user.branchEmpCode,
                IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                Token_send: "1"
            }
            if (this.state.schemPlanId) {
                data.SCHEMEPLANID = this.state.schemPlanId
                data.BACODE = this.state.values.partnerCode
            }
        } else if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {// direct user
            this.state.showCity ?
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    _city: this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME,//'MUMBAI',
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: 'OAO_middle',
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                } :
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    _city: this.state.values.fetchedCity,//'MUMBAI',
                    _state: this.state.values.fetchedState,
                    IsSouthCitySelected: this.state.values.isSouthIndian ? "1" : "0",
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: 'OAO_middle',
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                    IsNewReferral: (this.state.values.IsValid === true && this.state.values.refereeNamee !== "") ? "1" : "0",
                    Referee_Code: this.state.values.refereeCode,
                    Referee_Name: this.state.values.refereeName,
                    Referral_via: this.state.values.referedVia,
                    Token_send: "1"
                }
            if (this.state.values.showPartner) {//direct user with partner code entered, make it pp
                this.props.setUser({ applicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct })
                data = {
                    ...data,
                    BACODE: this.state.values.partnerCode,
                    ApplicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct,
                    _emailid: "",
                    _state: "",
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                    IPAddress: "",
                    _Otp: "",
                    _uniqueId: "",
                    AppLoginId: "",//"RETAILDOM",
                    _ROLEMAPPINGID: "",
                    _pincode: "",
                    BranchEmpCode: "",//"RETAILDOM",
                    _FamilyDeclaration: "",
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                    Token_send: "1"
                }
            }

        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {// crm user = branch user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _city: this.state.showCity ? this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME : "",//'MUMBAI',
                _strSource: 'Online',
                _interestedin: 'All',
                ApplicationType: 'CRM',
                AppLoginId: this.props.user.appLoginId,
                _applicationFrom: 'Web',
                BranchEmpCode: this.props.auRm.length > 0 ? this.state.values.auRm : this.props.user.branchEmpCode,
                isOcrUser: '1',
                SHADOWUSERID: this.props.user.branchEmpCode,
                IsDIY: this.props.user.isDiy,
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: 'OAO_middle',
                IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                Token_send: "1"
            }
        }
        return data;
    }
    //#endregion

    //#region open account api calling
    openAccount = () => {
        // this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode);
        // history.push('/mobileOTP');
        // return;

        let data = this.getOpenAccountReqData();
        let applicationType = this.props.user.applicationType;
        let TRADE_URL = process.env.REACT_APP_FINISH_URL;
        this.props.setLoading()
        console.log("USERdata-->", data)
        api.openAccount(data).then(response => {
            console.log('openAccount response', response);
            this.props.clearLoading()
            if (response.data.code == "200") {
                if (response.data.Status) {
                    if (response.data.Data.ISSUPERJET !== "1" && response.data.Data.ISJET !== "1") {
                        window.location = response.data.Data._ReturnURL;
                        return;
                    }

                    if (response.data.Data && response.data.Data.Status === "4") {//new user

                        window.dataLayer.push({
                            event: 'lead_filled',
                            Parameters: [{
                                'language': this.state.selectedLanguage,
                                'mobile_number': this.state.values.mobileNo,
                                'referral_code': this.state.values.referralCode,
                                'south_indian_language': this.state.values.isSouthIndian ? "yes" : "no"
                            }]
                        });
                        console.log("pushed event on reg page => ", window.dataLayer);

                        window.clevertap.event.push(
                            "lead_filled",
                            {
                                "language": this.state.selectedLanguage,
                                "mobile_number": this.state.values.mobileNo,
                                "referral_code": this.state.values.referralCode,
                                "south_indian_language": this.state.values.isSouthIndian ? "yes" : "no",
                                "ekycuserid": response.data.Data.UserId,
                                "lsqid": response.data.Data.LSQLeadId,
                                'ProspectOpportunityId': response.dada.Data.OpportunityId
                            }
                        );
                        console.log("pushed clevertap event => ", window.clevertap);


                        window.clevertap.onUserLogin.push({
                            "Site": {
                                "Name": this.state.values.fullName,
                                "Email": "", // Email address of the user
                                "Phone": this.state.values.mobileNo, // Phone (with the country code)
                                "Identity": "",
                                "Gender": "", // Can be either M or F
                                DOB: new Date(), // Date of Birth. Date object
                                // optional fields. controls whether the user will be sent email, push etc.
                                "MSG-email": false, // Disable email notifications
                                "MSG-push": true, // Enable push notifications
                                "MSG-sms": true, // Enable sms notifications
                                "MSG-whatsapp": true // Enable WhatsApp notifications
                            }
                        })

                        var presentDate = new Date().toLocaleString().slice(0, 10);
                        window.clevertap.profile.push({
                            "Site": {
                                "customer_status": "Lead",
                                "lead_filled_date": presentDate,
                                "ekycuserid": response.data.Data.UserId
                            }
                        })

                        this.props.setUser({
                            // type: this.state.values.showPartner ? userType.pp : userType.direct,
                            id: response.data.Data.UserId,
                            tokenKey: response.data.Data.TokenKey,
                            uniqueId: response.data.Data._uniqueId,
                            fullName: capitalCase(this.state.values.fullName),
                            mobileNumber: this.state.values.mobileNo,
                            isOtpRequired: true
                        })

                        if (this.state.IsTrueCallerUser) {
                            this.redirect();
                        } else {
                            if (isMobile) {
                                // for mobile ( to open mobile otp popup )
                                // $('body').addClass('open mobileotp noscroll');
                                this.setState({ showMobileOtpPopup: true, otpTimer: 60, showTimer: true, disableResendButton: true, })

                                this.myInterval = setInterval(() => {
                                    if (this.state.otpTimer > 1) {
                                        this.setState(({ otpTimer }) => ({
                                            otpTimer: otpTimer - 1
                                        }))
                                    } else {
                                        clearInterval(this.myInterval);
                                        this.setState({ showTimer: false })
                                    }
                                    // console.log("Timer1=>", this.state.otpTimer);
                                }, 1000);

                                setTimeout(() => {
                                    this.setState({ disableResendButton: false })
                                }, 60000)   // 60 sec

                                setTimeout(() => {
                                    this.setState({ showCheckBoxes: false })
                                }, 1000)   // 1 sec

                            } else {
                                // for desk
                                this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode);
                                history.push('/mobileOTP');
                                window.scrollTo(0, 0)
                            }
                        }

                    } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && (applicationType.toLowerCase() === userType.direct.toLowerCase())) {//already existed user
                        // this.resumeApplication()
                        if (this.state.IsTrueCallerUser) {
                            // window.location = response.data.Data._ReturnURL;
                            // return;
                            this.resumeApplication();
                        } else {
                            let data = {
                                _mobile: this.state.values.mobileNo
                            }
                            this.sendOtp(data)

                            this.setState({ otpTimer: 60, showTimer: true, disableResendButton: true, })

                            this.myInterval = setInterval(() => {
                                if (this.state.otpTimer > 1) {
                                    this.setState(({ otpTimer }) => ({
                                        otpTimer: otpTimer - 1
                                    }))
                                } else {
                                    clearInterval(this.myInterval);
                                    this.setState({ showTimer: false })
                                }
                                // console.log("Timer1=>", this.state.otpTimer);
                            }, 1000);

                            setTimeout(() => {
                                this.setState({ disableResendButton: false })
                            }, 60000)   // 60 sec
                        }
                    } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && this.props.user.strPSource == "LIVEPHOTO") {//already existed user crm/ba

                        let data = {
                            _mobile: this.state.values.mobileNo
                        }
                        this.sendOtp(data)

                    } else if (response.data.Data && response.data.Data.IsPrivilege) {
                        window.location = TRADE_URL;
                    } else {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.apiResponse = response.data.Data.Message;
                            // draft.isShowErrorPopup = true;
                        }))
                    }
                } else {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.apiResponse = response.data.Message;
                        // draft.isShowErrorPopup = true;
                    }))
                }
            } else {
                //added by sushil
                const EkycApiFailRef = firebase.database().ref(process.env.REACT_APP_FIREBASE_OBJECT_NAME);
                const EkycApiFailData = {
                    ApplicationType: data.ApplicationType,
                    IsSouthCitySelected: data.IsSouthCitySelected,
                    isOcrUser: data.isOcrUser,
                    _applicationFrom: data._applicationFrom,
                    _city: data._city,
                    _interestedin: data._interestedin,
                    _mobile: data._mobile,
                    _name: data._name,
                    _state: data._state,
                    _strPSource: data._strPSource,
                    _strPSourceDetails: data._strPSourceDetails,
                    _strSource: data._strSource,
                    IsNewReferral: data.IsNewReferral,
                    Referee_Code: data.Referee_Code,
                    Referee_Name: data.Referee_Name,
                    Referral_via: data.Referral_via,
                };
                EkycApiFailRef.push(EkycApiFailData);
            }

        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
                // draft.isShowErrorPopup = true;
            }))
            console.log(' openAccount error', error);
        })
    }
    //#endregion

    redirect = () => {
        if (this.props.user.applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push('/dad/verifyEmail');
        } else if (this.props.user.applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push('/branch/verifyEmail');
        } else if (this.props.user.applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            if (this.state.isDIY) {
                history.push('/ba/verifyEmail');
            } else {
                history.push('/ba/verifyEmail');
            }
        }
    }

    //#region resume api calling
    resumeApplication = () => {
        // alert("inside resumeApplication")
        let applicationType = this.props.user.applicationType;
        let data = {
            UserId: this.state.values.mobileNo,
            _password: this.state.values.otp,
            _uniqueId: this.props.user.uniqueId,
            BranchEmpCode: this.props.user.branchEmpCode,
            AppLoginId: this.props.user.appLoginId,
            IsDIY: this.props.user.isDiy,
            IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
        }
        this.props.setLoading();
        api.resumeApplication(data).then(response => {
            // alert("again inside resume application")
            this.props.clearLoading();
            if (response.data.Status) {
                // $('body').removeClass('open mobileotp noscroll');
                if (response.data.Data.ISSUPERJET !== "1") {
                    window.location = response.data.Data._ReturnURL;
                    return;
                }

                if (response.data.Data && response.data.Data.Status === "1") {//new user
                    // alert("resume applcation ke andr check if it is new user")


                    this.props.setUser({
                        // type: this.state.values.showPartner ? userType.pp : userType.direct,
                        id: response.data.Data.UserId,
                        tokenKey: response.data.Data.TokenKey,
                        uniqueId: response.data.Data._uniqueId,
                        fullName: capitalCase(this.state.values.fullName),
                        mobileNumber: this.state.values.mobileNo,
                        isOtpRequired: true
                    })

                    let page = this.getPage(response.data.Data.PageName); // get page logic
                    console.log('page', page)
                    if (page === 'verification') {
                        this.callResumeForm(response.data.Data.ApplicationType);
                    } else if (page === 'tracker') {
                        window.location = TRACK_URL;
                        return
                    } else if (page === 'trade') {
                        window.location = TRADE_URL;
                        return
                    }

                    this.redirectPage(response.data.Data.ApplicationType, page);//redirect logic on positive response

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && (applicationType.toLowerCase() === userType.direct.toLowerCase())) {//already existed user
                    // alert("resume application and existing user")

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && this.props.user.strPSource == "LIVEPHOTO") {//already existed user crm/ba

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsPrivilege) {
                    window.location = TRADE_URL;
                } else {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.otp = response.data.Data.Message;
                        // draft.isShowErrorPopup = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.otp = response.data.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }

        }).catch(error => {
            console.log('resumeApplication error', error);
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
            }))
        })
    }
    //#endregion

    //#region resume form api call
    callResumeForm = (applicationType) => {
        let type;
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            type = 'dad';
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            type = 'branch';
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            type = 'ba';
        }

        let currentUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/verifyEmail';
        let successUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/esign';

        let data = {
            ReturnURLSUCCESS: successUrl,
            ReturnURLFAILURE: currentUrl,
            Type: 'CLIENTLOGIN'
        }
        this.props.resumeForm(data).then(res => {
            window.location.href = res[0].RESUMEFORM_URL;
        })
    }
    //#endregion

    redirectPage = (applicationType, page, isDiy) => {
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push(`/dad/${page}`);
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push(`/branch/${page}`);
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            history.push(`/ba/${page}`);
        }
    }

    //#region send otp
    sendOtp = (data) => {
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {
                this.props.setUser({ uniqueId: response._Uniqueid })
                // otp is successfully resend.

                // let jumpToResume = regFormType.resumeApp;
                // this.handleCurrentForm(jumpToResume, true, response._uniqueId)

                if (isMobile) {
                    // for mobile ( to open mobile otp popup )
                    this.setState({ showMobileOtpPopup: true })

                    setTimeout(() => {
                        this.setState({ showCheckBoxes: false })
                    }, 1000)   // 1 sec

                    $('body').addClass('open mobileotp noscroll');
                    this.setState(prevState => produce(prevState, draft => {
                        delete draft.error.otp
                        draft.values.otp = "";
                        draft.showMobileOtpPopup = true;
                    }))
                } else {
                    // for desk
                    this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode);
                    // alert("mobileOTP1")
                    history.push('/mobileOTP')
                    window.scrollTo(0, 0)
                }

            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = error.message;
            }))
        })
    }
    //#endregion

    render() {
        return (
            <div id="openaccount">
                <div className="container">
                    <h2 className="openacchead">Open Free Demat Account in 5 Minutes</h2>
                    <p>Join the family of 2.5 Mn+ Happy Demat Account Holders</p>

                    <form action="">
                        {this.props.showFullName && <RegInput
                            name={"fullName"}
                            value={this.state.values.fullName}
                            error={this.state.error.fullName}
                            maxLength={60}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            type={"text"}
                            placeholder={"Enter your Full Name as per PAN"}
                            disabled={this.props.regFullName !== "" ? true : false}
                        />}
                        <div className="mobnum">
                            {this.props.showMobile && <RegMobileNo
                                name={"mobileNo"}
                                value={this.state.values.mobileNo}
                                error={this.state.error.mobileNo}

                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                                placeholder={"Enter your Mobile Number"}
                                disabled={this.props.regMobileNo.length === 10 && this.props.regMobileNo !== "" ? true : false}
                            // onClick={isAndroid && this.state.showTrueCallerPopup && this.handleMobileClick}
                            />}

                        </div>
                        <div className="cta dark" onClick={this.handleOpenAccount}>
                            Proceed
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
    resendOtp: (data) => fetchWithWait({ dispatch, action: resendOtp(data) }),
    setLoading: (data) => dispatch(setLoading(data)),
    clearLoading: () => dispatch(clearLoading()),
    setResponseErrorSnack: (payload) => dispatch(setResponseErrorSnack(payload))
})

const mapStateToProps = (state) => ({
    user: state.user,
    auRm: state.auRmDetails.auRm,
    analytics: state.analytics
})

export default connect(mapStateToProps, mapDispatchToProps)(RegOpenAccFormMiddle);
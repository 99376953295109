import React, { Component } from 'react';
import Slider from "react-slick";
import customer from '../../../assets/images/customer.png';
import RegReviewCard from '../../molecules/regReviewCard';


var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Reviews = [
    {
        image: customer, name: 'Susant Banergee', title: 'Easy to use app!',
        description: 'A superb app. Easy to use. The clients are always getting the advantage of intense research. Best trading app.', stars: 3.5
    },
    {
        image: customer, name: 'Susant Banergee', title: 'Easy to use app!',
        description: 'A superb app. Easy to use. The clients are always getting the advantage of intense research. Best trading app.', stars: 3.5
    },
    {
        image: customer, name: 'Susant Banergee', title: 'Easy to use app!',
        description: 'A superb app. Easy to use. The clients are always getting the advantage of intense research. Best trading app.', stars: 3.5
    }
]

class RegCustomerReview extends Component {
    render() {
        return (
            <section className="customer-review section-spac">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="customer-review-wrapper">
                                <div className="top-title">
                                    <h2>What our customers say</h2>
                                    <p className="cat hidden-xs">Reviews, comments from MO's customers and community</p>
                                </div>
                            </div>
                                    <Slider {...settings}>
                                        {
                                            Reviews.map((review, index) => <RegReviewCard key={index} {...review} />
                                            )
                                        }
                                    </Slider>
                                </div>
                    </div>

                </div>
            </section>
        )
    }
}
export default RegCustomerReview;
import Api from '.';
import { endpoints } from './endpoints';

export default class EsignApi extends Api {

    getEsignDetails(data) {
        let url = this.buildUrl(endpoints.esign.getEsignDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getEsignDetailsKra(data) {
        let url = this.buildUrl(endpoints.esign.getEsignDetailsKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getEsignDetailsDdpi(data) {
        let url = this.buildUrl(endpoints.esign.getEsignDetailsDdpi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAuthMode(data) {
        let url = this.buildUrl(endpoints.esign.getAuthMode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAuthModeKra(data) {
        let url = this.buildUrl(endpoints.esign.getAuthModeKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAuthModeDdpi(data) {
        let url = this.buildUrl(endpoints.esign.getAuthModeDdpi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performDigioEsignRequest(data) {
        let url = this.buildUrl(endpoints.esign.performDigioEsignRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performDigioEsignResponse(data) {
        let url = this.buildUrl(endpoints.esign.performDigioEsignResponse)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performDigioEsignRequestKra(data) {
        let url = this.buildUrl(endpoints.esign.performDigioEsignRequestKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performDigioEsignResponseKra(data) {
        let url = this.buildUrl(endpoints.esign.performDigioEsignResponseKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAvailableClientCode(data) {
        let url = this.buildUrl(endpoints.esign.getAvailableClientCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    generateClientCode(data) {
        let url = this.buildUrl(endpoints.esign.generateClientCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    pushDataInDrishti(data) {
        let url = this.buildUrl(endpoints.esign.pushDataInDrishti)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    downloadPdfForm(data) {
        let url = this.buildUrl(endpoints.esign.downloadPdfForm)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    pdfFileDownload(data) {
        let url = this.buildUrl(endpoints.esign.pdfFileDownload)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    requestBiometric(data) {
        let url = this.buildUrl(endpoints.esign.requestBiometric)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    emudhra(data) {
        let url = this.buildUrl(endpoints.esign.emudhra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getEmudhra(data) {
        let url = this.buildUrl(endpoints.esign.getEmudhra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    emudhraKra(data) {
        let url = this.buildUrl(endpoints.esign.emudhraKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getEmudhraKra(data) {
        let url = this.buildUrl(endpoints.esign.getEmudhraKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    emudhraDdpi(data) {
        let url = this.buildUrl(endpoints.esign.emudhraDdpi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getEmudhraDdpi(data) {
        let url = this.buildUrl(endpoints.esign.getEmudhraDdpi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performEmudhraEsignRequest(data) {
        let url = this.buildUrl(endpoints.esign.performEmudhraEsignRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performEmudhraEsignRequestKra(data) {
        let url = this.buildUrl(endpoints.esign.performEmudhraEsignRequestKra)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    performEmudhraEsignRequestDdpi(data) {
        let url = this.buildUrl(endpoints.esign.performEmudhraEsignRequestDdpi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    generateClientCodeMF(data) {
        let url = this.buildUrl(endpoints.esign.generateClientCodeMF)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

}
import { put } from 'redux-saga/effects';
import NomineeApi from "../services/nomineeApi";
import { SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN, SET_NOMINEEDETAILS, SET_STATE_CITY_FROM_PINCODE, SET_BIND_STATE, SET_BIND_CITY, PRIMARY_APPLICANT_ADDRESS, SET_NOMINEE_GENDER_INFO_DROPDOWN, SET_NOMINEEDETAILS4 } from '../redux/types/nominee';
import { setLoading, clearLoading } from '../redux/actions/loader';
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError';
import { apiErrorResponse } from '../helper/constant';
import { isMobile } from 'react-device-detect';

let api = new NomineeApi();

export function* getNomineeRelationshipDropdown({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.getNomineeRelationshipDropdown(payload)

        yield put(clearLoading());
        console.log('getNomineeRelationshipDropdown response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: SET_NOMINEEDETAILS, payload: response.data.Data.GETNOMINEEDETAILS })
                yield put({ type: SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN, payload: response.data.Data.GETNOMINEERELATIONSHIP })
                yield put({ type: PRIMARY_APPLICANT_ADDRESS, payload: response.data.Data.PRIMARYAPPLICANTADDRESS[0] })
                yield put({ type: SET_NOMINEE_GENDER_INFO_DROPDOWN, payload: response.data.Data.GETNOMINEEGENDER })
                yield put({ type: SET_NOMINEEDETAILS4, payload: response.data.Data.GETNOMINEEDETAILS4 })

                resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getNomineeDetailsById({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.getNomineeDetailsById(payload)

        yield put(clearLoading());
        console.log('getNomineeDetailsById response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: SET_NOMINEEDETAILS, payload: response.data.Data.GETNOMINEEDETAILS })
                resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getStateCityFromPincode({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.getStateCityFromPincode(payload)

        yield put(clearLoading());
        console.log('getStateCityFromPincode response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {

                yield put({ type: SET_STATE_CITY_FROM_PINCODE, payload: response.data.Data.PincodeDetails })

                resolve && resolve(response.data.Data.PincodeDetails);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* bindState({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.bindState(payload)

        yield put(clearLoading());
        console.log('bindState response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {

                yield put({ type: SET_BIND_STATE, payload: response.data.Data.StateDetails })

                resolve && resolve(response.data.Data.StateDetails);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* bindCity({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.bindCity(payload)

        yield put(clearLoading());
        console.log('bindCity response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {

                yield put({ type: SET_BIND_CITY, payload: response.data.Data.CityDetails })

                resolve && resolve(response.data.Data.CityDetails);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* updateNomineeInfo({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.updateNomineeInfo(payload)

        yield put(clearLoading());
        console.log('updateNomineeInfo response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve && resolve(response);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                if (!isMobile) {
                    yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
                }
            }
        }
        else {
            if (response.data.code !== 401) {
                resolve && resolve(response);
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}
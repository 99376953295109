import { put, call } from 'redux-saga/effects';

import RegistrationApi from "../services/registrationApi";
import { saveAuRmDetails } from "../redux/actions/user";
import { apiErrorResponse } from '../helper/constant'
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new RegistrationApi();

export function* getAuRmDetails({ type, payload }) {
    let responsePayload = {};
    try {
        let { data } = yield api.getAuRmDetails(payload);
        console.log(' getAuRmDetails saga', data.Data);
        if (data.Status && data) {
            yield put(saveAuRmDetails(data.Data))
        }
        else {
            responsePayload = { type: "error", message: data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}
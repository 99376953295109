import React, { Component, useState } from 'react';


const MFDSection5 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (

        <>
            <>
                <section className="sectionpad sectioncontainer">
                    <div className="text-center">
                        <h2 className="textlabel">
                            <span className="f32 line1 mobf24 lightgrey semibold">
                                Mutual Funds
                            </span>
                            <div>
                                <span className="f30 semibold mobf24">in a nutshell</span>
                            </div>
                        </h2>
                    </div>
                    <div className="mtop30" />
                    <p className="lightgrey f16 font-regular">
                        Managed by qualified fund managers, Mutual Funds help retail investors
                        participate in the markets by pooling their money, which is invested in
                        multiple assets like stocks, bonds gold, etc. by the fund manager on
                        behalf of the investors. Investors own units of the mutual fund against
                        their investments, and the value of these units fluctuate with the
                        performance of the underlying assets. With multiple mutual funds in each
                        category such as - Equity Funds, Debt Funds, Hybrid Funds, Tax Saver
                        Funds, etc. - there is a mutual fund for everyone!
                    </p>
                </section>
                <section className="sectionpad ptop0">
                    <div className="text-center">
                        <h2 className="textlabel">
                            <span className="f32 line1 mobf24 lightgrey semibold">Why invest</span>
                            <div>
                                <span className="f30 semibold mobf24">in Mutual Funds?</span>
                            </div>
                        </h2>
                    </div>
                    <div className="mtop30" />
                    <div className="font-semi-bold lightblack f16">
                        Tax Benefits up to ₹46,800
                    </div>
                    <p className="lightgrey f16">
                        {" "}
                        Invest in Tax saving ELSS schemes to save up to ₹46,800 on taxes.
                    </p>
                    <div className="mtop20" />
                    <div className="font-semi-bold lightblack f16">
                        Start with as low as ₹100
                    </div>
                    <p className="lightgrey f16">
                        {" "}
                        Start your investing journey in Mutual Funds starting with as low as ₹100.
                    </p>
                    <div className="mtop20" />
                    <div className="font-semi-bold lightblack f16">
                        Wealth creation through long-term compounding
                    </div>
                    <p className="lightgrey f16">
                        {" "}
                        Leverage the power of compounding and multiply your capital over
                        long-term.
                    </p>
                    <div className="mtop20" />
                    <div className="font-semi-bold lightblack f16">
                        Managed by experienced fund managers
                    </div>
                    <p className="lightgrey f16">
                        {" "}
                        Handled by SEBI registered fund managers, mutual funds can be relatively
                        safer for retail investors{" "}
                        <a
                            href="https://www.motilaloswal.com/markets-overview"
                            className="seohreflink"
                            target="_blank"
                        >
                            investing in the stock market
                        </a>{" "}
                        for the first time.
                    </p>
                    <div className="mtop20" />
                </section>
                <section className="sectionpad ptop0">
                    <div className="text-center">
                        <h2 className="textlabel">
                            <span className="f32 line1 mobf24 lightgrey semibold">Why invest</span>
                            <div>
                                <span className="f30 semibold mobf24">with Motilal Oswal?</span>
                            </div>
                        </h2>
                    </div>
                    <div className="mtop30" />
                    <ul className="backcms-ulli-list mtop20">
                        <li>Top-rated Mutual Funds selected by Experts </li>
                        <li>Simplified SIPs through UPI Mandate</li>
                        <li>Single-click Lumpsum Investments</li>
                        <li>Instant payout</li>
                    </ul>
                </section>
            </>

        </>

    );
}

export default MFDSection5;

import { produce } from 'immer';
import { GET_SCHEME_DETAILS_BRANCH_RESPONSE, ON_SCHEME_CHANGE_BRANCH_RESPONSE, GET_USER_PAYMENT_DETAILS_RESPONSE, GET_TRADER_DETAILS_RESPONSE, INSERT_TRADER_DETAILS_RESPONSE } from '../types/scheme';

const initialState = {
    schemeDropdown: "",
    dpTariffDropdown: "",
    additionalSchemeInfoEQ: "",
    additionalSchemeInfoCOMM: "",
    getUserPaymentDetails: "",
    getTraderDetails: "",
    insertTraderDetails: "",
    PaymentsInfo: "",
    MarginUpload: "",
    SchemeName: "",
    DPTariff: "",
    MarginWaiver: ""
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SCHEME_DETAILS_BRANCH_RESPONSE:
            return { ...state, ...payload };
        case ON_SCHEME_CHANGE_BRANCH_RESPONSE:
            return { ...state, ...payload };
        case GET_USER_PAYMENT_DETAILS_RESPONSE:
            return { ...state, ...payload };
        case GET_TRADER_DETAILS_RESPONSE:
            return { ...state, ...payload };
        case INSERT_TRADER_DETAILS_RESPONSE:
            return { ...state, ...payload }
        default:
            return state
    }

})

export default reducer;
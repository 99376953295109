import React, { Component } from 'react'
import RegHeader from '../../organism/regHeader'
import RegBanner from '../../organism/regBanner'
import RegForm from '../../organism/regForm'
import RegFeature from '../../organism/regFeature'
import RegProcedure from '../../organism/regProcedure'
import RegProcedureList from "../../organism/regProcedureList"
import RegCustomerReview from '../../organism/regCustomerReview'
import RegFAQs from '../../organism/regFAQs'
import RegNewCustomerReview from '../../organism/customerReview'
import RegInfo from '../../organism/regInfo'
import RegAccOpenInfo from '../../organism/regAccOpenInfo'
import RegInfoPage3 from '../../organism/regAccOpenInfo2'
import RegNewBanner from '../../organism/regMainBanner/index'
import RegReadyToInvest from '../../organism/regReadyToInvest'
import RegFooter from '../../organism/regFooter'
// import './index.css';
// import './registration.css'
// import './header.css'
// import './footer.css'
// import './form.css'
// import './owl.carousel.min.css'
import CustomerReviewByPJ from '../../organism/customerReviewByPJ'
import RegButton from '../../molecules/regButton'
import { Link } from 'react-router-dom'
import loadable from '@loadable/component'
import { connect } from 'react-redux'
import { setUser, getAuRmDetails } from '../../../redux/actions/user'
import { userType, regFormType, authorizationError, LinkConstant } from '../../../helper/constant'
import queryString from 'query-string';
import { getUppercaseKeyObject, getBlogsDetails } from '../../../helper/method'
import { setHomePageQueryParams } from '../../../redux/actions/homePageQueryParams'
import classNames from 'classnames'
import Modal from '../../molecules/modal'
import ProceedButton from '../../molecules/proceedButton'
import { isMobile } from "react-device-detect";
import { TailSpin } from 'react-loader-spinner'
import { FaTimes } from 'react-icons/fa'
import $ from "jquery";
import SessionExpiredPopup from '../../organism/sessionExpiredPopup'
import reghome from '../../../assets/images/home.svg';
import Image from '../../atom/image';
import Steps from '../../organism/regSteps';
import RegBasicInfo from '../../organism/regBasicInfo';
import RegOpenAccountForm from '../../organism/regOpenAccountForm';
import RegMiddleForm from '../../organism/regOpenAccFormMiddle';
import RegBenifits from '../../organism/regShowBenifits';
import ShowInvestments from '../../organism/regShowInvestments';
import CustomerReviewSjet from '../../organism/regCustomerReviewSjet';
import SjetBlogs from '../../organism/regSjetBlogs';
import FAQsJet from '../../organism/regFAQsJet';
import StickyForm from '../../organism/stickyForm';

class RegistrationStatic extends Component {
    constructor(props) {
        super();
        this.state = {
            mobileOpenAccountButtonClick: false,
            open: false,
            showFullName: true,
            showMobile: true,
            showCity: true,
            openProPartnerPopup: false,
            showRegInfo: false,
            showRegInfo1: false,
            showRegInfo2: false,
            showRegInfo3: false,
            showRegInfo4: false,
            showRegInfo5: false,
            showRegInfo6: false,
            showRegInfo7: false,
            regFullName: '',
            regMobileNo: '',
            isSouthIndianSelected: false,
            showPartner: false,
            partnerCode: '',
            referralCode: '',
            selectedLanguage: '',
            getRegBlogsDetails: [],
            isMobileDevice: false
        }
    }

    //#region  Reading query parameters from registration link . And storing in redux store
    componentDidMount = async () => {
        this.setState({
            showRegInfo: true,
            showRegInfo1: true,
            showRegInfo2: true,
            showRegInfo3: true,
            showRegInfo4: true,
            showRegInfo5: true,
            showRegInfo6: true,
            showRegInfo7: true,
        })
        this.initScript()
        console.log("test123->", window.pageYOffset, "Hight->", window.innerHeight, "IWDTH->", window.innerWidth)

        // if (window.pageYOffset) {
        //     console.log("windowpageYOffset-->", window.pageYOffset,"windowpageX-->Offset", window.pageXOffset)
        // }
        console.log("test123-->", window.pageYOffset)
        // if (isMobile) {
        //     this.setState({ showRegInfo: true })
        // }

        // if (!isMobile) {
        //     setTimeout(() => {
        //         this.setState({
        //             showRegInfo: true,
        //             showRegInfo1: true,
        //             showRegInfo2: true,
        //             showRegInfo3: true,
        //         })
        //     }, 2000)   // 2 sec

        //     setTimeout(() => {
        //         this.setState({
        //             showRegInfo4: true,
        //             showRegInfo5: true,
        //             showRegInfo6: true,
        //             showRegInfo7: true,
        //         })
        //     }, 3000)   // 3 sec
        // }

        // document.addEventListener("scroll", () => {
        //     if (window.pageYOffset > 290 || window.innerHeight > 600) {
        //         // if ($(window).width() < 767) {
        //         //     // alert("called1")
        //         //     // return;
        //         //     this.setState({ isMobileDevice: true })
        //         // } else {
        //         // alert("called2")
        //         this.setState({ showRegInfo: true })
        //         // }
        //     } if (window.pageYOffset > 570) {
        //         this.setState({ showRegInfo1: true })
        //     } if (window.pageYOffset > 1500) {
        //         this.setState({ showRegInfo2: true })
        //     } if (window.pageYOffset > 1720) {
        //         this.setState({ showRegInfo3: true })
        //     } if (window.pageYOffset > 2381 && this.state.regMobileNo === "") {
        //         this.setState({ showRegInfo4: true })
        //     } if (this.state.regMobileNo !== "") {
        //         if (window.pageYOffset > 1820) {
        //             this.setState({ showRegInfo4: true })
        //         }
        //     }
        //     if (window.pageYOffset > 3060 && this.state.regMobileNo === "") {
        //         this.setState({ showRegInfo5: true })
        //     } if (this.state.regMobileNo !== "") {
        //         if (window.pageYOffset > 2700) {
        //             this.setState({ showRegInfo5: true })
        //         }
        //     }
        //     if (window.pageYOffset > 3415 && this.state.regMobileNo === "") {
        //         this.setState({ showRegInfo6: true })
        //     } if (this.state.regMobileNo !== "") {
        //         if (window.pageYOffset > 3035) {
        //             this.setState({ showRegInfo6: true })
        //         }
        //     }
        //     if (window.pageYOffset > 3880 && this.state.regMobileNo === "") {
        //         this.setState({ showRegInfo7: true })
        //     } if (this.state.regMobileNo !== "") {
        //         if (window.pageYOffset > 3500) {
        //             this.setState({ showRegInfo7: true })
        //         }
        //     }
        // })
        // console.log("test123-->", this.state.showRegInfo)

        //this type of import because cipher.js file contains window reference. And On server we don't have access.
        import('../../../lib/crypto/client').then(({ default: ParameterUtils }) => {
            this.parameterUtils = new ParameterUtils();
            let url = window.location.search;

            let params = {};

            if (url.indexOf('?') !== -1) {
                let queryStringFromUrl = window.location.search.split('?')[1];
                console.log('queryString', queryStringFromUrl)

                if (queryStringFromUrl.indexOf('diyid') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        diyId: params.diyid,
                    })
                };

                if (queryStringFromUrl.indexOf('diymass') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        diymass: params.diymass
                    })
                };

                if (queryStringFromUrl.indexOf('referralCode') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        referralCode: params.referralCode,
                    })
                };

                //#region utm campaign logic
                let utmParamsObj = queryString.parse(queryStringFromUrl);
                if (utmParamsObj) {
                    utmParamsObj = getUppercaseKeyObject(utmParamsObj);
                    if (utmParamsObj.SRC || utmParamsObj.PD || utmParamsObj.UTM_SOURCE || utmParamsObj.UTM_CAMPAIGN) {
                        this.props.setUser({
                            source: utmParamsObj.SRC || '',
                            sourceDetail: utmParamsObj.PD || '',
                            utmSource: utmParamsObj.UTM_SOURCE || '',
                            utmCampaign: utmParamsObj.UTM_CAMPAIGN || ''
                        })
                    }
                    if (utmParamsObj.APPLICATIONTYPE) {
                        this.props.setUser({
                            applicationType: utmParamsObj.APPLICATIONTYPE || ''
                        })
                    }
                    if (utmParamsObj.SRC === "Website") {
                        this.setState({ showCity: false })
                    }
                    if (utmParamsObj.SRC === "ProPartner" || utmParamsObj.SRC === "Pro-partner") {
                        this.setState({ openProPartnerPopup: true })
                    }
                    sessionStorage.setItem('lead_source_url', utmParamsObj.SRC);
                }
                //#endregion

                //    #region TABNAME/COMMANDTYPE logic
                let commandParamsObj = queryString.parse(queryStringFromUrl);
                if (commandParamsObj) {
                    commandParamsObj = getUppercaseKeyObject(commandParamsObj);
                    //if comes from sathi for resume app. then directly lands on resume app
                    if (commandParamsObj.COMMANDTYPE || commandParamsObj.DIYMASS) {
                        isMobile && this.setState({
                            mobileOpenAccountButtonClick: true
                        })
                        this.props.setHomePageQueryParams({ formTypeCommingFromParams: commandParamsObj.COMMANDTYPE || commandParamsObj.DIYMASS });//3 types of form(Open new, resume application, track).
                        return;
                    }
                }
                // #endregion

                params = this.parameterUtils.decrypt(queryStringFromUrl);
                console.log(params, 'decrypt params');
            }

            if (!params) {
                params = queryString.parse(params);
            } else {
                params = queryString.parse(params);
            }
            console.log('b params', params);
            params = getUppercaseKeyObject(params);
            console.log('a params', params);
            if (params.APPLICATIONTYPE === userType.crm && params.ISAUSHADOWACCOUNTENABLE == 1) {
                this.props.getAuRmDetails({
                    RMCode: params.BRANCHEMPCODE
                })
            }
            console.log('params', params)
            this.props.setUser({
                applicationType: params.APPLICATIONTYPE || userType.direct,
                appLoginId: params.APPLOGINID,
                branchEmpCode: params.BRANCHEMPCODE,
                isDiy: params.ISDIY || ""
            })

            if (params) {
                //if comes from sathi for resume app. then directly lands on resume app
                if (params.TABNAME) {
                    this.props.setHomePageQueryParams({ formTypeCommingFromParams: params.TABNAME });//3 types of form(Open new, resume application, track).
                }
            }
        })

        // this.setState({
        //     open: this.props.popup.open
        // })

        if (this.props.popup.open) {
            $('.sessionExpired')[0].click(); // To open popup for desktop
            if ($(window).width() < 767) {  // To open bottomSheet
                $("body").addClass("sessionExpiredSlide open");
                $("#sessionExpired").removeClass('overlay');
            }
        }

        $('.popupcta').click(function () {
            $('body').removeClass('sessionExpiredSlide').removeClass('open').removeClass('overlay');
        });

        $('body').attr('id', 'home');

        let getRegBlogsDetails = await getBlogsDetails();
        console.log("getRegBlogsDetails=", getRegBlogsDetails);
        this.setState({ getRegBlogsDetails: getRegBlogsDetails })
    }
    //#endregion

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.applicationType !== this.props.user.applicationType) {
            if (this.props.user.applicationType === userType.direct) {
                this.setState({ showCity: false })
            }
        }
    }

    initScript = () => {

        // $(document).ready(function () {

        //     //$('.inputs').keyup(function(){
        //     // if($(this).val().length==$(this).attr('maxlength'))
        //     //	$(this).next(':input').focus()
        //     // });

        //     $('.digit-group').find('input').each(function () {
        //         $(this).attr('maxlength', 1);
        //         $(this).on('keyup', function (e) {
        //             var parent = $($(this).parent());

        //             if (e.keyCode === 8 || e.keyCode === 37) {
        //                 var prev = parent.find('input#' + $(this).data('previous'));

        //                 if (prev.length) {
        //                     $(prev).select();
        //                 }
        //             } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
        //                 var next = parent.find('input#' + $(this).data('next'));

        //                 if (next.length) {
        //                     $(next).select();
        //                 } else {
        //                     if (parent.data('autosubmit')) {
        //                         parent.submit();
        //                     }
        //                 }
        //             }
        //         });
        //     });



        //     //  $(document).on('blur', 'input, textarea', function(){
        //     //    $('.fourbox').removeClass("last");
        //     //  });


        //     $('.slides').slick({
        //         dots: true,
        //         infinite: false,
        //         speed: 300,
        //         slidesToShow: 2,
        //         slidesToScroll: 2,
        //         autoplay: true,
        //         responsive: [
        //             {
        //                 breakpoint: 1024,
        //                 settings: {
        //                     slidesToShow: 2,
        //                     slidesToScroll: 1,
        //                     infinite: true,
        //                     dots: true
        //                 }
        //             },
        //             {
        //                 breakpoint: 600,
        //                 settings: {
        //                     slidesToShow: 2,
        //                     slidesToScroll: 2
        //                 }
        //             },
        //             {
        //                 breakpoint: 480,
        //                 settings: {
        //                     slidesToShow: 1,
        //                     slidesToScroll: 1
        //                 }
        //             }
        //         ]
        //     });

        //     $('.slick-next').html('❯');
        //     $('.slick-prev').html('❮');
        //     $('.slick-dots li button').html("");
        // });
        if ($(window).width() < 767) {
            $('.mobcta').click(function () {
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $('.name').focus();
            });
        }

        $('.cta.tc').click(function () {
            $('body').addClass('open tc');
        });


        $('.close , .otherno').click(function () {
            $('body#home').removeClass('open').removeClass('mobileotp ').removeClass('noscroll').removeClass('tc');;
        });

        $('.cta.mobileotp').click(function () {
            $('body').addClass('open mobileotp noscroll');
            $('body').removeClass('tc');
        });

        // $("body").scroll(function () {
        //     alert("test")
        // });

        // $('.cta.dark.inline-block').click(function () {

        //     $('.readmoreblock').slideToggle();
        // });

        // $('#slidetoggle').click(function(){
        //     alert("test");
        // });
    }

    handleClickOnOpenAccount = () => {
        this.setState({
            mobileOpenAccountButtonClick: true
        })
    }

    closeMobileForm = () => {
        this.setState({
            mobileOpenAccountButtonClick: false
        })
    }

    handleClosePopup = () => {
        this.setState({
            open: false
        })
    }

    closeProPartnerPopup = () => {
        this.setState({ openProPartnerPopup: false })
    }

    handleOkClick = () => {
        $('.popupcta')[0].click();
    }

    handleCurrentOTPForm = (fullName, mobileNo, isSouthIndianSelected, showPartner, partnerCode, selectedLanguage, referralCode) => {
        this.setState({ regFullName: fullName, regMobileNo: mobileNo, isSouthIndianSelected: isSouthIndianSelected, showPartner: showPartner, partnerCode: partnerCode, referralCode: referralCode, selectedLanguage: selectedLanguage })
    }

    render() {
        return (
            <>
                {/* <RegHeader /> */}
                {/* <div class="tophead">
                    <div class="header">                    </div>
                    <div class="breadcrumb-wrapper">
                        <div class="container">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="https://www.motilaloswal.com/">
                                        <Image src={reghome} className="breadcrumb-icon" alt={''} width="" height="" />
                                    </a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Open Demat Account</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <section>
                        <div class="container">
                            <div class="msection">

                                <div class="left">
                                    {this.state.mobileOpenAccountButtonClick &&
                                        <i
                                            className={classNames("fa fa-times", "modal-close-icon", "reg-close")}
                                            onClick={this.closeMobileForm}
                                        >
                                        </i>}
                                    {!this.state.mobileOpenAccountButtonClick && <RegNewBanner
                                        isBranchUser={this.props.user.applicationType === userType.crm}
                                        isBaUser={this.props.user.applicationType === userType.pp}

                                    />}

                                    {!this.state.mobileOpenAccountButtonClick &&
                                        <>
                                            <RegInfo />
                                            {this.state.showRegInfo ? <RegAccOpenInfo /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                                            {this.state.showRegInfo ? this.state.showRegInfo1 ? <RegInfoPage3 /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                            {this.state.showRegInfo1 ? this.state.showRegInfo2 ? <RegProcedureList /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                        </>
                                    }


                                </div>

                                <div class="right">
                                    <RegForm
                                        formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                        isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                        showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                        showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                        showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                        showOTP={this.props.showOTP}
                                    />
                                </div>

                                <SessionExpiredPopup handleOkClick={this.handleOkClick} />

                                <Modal
                                    open={this.state.openProPartnerPopup}
                                    closeOnDocumentClick={false}
                                    lockScroll
                                    modal
                                >
                                    <div className="regPopup-msg">{"Sorry - This link has been disabled. Please click on “Continue” to proceed for account opening process."}</div>
                                    <div className="popup-btn">
                                        <a href={LinkConstant.ekycLiveURL}>
                                            <ProceedButton
                                                handleProceed={this.closeProPartnerPopup}
                                                label={'Continue'}
                                                className={'noMargin'}
                                            />
                                        </a>
                                    </div>
                                </Modal>

                            </div>
                        </div>
                    </section>

                    {!this.state.mobileOpenAccountButtonClick &&
                        <>
                            {this.state.showRegInfo2 ? this.state.showRegInfo3 ? <RegProcedure /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                            <div id="customerreview">
                                <div class="container">
                                    <div class="msection">
                                        <div class="left">

                                            {this.state.showRegInfo3 ? this.state.showRegInfo4 ? <RegNewCustomerReview /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                            {this.state.showRegInfo4 ? this.state.showRegInfo5 ? <RegFAQs /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div > */}
                <div className="tophead">
                    <div class="header">
                    </div>
                    <div class="breadcrumb-wrapper">
                        <div class="container">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="https://www.motilaloswal.com/">
                                        <Image src={reghome} className="breadcrumb-icon" alt={''} width="100%" height="auto" />
                                    </a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Open Demat Account</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <section>
                        <div id="demo"></div>
                        <div id="demo1"></div>
                        <div className="container">
                            <div className="msection">
                                {!this.state.mobileOpenAccountButtonClick && <RegNewBanner
                                    isBranchUser={this.props.user.applicationType === userType.crm}
                                    isBaUser={this.props.user.applicationType === userType.pp}

                                />}
                                <div className="right">
                                    <div className="nformbox">
                                        <RegForm
                                            formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                            isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                            showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                            showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                            showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                            showOTP={this.props.showOTP}
                                            middleFormFullName={this.state.regFullName}
                                            middleFormMobile={this.state.regMobileNo}
                                            handleCurrentOTPForm={this.handleCurrentOTPForm}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* {this.state.showRegInfo ?  */}
                <Steps />
                {/* : this.state.isMobileDevice ? <Steps />
                        : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                <section>
                    {/* {this.state.showRegInfo1 ?  */}
                    <RegBasicInfo />
                    {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                    {/* {this.state.showRegInfo2 ?
                        <>{this.state.regMobileNo === "" && */}
                    <RegMiddleForm
                        showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                        showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                        handleCurrentOTPForm={this.handleCurrentOTPForm}
                        regMobileNo={this.state.regMobileNo}
                        regFullName={this.state.regFullName}
                    />
                    {/* }</> :
                        <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                    <div className="container">
                        <div class="mtop40"></div>
                        {/* {this.state.showRegInfo3 ?  */}
                        <RegBenifits />
                        {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                        {/* {this.state.showRegInfo4 ?  */}
                        <ShowInvestments />
                        {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                        <div className="text-center">
                            {/* {this.state.showRegInfo5 ?  */}
                            <CustomerReviewSjet />
                            {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                            {
                                this.state.showRegInfo6 ?  */}
                            <SjetBlogs getRegBlogsDetails={this.state.getRegBlogsDetails} />
                            {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>
                            }
                            {this.state.showRegInfo7 ?  */}
                            <FAQsJet />
                            {/* : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                        </div>
                    </div>
                </section>
                <div class="mtop40"></div>
                {/* {this.state.showRegInfo1 ?
                    <>
                        {this.state.regMobileNo === "" && */}
                <StickyForm
                    showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                    showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                    handleCurrentOTPForm={this.handleCurrentOTPForm}
                    regMobileNo={this.state.regMobileNo}
                    regFullName={this.state.regFullName}
                />
                {/* }
                    </> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}

                <div class="mobcta mob">
                    <a href="#" class="cta dark">
                        Open Demat Account
                    </a>
                </div>
                <RegFooter />
                <a href="#sessionExpired" className="sessionExpired d-none">sessionExpiredPopup</a>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
    getAuRmDetails: (data) => dispatch(getAuRmDetails(data)),
    setHomePageQueryParams: (data) => dispatch(setHomePageQueryParams(data)),
})

const mapStateToProps = (state) => ({
    formTypeCommingFromParams: state.homePageQueryParams.formTypeCommingFromParams,
    user: state.user,
    popup: state.responseError.responseErrorPopup,
})


export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStatic)
import { put } from 'redux-saga/effects';
import PersonalApi from "../services/personalApi";
import { UPDATE_PERSONAL_DETAIL, SET_PERSONAL_INFO_DROPDOWN, UPDATE_INCOMEPROOF_DETAIL, SET_INCOMEPROOF_INFO_DROPDOWN, GET_NOMINEE_GENDER, GET_NOMINEE_RELATIONSHIP, GET_NOMINEE_TYPE, GET_PERSONAL_DETAILS_USEQUITY, UPDATE_PERSONAL_DETAILS_USEQUITY, SET_PERSONAL_INFO_USEQUITY_DROPDOWN, GET_PERSONAL_INFO_USEQUITY, UPDATE_PERSONAL_INFO_USEQUITY } from '../redux/types/personal';
import { setLoading, clearLoading } from '../redux/actions/loader';
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError';
import { apiErrorResponse } from '../helper/constant';
import { PRIMARY_APPLICANT_ADDRESS } from '../redux/types/nominee';

let api = new PersonalApi();

export function* getPersonalInfo({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.getPersonalInfo(payload)

        yield put(clearLoading());
        console.log('personal response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: UPDATE_PERSONAL_DETAIL, payload: response.data.Data.PersonalDetails })//input fields values
                yield put({ type: SET_PERSONAL_INFO_DROPDOWN, payload: response.data.Data.DropDownValues })//drop down values of personal details form
                yield put({ type: GET_NOMINEE_GENDER, payload: response.data.Data.GETNOMINEEGENDER })//drop down values of nominee details form
                yield put({ type: GET_NOMINEE_RELATIONSHIP, payload: response.data.Data.GETNOMINEERELATIONSHIP })//drop down values of nominee details form
                yield put({ type: PRIMARY_APPLICANT_ADDRESS, payload: response.data.Data.PRIMARYAPPLICANTADDRESS[0] })
                yield put({ type: GET_NOMINEE_TYPE, payload: response.data.Data.NomineeType })
                resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getIncomeProofDetails({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.getIncomeProofDetails(payload)

        yield put(clearLoading());
        console.log('getIncomeProofDetails_response', response.data.Data.getIncomeProofDetails);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: UPDATE_INCOMEPROOF_DETAIL, payload: response.data.Data.getIncomeProofDetails })//input fields values
                yield put({ type: SET_INCOMEPROOF_INFO_DROPDOWN, payload: response.data.Data.DropDownValues })//drop down values of personal details form

                resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}


export function* updatePersonalInfo({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let { data, PersonalDetails } = payload;
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));

        let response = yield api.updatePersonalInfo(data)

        yield put(clearLoading());
        console.log('personal update response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: UPDATE_PERSONAL_DETAIL, payload: PersonalDetails })//input fields values
                resolve && resolve(response.data.Data[0]);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* updatePersonalOtherDetails({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());

        let response = yield api.updatePersonalOtherDetails(payload)

        yield put(clearLoading());
        console.log('personal response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getPersonalDetailsUsEquity({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};

        yield put(setLoading());
        console.log('personal request', payload);
        let response = yield api.getPersonalDetailsUsEquity(payload)

        yield put(clearLoading());
        console.log('personal response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                // yield put({ type: GET_PERSONAL_DETAILS_USEQUITY, payload: response.data.Data.GETPERSONALDETAILS_USEQUITY })//input fields values                
                yield put({ type: SET_PERSONAL_INFO_USEQUITY_DROPDOWN, payload: response.data.Data.USEQUITYDROPDOWN })//drop down values of personal details form                
                yield put({ type: GET_PERSONAL_INFO_USEQUITY, payload: response.data.Data.GETPERSONALDETAILS_USEQUITY })//input fields values
                // yield put({ type: UPDATE_PERSONAL_DETAIL, payload: response.data.Data.PersonalDetails })//input fields values
                // yield put({ type: SET_PERSONAL_INFO_DROPDOWN, payload: response.data.Dassta.DropDownValues })//drop down values of personal details form
                // yield put({ type: GET_NOMINEE_GENDER, payload: response.data.Data.GETNOMINEEGENDER })//drop down values of nominee details form
                // yield put({ type: GET_NOMINEE_RELATIONSHIP, payload: response.data.Data.GETNOMINEERELATIONSHIP })//drop down values of nominee details form
                // yield put({ type: PRIMARY_APPLICANT_ADDRESS, payload: response.data.Data.PRIMARYAPPLICANTADDRESS[0] })
                // yield put({ type: GET_NOMINEE_TYPE, payload: response.data.Data.NomineeType })
                // resolve && resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}


export function* updatePersonalDetailsUsEquity({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let { data, PersonalDetails } = payload;
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));

        let response = yield api.updatePersonalDetailsUsEquity(data)

        yield put(clearLoading());
        console.log('personal update response', response);
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                yield put({ type: UPDATE_PERSONAL_INFO_USEQUITY, payload: response.data.Data })//input fields values
                resolve && resolve(response.data.Data[0]);
            }
            else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        console.log('catch', e)
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

import OfferApi from "../services/offerApi";
import { put, call } from 'redux-saga/effects';
import { saveOfferRedeem, saveInitOffer, saveInitSkip } from "../redux/actions/offer";
import { push } from 'connected-react-router';
import { PAYMENT_STATUS_RESPONSE, PAYMENT_STATUS_FAIL } from '../redux/types/offer';
import Cookie from '../lib/cookies';
import { clearLoading, setLoading } from "../redux/actions/loader";
import { apiErrorResponse } from '../helper/constant';
import { showSnackBar } from "../lib/snackBar";
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new OfferApi();
let cookie = new Cookie();

export function* offerRedeem({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {

        yield put(setLoading());

        let response = yield api.initiateRedeem(payload)

        yield put(clearLoading());
        if (response.data && response.data.Status) {
            //store save
            yield put(saveOfferRedeem(response.data))
            resolve(response.data);
        }
        else {
            responsePayload = { type: "error", message: response.data.Data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}

export function* initSkip({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());

        let response = yield api.initiateSkip(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                //store save
                console.log('initiateOffer response.data', response.data.Data);
                yield put(saveInitSkip(response.data.Data));
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* initOffer({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.initiateOffer(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                console.log("response.data", response.data)
                resolve(response.data.Data);
                yield put(saveInitOffer(response.data.Data));
                responsePayload = { type: "success", message: 'Payment Initiated' }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            } else {
                responsePayload = { type: "error", message: 'Please select the offer' }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* offerpaymentStatus({ type, payload }) {

    try {
        let responsePayload = {};
        let { data } = yield api.offerpaymentStatus(payload)
        console.log('data offerpaymentStatu', data)
        if (data.Status) {
            if (data.Data) {

                responsePayload.commodityPaymentStatus = data.PAYMENTSTATUS[0].COMMPAYMENTSTATUS ? data.PAYMENTSTATUS[0].COMMPAYMENTSTATUS : "";
                responsePayload.equityPaymentStatus = data.PAYMENTSTATUS[0].EQPAYMENTSTATUS ? data.PAYMENTSTATUS[0].EQPAYMENTSTATUS : "";

                cookie.removeCookie('pInit');

                yield put({ type: PAYMENT_STATUS_RESPONSE, payload: responsePayload })
                showSnackBar({
                    message: 'Payment Successfully Done'
                })
                yield put(push('/dad/redeem'));
                cookie.removeCookie('pInit');
            } else {

                yield put({ type: PAYMENT_STATUS_FAIL })
                showSnackBar({
                    message: 'Payment Failed'
                })
            }
        }
        else {
            let responsePayload = {};
            responsePayload = { type: "error", message: data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: 'Payment Failed' }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        // yield put({ type: PAYMENT_STATUS_FAIL })

    }
}


export function* downloadesignPdfForm({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let { data } = yield api.downloadesignPdfForm(payload)
        console.log(data, " data in download ");
        if (data.Status) {
            yield put(clearLoading());
            resolve(data.Data);

            if (data.Data) {
                console.log(" API response=>", data);

                var popup_window = window.open(data.Data, '_blank');
                try {
                    popup_window.focus();
                } catch (e) {
                    responsePayload = {
                        type: "error", message: 'Pop-up Blocker is enabled! Please add this site to your exception list.'
                    }
                    yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

                }
                console.log(" API response=>", data);
                //window.open(data, '_blank');//new tab

            } else {
                responsePayload = { type: "error", message: data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getOffersList({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getOffersList(payload)
        yield put(clearLoading());

        console.log("response getOffersList", response);

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}

export function* registerWalletOffer({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.registerWalletOffer(payload)
        yield put(clearLoading());

        console.log("response registerWalletOffer", response);

        if (response.data && response.data.Status) {
            showSnackBar({
                type: 'success',
                message: response.data.Message
            })
            resolve && resolve();
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}
export function* getValuePackDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getValuePackDetails(payload)
        yield put(clearLoading());

        console.log("response getValuePackDetails", response);

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}

export function* initiateValuepack({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.initiateValuepack(payload)
        yield put(clearLoading());

        console.log("response initiateValuepack", response);

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}

export function* updateValuePackDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.updateValuePackDetails(payload)
        yield put(clearLoading());

        console.log("response updateValuePackDetails", response);

        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}
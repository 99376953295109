import React from 'react'
import classNames from 'classnames';
import Input from '../../atom/input'
import { isValidNumber } from '../../../helper/method'

const RegMobileNo = (props) => {

    const handleChange = ({ name, value }) => {
        console.log('value', value, isValidNumber(value))
        if (value.length <= 10 && isValidNumber(value.trim())) {
            props.onChange && props.onChange({ name, value })
        }
    }


    const handleKeyPress = (evt) => {
        // Reference
        // https://stackoverflow.com/questions/1444477/keycode-and-charcode#:~:text=keyCode%3A%20Returns%20the%20Unicode%20value,other%20type%20of%20keyboard%20event.&text=charCode%3A%20Returns%20the%20Unicode%20value,pressed%20during%20a%20keypress%20event.
        evt = evt || window.event;
        var charCode = evt.which || evt.keyCode;
        var charStr = String.fromCharCode(charCode);
        // alert(charStr + 'charStr' + charCode);
        if (charStr === "+" || charStr === "-" || charStr === "." || charStr === "e" || charStr === "E") {
            evt.preventDefault();
            return false;
        }

    }

    return (
        <div className="form-group">
            <label for="exampleFormControlInput1" className="desk">{props.Label}</label>
            <div className={"mobnum"}>
                {!props.customhook && <span>+91</span>}
                <Input type="tel" maxLength="10"
                    {...props}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className="form-control"
                />
            </div>

            <div className={classNames('reg-error dad-error disappear text-left', { 'appear': props.error })}>{props.error}</div>

        </div>
    )
}
export default RegMobileNo
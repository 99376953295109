import { endpoints } from './endpoints';
import client from '../lib/crypto/client';

const getFetchBadAssSecure = () => {
    if ("object" === typeof window) {
        let getBadObj = ("object" === typeof window.badAssGuys) ? window.badAssGuys.badAssSecure : ""; // "6693614066929259"
        return getBadObj
    } else {
        return ""
    }
}

const fetchHeader = { "Content-Type": "application/json" }
// const fetchHeaderFile = { "Content-Type": "application/json", "mimeType": "multipart/form-data" }
const fetchHeaderFile = {}// { "Content-Type": "multipart/form-data" }
const isCrypto = process.env.REACT_APP_CRYPTO === "on" ? true : false;

export default class Api {

    constructor() {
        this.crypto = new client(); //crypto instance
    }

    fetch = (url, method, body) => {

        console.log(body, 'fetch body');

        let opt = {
            method: method,
            headers: fetchHeader,
            body: body,
            credentials: 'same-origin'

        }

        let encryptedBody = isCrypto ? { "body": this.crypto.encryptBody(body) } : body; // justify and encrypt the body with crypotgraphic algos
        let getBadAssGuySecure = getFetchBadAssSecure(); // fetch the bad ass secure guy

        if (body) opt.body = JSON.stringify(encryptedBody); // add encrypted body if crypto is on
        if (getBadAssGuySecure) opt.headers.meta = getBadAssGuySecure; // add the encrypted AES key to header

        opt.headers.ekycsession = sessionStorage.tId;//multi session

        opt.headers.my_token = process.env.REACT_APP_MY_TOKEN;

        // let decryptedBody = this.crypto.decryptBody(encryptedBody);
        // console.log(decryptedBody, 'decryptedBody');
        console.log(encryptedBody, 'fetch encryptedBody');

        return fetch(url, opt).then((response) => response.json())

    }

    fetchFile = (url, method, body) => {

        let opt = {
            method: method,
            headers: fetchHeaderFile,
            body: body,
            credentials: 'same-origin'
        }
        console.log('calling from service');

        opt.headers.ekycsession = sessionStorage.tId;//multi session

        return fetch(url, opt).then((response) => response.json())

    }

    buildUrl = (path) => {
        return `${endpoints.baseUrl}${path}`;
    }

}
import { produce } from 'immer';
import { SET_USER, INIT_SET_USER_RESPONSE } from '../types/user';

const initialState = {
    id: null,
    fullName: null,
    mobileNumber: null,
    applicationType: null,
    isOtpRequired: true,
    clientCode: null,
    uniqueId: null,
    email: null,
    diyId: null,
    offerpaymentcompleted: "",
    paymentStatusEq: "",
    paymentStatusComm: "",
    strPSource: null,
    isDiy: "",
    pageName: "",
    diymass: null,
    isEmailUpdated: null,
    valuepackPaymentCompleted: "",
    PaymentStatus: null,
    uniqueEmailId: '',
    referralCode: null,
    lsqId: null,
    opportunityId: null,
    branchCode: "",
    branchEmpCode: null,
    isAlliance: "",
    isBRBALogin: "",
    appLoginIdFromRedirect: "",
    branchEmpCodeFromRedirect: "",
    isWhatsappUser: true,
    whatsappUserPageName: "AADHAR",   // AADHAR   // CLIENTPHOTO   // PANSIGN    // INCOMEPROOFFO
    showReferralBanner: false,
    redirectionURL: "",
    oneMoneyStatus:"",
    IsReversePennyDrop:"",
    MFCust: "",
    intrestedIN: "",
    isCrop:"",
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_USER:
            return { ...state, ...payload };
        case INIT_SET_USER_RESPONSE:
            return { ...state, ...payload };
        default:
            return state
    }

})

export default reducer;
import Api from '.';
import { endpoints } from './endpoints';

export default class ProofApi extends Api {

    uploadProof(data) {
        let url = this.buildUrl(endpoints.proof.uploadProof)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    uploadProofUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.uploadProofUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getProofList(data) {
        let url = this.buildUrl(endpoints.proof.getProofList)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    deleteProof(data) {
        let url = this.buildUrl(endpoints.proof.deleteProof)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    deleteProofDetailsUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.deleteProofDetailsUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    viewProof(data) {
        let url = this.buildUrl(endpoints.proof.viewProof)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    viewUploadedImageUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.viewUploadedImageUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getProofVerificationStatus(data) {
        let url = this.buildUrl(endpoints.proof.getProofVerificationStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    submitProof(data) {
        let url = this.buildUrl(endpoints.proof.submitProof)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    submitOCRProof(data) {
        let url = this.buildUrl(endpoints.proof.submitOCRProof)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    captureAdharXML(data) {
        let url = this.buildUrl(endpoints.proof.captureAdharXML)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    fetchAdharXML(data) {
        let url = this.buildUrl(endpoints.proof.fetchAdharXML)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    checkUncheckMobileAdharLink(data) {
        let url = this.buildUrl(endpoints.proof.checkUncheckMobileAdharLink)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    gotoVerificationPageApi(data) {
        let url = this.buildUrl(endpoints.proof.gotoVerificationPageApi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validatePAN(data) {
        let url = this.buildUrl(endpoints.proof.validatePAN)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateAadhaarApi(data) {
        let url = this.buildUrl(endpoints.proof.validateAadhaarApi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateAccountAndIfsc(data) {
        let url = this.buildUrl(endpoints.proof.validateAccountAndIfsc)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateEmailOTP(data) {
        let url = this.buildUrl(endpoints.proof.validateEmailOTP)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    resendEmailOTP(data) {
        let url = this.buildUrl(endpoints.proof.resendEmailOTP)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    pdfUpload(data) {
        let url = this.buildUrl(endpoints.proof.pdfUpload);
        let { FileType, Extension, file, ApplicationType, IncomeProofType, BANKPROOF_TYPE, CMRCOPYPROOF_TYPE, CallBankOcr } = data;

        let formData = new FormData();
        formData.append('FileType', FileType);
        formData.append('Extension', Extension);
        formData.append('file', file);
        formData.append('ApplicationType', ApplicationType);
        formData.append('IncomeProofType', IncomeProofType);
        formData.append('BANKPROOF_TYPE', BANKPROOF_TYPE);
        formData.append('CMRCOPYPROOF_TYPE', CMRCOPYPROOF_TYPE);
        formData.append('CallBankOcr', CallBankOcr);
        return this.fetchFile(url, "POST", formData).then(response => response);
    }
    addRemovePOA(data) {
        let url = this.buildUrl(endpoints.proof.addRemovePOA)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getMICR(data) {
        let url = this.buildUrl(endpoints.proof.getMICR)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getBankDetails(data) {
        let url = this.buildUrl(endpoints.proof.getBankDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getOneMoneyBankDetails(data) {
        let url = this.buildUrl(endpoints.proof.getOneMoneyBankDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    updateMobileNumber(data) {
        let url = this.buildUrl(endpoints.proof.updateMobileNumber)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    OneMoneyRedirection(data) {
        let url = this.buildUrl(endpoints.proof.OneMoneyRedirection)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    deleteBankDetails(data) {
        let url = this.buildUrl(endpoints.proof.deleteBankDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    deleteNominee(data) {
        let url = this.buildUrl(endpoints.proof.deleteNominee)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateNomineePAN(data) {
        let url = this.buildUrl(endpoints.proof.validateNomineePAN)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    captureLivePhoto(data) {
        let url = this.buildUrl(endpoints.proof.captureLivePhoto)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    saveLivePhoto(data) {
        let url = this.buildUrl(endpoints.proof.saveLivePhoto)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    digiLockerRequest(data) {
        let url = this.buildUrl(endpoints.proof.digiLockerRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    fairXpayKycRequestRedirectionSuperApp(data) {
        let url = this.buildUrl(endpoints.proof.fairXpayKycRequestRedirectionSuperApp)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    digiLockerResponse(data) {
        let url = this.buildUrl(endpoints.proof.digiLockerResponse)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    shareLink(data) {
        let url = this.buildUrl(endpoints.proof.shareLink)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validatePanDob(data) {
        let url = this.buildUrl(endpoints.proof.validatePanDob)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    verifyPanOnName(data) {
        let url = this.buildUrl(endpoints.proof.verifyPanOnName)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getPanDetails(data) {
        let url = this.buildUrl(endpoints.proof.getPanDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getPANBankDetails(data) {
        let url = this.buildUrl(endpoints.proof.getPANBankDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getCmrCopyDetails(data) {
        let url = this.buildUrl(endpoints.proof.getCmrCopyDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateDpIdApi(data) {
        let url = this.buildUrl(endpoints.proof.validateDpIdApi)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getBankList(data) {
        let url = this.buildUrl(endpoints.proof.getBankList)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getBankListByIfsc(data) {
        let url = this.buildUrl(endpoints.proof.getBankListByIfsc)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getMicrBasedOnBankBranch(data) {
        let url = this.buildUrl(endpoints.proof.getMicrBasedOnBankBranch)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getClientPhoto(data) {
        let url = this.buildUrl(endpoints.proof.getClientPhoto)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    updateProofStage(data) {
        let url = this.buildUrl(endpoints.proof.updateProofStage)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    insertUpdateStageUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.insertUpdateStageUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getClientSignature(data) {
        let url = this.buildUrl(endpoints.proof.getClientSignature)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAadharDetails(data) {
        let url = this.buildUrl(endpoints.proof.getAadharDetails)
        console.log(data, ' ', url, ' services/proofApi.js');
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    }

    insertNriFlag(data) {
        let url = this.buildUrl(endpoints.proof.insertNriFlag)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    moveBackwordStatus(data) {
        let url = this.buildUrl(endpoints.proof.moveBackwordStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getAadhaarConsent(data) {
        let url = this.buildUrl(endpoints.proof.getAadhaarConsent)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    hypervergeLivePhotoRequest(data) {
        let url = this.buildUrl(endpoints.proof.hypervergeLivePhotoRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ainxtLivePhotoRequest(data) {
        let url = this.buildUrl(endpoints.proof.ainxtLivePhotoRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    ainxtLivePhotoResponse(data) {
        let url = this.buildUrl(endpoints.proof.ainxtLivePhotoResponse)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    hypervergeLivePhotoResponse(data) {
        let url = this.buildUrl(endpoints.proof.hypervergeLivePhotoResponse)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    insertUpdateSmallCasesConsent(data) {
        let url = this.buildUrl(endpoints.proof.insertUpdateSmallCasesConsent)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    livePhotoLocationDetails(data) {
        let url = this.buildUrl(endpoints.proof.livePhotoLocationDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getProofDetails(data) {
        let url = this.buildUrl(endpoints.proof.getProofDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getProofDetailsUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.getProofDetailsUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getAadhaarDetailSuperApp(data) {
        let url = this.buildUrl(endpoints.proof.getAadhaarDetailSuperApp)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    aadhaarLinksUsEquity(data) {
        let url = this.buildUrl(endpoints.proof.aadhaarLinksUsEquity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ainxtDigiLockerRequest(data) {
        let url = this.buildUrl(endpoints.proof.ainxtDigiLockerRequest)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    ainxtDigilockerResponse(data) {
        let url = this.buildUrl(endpoints.proof.ainxtDigilockerResponse)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validatePansign(data) {
        let url = this.buildUrl(endpoints.proof.validatePansign)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    updateOneMoneyStatus(data) {
        let url = this.buildUrl(endpoints.proof.updateOneMoneyStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updateMFDFlag(data) {
        let url = this.buildUrl(endpoints.proof.updateMFDFlag)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    generateRevPennyToken(data) {
        let url = this.buildUrl(endpoints.proof.generateRevPennyToken)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    saveRevPennyDetails(data) {
        let url = this.buildUrl(endpoints.proof.saveRevPennyDetails)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateBankProofPDF(data) {
        let url = this.buildUrl(endpoints.proof.validateBankProofPDF)
        let { FileType, Extension, file, ApplicationType, IncomeProofType, BANKPROOF_TYPE, CMRCOPYPROOF_TYPE } = data;
        let formData = new FormData();
        formData.append('FileType', FileType);
        formData.append('Extension', Extension);
        formData.append('file', file);
        formData.append('ApplicationType', ApplicationType);
        formData.append('IncomeProofType', IncomeProofType);
        formData.append('BANKPROOF_TYPE', BANKPROOF_TYPE);
        formData.append('CMRCOPYPROOF_TYPE', CMRCOPYPROOF_TYPE);
        return this.fetchFile(url, "POST", formData).then(response => response);
    }
    validateBankProofImage(data) {
        let url = this.buildUrl(endpoints.proof.validateBankProofImage)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    validateBankProofPdfBase64(data) {
        let url = this.buildUrl(endpoints.proof.validateBankProofPdfBase64)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    uploadMultiPartBase64(data) {
        let url = this.buildUrl(endpoints.proof.uploadMultiPartBase64)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

}

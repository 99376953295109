export const UPLOAD_PROOF = 'UPLOAD_PROOF';
export const UPLOAD_PROOF_USEQUITY = 'UPLOAD_PROOF_USEQUITY';
export const SAVE_UPLOAD_PROOF_DATA = 'SAVE_UPLOAD_PROOF_DATA';

export const UPLOAD_MULTI_PART = 'UPLOAD_MULTI_PART';

export const GET_PROOF_LIST = 'GET_PROOF_LIST';
export const SAVE_PROOF_LIST = 'SAVE_PROOF_LIST';

export const DELETE_PROOF = 'DELETE_PROOF';

export const DELETE_PROOF_USEQUITY = 'DELETE_PROOF_USEQUITY';

export const UPDATE_AFTER_DELETE_PROOF = 'UPDATE_AFTER_DELETE_PROOF'

export const VIEW_PROOF = 'VIEW_PROOF';

export const VIEW_UPLOAD_IMAGE_USEQUITY = "VIEW_UPLOAD_IMAGE_USEQUITY";

export const SAVE_BASE64_PROOF_CONTENT = 'SAVE_BASE64_PROOF_CONTENT';

export const GET_PROOF_VERIFICATION_STATUS = 'GET_PROOF_VERIFICATION_STATUS';
export const SUBMIT_PROOF = 'SUBMIT_PROOF';

export const SUBMIT_OCR_PROOF = 'SUBMIT_OCR_PROOF';

export const CAPTURE_ADHAR_XML = 'CAPTURE_ADHAR_XML';
export const FETCH_ADHAR_XML = 'FETCH_ADHAR_XML';
export const CHECK_UNCHECK_MOBILE_ADHAR_LINK = 'CHECK_UNCHECK_MOBILE_ADHAR_LINK';
export const GOTO_VERIFICATION_PAGE = 'GOTO_VERIFICATION_PAGE';

export const SHOW_PAN_TEXT_BOX = 'SHOW_PAN_TEXT_BOX';
export const HIDE_PAN_TEXT_BOX = 'HIDE_PAN_TEXT_BOX';

export const VALIDATE_ACCOUNT_AND_IFSC = 'VALIDATE_ACCOUNT_AND_IFSC';
export const VALIDATE_PAN = 'VALIDATE_PAN';
export const VALIDATE_AADHAAR = 'VALIDATE_AADHAAR';
export const SAVE_VALIDATE_ADHAR_NO = 'SAVE_VALIDATE_ADHAR_NO';

export const SAVE_VALIDATION_PAN = 'SAVE_VALIDATION_PAN';//after getting response of pan validation
export const SAVE_VALIDATE_ACCOUNT_AND_IFSC = 'SAVE_VALIDATE_ACCOUNT_AND_IFSC';//after getting response of ifsc and acc validation

export const UPDATE_BANK_PROOF = 'UPDATE_BANK_PROOF';

export const UPDATE_IS_ADHAR_XML = 'UPDATE_IS_ADHAR_XML';

export const VALIDATE_EMAIL_OTP = 'VALIDATE_EMAIL_OTP';

export const RESEND_EMAIL_OTP = 'RESEND_EMAIL_OTP';

export const VERIFY_OTP_EMAIL = 'VERIFY_OTP_EMAIL';

export const UPDATE_AFTER_PAN_SITE_REJECTION = 'UPDATE_AFTER_PAN_SITE_REJECTION';

export const ADD_REMOVE_POA = 'ADD_REMOVE_POA';

export const GET_MICR = 'GET_MICR';

export const SET_MICR = 'SET_MICR';

export const DELETE_NOMINEE = 'DELETE_NOMINEE';

export const VALIDATE_NOMINEE_PAN = 'VALIDATE_NOMINEE_PAN';

export const CAPTURE_LIVE_PHOTO = 'CAPTURE_LIVE_PHOTO';

export const SAVE_LIVE_PHOTO = 'SAVE_LIVE_PHOTO';

export const DIGI_LOCKER_REQUEST = 'DIGI_LOCKER_REQUEST';

export const DIGI_LOCKER_RESPONSE = 'DIGI_LOCKER_RESPONSE';

export const FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP = "FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP";

export const FAIR_X_PAY_RESPONSE = "FAIR_X_PAY_RESPONSE";

export const SHARE_LINK = 'SHARE_LINK';

export const VALIDATE_PAN_DOB = 'VALIDATE_PAN_DOB';

export const VERIFY_PAN_ON_NAME = 'VERIFY_PAN_ON_NAME';

export const GET_PAN_DETAILS = 'GET_PAN_DETAILS';

export const GET_PAN_BANK_DETAILS = "GET_PAN_BANK_DETAILS";

export const GET_CMR_COPY_DETAILS = "GET_CMR_COPY_DETAILS";

export const GET_BANK_LIST = "GET_BANK_LIST";

export const GET_BANK_LIST_BY_IFSC = "GET_BANK_LIST_BY_IFSC";

export const GET_MICR_BASED_ON_BANK_BRANCH = "GET_MICR_BASED_ON_BANK_BRANCH";

export const GET_AADHAR_DETAILS = 'GET_AADHAR_DETAILS';

export const VALIDATE_DPID = 'VALIDATE_DPID';

export const GET_CLIENT_PHOTO = 'GET_CLIENT_PHOTO';

export const UPDATE_PROOF_STAGE = 'UPDATE_PROOF_STAGE';

export const UPDATE_PROOF_STAGE_USEQUITY = 'UPDATE_PROOF_STAGE_USEQUITY';

export const GET_CLIENT_SIGNATURE = 'GET_CLIENT_SIGNATURE';

export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';

export const GET_ONEMONEY_BANK_DETAILS = 'GET_ONEMONEY_BANK_DETAILS';

export const UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER';

export const ONE_MONEY_WEB_REDIRECTION = 'ONE_MONEY_WEB_REDIRECTION';

export const DELETE_BANK_DETAILS = 'DELETE_BANK_DETAILS';

export const INSERT_NRI_FLAG = 'INSERT_NRI_FLAG';

export const MOVE_BACKWORD_STATUS = 'MOVE_BACKWORD_STATUS';

export const GET_AADHAAR_CONSENT = 'GET_AADHAAR_CONSENT';

export const HYPERVERGE_LIVE_PHOTO_REQUEST = 'HYPERVERGE_LIVE_PHOTO_REQUEST';

export const AINXT_LIVE_PHOTO_REQUEST = 'AINXT_LIVE_PHOTO_REQUEST';

export const AINXT_LIVE_PHOTO_RESPONSE = 'AINXT_LIVE_PHOTO_RESPONSE';

export const HYPERVERGE_LIVE_PHOTO_RESPONSE = 'HYPERVERGE_LIVE_PHOTO_RESPONSE';

export const INSERT_UPDATE_SMALL_CASES_CONSENT = 'INSERT_UPDATE_SMALL_CASES_CONSENT';

export const LIVE_PHOTO_LOCATION_DETAILS = 'LIVE_PHOTO_LOCATION_DETAILS';

export const GET_PROOF_DETAILS = 'GET_PROOF_DETAILS';

export const GET_PROOF_DETAILS_USEQUITY = 'GET_PROOF_DETAILS_USEQUITY';

export const GET_AADHAR_DETAILS_SUPERAPP = "GET_AADHAR_DETAILS_SUPERAPP";

export const AADHAARLINKUSEQUITY = "AADHAARLINKUSEQUITY";

export const AINXT_DIGILOCKER_REQUEST = "AINXT_DIGILOCKER_REQUEST";

export const AINXT_DIGILOCKER_RESPONSE = "AINXT_DIGILOCKER_RESPONSE";

export const VALIDATE_PANSIGN = "VALIDATE_PANSIGN";

export const UPDATE_ONE_MONEY_STATUS = "UPDATE_ONE_MONEY_STATUS";

export const SAVE_REV_PENNY_DETAILS = "SAVE_REV_PENNY_DETAILS";

export const UPDATE_MFD_FLAG = "UPDATE_MFD_FLAG";

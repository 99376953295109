// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
// import './css/index.css';
// import './css/global.css';

// import './css/main.css';
import './scss/main.scss';
import Router from './components';
import * as serviceWorker from './lib/serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from './lib/history';
import { registerAesKey } from './lib/crypto';

registerAesKey(); // intiate the bad ass person in the world

// Let the reducers handle initial state
const initialState = {}
const store = configureStore(initialState)

ReactDOM.render(
    <Provider store={store}>
        {/* <BrowserRouter> */}
        <ConnectedRouter history={history}>
            <Router />
        </ConnectedRouter>
        {/* </BrowserRouter> */}
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import Api from '.';
import { endpoints } from './endpoints';

export default class PersonalApi extends Api {

    getNomineeRelationshipDropdown(data) {
        let url = this.buildUrl(endpoints.nominee.getNomineeRelationshipDropdown)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getNomineeDetailsById(data) {
        let url = this.buildUrl(endpoints.nominee.getNomineeDetailsById)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getStateCityFromPincode(data) {
        let url = this.buildUrl(endpoints.nominee.getStateCityFromPincode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    bindState(data) {
        let url = this.buildUrl(endpoints.nominee.bindState)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    bindCity(data) {
        let url = this.buildUrl(endpoints.nominee.bindCity)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updateNomineeInfo(data) {
        let url = this.buildUrl(endpoints.nominee.updateNomineeInfo)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/Ellipse1977.svg'
import tick from '../../../assets/images/regscreen/ticksquare.svg'
import videoimg from '../../../assets/images/regscreen/VideoIMG.jpg'

class RegSection2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (

            <section className={this.props.isBaUser ? "sectionpad ptop90" : "sectionpad ptop30"}>
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey font-regular">
                            Open your Demat Account
                        </span>
                        <div>
                            <span className="f30 semibold mobf22">{" "}instantly in 4 easy steps</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop60" />
                <div className="dflex spacebetween mobwrap">
                    <div className="d66">
                        <div className="whitebbox">
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 1</div>
                                    <div className="step f14 lightgrey">
                                        Register with your Mobile Number

                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 2</div>
                                    <div className="step f14 lightgrey">
                                        Verify OTP

                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 3</div>
                                    <div className="step f14 lightgrey">
                                        Upload Documents (Aadhaar, PAN)
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20">
                                <div className="left last">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 4</div>
                                    <div className="step f14 lightgrey">
                                        E-sign with Aadhaar

                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20" style={{ display: "none" }}>
                                <div className="left last">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 5</div>
                                    <div className="step f14 lightgrey">
                                        Enter OTP receAnd done! You are all set to invest and trade.ived
                                        on the mobile number
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d30">
                        <div className="mtop20 mob" />
                        <video
                            className="video"
                            width="100%"
                            height="140"
                            muted
                            controls
                            poster={videoimg} // Add your poster image URL here
                            playsInline={true}
                            webkit-playsinline={true}
                        >
                            <source
                                src="https://dbs7qpzv4mcv.cloudfront.net/open_demat_account.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div className="mtop10" />
                        <span className="font-semi-bold f14 line1">
                            Documents required to open a Demat Account
                        </span>
                        <div className="whitebbox mtop10 pad15">
                            <div className="mtop10 desk" />
                            <div className="dflex mtop10 mobmtop0">
                                <img alt="" src={tick} />
                                <span className="ml10 f14">PAN Card</span>
                            </div>
                            <div className="dflex mtop10">
                                <img alt="" src={tick} />
                                <span className="ml10 f14">Aadhaar Card</span>
                            </div>
                            <div className="mtop10 desk" />
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default RegSection2;

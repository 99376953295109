import esignApi from "../services/esignApi";
import { put } from 'redux-saga/effects'
import { GET_ESIGN_DETAILS_RESPONSE, GET_ESIGN_DETAILS_FAIL, GET_AUTH_MODE_RESPONSE, GET_AUTH_MODE_FAIL, GET_AVAILABLE_CLIENTCODE_RESPONSE, GET_AVAILABLE_CLIENTCODE_FAIL, GENERATE_CLIENTCODE_RESPONSE, GENERATE_CLIENTCODE_FAIL, EMUDRA_RESPONSE, EMUDRA_FAIL, GET_EMUDRA_RESPONSE, GET_EMUDRA_FAIL, PUSH_DATA_IN_DRISHTI_RESPONSE, PUSH_DATA_IN_DRISHTI_FAIL } from '../redux/types/esign'
import { setLoading, clearLoading } from "../redux/actions/loader";
import { apiErrorResponse } from '../helper/constant'
import { showSnackBar } from '../lib/snackBar';
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new esignApi();

export function* getEsignDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getEsignDetails(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getEsignDetails API response=>", response);
            responsePayload.getEsignDetails = response.data.Data[0];
            yield put({ type: GET_ESIGN_DETAILS_RESPONSE, payload: responsePayload.getEsignDetails })
            resolve && resolve(response.data.Data[0]);
        } else {
            yield put(clearLoading());
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }

        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getEsignDetailsKra({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getEsignDetailsKra(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getEsignDetailsKra API response=>", response);
            responsePayload.getEsignDetails = response.data.Data[0];
            yield put({ type: GET_ESIGN_DETAILS_RESPONSE, payload: responsePayload.getEsignDetails })
            resolve && resolve(response.data.Data[0]);
        } else {
            yield put(clearLoading());
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }

        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getEsignDetailsDdpi({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getEsignDetailsDdpi(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getEsignDetailsDdpi API response=>", response);
            responsePayload.getEsignDetails = response.data.Data[0];
            yield put({ type: GET_ESIGN_DETAILS_RESPONSE, payload: responsePayload.getEsignDetails })
            resolve && resolve(response.data.Data[0]);
        } else {
            yield put(clearLoading());
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }

        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getAuthMode({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAuthMode(payload)

        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getAuthMode API response=>", response);
            responsePayload.getAuthMode = response.data.Data.AUTHENTICATION_MODE[0];
            yield put({ type: GET_AUTH_MODE_RESPONSE, payload: responsePayload.getAuthMode })
            resolve && resolve(response.data.Data.AUTHENTICATION_MODE[0]);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log(e, 'error in getAuthMode');
    }
}

export function* getAuthModeKra({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAuthModeKra(payload)

        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getAuthModeKra API response=>", response);
            responsePayload.getAuthMode = response.data.Data.AUTHENTICATION_MODE[0];
            yield put({ type: GET_AUTH_MODE_RESPONSE, payload: responsePayload.getAuthMode })
            resolve && resolve(response.data.Data.AUTHENTICATION_MODE[0]);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log(e, 'error in getAuthModeKra');
    }
}

export function* getAuthModeDdpi({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getAuthModeDdpi(payload)

        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getAuthModeDdpi API response=>", response);
            responsePayload.getAuthMode = response.data.Data.AUTHENTICATION_MODE[0];
            yield put({ type: GET_AUTH_MODE_RESPONSE, payload: responsePayload.getAuthMode })
            resolve && resolve(response.data.Data.AUTHENTICATION_MODE[0]);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        console.log(e, 'error in getAuthModeDdpi');
    }
}

export function* getAvailableClientCode({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        const { userType = '', ...requiredPayload } = payload;
        payload = requiredPayload;
        let loadingMessageObj = (userType === 'alliance') ? null :
            (userType === ('DIRECT' || 'CRM')) ? {
                // message: "Please wait while we generate your client code and some EXCITING OFFERS for you"
                message: ""
            } : {
                // message: "Please wait while generating client code",
                message: "",
                additionalMessage: "Did you know that 30,000 investors choose Motilal Oswal to help them with their investments last month!"
            }
        yield put(setLoading(loadingMessageObj));
        let response = yield api.getAvailableClientCode(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("getAvailableClientCode API response=>", response.data.Data);
            responsePayload.getAvailableClientCode = response.data.Data;
            yield put({ type: GET_AVAILABLE_CLIENTCODE_RESPONSE, payload: responsePayload.getAvailableClientCode })
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in getAvailableClientCode');
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* generateClientCode({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        const { userType = '', ...requiredPayload } = payload;
        payload = requiredPayload;
        let loadingMessageObj = (userType === 'alliance') ? null :
            (userType === ('DIRECT' || 'CRM')) ? {
                // message: "Please wait while we generate your client code and some EXCITING OFFERS for you"
                message: ""
            } : {
                // message: "Please wait while generating client code",
                message: "",
                additionalMessage: "Did you know that 30,000 investors choose Motilal Oswal to help them with their investments last month!"
            }
        yield put(setLoading(loadingMessageObj));
        let response = yield api.generateClientCode(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("generateClientCode API response=>", response.data.Data);
            responsePayload.generateClientCode = response.data.Data;
            // yield put({ type: GENERATE_CLIENTCODE_RESPONSE, payload: responsePayload.generateClientCode })
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* emudhra({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.emudhra(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in emudhra');
        reject && reject(e);
    }
}

export function* emudhraKra({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.emudhraKra(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in emudhra');
        reject && reject(e);
    }
}

export function* pushDataInDrishti({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading());
        let response = yield api.pushDataInDrishti(payload)
        if (response.data && response.data.Status) {
            // yield put(clearLoading());
            console.log("pushDataInDrishti API response=>", response.data.Data);
            responsePayload.pushDataInDrishti = response.data.Data;
            yield put({ type: PUSH_DATA_IN_DRISHTI_RESPONSE, payload: responsePayload.pushDataInDrishti })
            resolve && resolve(response.data.Data);
        } else {
        }
    } catch (e) {
    }
}

export function* getEmudhra({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for get emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.getEmudhra(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in GET emudhra');
        reject && reject(e);
    }
}

export function* getEmudhraKra({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for get emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.getEmudhraKra(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in GET emudhra');
        reject && reject(e);
    }
}

export function* emudhraDdpi({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.emudhraDdpi(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in emudhra');
        reject && reject(e);
    }
}

export function* getEmudhraDdpi({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        // yield put(setLoading({ message: "Please wait for get emudhra" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.getEmudhraDdpi(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        console.log(e, 'error in GET emudhra');
        reject && reject(e);
    }
}

export function* generateClientCodeMF({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        const { userType = '', ...requiredPayload } = payload;
        payload = requiredPayload;
        let loadingMessageObj = (userType === 'alliance') ? null :
            (userType === ('DIRECT' || 'CRM')) ? {
                // message: "Please wait while we generate your client code and some EXCITING OFFERS for you"
                message: ""
            } : {
                // message: "Please wait while generating client code",
                message: "",
                additionalMessage: "Did you know that 30,000 investors choose Motilal Oswal to help them with their investments last month!"
            }
        yield put(setLoading(loadingMessageObj));
        let response = yield api.generateClientCodeMF(payload)
        if (response.data && response.data.Status) {
            yield put(clearLoading());
            console.log("generateClientCodeMF API response=>", response.data.Data);
            responsePayload.generateClientCodeMF = response.data.Data;
            // yield put({ type: GENERATE_CLIENTCODE_RESPONSE, payload: responsePayload.generateClientCodeMF })
            resolve && resolve(response.data.Data);
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

import React from 'react';
import benefits1 from '../../../assets/images/benefits-01.png';
import benefits2 from '../../../assets/images/benefits-02.png';
import benefits3 from '../../../assets/images/benefits-03.png';
import benefits4 from '../../../assets/images/benefits-04.png';
import benefits5 from '../../../assets/images/benefits-05.png';
import benefits6 from '../../../assets/images/benefits-06.png';
import Image from '../../atom/image';

const RegShowBenifits = () => {
  return (
    <>
      <div class="text-center">
        <h2 class="conbox">
          BENEFITS OF OPENING A DEMAT ACCOUNT WITH MOTILAL OSWAL
        </h2>
        <div class="mtop40"></div>

      </div>

      <div class="mtop40"></div>
      <div id="benifits">
        <div className="mobileswiper">
          <div class="threeblock">
            <div class="blocks">
              <div class="borderbox">
                {/* <Image src={benefits1} alt={''} width="" height="" /> */}
                <div class='sprite benefits-01'></div>
                <h3 class="head3">Lowest-brokerage - the new beau</h3>
                <p>Lost in the search for the lowest-brokerage plan? Now, trade with Motilal Oswal at just ₹20 per lot across segments.</p>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <div class='sprite benefits-02'></div>
                {/* <Image src={benefits2} alt={''} width="" height="" /> */}
                <h3 class="head3">Research-based expert assistance</h3>
                <p>35+ years of experience in equity trading, highly-rewarding research team, research published for more than 250 stocks covering 20+ sectors.
              </p>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <div class='sprite benefits-03'></div>
                {/* <Image src={benefits3} alt={''} width="" height="" /> */}
                <h3 class="head3">5+ Million Happy Customers
              </h3>
                <p>Strengthening a community of smart investors by inducting our knowledge first philosophy.
              </p>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <div class='sprite benefits-04'></div>
                {/* <Image src={benefits4} alt={''} width="" height="" /> */}
                <h3 class="head3">No Paper. 100% Online Demat Account
              </h3>
                <p>100% paperless process. All you need is to sign-up with us and we will guide you till the last minute. No hurries. No worries.</p>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <div class='sprite benefits-05'></div>
                {/* <Image src={benefits5} alt={''} width="" height="" /> */}
                <h3 class="head3">Multiple products on 1 platform
              </h3>
                <p>Whether you need to do an investment in mutual funds or in an IPO or in equities, do it all through only one app - MO INVESTOR
              </p>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <div class='sprite benefits-06'></div>
                {/* <Image src={benefits6} alt={''} width="" height="" /> */}
                <h3 class="head3">Rewards on the go
              </h3>
                <p>Opening a Demat Account online with Motilal Oswal brings a box-full of rewards. ₹0* AMC for the 1st Year, Scratch card rewards up to ₹10,000 and more.
              </p>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  )
}
export default RegShowBenifits;
import React, { Component } from 'react';
import playstore from '../../../assets/images/regscreen/PlayStore2.png'
import appstore from '../../../assets/images/regscreen/AppStore3.png'
import rise from '../../../assets/images/regscreen/riseapplogo.png'
import investor from '../../../assets/images/regscreen/MOInvesternewnew.png'
import bullet from '../../../assets/images/regscreen/bullet.svg'


class RegSection9 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="text-center">
          <h2 className="textlabel">
            <span className="f32 line1 mobf24 lightgrey semibold">
              Turbo-charge your Investments
            </span>
          </h2>
        </div>
        <div className="managebox mtop50 desk">
          <div className="dflex aligncenter wrap spacearound">
            <div className="lft text-center mobflex100">
              <div className="riseapp-logo">
                <picture>
                  <img
                    src={rise}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="mtop20" />
              <div className="f14 px12">
                Superfast trading, powerful research &amp; advance trading tools
              </div>
              <div className="dflex justifycenter mtop20">
                <div>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en"
                  >
                    <img
                      alt=""
                      src={playstore}
                    />
                  </a>
                </div>
                <div className="ml20">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/in/app/mo-investor/id1055577451"
                  >
                    <img
                      alt=""
                      src={appstore}
                    />
                  </a>
                </div>
              </div>
              <div className="mtop20">
                <span className="f14 font-semi-bold mobmtop20">
                  Other tools available for you
                </span>
              </div>
              <div className="mtop20" />
              <div className="dflex wrap justifycenter mtop10">
                <div className="bbl dflex aligncenter ml15">
                  <div>
                    <img
                      alt=""
                      src={bullet}
                    />
                  </div>
                  <span className="f12 ml10">Portfolio Management Service</span>
                </div>
                <div className="bbl dflex aligncenter ml15">
                  <div>
                    <img
                      alt=""
                      src={bullet}
                    />
                  </div>
                  <span className="f12 ml10">Trading Terminal</span>
                </div>
                <div className="bbl dflex aligncenter ml15">
                  <div>
                    <img
                      alt=""
                      src={bullet}
                    />
                  </div>
                  <span className="f12 ml10">Research 360</span>
                </div>
              </div>
            </div>
            <div className="rft">
              <picture>
                <img
                  src={investor}
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="managemob mob text-center">
          <div className="whiteboxnew mtop20">
            <div>
              <div className="riseapp-logo">
                <picture>
                  <source
                    srcSet={rise}
                    type="image/webp"
                    loading="lazy"
                  />
                  <img
                    src={rise}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>
            <div className="mtop20">
              <span className="lightgrey mobf14">
                Superfast trading, powerful research &amp; advance trading tools
              </span>
            </div>
            <div className="mtop30" />
            <picture>
              <img
                src={investor}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
          <div className="appholder">
            <div className="dflex justifycenter gap15">
              <div>
                <a
                  id="wt99_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt191_block_wtColumn1_wt249"
                  tabIndex={376}
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en"
                >
                  <img
                    alt=""
                    src={playstore}
                  />
                </a>
              </div>
              <div>
                <a
                  id="wt99_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt191_block_wtColumn1_wt148"
                  tabIndex={377}
                  target="_blank"
                  href="https://apps.apple.com/in/app/mo-investor/id1055577451"
                >
                  <img
                    alt=""
                    src={appstore}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}

export default RegSection9;
import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'

class RegSection10 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="whatisproduct-sec">
          <div className="cms">
            <h2>
              <div className="text-center line1">
                <span className="lightgrey f32 font-semi-bold mobf24">What is a</span>
              </div>
              <div className="text-center line1">
                <span className="f32 font-semi-bold mobf24"> Demat Account?
                </span>
              </div>
            </h2>
            <div className="mtop30" />
            <p>
              A Demat Account, short for dematerialized account, 
              is an electronic account that holds securities such as stocks,
               bonds, mutual funds, and exchange-traded funds (ETFs) in electronic form.
              It replaces the traditional method of holding physical share certificates.
            </p>
            <div className="mtop20" />
            <p>
            Opening a Demat Account is essential for trading or investing in the stock market. To{" "}
            open a Demat Account in India
              {/* <a
                href="https://www.motilaloswal.com/open-demat-account/"
                className="seohreflink"
                target="_blank"
              >
                open a Demat Account in India
              </a> */}
              {" "}
              , you have to choose a broker that provides you with the best Demat Account services. By opening a Demat Account, you can enjoy the convenience of managing your investments electronically, eliminating the risk of loss or damage to physical share certificates.
               It also provides easy access to your investment portfolio and enables quick and secure transactions.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default RegSection10;
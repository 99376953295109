import React from 'react';
import Popup from "reactjs-popup";
import './modal.css'
import classNames from 'classnames';
import { FaTimes } from 'react-icons/fa'

const Modal = ({ children, ...otherProps }) => {
    return (
        <Popup {...otherProps}>
            {children}
            {
                otherProps.showCloseButton &&
                // <i
                //     className={classNames("fa fa-times", "modal-close-icon")}
                //     onClick={otherProps.onClose}
                // >
                // </i>
                <FaTimes className="modal-close-icon" onClick={otherProps.onClose} />
            }
        </Popup>
    )
}
export default Modal;
import SchemeApi from "../services/schemeApi";
import VerificationApi from "../services/verificationApi";
import { put } from 'redux-saga/effects'
import { GET_SCHEME_DETAILS_BRANCH_RESPONSE, GET_SCHEME_DETAILS_BRANCH_FAIL, GET_USER_PAYMENT_DETAILS_RESPONSE, GET_USER_PAYMENT_DETAILS_FAIL, GET_TRADER_DETAILS_RESPONSE, GET_TRADER_DETAILS_FAIL, INSERT_TRADER_DETAILS_RESPONSE, INSERT_TRADER_DETAILS_FAIL } from '../redux/types/scheme';
import { apiErrorResponse } from '../helper/constant'
import { setLoading, clearLoading } from "../redux/actions/loader";
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new SchemeApi();
let api2 = new VerificationApi();



export function* getSchemeDetailsBranch({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.getSchemeDetailsBranch(payload)

        if (response.data && response.data.Status) {
            if (response.data.Data) {

                responsePayload.schemeDropdown = response.data.Data.SchemeName ? formatSchemeDropDown(response.data.Data.SchemeName) : "";
                responsePayload.dpTariffDropdown = response.data.Data.DPTariff ? formatDpTariffDropDown(response.data.Data.DPTariff) : "";
                responsePayload.additionalSchemeInfoEQ = response.data.Data.AdditionalSchemeInfoEQ;
                responsePayload.additionalSchemeInfoCOMM = response.data.Data.AdditionalSchemeInfoCOMM;
                responsePayload.PaymentsInfo = response.data.Data.PaymentsInfo;
                responsePayload.MarginUpload = response.data.Data.MarginUpload;
                responsePayload.SchemeName = response.data.Data.SchemeName;
                responsePayload.DPTariff = response.data.Data.DPTariff
                responsePayload.SelectedSegment = response.data.Data.SelectedSegment
                responsePayload.SubscriptionPlans = response.data.Data.SubscriptionPlans
                responsePayload.MarginWaiver = response.data.Data.MarginWaiver ? formatMarginWaiverDropDown(response.data.Data.MarginWaiver) : "";

                console.log("sagas++>", responsePayload.SubscriptionPlans)

                yield put({ type: GET_SCHEME_DETAILS_BRANCH_RESPONSE, payload: responsePayload })
                resolve && resolve();

            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

            }
        } else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getUserPaymentDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {

        yield put(setLoading());
        let response = yield api.getUserPaymentDetails(payload)
        yield put(clearLoading());
        console.log("getUserPaymentDetails saga=>", response.data.Data);

        if (response.data && response.data.Status) {
            if (response.data.Data) {
                responsePayload.getUserPaymentDetails = response.data.Data.FUNDTRANSFER[0];

                yield put({ type: GET_USER_PAYMENT_DETAILS_RESPONSE, payload: responsePayload })
                resolve && resolve(responsePayload);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* onSchemeChangeBranch({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.onSchemeChangeBrach(payload)

        if (response.data && response.data.Status) {
            if (response.data.Data) {

                responsePayload.dpTariffDropdown = formatDpTariffDropDown(response.data.Data.DPTariff);
                responsePayload.additionalSchemeInfoEQ = response.data.Data.AdditionalSchemeInfoEQ;
                responsePayload.additionalSchemeInfoCOMM = response.data.Data.AdditionalSchemeInfoCOMM;

                yield put({ type: GET_SCHEME_DETAILS_BRANCH_RESPONSE, payload: responsePayload })
                resolve && resolve(responsePayload);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }

    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* saveSchemeDetailsBranch({ type, payload, resolve, reject }) {
    try {
        let response = yield api.saveSchemeDetailsBrach(payload)
        console.log("Response", response.data.Data);
        if (response.data.Status && response.data) {
            if (response.data.Data) {

                yield put({ type: GET_SCHEME_DETAILS_BRANCH_RESPONSE, payload: response.data.Data })

                resolve && resolve(response.data.Data);
            } else {
                let responsePayload = {};
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: GET_SCHEME_DETAILS_BRANCH_FAIL, payload: responsePayload })
            }
        } else {
            let responsePayload = {};
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* saveSchemeDetailsDirect({ type, payload, resolve, reject }) {
    try {
        let response = yield api.saveSchemeDetailsDirect(payload)
        console.log("Response", response.data.Data);
        if (response.data.Status && response.data) {
            if (response.data.Data) {
                resolve && resolve(response.data.Data);
            } else {
                let responsePayload = {};
                responsePayload = { type: "error", message: response.data.Message }
            }
        }
        else {
            let responsePayload = {};
            responsePayload = { type: "error", message: response.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

function formatSchemeDropDown(arrayOfobj) {
    let sortOptions = arrayOfobj;
    let options = sortOptions.map((option) => {
        return { label: option.SCHEMENAME, value: option.SCHEMEID, id: option.SCHEMEID, selected: option.SELECTED_SCHEMEID, selected_DPTariff: option.DPSCHEME };
    })

    return options;
}

function formatDpTariffDropDown(arrayOfobj) {
    let sortOptions = arrayOfobj;
    let options = sortOptions.map((option) => {
        return { label: option.DP_SCHEMENAME, value: option.DP_SCHEMENAME };
    })

    return options;
}

function formatMarginWaiverDropDown(arrayOfobj) {
    let sortOptions = arrayOfobj;
    let options = sortOptions.map((option) => {
        return { label: option.DISPLAYTEXT, value: option.DOCUMENTKEY, selectedValue: option.SELECTED_DOCUMENT };
    })

    return options;
}

function formatTraderDropDown(arrayOfobj) {
    let sortOptions = arrayOfobj;
    let options = sortOptions.map((option) => {
        return { label: option.TRADERID, value: option.TRADERID, selected: option.SELECTEDTRADERID };
    })

    return options;
}

function formatSubBrokerDropDown(arrayOfobj) {
    let sortOptions = arrayOfobj;
    let options = sortOptions.map((option) => {
        return { label: option.SUBBROKERID, value: option.SUBBROKERID, selected: option.SELECTEDSUBBROKERID };
    })

    return options;
}

export function* getTraderDetails({ type, payload, resolve, reject }) {
    try {

        let response = yield api2.getTraderCode(payload)
        console.log("Response", response);
        if (response.data.Status && response.data) {
            if (response.data.Data) {
                let responsePayload = {};

                responsePayload.getTraderDetails = response.data.Data.Table ? formatTraderDropDown(response.data.Data.Table) : "";
                responsePayload.getSubBrokerDetails = response.data.Data.Table1 ? formatSubBrokerDropDown(response.data.Data.Table1) : "";

                yield put({ type: GET_TRADER_DETAILS_RESPONSE, payload: responsePayload })

                resolve && resolve();
            } else {
                let responsePayload = {};
                if (response.data.code !== 401) {
                    responsePayload = { type: "error", message: response.data.Message }
                    yield put({ type: GET_TRADER_DETAILS_FAIL, payload: responsePayload })
                }
            }
        }
        else {
            let responsePayload = {};

            responsePayload = { type: "error", message: response.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}


export function* insertTraderDetails({ type, payload, resolve, reject }) {
    try {

        let response = yield api2.insertTraderCode(payload)
        console.log("Response", response);
        if (response.data.Status && response.data) {
            if (response.data.Data) {
                let responsePayload = {};
                responsePayload.insertTraderDetails = response.data.Data;

                yield put({ type: INSERT_TRADER_DETAILS_RESPONSE, payload: responsePayload })

                // resolve && resolve();
                resolve && resolve(response.data.Data);
            } else {
                let responsePayload = {};
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: INSERT_TRADER_DETAILS_FAIL, payload: responsePayload })
            }
        }
        else {
            let responsePayload = {};
            responsePayload = { type: "error", message: response.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}
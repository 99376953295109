import React from 'react'
import classNames from 'classnames';

const RegOrangeText = (props) => {
  const { className, label, ...otherProps } = props
  return (
    <span
      className={classNames("font-default-opensans orange-text", { [props.className]: props.className })}
      {...otherProps}
    >
      {label}
    </span>
  )
}

export default React.memo(RegOrangeText);
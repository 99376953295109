import React from 'react'
import Modal from '../../molecules/modal'
import ProceedButton from '../../molecules/proceedButton';
import RegInput from '../../molecules/regInput';
import classNames from 'classnames'

const RegMobileOtpBottomSheet = ({ handlePopupClose, handleResendOTP, handleResumeApplication, otpValue, otpError, onChange, onBlur, showOtpTimer, otpTimer, disableResendButton }) => {
    return (
        <>
            <div class="slideupbox mobileotp forceslide mob newregBack">
                <div class="close mob" onClick={handlePopupClose}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3007 0.710215C12.9107 0.320215 12.2807 0.320215 11.8907 0.710215L7.0007 5.59022L2.1107 0.700215C1.7207 0.310215 1.0907 0.310215 0.700703 0.700215C0.310703 1.09021 0.310703 1.72022 0.700703 2.11022L5.5907 7.00022L0.700703 11.8902C0.310703 12.2802 0.310703 12.9102 0.700703 13.3002C1.0907 13.6902 1.7207 13.6902 2.1107 13.3002L7.0007 8.41021L11.8907 13.3002C12.2807 13.6902 12.9107 13.6902 13.3007 13.3002C13.6907 12.9102 13.6907 12.2802 13.3007 11.8902L8.4107 7.00022L13.3007 2.11022C13.6807 1.73022 13.6807 1.09022 13.3007 0.710215Z" fill="#606A76" />
                    </svg>
                </div>

                <div class="nnmreg">
                    Mobile OTP
                </div>

                <div class="mtop20"></div>
                <RegInput
                    name={"otp"}
                    // type={"number"}
                    value={otpValue}
                    error={otpError}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={"Please enter 4 digit Mobile OTP"}
                    maxLength={4}
                    Label={"Enter Mobile OTP"}
                    type={"tel"}
                />

                {/* <div class="mobotp ">
                    <input type="text" class="form-control one inputs" maxlength="1" />
                    <input type="text" class="form-control two inputs" maxlength="1" />
                    <input type="text" class="form-control three inputs" maxlength="1" />
                    <input type="text" class="form-control four inputs" maxlength="1" />
                </div>

                {otpError !== "" && <div className="dad-error mtop10">{otpError}</div>} */}

                <div class="reblk">
                    <div className="blksreg">
                        {showOtpTimer && <span>{"00 : " + otpTimer}</span>}
                    </div>
                    <div className="blksreg">
                        {!disableResendButton &&
                            <span className={classNames("resendotp", { "disableResendButton": disableResendButton })} onClick={handleResendOTP}>Resend OTP</span>
                        }
                    </div>
                </div>

                <div class="mtop10"></div>
                <div className="text-center semibold">
                        <a className="cta newcta f14 mtop15" onClick={handleResumeApplication}>
                            PROCEED
                            <svg
                                width={15}
                                height={10}
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.16667 10L8 8.79167L10.9583 5.83333H0.833336V4.16667H10.9583L8 1.20833L9.16667 0L14.1667 5L9.16667 10Z"
                                    fill="#0E0F11"
                                />
                            </svg>
                        </a>
                    </div>
                {/* <a class="cta dark slidecta" onClick={handleResumeApplication}>
                    Proceed
                </a> */}
            </div>
            <div class="backdrop"></div>

        </>
    )
}
export default RegMobileOtpBottomSheet;
import React, { Component } from 'react'
import Image from '../../atom/image';
import demat1 from '../../../assets/images/demat1.svg'
import demat2 from '../../../assets/images/demat2.svg'
import demat3 from '../../../assets/images/demat3.svg'
import demat4 from '../../../assets/images/demat4.svg'
import demat5 from '../../../assets/images/demat5.svg'
import demat6 from '../../../assets/images/demat6.svg'

class RegInfo3 extends Component {
    render() {
        return (
            <>
                <div class="mtop40"></div>

                <div class="text-center">
                    <div class="conbox">
                        <h2>
                            WHY OPEN DEMAT ACCOUNT WITH MOTILAL OSWAL?
                        </h2>
                    </div>
                    <div class="mtop20"></div>
                </div>

                <div class="twoblock">
                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat1} alt="" />
                            <h3>1 Million+ Happy Demat Account Holders</h3>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat2} alt="" />
                            <h3>
                                High tech 4-star apps for traders &amp; investors</h3>
                        </div>
                    </div>
                </div>

                <div class="twoblock">
                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat3} alt="" />
                            <h3>30+ Years of Research Expertise</h3>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat4} alt="" />
                            <h3>Personal Advisor / Advisory for Customers</h3>
                        </div>
                    </div>
                </div>

                <div class="twoblock">
                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat5} alt="" />
                            <h3>
                                Customized Investment Plans with One Click Investment</h3>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="icmbox">
                            <img src={demat6} alt="" />
                            <h3>
                                Wide Choice of Asset Classes to Invest in
                            </h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default RegInfo3;
import React, { Component } from 'react'
import trophy from '../../../assets/images/trophy.svg';
import Image from '../../atom/image';
import equity from '../../../assets/images/equity-express.svg';

//#region awards Array
const winnerDescription = [
    {
        image: trophy,
        title: 'Best broking business of the year 2018',
        by: 'Moneycontrol'
    },
    {
        image: trophy,
        title: '6-time winner of best performing equity broker',
        by: 'UTI-CNBC TV18'
    },
    {
        image: trophy,
        title: 'Broking house of the year (west)',
        by: 'MCX awards 2019'
    },
];
//#endregion

class RegBanner extends Component {
    render() {
        return (
            <div className="col-lg-8 express-wrapper  order-2 order-lg-1">

                {/* heading */}
                <div className="d-flex align-items-center justify-content-center">

                    <Image src={equity} className="express-icon" alt={''} />
                    {/* <figure> */}
                    {/* <Image src={trophy} alt={""} /> */}
                    {/* </figure> */}

                    <div className="equity-wrapper">
                        <h1 className="text-transform-upper font-josefinSans-bold ">think <span className="orange-text">equity</span></h1>
                        <h1 className="text-transform-upper font-josefinSans-bold ">think <span className="orange-text">motilal oswal</span></h1>
                        <p className="font16 font-default-opensans text-transform-upper">IN volatile stock markets. buy right sit tight</p>
                    </div>

                </div>

                {/* Winner */}
                <div className="d-sm-flex align-items-sm-center justify-content-sm-between mt40">
                    {
                        winnerDescription.map(({ image, title, by }) =>

                            <div key={by} className="col-lg-3 text-center">
                                <figure>
                                    <Image src={image} alt={""} />
                                    <p className="font14 font-default-josefinSans orange-text mt20">
                                        {title}
                                    </p>
                                    <p className="font14 font-default-opensans">{by}</p>
                                </figure>
                            </div>
                        )
                    }
                </div>

                <div className="open-demat-acc align-items-center justify-content-center mt50">
                    <h1 class="text-center">OPEN DEMAT ACCOUNT</h1>
                    {/* <h5>TO INVEST WITH THE BEST BRAND IN INDIA</h5> */}
                </div>

                {/* Request to open account */}
                {!this.props.isBranchUser &&
                    <div className="d-flex align-items-center justify-content-center mt50">
                        {/* <p className="ad-wrapper font16 font-opensans-semibold text-transform-upper">Open an account today and get gold worth <sup><i className="rupees-icon">&#8377;</i></sup><span>2,000</span></p> */}
                        <ul className="list3-dma">
                            {this.props.isBaUser ? null :
                                < li >
                                    <h2 className="font-opensans-semibold text-transform-upper">Zero<sup>*</sup></h2>
                                    <p className="font18 font-opensans-semibold text-transform-upper">AMC for Lifetime</p>
                                </li>
                            }
                            <li>
                                <h2 className="font-opensans-semibold text-transform-upper"><i className="rupees-icon">&#8377;</i>3,000</h2>
                                <p className="font18 font-opensans-semibold text-transform-upper">Worth Discount Coupons</p>
                            </li>
                            {this.props.isBaUser ? null :
                                <li>
                                    <h2 className="font-opensans-semibold text-transform-upper"><i className="rupees-icon">&#8377;</i>1,000<sup>*</sup></h2>
                                    <p className="font18 font-opensans-semibold text-transform-upper">BROKERAGE CASHBACK</p>
                                </li>
                            }
                        </ul>
                    </div>
                }
            </div>
        )
    }
}
export default RegBanner;
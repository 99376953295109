import React, { Component } from 'react';
import classNames from 'classnames'
import RegInput from '../../molecules/regInput';
import RegMobileNo from '../../molecules/regMobileNo';
import RegButton from '../../molecules/regButton';
import { RegCheckbox } from '../../molecules/regCheckbox';
import RegOrangeText from '../../atom/regOrangeText';
import produce from 'immer';
import RegSelect from '../../molecules/regSelect';
import RegHeading from '../../molecules/regHeading';
import ErrorPopup from '../../organism/errorPopup';
import PartnerDetailsPopup from '../../organism/partnerDetailsPopup'
import { regFormType, userType, regFormLinks, apiErrorResponse, mobileExistMessage } from '../../../helper/constant';
import { isValidMobileNo, isValidName, fetchWithWait, isValidAlphaNumeric, getUserMachinePublicIp, getUserMachineLocalIp, getUserCity, getCityFromLatLong, isValidOtp } from '../../../helper/method';
import RegistrationApi from '../../../services/registrationApi';
import history from "../../../lib/history";
import { connect } from 'react-redux';
import { resendOtp, setUser } from '../../../redux/actions/user';
import { setResponseErrorSnack } from '../../../redux/actions/responseError';
import Loader from '../loader';
import { setLoading, clearLoading } from '../../../redux/actions/loader';
import { capitalCase } from 'change-case';
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import RegLanguageSelection from "../../molecules/regLanguageSelection"

let api = new RegistrationApi();

const otpLength = 4;
let TRACK_URL = process.env.REACT_APP_TRACK_URL;
let TRADE_URL = process.env.REACT_APP_FINISH_URL;

class RegOpenAccountFormWithOTP extends Component {

    constructor(props) {
        super();
        this.state = {
            values: {
                fullName: '',
                mobileNo: '',
                otp: '',
                city: '',
                partnerCode: '',
                showPartner: false,
                isPolicyAccepted: true, // checkbox state
                auRm: '',
                fetchedCity: '',
                fetchedState: '',
                isSouthIndian: false,
                referralCode: '',
                TnC: true,
                referralCodeMsg: ''
            },
            error: {
            },
            availableCities: [],
            isShowErrorPopup: false,
            baDetails: {},
            openPartnerDetailDialog: false,
            isBADetailShow: true,
            isDIY: false,
            schemPlanId: null,
            disableFullNameForDiy: true,
            disableMobileForDiy: true,
            disablePartnerCodeForDiy: true,
            showCity: false,
            message: '',
            disableFullName: false,
            disableResendButton: false,
            timer: 60,
            showTimer: false,
            selectedLanguage: '',
            otpTimer: 60,
            showOtpTimer: false,
            showResendButton: false,
            disabledCity: false,
            hasLoaded: false,
        }
    }

    componentDidMount = async () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.otpTimer()
        this.fetchCitiesIfNeeded()
        this.setState(prevState => produce(prevState, draft => {
            draft.values.fullName = this.props.regFullName ? this.props.regFullName : this.props.middleFormFullName;
            draft.values.mobileNo = this.props.regMobileNo ? this.props.regMobileNo : this.props.middleFormMobile;
            draft.values.isSouthIndian = this.props.isSouthIndianSelected;
            draft.values.showPartner = this.props.showPartner;
            draft.values.partnerCode = this.props.partnerCode;
            draft.values.referralCode = this.props.referralCode;
            draft.selectedLanguage = this.props.selectedLanguage;
            draft.disableFullName = this.props.disableFullName;
            draft.values.referralCodeMsg = this.props.referralCodeMsg;
            draft.disabledCity = this.props.disabledCity;
            draft.values.city = this.props.city;
        }), () => {
            let vailid = isValidName(this.props.regFullName);
            console.log('vailid', vailid)
            if (!vailid) {
                this.setState(prevState => produce(prevState, draft => {
                    draft.values.fullName = '';
                    draft.disableFullName = false;
                }))
            }

            setTimeout(() => {
                if ((sessionStorage.client_name !== "" && sessionStorage.client_name !== undefined) || (sessionStorage.client_mobile !== "" && sessionStorage.client_mobile !== undefined)) {
                    window.dataLayer.push({
                        event: 'lead_filled',
                        Parameters: [{
                            'language': 'English',
                            'mobile_number': this.props.user.mobileNumber,
                            'referral_code': '',
                            'south_indian_language': "no",
                            'ekycuserid': this.props.user.id,
                            'lsqid': this.props.user.lsqId,
                            'ProspectOpportunityId': this.props.user.opportunityId
                        }]
                    });
                    console.log("pushed lead filled event => ", window.dataLayer);

                    var presentDate = new Date().toLocaleString().slice(0, 10);
                    window.clevertap.onUserLogin.push({
                        "Site": {
                            "Name": this.state.values.fullName,
                            "Phone": "+91" + this.props.user.mobileNumber, // Phone (with the country code)
                            "Identity": "",
                            "Gender": "", // Can be either M or F
                            DOB: new Date(), // Date of Birth. Date object
                            // optional fields. controls whether the user will be sent email, push etc.
                            "MSG-email": false, // Disable email notifications
                            "MSG-push": true, // Enable push notifications
                            "MSG-sms": true, // Enable sms notifications
                            "MSG-whatsapp": true, // Enable WhatsApp notifications
                            "customer_status": "Lead",
                            "lead_filled_date": presentDate,
                            "ekycuserid": this.props.user.id,
                        }
                    })

                    window.clevertap.event.push(
                        "lead_filled",
                        {
                            'language': 'English',
                            'mobile_number': this.props.user.mobileNumber,
                            'referral_code': '',
                            'south_indian_language': "no",
                            'ekycuserid': this.props.user.id,
                            'lsqid': this.props.user.lsqId,
                            'ProspectOpportunityId': this.props.user.opportunityId
                        }
                    );
                    console.log("pushed lead filled clevertap event => ", window.clevertap);
                }
            }, 10000)   // 10 sec
        })

        this.setState({ showCity: this.props.showCity })

        let ipPublicAddress = await getUserMachinePublicIp();
        console.log("IP public=", ipPublicAddress, ipPublicAddress.IPv4);

        if (ipPublicAddress.city === null || ipPublicAddress.state === null) {
            let cityFromLL = await getCityFromLatLong(ipPublicAddress.latitude, ipPublicAddress.longitude);
            console.log("getCityFromLatLong=", cityFromLL);

            let city = cityFromLL.results[0].components.city ? cityFromLL.results[0].components.city : cityFromLL.results[0].components.state_district ? cityFromLL.results[0].components.state_district : "Others";
            let state = cityFromLL.results[0].components.state ? cityFromLL.results[0].components.state : "Others";

            this.setState(prevState => produce(prevState, draft => {
                if (ipPublicAddress.city !== null) { // 1st preference given to geolocation city
                    draft.values.fetchedCity = ipPublicAddress.city;  // fetchedCity used only for SRC="Website" user
                    draft.values.fetchedState = ipPublicAddress.state;
                } else {
                    draft.values.fetchedCity = city; // if geolocation captures city as null then get city from lat long
                    draft.values.fetchedState = state;
                    // this.setState({ showCity: true })            //show City dropdown when api fetch city as null
                }
            }))
        } else {
            this.setState(prevState => produce(prevState, draft => {
                draft.values.fetchedCity = ipPublicAddress.city;
                draft.values.fetchedState = ipPublicAddress.state;
            }))
        }
        //  if (this.props.regMobileNo == "" || this.props.regMobileNo == undefined) {
        // //     history.push('/')
        // // }

        if (this.state.values.mobileNo == "" || this.state.values.mobileNo == undefined) {
            history.push('/')
        }

        if (this.props.user.applicationType === "CRM") {
            this.getCities();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.diyId !== this.props.user.diyId) {
            console.log(this.props.user.diyId, 'diyId on update');
            this.checkDiyBa();
        }
        if (prevProps.showCity !== this.props.showCity) {
            this.setState({ showCity: this.props.showCity })
        }

        // if (prevProps.user.applicationType !== this.props.user.applicationType && this.props.user.applicationType === "CRM") {
        //     // if (this.props.user.applicationType === "CRM") {
        //     this.getCities();
        //     // }
        // }

        if (prevProps.user.applicationType !== this.props.user.applicationType) {
            this.fetchCitiesIfNeeded();
        }

        if (prevProps.regFullName !== this.props.regFullName || prevProps.regMobileNo !== this.props.regMobileNo) {
            this.setState(prevState => produce(prevState, draft => {
                draft.values.fullName = this.props.regFullName;
                draft.values.mobileNo = this.props.regMobileNo;
                draft.values.isSouthIndian = this.props.isSouthIndianSelected;
                draft.values.showPartner = this.props.showPartner;
                draft.values.partnerCode = this.props.partnerCode;
                draft.disableFullName = this.props.disableFullName;
                draft.values.referralCode = this.props.referralCode;
                draft.selectedLanguage = this.props.selectedLanguage;
                draft.values.referralCodeMsg = this.props.referralCodeMsg;
            }))
        }
        if (prevProps.city !== this.props.city) {
            this.setState(prevState => produce(prevState, draft => {
                draft.values.city = this.props.city;
                console.log("regcity11", draft.values.city)
            }))
        }

        if (prevState.error.apiResponse !== this.state.error.apiResponse) {
            var elmnt = document.getElementsByClassName("reg-error");
            console.log("reg-error element", elmnt);
            elmnt[1].scrollIntoView();
        }
    }

    fetchCitiesIfNeeded = () => {
        if (this.props.user.applicationType === "CRM" && !this.state.hasLoaded) {
            this.getCities();
        }
    };

    otpTimer = () => {
        this.myInterval = setInterval(() => {
            if (this.state.otpTimer > 0) {
                this.setState(({ otpTimer }) => ({
                    otpTimer: otpTimer - 1
                }))
            } else {
                clearInterval(this.myInterval);
                this.setState({ showOtpTimer: false })
            }
            // console.log("Timer1=>", this.state.otpTimer);
        }, 1000);

        setTimeout(() => {
            this.setState({ disableResendButton: false })
        }, 60000)   // 60 sec
    }

    //#region diy user logic.
    checkDiyBa = () => {

        if (!this.props.user.diyId) {
            return;
        }

        let data = {
            _guid: this.props.user.diyId
        }

        // this.props.setLoading({ message: "We are fetching your details..." })
        this.props.setLoading({ message: "" });

        api.getBaDiyDetails(data).then(response => {
            console.log('diy ba response', response);
            this.props.clearLoading()

            if (response.data) { //set user field for diy

                this.props.setUser({
                    applicationType: response.data[0].APPLICATIONTYPE,
                    strPSource: response.data[0].PSOURCE
                })

                this.setState(prevState => produce(prevState, draft => {
                    draft.values.fullName = response.data[0].NAME;
                    draft.values.mobileNo = response.data[0].MOBILE;
                    draft.values.partnerCode = response.data[0].PARTNERCODE;
                    draft.schemPlanId = response.data[0].SCHEMEPLANID;
                    draft.isDIY = true;
                }))

                if (response.data[0].NAME === null || response.data[0].NAME === '') {
                    this.setState({ disableFullNameForDiy: false })
                }

                if (response.data[0].MOBILE === null || response.data[0].MOBILE === '') {
                    this.setState({ disableMobileForDiy: false })
                }

                if (response.data[0].PARTNERCODE === null || response.data[0].PARTNERCODE === '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = false;
                        draft.disablePartnerCodeForDiy = false;
                    }))
                } else if (response.data[0].PARTNERCODE !== null || response.data[0].PARTNERCODE !== '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.data.Message;
                    draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
                draft.isShowErrorPopup = true;
            }))
        })

    }
    //#endregion

    //#region Open Account Request data
    getOpenAccountReqData = () => {
        let data;
        let applicationType = this.props.user.applicationType;
        if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {// PP user = BA user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _strSource: 'Online',
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                _interestedin: 'All',
                ApplicationType: 'PP',
                AppLoginId: this.state.values.partnerCode || this.props.user.appLoginId,
                _applicationFrom: 'Web',
                isOcrUser: '1',
                BranchEmpCode: this.props.user.branchEmpCode
            }
            if (this.state.schemPlanId) {
                data.SCHEMEPLANID = this.state.schemPlanId
                data.BACODE = this.state.values.partnerCode
            }
        } else if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {// direct user
            this.state.showCity ?
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    // _city: this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME,//'MUMBAI',
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                } :
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    _city: this.state.values.fetchedCity,//'MUMBAI',
                    _state: this.state.values.fetchedState,
                    IsSouthCitySelected: this.state.values.isSouthIndian ? "1" : "0",
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                }
            if (this.state.values.showPartner) {//direct user with partner code entered, make it pp
                this.props.setUser({ applicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct })
                data = {
                    ...data,
                    BACODE: this.state.values.partnerCode,
                    ApplicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct,
                    _emailid: "",
                    _state: "",
                    _strPSource: this.props.user.source ? this.props.user.source : '',
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                    IPAddress: "",
                    _Otp: "",
                    _uniqueId: "",
                    AppLoginId: "",//"RETAILDOM",
                    _ROLEMAPPINGID: "",
                    _pincode: "",
                    BranchEmpCode: "",//"RETAILDOM",
                    _FamilyDeclaration: "",
                }
            }

        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {// crm user = branch user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _city: this.state.showCity ? this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME : "",//'MUMBAI',
                _strSource: 'Online',
                _interestedin: 'All',
                ApplicationType: 'CRM',
                AppLoginId: this.props.user.appLoginId,
                _applicationFrom: 'Web',
                BranchEmpCode: this.props.auRm.length > 0 ? this.state.values.auRm : this.props.user.branchEmpCode,
                isOcrUser: '1',
                SHADOWUSERID: this.props.user.branchEmpCode,
                IsDIY: this.props.user.isDiy,
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
            }
        }
        return data;
    }
    //#endregion

    //#region bindcity api calling
    getCities = (params) => {
        try {
            api.getCities({ _stateid: '0' }).then(response => {
                console.log(' getCities response', response);
                if (response.data && response.data.Status) {
                    this.setState({
                        availableCities: response.data.Data.CityDetails,
                        hasLoaded: true, // Update the flag once data is fetched
                    })
                }
                else {
                    this.props.setResponseErrorSnack({ type: 'error', message: response.data.Message });
                }
            })
        } catch (error) {
            this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
        }
    }
    //#endregion

    //#region resume form api call
    callResumeForm = (applicationType) => {
        let type;
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            type = 'dad';
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            type = 'branch';
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            type = 'ba';
        }

        let currentUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/verifyEmail';
        let successUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/esign';

        let data = {
            ReturnURLSUCCESS: successUrl,
            ReturnURLFAILURE: currentUrl,
            Type: 'CLIENTLOGIN'
        }
        this.props.resumeForm(data).then(res => {
            window.location.href = res[0].RESUMEFORM_URL;
        })
    }
    //#endregion

    getPage = (pageName, ApplicationType) => {
        switch (pageName) {
            case 'OTPEMAIL':
                return 'verifyEmail'
            case 'ESIGN':
                return 'esign'
            case 'KRAESIGN':
                return 'esignKra'
            case 'DDPIESIGN':
                return 'esignDdpi'
            case 'PERSONALDETAIL':
                return 'personalAndNominee'
            case 'FUNDTRANSFER':
                if (ApplicationType == userType.crm || ApplicationType == userType.pp) {
                    return 'scheme'
                } else {
                    return 'fundTransferScheme'
                }
            case 'PANBANK':
                return 'panBankUpload'
            case 'PAN':
                return 'pan'
            case 'BANK':
                return 'bank'
            case 'PROOFUPLOAD':
                return 'adhaarPhotoIncomeProof'
            case 'CONGRATULATION':
                return 'congratulation'
            case 'VERIFICATION':
                return 'verification'
            case 'TRACKER':
                return 'tracker'
            case 'TRADE':
                return 'trade'
            case 'CMRCOPY':
                return 'cmr'
            default:
                break;
        }
    }

    //#region resume api calling
    openAccount = () => {
        let applicationType = this.props.user.applicationType;
        let uniqueIdRequest = this.state.uniqueId || this.props.user.uniqueId;

        let data = {
            UserId: this.state.values.mobileNo,
            _password: this.state.values.otp,
            _uniqueId: this.state.uniqueId || this.props.user.uniqueId,
            BranchEmpCode: this.props.user.branchEmpCode || this.props.user.branchEmpCodeFromRedirect,
            AppLoginId: this.props.user.appLoginId || this.props.user.appLoginIdFromRedirect,
            IsDIY: this.props.user.isDiy,
            IsBRBALogin: this.props.user.isBRBALogin
        }
        this.props.setLoading();
        api.resumeApplication(data).then(response => {
            this.props.clearLoading();
            if (response.data.Status) {

                if (response.data.Data.ISSUPERJET !== "1") {
                    window.location = response.data.Data._ReturnURL;
                    return;
                }

                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = "";
                }))

                if (response.data.Data && response.data.Data.Status === "1") {//new user

                    if (uniqueIdRequest !== response.data.Data._uniqueId) {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.otp = "OTP entered is incorrect";
                        }))
                        return;
                    }

                    this.props.setUser({
                        // type: this.state.values.showPartner ? userType.pp : userType.direct,
                        id: response.data.Data.UserId,
                        tokenKey: response.data.Data.TokenKey,
                        uniqueId: response.data.Data._uniqueId,
                        fullName: capitalCase(this.state.values.fullName),
                        mobileNumber: this.state.values.mobileNo,
                        isOtpRequired: true,
                        lsqId: response.data.Data.LSQLeadId,
                        opportunityId: response.data.Data.OpportunityId
                    })

                    let page = this.getPage(response.data.Data.PageName, response.data.Data.ApplicationType); // get page logic
                    console.log('page', page)
                    if (page === 'verification') {
                        this.callResumeForm(response.data.Data.ApplicationType);
                    } else if (page === 'tracker') {
                        window.location = TRACK_URL;
                        return
                    } else if (page === 'trade') {
                        window.location = TRADE_URL;
                        return
                    }

                    if (this.props.user.applicationType === userType.direct || this.props.user.applicationType === 'direct') {

                        if ((sessionStorage.client_name !== "" && sessionStorage.client_name !== undefined) || (sessionStorage.client_mobile !== "" && sessionStorage.client_mobile !== undefined)) {
                            var presentDate = new Date().toLocaleString().slice(0, 10);
                            window.clevertap.onUserLogin.push({
                                "Site": {
                                    "Name": this.state.values.fullName,
                                    "Phone": "+91" + this.state.values.mobileNo, // Phone (with the country code)
                                    "Identity": "",
                                    "Gender": "", // Can be either M or F
                                    DOB: new Date(), // Date of Birth. Date object
                                    // optional fields. controls whether the user will be sent email, push etc.
                                    "MSG-email": false, // Disable email notifications
                                    "MSG-push": true, // Enable push notifications
                                    "MSG-sms": true, // Enable sms notifications
                                    "MSG-whatsapp": true, // Enable WhatsApp notifications
                                    "customer_status": "Lead",
                                    "lead_filled_date": presentDate,
                                    "ekycuserid": this.props.user.id,
                                }
                            })
                        }

                        window.dataLayer.push({
                            event: 'lead_verified',
                            Parameters: [{
                                'mobile_number': this.state.values.mobileNo,
                                'truecaller_verified': "no",
                                'ekycuserid': response.data.Data.UserId,
                                'lsqid': response.data.Data.LSQLeadId,
                                'ProspectOpportunityId': response.data.Data.OpportunityId
                            }]
                        });
                        console.log("pushed event on reg page => ", window.dataLayer);


                        window.clevertap.event.push(
                            "lead_verified",
                            {
                                "mobile_number": this.state.values.mobileNo,
                                "truecaller_verified": "no",
                                "ekycuserid": response.data.Data.UserId,
                                "lsqid": response.data.Data.LSQLeadId,
                                'ProspectOpportunityId': response.data.Data.OpportunityId
                            }
                        )
                        console.log("pushed clevertap event => ", window.clevertap);
                    }

                    this.redirect(response.data.Data.ApplicationType, page);//redirect logic on positive response

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && (applicationType.toLowerCase() === userType.direct.toLowerCase())) {//already existed user

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && this.props.user.strPSource == "LIVEPHOTO") {//already existed user crm/ba

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsPrivilege) {
                    window.location = TRADE_URL;
                } else {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.otp = response.data.Data.Message;
                        draft.isShowErrorPopup = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.otp = response.data.Message;
                    draft.isShowErrorPopup = true;
                }))
            }

        }).catch(error => {
            console.log('resumeApplication error', error);
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
            }))
        })
    }
    //#endregion

    //#region send otp
    sendOtp = (data) => {
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {
                // otp is successfully resend.

                let jumpToResume = regFormType.resumeApp;
                this.handleCurrentForm(jumpToResume, true, response._Uniqueid)

            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.Message;
                    draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();

            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = error.message;
                // draft.isShowErrorPopup = true;
            }))
        })
    }
    //#endregion

    //#region validate partner code api calling
    validatePartnerCode = (partner) => {
        api.validatePartnerCode(partner).then(response => {
            console.log('validatePartnerCode response.data.Data', response.data.Data)
            console.log('validatePartnerCode response.data.Data', response.data)
            if (response.data.Status) {
                if (response.data.Data && response.data.Data.BADETAILS) {
                    if (response.data.Data.BADETAILS[0].STATUS === 0) {//Invalid partner code

                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.partnerCode = 'Invalid Partner Code'
                        }))

                    }
                    else if (response.data.Data.BADETAILS[0].STATUS === 1) {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.baDetails = response.data.Data.BADETAILS[0];
                            // openPartnerDetailDialog: isEkycUpperMost ? false : true,
                            draft.openPartnerDetailDialog = true
                        }))
                        console.log("BA Details=>", this.state.baDetails);

                    }
                } else {

                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.partnerCode = apiErrorResponse
                    }))

                }
            }
            else {

                this.setState(prevState => produce(prevState, draft => {
                    draft.error.partnerCode = apiErrorResponse
                }))
            }

        }).catch(error => {

            this.setState(prevState => produce(prevState, draft => {
                draft.error.partnerCode = apiErrorResponse
            }))

        })

    }
    //#endregion

    //#region open account button clicked. Send req to backend
    handleOpenAccount = () => {
        let valid = this.validateForm();
        console.log('handleOpenAccount valid', valid);
        if (valid && this.state.isBADetailShow) {
            console.log('send req')
            this.openAccount();
        }
    }
    //#endregion

    clearError = (name) => {
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error[name]
        }), () => {
            console.log('error cleared');
        })
    }

    //#region  show partner code field on clicking on 'Got a partner code?'
    showPartnerCode = () => {
        this.setState(prevState => produce(prevState, draft => {
            draft.values.showPartner = true;
        }))
    }
    //#endregion

    handleLabelClick = () => {
        window.open("https://ekyc.motilaloswal.com/EKYC_Icons/TermsAndConditions.pdf", '_blank');//new tab
    }

    //#region change in any input field is handled here
    handleInputChange = ({ name, value }) => {
        console.log('handleInputChange name,value', name, value)
        if (name === "mobileNo" && value.length != 0) {
            let validMob = isValidMobileNo(value);

            if (!validMob) {
                return false;
            }
        } else if (name === "fullName" && value.length != 0) {
            if (value[0] == ".") {
                return false;
            }
            let validName = isValidName(value);
            if (!validName) {
                return false;
            }
            if (value.includes('.')) {
                value = value.replace('..', '.')        // allow only single dot(.)
                value = value.replace(/ +/g, ' ', '');  //Remove extra white spaces
            }
            else {
                value = value.replace(/ +(?= )/g, '');  //allow only single space after every one word
            }
        } else if (name === "partnerCode") {
            if (!isValidAlphaNumeric(value)) {
                return;
            }
            if (value.length == 0) {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = true
                }))
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = false
                }))
            }
            value = value.toUpperCase();
        } else if (name === 'otp') {
            if (!isValidOtp(value)) {
                return;
            }
        }
        this.clearError(name);
        if (!(name === 'otp' && value.length > otpLength)) {
            this.setState(prevState => produce(prevState, draft => {
                draft.values[name] = value;
            }), () => {
                if (this.state.values.city) {// this is work around for city to unset city error in mobile
                    this.clearError(name);
                }
            })
        }
    }
    //#endregion

    //#region set current form to display. 3 form types. open account, resume Application and track App.
    handleCurrentForm = (value, isUserExisted, uniqueId, showLeftArrowBtn) => {
        // if (this.props.user.applicationType.toLowerCase() === userType.direct.toLowerCase() || this.props.user.strPSource == "LIVEPHOTO") {
        this.props.handleCurrentForm(value, this.state.values.mobileNo, isUserExisted, uniqueId, showLeftArrowBtn)
        // }
    }
    //#endregion

    //#region any input field blur is handled here.
    handleInputBlur = ({ name, value, isRequired }) => {
        console.log('Input blur', name, value, isRequired);
        this.validateField({ name, value, isRequired });
        if (name === 'partnerCode' && value) {
            let data = {
                _BACODE: value,
                ApplicationType: 'PP'
            }
            this.validatePartnerCode(data);
        }
    }
    //#endregion

    //#region validation of field 
    validateField = ({ name, value, isRequired, callback = () => { } }) => {
        console.log('validate', name, value, isRequired)
        if (isRequired && !value) {// value is reqd but left blank
            callback();
            this.setState(prevState => produce(prevState, draft => {
                if (name === "fullName") {
                    draft.error[name] = 'Please enter your Full Name';
                } else if (name === "mobileNo") {
                    draft.error[name] = 'Please enter your Mobile Number';
                } else if (name === "city") {
                    draft.error[name] = 'Please select city';
                } else if (name === "otp") {
                    draft.error[name] = 'Please enter OTP';
                } else if (name === "isPolicyAccepted") {
                    draft.error[name] = true;
                }
                else if (this.state.isDIY && name === "partnerCode" && this.props.user.strPSource !== "LIVEPHOTO") {
                    draft.error[name] = 'Please enter partner code';
                    draft.values.showPartner = true;
                }
            }), () => {
                console.log('error set')
            })
        } else if (name === "mobileNo") {
            this.validateMobileNumber({ name, value, callback })
        } else if (name === "fullName") {
            this.validateFullName({ name, value, callback })
        } else if (name === "partnerCode") {
            if (this.state.error.partnerCode) {//if field error is set on blue of partner code.
                this.setState(prevState => produce(prevState, draft => {
                    draft.values.showPartner = true;
                }))
                callback();
            }
        } else if (name === "otp") {
            this.validateOtp({ name, value, callback })
        }
    }
    //#endregion

    validateMobileNumber = ({ name, value, callback }) => {
        let vailid = isValidMobileNo(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 10) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Mobile Number';
            }))
        }
    }

    validateFullName = ({ name, value, callback }) => {
        let vailid = isValidName(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 2) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Full Name';
            }))
        }
    }

    validateOtp = ({ name, value, callback }) => {
        if (value.length < otpLength) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = `OTP must be of ${otpLength} digits`;
            }))
        }
    }

    //#region validation of whole form
    validateForm = () => {
        const { fullName, mobileNo, city, partnerCode, isPolicyAccepted, auRm, otp } = this.state.values
        let decision = true;

        const makeDecision = () => {
            decision = false;
        }

        let fieldsToValidate = [
            (this.props.showFullName) && { name: 'fullName', value: fullName, isRequired: true },
            (this.props.showMobile) && { name: 'mobileNo', value: mobileNo, isRequired: true },
            (this.props.showOTP || this.props.showMFDOtp) && { name: 'otp', value: otp, isRequired: true },

            (this.state.showCity) && (this.props.user.applicationType === userType.direct || this.props.user.applicationType === userType.crm)
            && { name: 'city', value: city, isRequired: true },

            (this.props.user.applicationType === userType.direct && this.state.values.showPartner && this.state.values.partnerCode != "") &&
            { name: 'partnerCode', value: partnerCode, isRequired: true },

            (this.props.user.applicationType === userType.direct && this.state.values.showPartner && this.state.values.partnerCode === "") &&
            { name: 'partnerCode', value: partnerCode, isRequired: false },

            (this.props.user.applicationType === userType.pp && this.state.isDIY && this.props.user.strPSource !== "LIVEPHOTO") &&
            { name: 'partnerCode', value: partnerCode, isRequired: true },

            { name: 'isPolicyAccepted', value: isPolicyAccepted, isRequired: true },

            (this.props.user.applicationType === userType.crm && this.props.auRm.length > 0)
            && { name: 'auRm', value: auRm, isRequired: true },
        ]
        fieldsToValidate = fieldsToValidate.filter(field => field);
        fieldsToValidate.forEach(field => this.validateField({ ...field, callback: makeDecision }))
        console.log('fieldsToValidate', fieldsToValidate);
        return decision;
    }
    //#endregion

    redirect = (applicationType, page, isDiy) => {
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push(`/dad/${page}`);
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push(`/branch/${page}`);
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            history.push(`/ba/${page}`);
        }
    }

    closeErrorPopup = () => {
        this.setState({ isShowErrorPopup: false })
        if (this.state.error.apiResponse == mobileExistMessage) {
            let jumpToResume = regFormType.resumeApp;
            this.handleCurrentForm(jumpToResume)
        }
    }

    handleBADetailsEdit = () => {
        this.setState({
            openPartnerDetailDialog: false
        },
            () => {
                var element = document.getElementsByName("partnerCode")
                console.log("Element=>", element);
                element[0].focus();
            }
        );
    }

    handleBADetailsClose = () => {
        this.setState({ openPartnerDetailDialog: false, isBADetailShow: true })
    }

    handleTrackApplication = () => {
        let trackUrl = process.env.REACT_APP_TRACK_URL;
        window.open(trackUrl);
        console.log("TrackURL", trackUrl);
    }

    handleResendOtp = () => {

        // if (this.validateForm()) {
        let data = {
            _mobile: this.state.values.mobileNo,
            ISRESENDOTP: "1"
        }
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {

                // otp is successfully resend.
                this.setState(prevState => produce(prevState, draft => {
                    draft.values.otp = '';
                    draft.error.otp = '';
                }))
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = "";
                }))
                this.setState({
                    uniqueId: response._Uniqueid,
                    message: response.Message + " " + this.state.values.mobileNo,
                    resetvalue: '',
                    disableResendButton: true,
                    timer: 60,
                    showTimer: true
                })

                this.myInterval = setInterval(() => {
                    if (this.state.timer > 1) {
                        this.setState(({ timer }) => ({
                            timer: timer - 1
                        }))
                    } else {
                        clearInterval(this.myInterval);
                        this.setState({ showTimer: false })
                    }
                    console.log("Timer1=>", this.state.timer);
                }, 1000);

                setTimeout(() => {
                    this.setState({ disableResendButton: false })
                }, 60000)   // 60 sec

            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.message = response.Message
                }))
            }
        }).catch(error => {
            this.props.clearLoading();

            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = error.message;
            }))
        })
        // }
    }

    handleLanguageSelection = (lang) => {
        this.setState({ selectedLanguage: lang })
    }

    render() {
        let message = this.state.message;

        console.log("City=", this.state.values.city, "Available city=", this.state.availableCities, "fetchedCity=", this.state.values.fetchedCity);

        let cities = this.state.availableCities.map(({ CITY_CODE, CITY_NAME }) => ({ label: CITY_NAME, value: CITY_CODE }));
        console.log('cities', cities);

        let auRm = this.props.auRm.map(auRmObj => ({
            label: auRmObj.BankEmployeeName,
            value: auRmObj.BankEmployeeCode
        }));


        // BA user partner code logic
        let baDiyUser = (this.props.user.applicationType === userType.pp) && this.state.isDIY;


        return (
            // <div className="open-acc-wrapper">
            //     <div className="d-flex justify-content-center align-items-center">

            //         <ul className="open-acc-list">
            //             <li>
            //                 <RegOrangeText
            //                     onClick={() => this.handleCurrentForm(regFormType.resumeApp, undefined, undefined, true)}
            //                     className={'font14 pointer'}
            //                     label={'Resume Application'}
            //                 />
            //             </li>

            //         </ul>
            //     </div>

            //     <div className="mt10">
            //         <div className="">
            //             <RegHeading label={this.props.user.applicationType === userType.crm
            //                 ? "OPEN A DEMAT ACCOUNT NOW!"
            //                 : this.props.user.applicationType === userType.pp ? "OPEN A DEMAT & TRADING ACCOUNT IN 5 MINUTES!" : "Open your FREE Demat Account in 5 Minutes"
            //             } />

            //             {this.props.showFullName && <RegInput
            //                 name={"fullName"}
            //                 value={this.state.values.fullName}
            //                 error={this.state.error.fullName}

            //                 maxLength={60}

            //                 onChange={this.handleInputChange}
            //                 onBlur={this.handleInputBlur}

            //                 type="text"
            //                 className={"mt-2"}
            //                 placeholder="Enter Full Name"
            //                 disabled={(this.state.isDIY && this.state.disableFullNameForDiy) || this.state.disableFullName}
            //             />
            //             }

            //             {this.props.showMobile && <RegMobileNo
            //                 name={"mobileNo"}
            //                 value={this.state.values.mobileNo}
            //                 error={this.state.error.mobileNo}

            //                 onChange={this.handleInputChange}
            //                 onBlur={this.handleInputBlur}

            //                 placeholder={"Enter Mobile Number"}
            //                 disabled={(this.state.isDIY && this.state.disableMobileForDiy) || this.props.disableMobileNo ? true : false}
            //             />
            //             }

            //             {this.props.showOTP && <RegInput
            //                 name={"otp"}
            //                 type={"number"}
            //                 value={this.state.values.otp}
            //                 error={this.state.error.otp}
            //                 message={message}
            //                 onChange={this.handleInputChange}
            //                 onBlur={this.handleInputBlur}
            //                 placeholder={"Enter OTP"}
            //                 className={"resume-otp-field-existed"}
            //                 maxLength={4}
            //             />}

            //             {
            //                 this.props.showOTP &&
            //                 <div className={classNames("resendOTP", { "disableResendButton": this.state.disableResendButton })} onClick={this.handleResendOtp}>
            //                     Resend OTP
            //                 </div>
            //             }

            //             {this.state.showTimer && <div className='reg-message'>You can resend your OTP in {this.state.timer} Seconds</div>}

            //             {
            //                 (this.props.user.applicationType === userType.direct || this.props.user.applicationType === userType.crm) &&
            //                 (this.state.showCity) &&
            //                 <RegSelect
            //                     name={'city'}
            //                     value={this.state.values.city}
            //                     error={this.state.error.city}
            //                     options={cities}

            //                     onChange={this.handleInputChange}
            //                     onBlur={this.handleInputBlur}

            //                     placeholder={'Select City'}
            //                 />
            //             }

            //             {
            //                 auRm.length > 0 &&
            //                 <RegSelect
            //                     name={'auRm'}
            //                     value={this.state.values.auRm}
            //                     error={this.state.error.auRm}
            //                     options={auRm}

            //                     onChange={this.handleInputChange}
            //                     onBlur={this.handleInputBlur}

            //                     placeholder={'Select Employee Code'}
            //                 />
            //             }

            //             {
            //                 this.props.user.applicationType === userType.direct &&
            //                 <div className="form-group-south">
            //                     <div className="edit-icon-table checkbox-label-with-text">
            //                         <RegCheckbox
            //                             name={"isSouthIndian"}
            //                             checked={this.state.values.isSouthIndian}

            //                             onChange={this.handleInputChange}
            //                         />
            //                         <span className="form-description-text-south ml10"> I prefer South Indian language</span>
            //                     </div>
            //                 </div>
            //             }

            //             <div className="form-group">
            //                 <div className="edit-icon-table checkbox-label-with-text">
            //                     {
            //                         (this.props.user.applicationType === userType.crm || this.props.user.applicationType === userType.pp) &&
            //                         <RegCheckbox
            //                             name={"isPolicyAccepted"}
            //                             checked={this.state.values.isPolicyAccepted}

            //                             onChange={this.handleInputChange}
            //                         />
            //                     }
            //                     <span className="form-description-text">I would like to open Account with Motilal Oswal Financial Services Ltd (MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications through whatsApp, SMS, calls even if I'm registered under DND.</span>
            //                 </div>
            //                 <div className={classNames('reg-error disappear', { 'appear': this.state.error.isPolicyAccepted })}>
            //                     Please Accept Terms and Condition
            //                 </div>
            //             </div>

            //             <RegButton
            //                 name={"openAccount"}

            //                 onClick={this.handleOpenAccount}

            //                 label={this.props.user.strPSource == "LIVEPHOTO" ? 'PROCEED' : 'OPEN DEMAT ACCOUNT'}
            //             />

            //             {this.state.error.apiResponse &&
            //                 <ErrorPopup
            //                     isShow={this.state.isShowErrorPopup}
            //                     errorMsg={this.state.error.apiResponse}
            //                     closeModal={this.closeErrorPopup}
            //                 />
            //             }
            //             {this.state.openPartnerDetailDialog &&
            //                 <PartnerDetailsPopup
            //                     open={this.state.openPartnerDetailDialog}
            //                     onEdit={this.handleBADetailsEdit}
            //                     closeModal={this.handleBADetailsClose}
            //                     baDetails={this.state.baDetails}
            //                 />
            //             }
            //         </div>
            //     </div>
            // </div>




            <div class="">
                <div className="opendtext f22 semibold mobf18">
                {this.props.user.intrestedIN == "MFD"  ? "Open Mutual Fund Account" : this.props.user.intrestedIN === "EQUITY_MFD" ? "Open Instant Demat Account" : (this.props.showMFDReg || this.props.showMFDOtp) ? "Open Mutual Fund Account" : "Open Instant Demat Account"}
                </div>

                {/* <div class="langtxt">Language</div>

                <RegLanguageSelection
                    onLanguageSelection={this.handleLanguageSelection}
                    selectedLanguage={this.state.selectedLanguage}
                /> */}

                <div class="mtop20"></div>
                <form action="#">

                    {this.props.showFullName && <RegInput
                        name={"fullName"}
                        value={this.state.values.fullName}
                        error={this.state.error.fullName}
                        maxLength={60}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                        type={"text"}
                        className={"mt-2"}
                        placeholder={"Enter your Full Name as per PAN"}
                        disabled={(this.state.isDIY && this.state.disableFullNameForDiy) || this.state.disableFullName}
                    // Label={"Full Name"}
                    />}

                    {this.props.showMobile && <RegMobileNo
                        name={"mobileNo"}
                        value={this.state.values.mobileNo}
                        error={this.state.error.mobileNo}

                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}

                        placeholder={"Enter your Mobile Number"}
                        disabled={(this.state.isDIY && this.state.disableMobileForDiy) || this.props.disableMobileNo ? true : false}
                        onClick={isAndroid && this.state.showTrueCallerPopup && this.handleMobileClick}
                    // Label={"Mobile Number"}
                    />}

                    {
                        (this.props.user.applicationType === userType.direct || this.props.user.applicationType === userType.crm) &&
                        (this.state.showCity) &&

                        <RegSelect
                            name={'city'}
                            value={this.state.values.city}
                            // value={ this.state.values.fetchedCity}
                            error={this.state.error.city}
                            options={cities}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            placeholder={'Select city'}
                            disabled={this.state.disabledCity}
                            className={"mt-2"}
                        />
                    }

                    {(this.props.showOTP || this.props.showMFDOtp) &&
                        <RegInput
                            name={"otp"}
                            value={this.state.values.otp}
                            error={this.state.error.otp}
                            message={message}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            placeholder={"Please enter 4 digit Mobile OTP"}
                            className={"resume-otp-field-existed"}
                            maxLength={4}
                            // Label={"Enter Mobile OTP"}
                            otpTimer={this.state.otpTimer}
                            showOtp={this.state.values.mobileNo}
                            type={"tel"}
                        />
                    }
                    {this.state.otpTimer === 0 &&
                        <div className="reblk webright">
                            {!this.state.disableResendButton &&
                                <span className={classNames("resendotp", { "disableResendButton": this.state.disableResendButton })} onClick={this.handleResendOtp}>Resend OTP</span>
                            }
                        </div>}

                    {this.state.showTimer && <div className='otptext'>Resend OTP in {this.state.timer} seconds</div>}

                    {this.state.values.referralCode !== "" && this.props.user.applicationType === userType.direct &&
                        <>
                            <div class="mtop20"></div>
                            <RegInput
                                name={"referralCode"}
                                value={this.state.values.referralCode}
                                // error={this.state.values.referralCodeMsg}
                                maxLength={8}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                type={"text"}
                                className={"mt-2"}
                                placeholder={"Enter Referral Code (Optional)"}
                                disabled={true}
                                Label={"Referral Code (Optional)"}
                            // disabled={this.props.disableReferralCode ? true : false}
                            />
                            <div className="reg-message">{this.state.values.referralCodeMsg}</div>
                        </>}

                    <div class="mtop20"></div>

                    {/* {
                        this.props.user.applicationType === userType.direct &&
                        <RegCheckbox
                            name={"isSouthIndian"}
                            checked={this.state.values.isSouthIndian}
                            onChange={this.handleInputChange}
                            Label={"I prefer South Indian language"}
                        />
                    } */}

                    <div class="mtop10"></div>

                    {/* <RegCheckbox
                        name={"isPolicyAccepted"}
                        checked={this.state.values.isPolicyAccepted}
                        // onChange={this.handleInputChange}    // no change
                        Label={"I would like to open Trading and Demat Account with Motilal Oswal Financial Services Ltd (MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications for any offerings on my registered mobile number through Call, email, SMS, Whatsapp or any other medium even if I'm registered under DND."}
                    /> */}

                    {/* <div class="mtop10"></div>

                    <RegCheckbox
                        name={"TnC"}
                        checked={this.state.values.TnC}
                        // onChange={this.handleInputChange}    // no change
                        Label={"I hereby agree to enclosed Terms & Condition for opening of Trading / Demat Account"}
                        handleLabelClick={this.handleLabelClick}
                    /> */}

                    {this.state.error.apiResponse !== "" &&
                        <>
                            <div class="mtop10"></div>
                            <div className={'reg-error'}>{this.state.error.apiResponse}</div>
                        </>
                    }

                    {
                        ((this.props.user.applicationType === userType.pp) || baDiyUser)
                        &&
                        // (

                        this.state.values.showPartner &&
                        // ?
                        <RegInput
                            name={"partnerCode"}
                            value={this.state.values.partnerCode}
                            error={this.state.error.partnerCode}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            type="text"
                            maxLength={20}
                            placeholder="Enter Partner Code"
                            // disabled={this.state.isDIY && this.state.disablePartnerCodeForDiy}
                            disabled={true}
                        />
                        // :
                        // <div>
                        //     <RegOrangeText
                        //         className={'font14 partner-text pointer'}
                        //         label={"Got a partner code?"}
                        //         onClick={this.showPartnerCode}
                        //     />
                        // </div>)
                    }

                    <div class="mtop20"></div>

                    {/* <RegButton
                        name={"openAccount"}
                        onClick={this.handleOpenAccount}
                        label={this.props.user.strPSource == "LIVEPHOTO" ? 'PROCEED' : 'Proceed'}
                        outerContainerClassName={"desk"}
                    /> */}

                    {/* <div class="mob">
                        <div class="cta dark tc" onClick={this.handleOpenAccount}>
                            Proceed
                            </div>
                    </div> */}

                    <div className="text-center semibold">
                        <a className={this.props.user.intrestedIN == "MFD" && isMobile ? "cta newcta f14 mtop15 fontsize13" : (this.props.showMFDReg || this.props.showMFDOtp) && isMobile ? "cta newcta f14 mtop15 fontsize13" : "cta newcta f14 mtop15"} onClick={this.handleOpenAccount}>
                            {this.props.user.intrestedIN == "MFD" ? "Open Mutual Fund Account" : this.props.user.intrestedIN === "EQUITY_MFD" ? "OPEN DEMAT ACCOUNT" : (this.props.showMFDReg || this.props.showMFDOtp) ? "Open Mutual Fund Account" : "OPEN DEMAT ACCOUNT"}
                            <svg
                                width={15}
                                height={10}
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.16667 10L8 8.79167L10.9583 5.83333H0.833336V4.16667H10.9583L8 1.20833L9.16667 0L14.1667 5L9.16667 10Z"
                                    fill="#0E0F11"
                                />
                            </svg>
                        </a>
                    </div>

                </form>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
    resendOtp: (data) => fetchWithWait({ dispatch, action: resendOtp(data) }),
    setLoading: (data) => dispatch(setLoading(data)),
    clearLoading: () => dispatch(clearLoading()),
    setResponseErrorSnack: (payload) => dispatch(setResponseErrorSnack(payload))
})

const mapStateToProps = (state) => ({
    user: state.user,
    auRm: state.auRmDetails.auRm
})

export default connect(mapStateToProps, mapDispatchToProps)(RegOpenAccountFormWithOTP);
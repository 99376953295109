import React, { Component, useState } from 'react';
import image1 from '../../../assets/images/regscreen/Ellipse1977.svg'
import tick from '../../../assets/images/regscreen/ticksquare.svg'

const MFDSection11 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0 pbottom0">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">
                            Complete Digital process
                        </span>
                        <div>
                            <span className="f30 semibold mobf24">
                                Steps to Invest in Mutual Fund
                            </span>
                        </div>
                    </h2>
                </div>
                <div className="mtop60" />
                <div className="dflex spacebetween mobwrap">
                    <div className="d66">
                        <div className="whitebbox">
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 1</div>
                                    <div className="step f14 lightgrey">
                                        Enter your name and mobile number &amp; enter the OTP received on
                                        the registered number
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 2</div>
                                    <div className="step f14 lightgrey">
                                        Enter your details such as Date of Birth, PAN number, Email
                                        Address and Bank account details
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20">
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 3</div>
                                    <div className="step f14 lightgrey">
                                        Complete Aadhaar KYC and mandatory E-Sign and you are all set to
                                        invest and trade
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20" style={{ display: "none" }}>
                                <div className="left">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 4</div>
                                    <div className="step f14 lightgrey">
                                        Enter OTP Complete Adhaar KYC and mandatory E-Signreceived on the
                                        mobile number
                                    </div>
                                </div>
                            </div>
                            <div className="dflex gap15 mtop20" style={{ display: "none" }}>
                                <div className="left last">
                                    <img alt="" src={image1} />
                                </div>
                                <div className="right ml20">
                                    <div className="step f14 font-semi-bold">Step 5</div>
                                    <div className="step f14 lightgrey">
                                        Enter OTP receAnd done! You are all set to invest and trade.ived
                                        on the mobile number
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d30">
                        <div className="mtop20 mob" />
                        <video className="video" width="100%" height="140" muted controls>
                            <source
                                src="https://dbs7qpzv4mcv.cloudfront.net/open_demat_account.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div className="mtop10" />
                        <span className="font-semi-bold f14 line1">
                            Documents required to open a demat
                        </span>
                        <div className="whitebbox mtop10 pad15">
                            <div className="mtop10 desk" />
                            <div className="dflex mtop10 mobmtop0">
                                <img alt="" src={tick} />
                                <span className="ml10 f14">PAN Card</span>
                            </div>
                            <div className="dflex mtop10">
                                <img alt="" src={tick} />
                                <span className="ml10 f14">Aadhaar Card</span>
                            </div>
                            <div className="mtop10 desk" />

                        </div>
                    </div>
                </div>
                <div className="mb70 desk" />
            </section>

        </>

    );
}

export default MFDSection11;

import React from 'react'
import Image from '../../atom/image';
// import offerImg from '../../../assets/images/OAO_Banner1.png';
// import offerImg1 from '../../../assets/images/MTF_Banner_Image.png';
import offerImg1 from '../../../assets/images/crmBanner.jpg';
import { userType } from '../../../helper/constant';
import { connect } from 'react-redux';
import offerImg from '../../../assets/images/OAO_Banner2.png';
import FranchiseBanner from '../../../assets/images/ppBanner.png';
import referralBanner from '../../../assets/images/referralBanner.jpg'

const MainBanner = (props) => {
    console.log("Moiz=====>", props.user.applicationType)
    return (
        <>
            <div className="left desk">
                <div className="opendemat text-center">{props.user.applicationType == "DIRECT" ? <h1>Open a Free Demat Account</h1> : props.user.applicationType == userType.crm ? "" : <h1>OPEN DEMAT ACCOUNT</h1>}</div>
                {(props.user.applicationType && props.user.applicationType !== "" && props.user.applicationType == userType.crm || props.user.applicationType == "crm") ?
                    <img src={offerImg1} alt="" className="img-width" width="100%" height="auto" />
                    :
                    (props.user.applicationType && props.user.applicationType !== "" && props.user.applicationType == userType.pp || props.user.applicationType == "pp") ?
                        <img src={FranchiseBanner} alt="" className="img-width" width="100%" height="auto" />
                        :
                        props.user.showReferralBanner ?
                            <img src={referralBanner} alt="" className="img-width" width="100%" height="auto" />
                            :
                            <img src={offerImg} alt="" className="img-width" width="100%" height="auto" />
                }

                <div className="mtop20"></div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(MainBanner);
import React, { Component } from 'react'
import Button from '../../atom/button';
import { authorizationError } from '../../../helper/constant'

class SessionExpiredPopup extends Component {
    render() {
        return (
            <>
                <div id="sessionExpired" class="overlay">
                    <div class="popup mobsign">
                        {/* <a class="close" id="myPopup" href="#">&times;</a> */}
                        <div class="content text-center">

                            <div className="thankpopupmain_jet">
                                <p className="mtop10">{authorizationError}</p>

                                <div class="text-center mtop20">
                                    <a href="#" className="popupcta" onClick={this.props.handleOkClick}>OK</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default SessionExpiredPopup;
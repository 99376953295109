import Api from '.';
import { endpoints } from './endpoints';

export default class VerificationApi extends Api {
    verifyOtpEmail(data) {//verification otp email
        let url = this.buildUrl(endpoints.verification.otpEmail)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getSchemeDetails(data) {//terms and consition data
        let url = this.buildUrl(endpoints.verification.schemeDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    resendOtp(data) {//resend otp
        let url = this.buildUrl(endpoints.verification.resendOtp)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initSetUser(data) {// init user api
        let url = this.buildUrl(endpoints.app.init)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initSetUserSuperApp(data) {// init user api
        let url = this.buildUrl(endpoints.app.initSuperApp)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }


    getTraderCode(data) {// 
        let url = this.buildUrl(endpoints.verification.getTraderCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    insertTraderCode(data) {// 
        let url = this.buildUrl(endpoints.verification.insertTraderCode)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
import React, { useEffect } from 'react';
import demat1 from '../../../assets/images/demat1.svg';
import demat2 from '../../../assets/images/demat2.svg';
import demat3 from '../../../assets/images/demat3.svg';
import demat4 from '../../../assets/images/demat4.svg';
import demat5 from '../../../assets/images/demat5.svg';
import demat6 from '../../../assets/images/demat6.svg';
import Image from '../../atom/image';
import Lazyframe from '../../molecules/lazyIframe';
import $ from 'jquery';


const RegBasicInfo = () => {
    useEffect(() => {
        $('#slidetoggle').click(function () {
            $('.readmoreblock').slideToggle();
        });
    }, [])

    return (
        <>
            <div class="container">

                <div class="mtop40"></div>
                <div id="whatisdemat">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="conbox">
                                WHAT IS DEMAT ACCOUNT?
                            </h2>
                        </div>
                        <div class="mtop40"></div>
                    </div>

                    <div class="dematbk">
                        <div class="lft">
                            <p>Like a Bank Account that keeps your physical money safe in the form of digital currency for easier transactions, opening a free DEMAT Account acts as a safe for the shares/stocks that you've bought. Online free DEMAT Account keeps your shares and stocks in a digital format, thus enabling you to keep track of the price movement. </p>
                            <br />
                            <p>Earlier, shares/stocks were kept in the form of physical receipts. This made it difficult for investors to keep hold of the receipts for a longer time. The advent of the DEMAT account thus made it easy for investors and traders to trade instantly and keep shares for as long as they desire. However, you need to have a trading account for the purpose of doing a sell transaction. Without a trading account you won't be able to sell the shares that you've brought and kept in the DEMAT Account. Opening a Demat Account comes with lots of benefits such as there is no hassle to keep hold of the physical receipts, it saves time and makes it easy for investors and traders to learn and track their portfolio 24 by 7. </p>
                            <br />



                        </div>
                        <div class="rgt">
                            {/* <iframe width="100%" height="250" src="https://www.youtube.com/embed/7Q9YbR8TLs4"
                                title="Open Demat Account" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe> */}
                            <Lazyframe
                                title="Open Demat Account"
                                url={"https://www.youtube.com/embed/7Q9YbR8TLs4"}
                            />
                        </div>
                    </div>
                    <div class="mtop10"></div>
                    <div class="readmoreblock">
                        <p>With the use of advanced technology, it is now possible for individuals to open a two-in-one account, which can be understood by viewing the video alongside or reading the text below. </p>
                        <br />
                        <p>Before registering for a Free* Demat Account, remember to keep the following documents handy:-</p>


                        <ul>
                            <li>PAN Card</li>
                            <li>AADHAR Card</li>
                            <li>Signed & cancelled blank bank cheque</li>
                            <li>BANK Account Statement (for past 6 months)</li>
                        </ul>
                        <div class="mtop10"></div>
                        <b>To open a DEMAT Account, follow the following given steps:-</b>
                        <div class="mtop10"></div>
                        <p>
                            <b>Step1</b> - Filling out Personal Details: To begin with, you will have to register with your mobile number and email address, and verify the same via OTP.
                        </p>
                        <div class="mtop5"></div>
                        <p><b>Step2</b> - Submitting necessary documents: To open a free DEMAT Account, you will have to verify your PAN Number, Aadhar Card details, Bank Account Number & IFSC Code with the Broker.</p>
                        <div class="mtop5"></div>
                        <p><b>Step3</b> - Completing E-KYC: To complete your E-KYC, you will have to fill in your personal details such as your income, your marital status, address, nominee, etc.</p>
                        <div class="mtop5"></div>
                        <p><b>Step4</b> - E-sign Aadhar Validation: For the last step, you will have to e-verify your Aadhar. This can be done by receiving an OTP to your Aadhar registered mobile number or by uploading the ‘front’ and ‘back’ side photographs of your Aadhar card.On successful account opening, you will be communicated your User Id and Password via email.</p>
                        <div class="mtop10"></div>


                    </div>
                    <div class="cta dark inline-block rdmore" id="slidetoggle">Read More</div>
                </div>
                <div class="mtop40"></div>

                <div class="text-center">
                    <h2 class="conbox">
                        WHY OPEN DEMAT ACCOUNT WITH MOTILAL OSWAL?
                    </h2>
                    <div class="mtop40"></div>

                </div>

                <div className="mobileswiper">
                    <div class="threeblock">
                        <div class="blocks">
                            <div class="borderbox">
                                {/* <img src="images/demat1.2dce1e1f.svg" alt="" /> */}
                                {/* <Image src={demat1} alt={''} width="" height="" /> */}
                                <div class='sprite demat12dce1e1f'></div>
                                <h3>2.5 Million+ Happy Demat Account Holders</h3>
                            </div>
                        </div>
                        <div class="blocks">
                            <div class="borderbox">
                                {/* <Image src={demat2} alt={''} width="" height="" /> */}
                                <div class='sprite demat27a3dc281'></div>
                                <h3>High tech 4-star apps for traders & investors</h3>
                            </div>
                        </div>
                        <div class="blocks">
                            <div class="borderbox">
                                <div class='sprite demat3fae7f037'></div>
                                {/* <Image src={demat3} alt={''} width="" height="" /> */}
                                <h3>30+ Years of Research Expertise</h3>
                            </div>
                        </div>
                        <div class="blocks">
                            <div class="borderbox">
                                {/* <Image src={demat4} alt={''} width="" height="" /> */}
                                <div class='sprite flat-pricing'></div>
                                <h3>Flat pricing (Rs. 20 Per Lot) on Equity and Currency</h3>
                            </div>
                        </div>
                        <div class="blocks">
                            <div class="borderbox">
                                {/* <Image src={demat5} alt={''} width="" height="" /> */}
                                <div class='sprite demat5b051347b'></div>
                                <h3>Customized Investment Plans with One Click Investment</h3>
                            </div>
                        </div>
                        <div class="blocks">
                            <div class="borderbox">
                                {/* <Image src={demat6} alt={''} width="" height="" /> */}
                                <div class='sprite demat6a6cbb795'></div>
                                <h3>Wide Choice of Asset Classes to Invest in</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mtop40"></div>
        </>
    )
}
export default RegBasicInfo;
import React, { Component, useState } from 'react';
import playstore from '../../../assets/images/regscreen/PlayStore2.png'
import appstore from '../../../assets/images/regscreen/AppStore3.png'
import facebook from '../../../assets/images/mfdregscreen/FacebookNegative.svg'
import twitter from '../../../assets/images/mfdregscreen/TwitterNegative1_1_.svg'
import youtube from '../../../assets/images/mfdregscreen/YouTubeNegative.svg'
import linkedin from '../../../assets/images/mfdregscreen/LinkedInNegative.svg'
import icon1 from '../../../assets/images/mfdregscreen/icnologo.svg'



const MFDSectionFooter = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="content-bottom">
                <div className="footerbg mtop100">
                    <div className="sectioncontainer">
                        <div>
                            <div className="mtop40 " />
                            <div className="dflex wrap spacebetween">
                                <div className="d28">
                                    <div>
                                        <div>
                                            <span className="font-semi-bold f16 lightwhite">
                                                Registered Office Address
                                            </span>
                                        </div>
                                        <div className="mtop10">
                                            <div>
                                                <span className="lightgrey">
                                                    Motilal Oswal Tower , Rahimtullah Sayani Road , Opposite
                                                    Parel ST Depot , Prabhadevi,
                                                </span>
                                            </div>
                                            <span className="lightgrey">Mumbai - 400025</span>
                                        </div>
                                        <div className="mtop10">
                                            <a
                                                id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wt19_wt510"
                                                tabIndex={473}
                                                href="mailto:query@motilaloswal.com"
                                            >
                                                <span className="lightgrey">query@motilaloswal.com</span>
                                            </a>
                                        </div>
                                        <div className="mtop10">
                                            <a
                                                id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wt19_wt477"
                                                tabIndex={474}
                                                href="tel:022%203828%201085"
                                            >
                                                <span className="lightgrey">022 3828 1085</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mtop30">
                                        <div>
                                            <span className="font-semi-bold f16 lightwhite">
                                                Correspondence Address
                                            </span>
                                        </div>
                                        <div className="mtop10">
                                            <div>
                                                <span className="lightgrey">
                                                    Palm Spring Centre, 2nd Floor, Palm Court Complex, New Link
                                                    Road, Malad (West),
                                                </span>
                                            </div>
                                            <span className="lightgrey">Mumbai - 400 064.</span>
                                        </div>
                                        <div className="mtop10">
                                            <a
                                                id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wt19_wt497"
                                                tabIndex={475}
                                                href="tel:022%207188%201000"
                                            >
                                                <span className="lightgrey">022 7188 1000</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mtop30" />
                                    <div>
                                        <span className="lightgrey">
                                            © 2024 Motilal Oswal Financial Services Limited (MOFSL)* Member
                                            of NSE, BSE, MCX, NCDEX CIN No.: L67190MH2005PLC153397
                                        </span>
                                    </div>
                                    <div className="mtop30" />
                                    <div className="dflex aligncenter footerlinks">
                                        <div>
                                            <a
                                                tabIndex={476}
                                                target="_blank"
                                                href="https://www.facebook.com/MotilalOswalSecurities?mibextid=ZbWKwL"
                                            >
                                                <img
                                                    alt=""
                                                    src={facebook}
                                                />
                                            </a>
                                        </div>
                                        <div className="ml10">
                                            <a
                                                tabIndex={477}
                                                target="_blank"
                                                href="https://twitter.com/MotilalOswalLtd"
                                            >
                                                <img
                                                    alt=""
                                                    src={twitter}
                                                />
                                            </a>
                                        </div>
                                        <div className="ml10">
                                            <a
                                                tabIndex={478}
                                                target="_blank"
                                                href="https://youtube.com/@MOFSL?si=70uBLJ4YCl5AK2B6"
                                            >
                                                <img
                                                    alt=""
                                                    src={youtube}
                                                />
                                            </a>
                                        </div>
                                        <div className="ml10">
                                            <a
                                                tabIndex={479}
                                                target="_blank"
                                                href="https://www.linkedin.com/company/motilal-oswal-financial-services-ltd/"
                                            >
                                                <img
                                                    alt=""
                                                    src={linkedin}
                                                />
                                            </a>
                                        </div>
                                        <div className="ml10">
                                            <a
                                                tabIndex={480}
                                                target="_blank"
                                                href="https://invest.motilaloswal.com/TradingView/TradingView.html"
                                            >
                                                <img
                                                    alt=""
                                                    src={icon1}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mtop40">
                                        <span className="font-semi-bold lightgrey f16">
                                            Download Our App
                                        </span>
                                    </div>
                                    <div className="dflex mtop20">
                                        <div>
                                            <div className="storelogo">
                                                <a
                                                    id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wt19_wt475"
                                                    tabIndex={481}
                                                    target="_blank"
                                                    href="https://apps.apple.com/in/app/motilal-oswal-demat-stocks/id1055577451"
                                                >
                                                    <img
                                                        alt=""
                                                        src={appstore}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="ml20">
                                            <div className="storelogo">
                                                <a
                                                    id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wt19_wt399"
                                                    tabIndex={482}
                                                    target="_blank"
                                                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en"
                                                >
                                                    <img
                                                        alt=""
                                                        src={playstore}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mtop30" />
                                </div>
                                <div className="d70">
                                    <div className="dflex wrap footer-nav">
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Offerings
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={483}
                                                        href="https://www.motilaloswal.com/invest-in-equity"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Indian Stocks (Equity)
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={484}
                                                        href="https://www.motilaloswal.com/invest-in-derivatives"
                                                    >
                                                        <span className="f14 lightgrey">Derivatives</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={485}
                                                        href="https://www.motilaloswal.com/invest-in-commodities-market"
                                                    >
                                                        <span className="f14 lightgrey">Commodity</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={486}
                                                        href="https://www.motilaloswal.com/invest-in-currency-and-forex-trading"
                                                    >
                                                        <span className="f14 lightgrey">Currency</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={487}
                                                        href="https://www.motilaloswal.com/invest-in-ipo"
                                                    >
                                                        <span className="f14 lightgrey">IPO</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={488}
                                                        href="https://www.motilaloswal.com/invest-in-mutual-fund"
                                                    >
                                                        <span className="f14 lightgrey">Mutual Funds</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={489}
                                                        href="https://invest.motilaloswal.com/TradingView/TradingView.html"
                                                    >
                                                        <span className="f14 lightgrey">Trading View</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={490}
                                                        href="https://www.motilaloswal.com/nri-demat-account"
                                                    >
                                                        <span className="f14 lightgrey">NRI</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={491}
                                                        href="https://www.motilaloswal.com/invest-in-IAP"
                                                    >
                                                        <span className="f14 lightgrey">IAP</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={492}
                                                        href="https://www.motilaloswal.com/invest-in-us-stocks"
                                                    >
                                                        <span className="f14 lightgrey">US Stocks</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={493}
                                                        href="https://www.motilaloswal.com/alternate-investment-funds"
                                                    >
                                                        <span className="f14 lightgrey">AIF</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={494}
                                                        href="https://www.motilaloswal.com/portfolio-management-services"
                                                    >
                                                        <span className="f14 lightgrey">PMS</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={495}
                                                        href="https://www.motilaloswal.com/invest-in-fd"
                                                    >
                                                        <span className="f14 lightgrey">FD</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={496}
                                                        target="_blank"
                                                        href="https://insurance.motilaloswal.com/"
                                                    >
                                                        <span className="f14 lightgrey">Insurance</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Market
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={497}
                                                        href="https://www.motilaloswal.com/market-overview-equity"
                                                    >
                                                        <span className="f14 lightgrey">Equity</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={498}
                                                        href="https://www.motilaloswal.com/market-overview-futures-options"
                                                    >
                                                        <span className="f14 lightgrey">Derivatives</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={499}
                                                        href="https://www.motilaloswal.com/market-overview-commodity"
                                                    >
                                                        <span className="f14 lightgrey">Commodity</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={500}
                                                        href="https://www.motilaloswal.com/market-overview-currency"
                                                    >
                                                        <span className="f14 lightgrey">Currency</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={501}
                                                        href="https://www.motilaloswal.com/market-overview-equity/top-gainers-and-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Equity Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={502}
                                                        href="https://www.motilaloswal.com/market-overview-commodity/top-gainers-and-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Commodity Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={503}
                                                        href="https://www.motilaloswal.com/market-overview-currency/top-gainers-and-top-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Currency Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Popular Stocks&nbsp;
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={515}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/HDFCBANK/500180/4987/nse"
                                                    >
                                                        <span className="f14 lightgrey">HDFC Bank&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={516}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/ICICIBANK/532174/5418/nse"
                                                    >
                                                        <span className="f14 lightgrey">ICICI Bank</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={517}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/SBILIFE/540719/22045/nse"
                                                    >
                                                        <span className="f14 lightgrey">SBI Life&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={518}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/TCS/532540/5400/nse"
                                                    >
                                                        <span className="f14 lightgrey">TCS&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={519}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/TITAN/50011/1016/nse"
                                                    >
                                                        <span className="f14 lightgrey">Titan&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={520}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/TATACONSUM/500800/557/nse"
                                                    >
                                                        <span className="f14 lightgrey">Tata Consumer&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={521}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/UPL/512070/2461/nse"
                                                    >
                                                        <span className="f14 lightgrey">UPL&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={522}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/DIVISLAB/532488/21188/nse"
                                                    >
                                                        <span className="f14 lightgrey">Divislab&nbsp;</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={523}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/BRITANNIA/500825/93/nse"
                                                    >
                                                        <span className="f14 lightgrey">Britannia</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={524}
                                                        href="https://www.motilaloswal.com/markets/equity-market-overview/ONGC/500312/6068/nse"
                                                    >
                                                        <span className="f14 lightgrey">ONGC&nbsp;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Partner with us
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={525}
                                                        href="https://www.motilaloswal.com/partner-corner/become-a-franchise-partner"
                                                    >
                                                        <span className="f14 lightgrey">Open a Franchisee</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={526}
                                                        href="https://www.motilaloswal.com/partner-corner/become-a-digi-partner"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Become a Digital Partner
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={527}
                                                        href="https://www.motilaloswal.com/partner-corner/ifa-partner"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Become a Channel Partner
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={528}
                                                        href="https://www.motilaloswal.com/partner-corner/become-a-agent-partner"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Become an Agent Remisier&nbsp;
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Support
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={531}
                                                        href="https://www.motilaloswal.com/contact-us"
                                                    >
                                                        <span className="f14 lightgrey">Contact us</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a tabIndex={532} href="https://www.motilaloswal.com/faq">
                                                        <span className="f14 lightgrey">FAQ’s</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={533}
                                                        href="https://www.motilaloswal.com/contact-us?locateus=true"
                                                    >
                                                        <span className="f14 lightgrey">Locate us</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Quick links
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a tabIndex={540} href="https://www.bseindia.com/">
                                                        <span className="f14 lightgrey">BSE</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a tabIndex={541} href="https://www.nseindia.com/">
                                                        <span className="f14 lightgrey">NSE</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a tabIndex={542} href="https://www.sebi.gov.in/">
                                                        <span className="f14 lightgrey">SEBI</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={543}
                                                        href="https://www.motilaloswal.com/Important-links"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            MOFSL-Important Links
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={544}
                                                        href="https://www.motilaloswal.com/Investor-Relations"
                                                    >
                                                        <span className="f14 lightgrey">Investor Relations</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={545}
                                                        href="https://www.motilaloswal.com/disclaimer_web"
                                                    >
                                                        <span className="f14 lightgrey">Disclaimer</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={546}
                                                        href="https://www.motilaloswal.com/Cookie-Policy"
                                                    >
                                                        <span className="f14 lightgrey">Cookie Policy</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={547}
                                                        href="https://www.nseindia.com/invest/first-time-investor-fraud-dos-and-donts"
                                                    >
                                                        <span className="f14 lightgrey">Investor Education</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={548}
                                                        target="_blank"
                                                        href="https://www.motilaloswal.com/open-demat-account/"
                                                    >
                                                        <span className="f14 lightgrey">Open Demat Account</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={549}
                                                        href="https://www.motilaloswal.com/blog-catalouge/trending-blogs"
                                                    >
                                                        <span className="f14 lightgrey">Trending Blogs</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ml10 mobml0">
                                            <ul className="bullets">
                                                <li className="bullets-item ph">
                                                    <span className="f16 lightwhite font-semi-bold">
                                                        Market
                                                    </span>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={497}
                                                        href="https://www.motilaloswal.com/market-overview-equity"
                                                    >
                                                        <span className="f14 lightgrey">Equity</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={498}
                                                        href="https://www.motilaloswal.com/market-overview-futures-options"
                                                    >
                                                        <span className="f14 lightgrey">Derivatives</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={499}
                                                        href="https://www.motilaloswal.com/market-overview-commodity"
                                                    >
                                                        <span className="f14 lightgrey">Commodity</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={500}
                                                        href="https://www.motilaloswal.com/market-overview-currency"
                                                    >
                                                        <span className="f14 lightgrey">Currency</span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={501}
                                                        href="https://www.motilaloswal.com/market-overview-equity/top-gainers-and-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Equity Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={502}
                                                        href="https://www.motilaloswal.com/market-overview-commodity/top-gainers-and-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Commodity Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="bullets-item ph">
                                                    <a
                                                        tabIndex={503}
                                                        href="https://www.motilaloswal.com/market-overview-currency/top-gainers-and-top-losers"
                                                    >
                                                        <span className="f14 lightgrey">
                                                            Currency Gainers &amp; Losers
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stockdirectory">
                                <div className="dflex aligncenter Directories">
                                    <div className="d17">
                                        <span className="lightwhite f16">Stock Directory</span>
                                    </div>
                                    <div className="Directorie d80">
                                        <a
                                            tabIndex={528}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=A"
                                        >
                                            A
                                        </a>
                                        <a
                                            tabIndex={529}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=B"
                                        >
                                            B
                                        </a>
                                        <a
                                            tabIndex={530}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=C"
                                        >
                                            C
                                        </a>
                                        <a
                                            tabIndex={531}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=D"
                                        >
                                            D
                                        </a>
                                        <a
                                            tabIndex={532}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=E"
                                        >
                                            E
                                        </a>
                                        <a
                                            tabIndex={533}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=F"
                                        >
                                            F
                                        </a>
                                        <a
                                            tabIndex={534}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=G"
                                        >
                                            G
                                        </a>
                                        <a
                                            tabIndex={535}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=H"
                                        >
                                            H
                                        </a>
                                        <a
                                            tabIndex={536}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=I"
                                        >
                                            I
                                        </a>
                                        <a
                                            tabIndex={537}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=J"
                                        >
                                            J
                                        </a>
                                        <a
                                            tabIndex={538}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=K"
                                        >
                                            K
                                        </a>
                                        <a
                                            tabIndex={539}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=L"
                                        >
                                            L
                                        </a>
                                        <a
                                            tabIndex={540}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=M"
                                        >
                                            M
                                        </a>
                                        <a
                                            tabIndex={541}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=N"
                                        >
                                            N
                                        </a>
                                        <a
                                            tabIndex={542}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=O"
                                        >
                                            O
                                        </a>
                                        <a
                                            tabIndex={543}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=P"
                                        >
                                            P
                                        </a>
                                        <a
                                            tabIndex={544}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=Q"
                                        >
                                            Q
                                        </a>
                                        <a
                                            tabIndex={545}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=R"
                                        >
                                            R
                                        </a>
                                        <a
                                            tabIndex={546}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=S"
                                        >
                                            S
                                        </a>
                                        <a
                                            tabIndex={547}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=T"
                                        >
                                            T
                                        </a>
                                        <a
                                            tabIndex={548}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=U"
                                        >
                                            U
                                        </a>
                                        <a
                                            tabIndex={549}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=V"
                                        >
                                            V
                                        </a>
                                        <a
                                            tabIndex={550}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=W"
                                        >
                                            W
                                        </a>
                                        <a
                                            tabIndex={551}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=X"
                                        >
                                            X
                                        </a>
                                        <a
                                            tabIndex={552}
                                            href="https://www.motilaloswal.com/markets/company-information/Search=Y"
                                        >
                                            Y
                                        </a>
                                        <a
                                            tabIndex={553}
                                            className="last-child"
                                            href="https://www.motilaloswal.com/markets/company-information/Search=Z"
                                        >
                                            Z
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="dflex aligncenter Directories">
                                    <div className="d17">
                                        <span className="lightwhite f16">MF Directory</span>
                                    </div>
                                    <div className="Directorie d80">
                                        <a
                                            tabIndex={593}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=A"
                                        >
                                            A
                                        </a>
                                        <a
                                            tabIndex={594}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=B"
                                        >
                                            B
                                        </a>
                                        <a
                                            tabIndex={595}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=C"
                                        >
                                            C
                                        </a>
                                        <a
                                            tabIndex={596}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=D"
                                        >
                                            D
                                        </a>
                                        <a
                                            tabIndex={597}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=E"
                                        >
                                            E
                                        </a>
                                        <a
                                            tabIndex={598}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=F"
                                        >
                                            F
                                        </a>
                                        <a
                                            tabIndex={599}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=G"
                                        >
                                            G
                                        </a>
                                        <a
                                            tabIndex={600}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=H"
                                        >
                                            H
                                        </a>
                                        <a
                                            tabIndex={601}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=I"
                                        >
                                            I
                                        </a>
                                        <a
                                            tabIndex={602}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=J"
                                        >
                                            J
                                        </a>
                                        <a
                                            tabIndex={603}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=K"
                                        >
                                            K
                                        </a>
                                        <a
                                            tabIndex={604}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=L"
                                        >
                                            L
                                        </a>
                                        <a
                                            tabIndex={605}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=M"
                                        >
                                            M
                                        </a>
                                        <a
                                            tabIndex={606}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=N"
                                        >
                                            N
                                        </a>
                                        <a
                                            tabIndex={607}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=O"
                                        >
                                            O
                                        </a>
                                        <a
                                            tabIndex={608}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=P"
                                        >
                                            P
                                        </a>
                                        <a
                                            tabIndex={609}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=Q"
                                        >
                                            Q
                                        </a>
                                        <a
                                            tabIndex={610}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=R"
                                        >
                                            R
                                        </a>
                                        <a
                                            tabIndex={611}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=S"
                                        >
                                            S
                                        </a>
                                        <a
                                            tabIndex={612}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=T"
                                        >
                                            T
                                        </a>
                                        <a
                                            tabIndex={613}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=U"
                                        >
                                            U
                                        </a>
                                        <a
                                            tabIndex={614}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=V"
                                        >
                                            V
                                        </a>
                                        <a
                                            tabIndex={615}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=W"
                                        >
                                            W
                                        </a>
                                        <a
                                            tabIndex={616}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=X"
                                        >
                                            X
                                        </a>
                                        <a
                                            tabIndex={617}
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=Y"
                                        >
                                            Y
                                        </a>
                                        <a
                                            tabIndex={618}
                                            className="last-child"
                                            href="https://www.motilaloswal.com/markets/mf-directory/Search=Z"
                                        >
                                            Z
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mtop60">
                            <span className="f10 lightgrey">
                                Motilal Oswal Financial Services Limited. (MOFSL) Member of NSE, BSE,
                                MCX, NCDEX - CIN no.: L67190MH2005PLC153397
                            </span>
                            <div className="mtop10" />
                            <span className="f10 lightgrey">
                                Registered Office Address: Motilal Oswal Tower, Rahimtullah Sayani
                                Road, Opposite Parel ST Depot, Prabhadevi, Mumbai-400025; Tel No.: 022
                                - 71934200 / 71934263;Website www.motilaloswal.com. Correspondence
                                Office Address: Palm Spring Centre, 2nd Floor, Palm Court Complex, New
                                Link Road, Malad (West), Mumbai- 400 064. Tel No: 022 7188 1000.
                                Registration Nos.: Motilal Oswal Financial Services Ltd. (MOFSL)*:
                                INZ000158836 (BSE/NSE/MCX/NCDEX);CDSL and NSDL: IN-DP-16-2015;
                                Research Analyst: INH000000412. AMFI: ARN 146822;Insurance Corporate
                                Agent: CA0579 .Motilal Oswal Asset Management Company Ltd. (MOAMC):
                                PMS (Registration No.: INP000000670); PMS and Mutual Funds are offered
                                through MOAMC which is group company of MOFSL. Motilal Oswal Wealth
                                Management Ltd. (MOWML): PMS (Registration No.: INP000004409) is
                                offered through MOWML, which is a group company of MOFSL. Motilal
                                Oswal Financial Services Ltd. is a distributor of Mutual Funds, PMS,
                                Fixed Deposit, Bond, NCDs, Insurance Products, Investment advisor and
                                IPOs.etc. *Research &amp; Advisory services is backed by proper
                                research. Registration granted by SEBI and certification from NISM in
                                no way guarantee performance of the intermediary or provide any
                                assurance of returns to investors. Please read the Risk Disclosure
                                Document prescribed by the Stock Exchanges carefully before investing.
                                There is no assurance or guarantee of the returns. #Such
                                representations are not indicative of future results. Investment in
                                securities market are subject to market risk, read all the related
                                documents carefully before investing. Investments in debt securities/
                                municipal debt securities/ securitised debt instruments are subject to
                                risks including delay and/ or default in payment. Read all the offer
                                related documents carefully. Details of Compliance Officer: Name:
                                Neeraj Agarwal, Email ID: na@motilaloswal.com, Contact
                                No.:022-40548085. Customer having any query/feedback/ clarification
                                may write to query@motilaloswal.com. In case of grievances for
                                services like Stock Broking, Research Analyst or any other services
                                rendered by Motilal Oswal Financial Services Limited (MOFSL) write to{" "}
                                <a
                                    href="mailto:grievances@motilaloswal.com"
                                    className="orangecolor f10"
                                >
                                    grievances@motilaloswal.com
                                </a>
                                , for DP to{" "}
                                <a
                                    href="mailto:dpgrievances@motilaloswal.com"
                                    className="orangecolor f10"
                                >
                                    dpgrievances@motilaloswal.com
                                </a>
                            </span>
                        </div>
                        <div className="footer-border mtop30" />
                        <div className="mtop30">
                            <span className="f10  lightgrey">
                                Motilal Oswal Commodities Broker Pvt. Ltd. - Member of MCX, NCDEX -
                                CIN U65990MH1991PTC060928
                            </span>
                            <div className="mtop10" />
                            <span className="f10 lightgrey">
                                Registration Numbers: MCX 29500, NCDEX -NCDEX-CO-04-00114. FMC Unique
                                membership code : MCX : MCX/TCM/CORP/0725, NCDEX: NCDEX/TCM/CORP/0033.
                                Website: www.motilaloswal.com Investment in Commodities is subject to
                                market risk and there is no assurance or guarantee of the returns.
                                Please read the Risks Disclosure Document and Do's &amp; Don'ts
                                prescribed by the commodity Exchanges carefully before investing.
                                Details of Compliance Officer: Name: Ms Sharmilee Chitale, Email ID:
                                sc@motilaloswal.com, Contact No.:022-38281085.Customer having any
                                query/feedback/ clarification may write to query@motilaloswal.com. In
                                case of grievances for Commodity Broking write to{" "}
                                <a
                                    href="mailto:commoditygrievances@motilaloswal.com"
                                    className="orangecolor f10"
                                >
                                    commoditygrievances@motilaloswal.com
                                </a>
                            </span>
                            <div className="mtop10" />
                            <div>
                                <span className="f10 lightgrey">“Attention Investors</span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    1. Stock Brokers can accept securities as margin from clients only
                                    by way of pledge in the depository system w.e.f. September 1, 2020.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    2. Update your mobile number &amp; email Id with your stock
                                    broker/depository participant and receive OTP directly from
                                    depository on your email id and/or mobile number to create pledge.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    3. Pay 20% upfront margin of the transaction value to trade in cash
                                    market segment.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    4. Investors may please refer to the Exchange's Frequently Asked
                                    Questions (FAQs) issued vide circular reference NSE/INSP/45191 dated
                                    July 31, 2020 and NSE/INSP/45534 and BSE vide notice no. 20200731-7
                                    dated July 31, 2020 and 20200831-45 dated August 31, 2020 dated
                                    August 31, 2020 and other guidelines issued from time to time in
                                    this regard
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    5. Check your Securities /MF/ Bonds in the consolidated account
                                    statement issued by NSDL/CDSL every month.
                                    <br />
                                    Issued in the interest of Investors"
                                </span>
                            </div>
                        </div>
                        <div className="footer-border mtop30" />
                        <div className="mtop30">
                            <span className="f10  lightgrey">Investor Alert</span>
                            <div className="mtop10" />
                            <div>
                                <span className="f10 lightgrey">
                                    1. KYC is one time exercise while dealing in securities markets -
                                    once KYC is done through a SEBI registered intermediary (Broker, DP,
                                    Mutual Fund etc.), you need not undergo the same process again when
                                    you approach another intermediary
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    2. For Stock Broking Transaction 'Prevent unauthorised transactions
                                    in your account --&gt; Update your mobile numbers/email IDs with
                                    your stock brokers. Receive information of your transactions
                                    directly from Exchange on your mobile/email at the end of the
                                    day...Issued in the interest of Investors.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    3. For Depository Transaction 'Prevent Unauthorized Transactions in
                                    your demat account --&gt; Update your Mobile Number with your
                                    Depository Participant. Receive alerts on your Registered Mobile for
                                    all debit and other important transactions in your demat account
                                    directly from CDSL/NSDL on the same day...Issued in the interest of
                                    investors.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    4. No need to issue cheques by investors while subscribing to IPO.
                                    Just write the bank account number and sign in the application form
                                    to authorise your bank to make payment in case of allotment. No
                                    worries for refund as the money remains in investor's account.
                                </span>
                            </div>
                            <div>
                                <span className="f10 lightgrey">
                                    5. Investors should be cautious on unsolicited emails and SMS
                                    advising to buy, sell or hold securities and trade only on the basis
                                    of informed decision. Investors are advised to invest after
                                    conducting appropriate analysis of respective companies and not to
                                    blindly follow unfounded rumours, tips etc. Further, you are also
                                    requested to share your knowledge or evidence of systemic
                                    wrongdoing, potential frauds or unethical behaviour through the
                                    anonymous portal facility provided on BSE &amp; NSE website.
                                </span>
                            </div>
                        </div>
                        <div className="footer-border mtop30" />
                        <div className="mtop30">
                            <span className="f10 lightgrey">
                                This is to inform that, many instances were reported by general public
                                where fraudsters are cheating general public by misusing our brand
                                name Motilal Oswal. The fraudsters are luring the general public to
                                transfer them money by falsely committing attractive brokerage /
                                investment schemes of share market and/or Mutual Funds and/or personal
                                loan facilities. Though we have filed complaint with police for the
                                safety of your money we request you to not fall prey to such
                                fraudsters. You can check about our products and services by visiting
                                our website{" "}
                                <a href="https://www.motilaloswal.com" className="orangecolor f10">
                                    www.motilaloswal.com
                                </a>
                                . You can also write to us at query@motilaloswal.com, to know more
                                about products and services.
                            </span>
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSectionFooter;

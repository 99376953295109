import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select from 'react-select';

//#region style override for react select
const customStyle = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Open Sans, sans-serif',
        color: "#495057",
        // fontFamily: 'inherit',

    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: '16px',
        border: 'none',
        boxShadow: 'none',
        transition: 'none',
        height: '46px',
        color: '#222020',
        fontFamily: 'inherit',

    }),
    container: (provided) => ({
        ...provided,
        border: 'none',
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #dedede',
        boxShadow: "none",
        '&:hover': { borderColor: '#dedede', },
        backgroundColor: "white",
        color: "#495057",
    }),
    indicatorSeparator: (provided) => ({
        display: "none"
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: "103px"
    })
}
//#endregion


const RegSelect = (props) => {

    const [isDisable, setIsDisable] = useState(false)

    useEffect(() => {
        if (props.disabled) {
            setIsDisable(props.disabled)
        }
    }, [getOption])


    const handleChange = (option) => {
        let { label, value } = option;
        props.onChange({ name: props.name, value });
    }

    const handleBlur = () => {
        props.onBlur({ name: props.name, value: props.value, isRequired: props.isRequired || true })
    }

    const getOption = (value) => {
        return props.options.find(obj => obj.value === value);
    }


    return (
        <div className="form-group">
            <Select
                options={props.options}
                value={getOption(props.value)}

                onChange={handleChange}
                onBlur={handleBlur}

                styles={customStyle}
                placeholder={props.placeholder}
                isDisabled={isDisable}
            // menuIsOpen={true}
            />

            <div className={classNames('reg-error disappear', { 'appear': props.error })}>{props.error}</div>
        </div>
    )
}

export default RegSelect;
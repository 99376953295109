import feedbackApi from "../services/feedbackApi";
import { put, call } from 'redux-saga/effects';
import { GET_FEEDBACK_QUESTIONS, GET_FEEDBACK_ANSWERS } from "../redux/types/feedback";
import { push } from 'connected-react-router';
import { } from '../redux/types/feedback';
import Cookie from '../lib/cookies';
import { clearLoading, setLoading } from "../redux/actions/loader";
import { apiErrorResponse } from '../helper/constant';
import { showSnackBar } from "../lib/snackBar";
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new feedbackApi();

export function* getFeedback({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        yield put(setLoading());
        let response = yield api.getFeedback(payload)
        yield put(clearLoading());

        console.log("response getFeedback", response);

        if (response.data && response.data.Status) {

            yield put({ type: GET_FEEDBACK_QUESTIONS, payload: response.data.Data.GETFEEDBACKQUESATIONS })
            yield put({ type: GET_FEEDBACK_ANSWERS, payload: response.data.Data.GETFEEDBACKANSWERS })

            resolve && resolve(response.data.Data);
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}

export function* updateFeedbackDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.updateFeedbackDetails(payload)
        yield put(clearLoading());

        console.log("response updateFeedbackDetails", response);

        if (response.data && response.data.Status) {

            resolve && resolve(response.data.Data);

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getVideoLinkDetails({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        // yield put(setLoading({ message: "Generating some more exciting offers for you" }));
        yield put(setLoading({ message: '' }));
        let response = yield api.getVideoLinkDetails(payload)
        yield put(clearLoading());

        console.log("response getVideoLinkDetails", response);

        if (response.data && response.data.Status) {

            resolve && resolve(response.data.Data);

        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            console.log('Something went wrong');
        }

    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }

}
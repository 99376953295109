import React, { Component, useState } from 'react';


const MFDSection10 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0 oyo-multiplewyas-outer">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">
                            There is a Mutual Fund for Everyone
                        </span>
                    </h2>
                </div>
                <div className="dflex wrap spacebetween mtop30">
                    <a
                        id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt52_block_wtColumn1_MO_Website_CW_wt87_block_wt1"
                        tabIndex={422}
                        className="whitegradientbox d48 mtop30 mobflex100 mobmtop20"
                    >
                        <div>
                            <div />
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">Equity Funds</h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Suitable for investors seeking higher returns over a longer
                                    duration.
                                </span>
                            </div>
                        </div>
                    </a>
                    <a
                        id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt52_block_wtColumn1_MO_Website_CW_wt87_block_wt52"
                        tabIndex={423}
                        className="whitegradientbox d48 mtop30 mobflex100 mobmtop20"
                    >
                        <div>
                            <div />
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">Debt Funds</h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Suitable for investors seeking stable returns in a shorter time
                                    duration.
                                </span>
                            </div>
                        </div>
                    </a>
                    <a
                        id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt52_block_wtColumn1_MO_Website_CW_wt87_block_wt4"
                        tabIndex={424}
                        className="whitegradientbox d48 mtop30 mobflex100 mobmtop20"
                    >
                        <div>
                            <div />
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">Hybrid</h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Suitable for investors seeking a balanced approach for medium to
                                    long duration.
                                </span>
                            </div>
                        </div>
                    </a>
                    <a
                        id="wt110_OutSystemsUIWeb_wtLayout_block_wtContent_wtMainContent_OutSystemsUIWeb_wt52_block_wtColumn1_MO_Website_CW_wt87_block_wt53"
                        tabIndex={425}
                        className="whitegradientbox d48 mtop30 mobflex100 mobmtop20"
                    >
                        <div>
                            <div />
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">Tax Saver</h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Suitable for investors seeking tax benefits up to Rs. 46,800 and
                                    wealth creation.
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                {/* <div class="mtop40"></div>
                     <div class="whitebbox">
                        <div>
                           <div class="col12">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Equity/Stocks:-
                                    Discover the ease of investing in top companies with expert
                                    research</span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">F&amp;O:- Trade at a
                                    lightning speed powered by expert minds and AI-fueled
                                    algorithms&nbsp;</span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Mutual Fund:- Invest in
                                    your future, one fund at a time, with Mutual Funds that
                                    matter</span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Upcoming IPO:- Invest
                                    early, invest smart with Pre-apply IPO application<br></span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">IAP:- Expert curated,
                                    AI-powered stock portfolio for better returns</span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Start a SIP :- Start
                                    small to treat yourself with a good life ahead. Start a SIP /
                                    UPI-based single click SIPs</span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Commodity:- Ride the
                                    waves of opportunity with strategic commodity trading / Trade
                                    across multiple commodities <br></span>
                                 </div>
                              </div>
                           </div>
                           <div class="col12 mtop20">
                              <div class="content">
                                 <div class="mtop4"><span class="f14 color8A93A0">Currency:- Dive into
                                    the global economy with strategic currency trading / Trade
                                    across 8+ currency pairs&nbsp;</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> */}
            </section>

        </>

    );
}

export default MFDSection10;

import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import { createLogger } from "redux-logger";
import createSagaMiddleware from 'redux-saga'
import rootSaga from "../sagas";
import { routerMiddleware } from "connected-react-router";
import history from "../lib/history";
// import { composeWithDevTools } from "redux-devtools-extension";


const sagaMiddleware = createSagaMiddleware()

const logger = createLogger({
  predicate: (getState, action) => !!process.env.NODE_ENV
});

const connectedRouterMiddleware = routerMiddleware(history);

export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  const middlewares = [
    logger,
    sagaMiddleware
  ]

  if (!process.env.IS_SERVER) {
    middlewares.push(connectedRouterMiddleware)
  }

  const enhancers = [applyMiddleware(...middlewares)];

  console.log(process.env.IS_SERVER, process.env.APP_MODE)
  if (!process.env.IS_SERVER && process.env.REACT_APP_MODE === "development") {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  }

  const store = createStore(
    reducers,
    initialState,
    compose(
      ...enhancers,
    )
  );

  sagaMiddleware.run(rootSaga);
  // Extensions
  //store.runSaga = sagaMiddleware.run
  store.asyncReducers = {}; // Async reducer registry

  return store;
}

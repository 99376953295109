import HelpUsApi from "../services/helpUsApi";
import VerificationApi from "../services/verificationApi";
import { put } from 'redux-saga/effects'
import { GET_WORK_DETAILS_RESPONSE, GET_WORK_DETAILS_FAIL, UPDATE_WORK_DETAILS_RESPONSE, UPDATE_WORK_DETAILS_FAIL } from '../redux/types/helpUs';
import { apiErrorResponse } from '../helper/constant'
import { setLoading, clearLoading } from "../redux/actions/loader";
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'

let api = new HelpUsApi();

export function* getWorkDetails({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.getWorkDetails(payload)

        if (response.data && response.data.Status) {
            if (response.data.Data) {

                responsePayload = response.data.Data;

                yield put({ type: GET_WORK_DETAILS_RESPONSE, payload: responsePayload })
                resolve && resolve(responsePayload);

            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* updateWorkDetails({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.updateWorkDetails(payload)

        if (response.data && response.data.Status) {
            if (response.data.Data) {

                responsePayload = response.data.Data;

                yield put({ type: UPDATE_WORK_DETAILS_RESPONSE, payload: responsePayload })
                resolve && resolve(responsePayload);

            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            if (response.data.code !== 401) {
                responsePayload = { type: "error", message: response.data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

import React from 'react'
import Button from '../../atom/button';
import classNames from 'classnames';

const ProceedButton = (props) => {
    return (
        <>
            <div className={props.className}>
                <div className={'col-md-12 col-sm-12 col-12'}>
                    <Button className={classNames("common-btn", "mt40")}
                        onClick={props.handleProceed}>
                        {props.label}
                    </Button>
                    {props.skip &&
                        <a href="#" onClick={props.skip}>Skip</a>
                    }
                </div>
            </div>
        </>
    )
}
export default ProceedButton;
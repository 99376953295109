import React, { Component } from 'react'
import RegHeader from '../../organism/regHeader';
import RegForm from '../../organism/regForm';
import { connect } from 'react-redux';

class MobileRegistration extends Component {

    render() {
        return (
            <>
                {/* <MobileView> */}
                <RegHeader />
                <div className="page-container">
                    <div className="leftSide">

                        <section className="section-spac equity-express-section">
                            <div className="container">
                                <div className="row">
                                    <RegForm
                                        formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                        isRegistrationLink={true} />
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
                {/* </MobileView> */}
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    formTypeCommingFromParams: state.homePageQueryParams.formTypeCommingFromParams
})

export default connect(mapStateToProps, null)(MobileRegistration)
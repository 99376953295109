import Api from '.';
import { endpoints } from './endpoints';

export default class OfferApi extends Api {
    getFeedback(data) {
        let url = this.buildUrl(endpoints.feedback.getFeedback)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    updateFeedbackDetails(data) {
        let url = this.buildUrl(endpoints.feedback.updateFeedbackDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    getVideoLinkDetails(data) {
        let url = this.buildUrl(endpoints.feedback.getVideoLinkDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
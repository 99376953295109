import Snackbar from 'cogo-toast';
import { isMobile } from 'react-device-detect';

export const showSnackBar = ({ type, message, option = {}, hideAfterSec, closeSnackbar }) => {
    let defaultOptions = { position: isMobile ? 'top-right' : 'bottom-center' }
    let hideError, hideSuccess, hideWarn, hideInfo;
    switch (type) {
        case 'error': {
            const { hide } = Snackbar.error(message, {
                ...defaultOptions, ...option, hideAfter: hideAfterSec ? hideAfterSec : 5,
                // onClick: () => { hide() }
            });
            hideError = hide;
            break;
        }
        case 'success': {
            const { hide } = Snackbar.success(message, {
                ...defaultOptions, ...option, hideAfter: hideAfterSec ? hideAfterSec : 5,
                // onClick: () => { hide() }
            });
            hideSuccess = hide;
            break;
        }
        case 'warn': {
            const { hide } = Snackbar.warn(message, {
                ...defaultOptions, ...option, hideAfter: hideAfterSec ? hideAfterSec : 300,
                // onClick: () => { hide() }
            });
            hideWarn = hide;
            break;
        }
        default: {
            const { hide } = Snackbar.info(message, {
                ...defaultOptions, ...option, hideAfter: hideAfterSec ? hideAfterSec : 300,
                // onClick: () => { hide() } 
            });
            hideInfo = hide;
            break;
        }
    }

    document.addEventListener('click', () => {
        closeSnackbar && closeSnackbar();
        hideError && hideError();
        hideSuccess && hideSuccess();
        hideWarn && hideWarn();
        hideInfo && hideInfo();
    })
}

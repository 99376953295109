import { produce } from 'immer';
import { GET_ESIGN_DETAILS_RESPONSE, GET_AUTH_MODE_RESPONSE, GET_AVAILABLE_CLIENTCODE, GENERATE_CLIENTCODE, EMUDRA, GET_EMUDRA, PUSH_DATA_IN_DRISHTI, CHECK_EMUDHRA, CHECK_VERIFIED, GENERATECLIENTCODE_MF } from '../types/esign';

const initialState = {
    getEsignDetails: "",
    getAuthMode: "",
    getAvailableClientCode: "",
    generateClientCode: "",
    emudhra: "",
    getEmudhra: "",
    pushDataInDrishti: "",
    checkEmudhra: "",
    checkVerified: "",
    generateClientCodeMF: "",

}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;
    console.log("In reducers *** type=>", type, "payload=>", payload, "action=>", action, "state=>", state);

    switch (type) {
        case GET_ESIGN_DETAILS_RESPONSE:
            // return { ...state, ...payload };
            state.getEsignDetails = payload;
            return;

        case GET_AUTH_MODE_RESPONSE:
            // return { ...state, ...payload };
            state.getAuthMode = payload;
            return;

        case GET_AVAILABLE_CLIENTCODE:
            state.getAvailableClientCode = payload;
            return;

        case GENERATE_CLIENTCODE:
            state.generateClientCode = payload;
            return;

        case EMUDRA:
            state.emudhra = payload;
            return;

        case GET_EMUDRA:
            state.getEmudhra = payload;
            return;
        case PUSH_DATA_IN_DRISHTI:
            state.pushDataInDrishti = payload;
            return;

        case CHECK_EMUDHRA:
            state.checkEmudhra = payload;
            return;

        case CHECK_VERIFIED:
            state.checkVerified = payload;
            return;

        case GENERATECLIENTCODE_MF:
            state.generateClientCodeMF = payload;
            return;

        default:
            return state
    }

})

export default reducer;
import React, { Component, useState } from 'react';


const MFDSection16 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="whatisproduct-sec">
                    <div className="cms">
                        <h2>
                            <div className="text-center line1">
                                <span className="lightgrey f32 font-semi-bold mobf24">
                                    How do Mutual Funds
                                </span>
                            </div>
                            <div className="text-center line1">
                                <span className="f32 font-semi-bold mobf24">work in India?</span>
                            </div>
                        </h2>
                        <div className="mtop30" />
                        <p>
                            Mutual funds work by pooling money from multiple investors and using
                            that collective pool of funds to invest in a diversified portfolio of
                            stocks, bonds, or other securities. Here's a detailed explanation of how
                            mutual funds operate:
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Creation of the Fund</div>
                        <p>
                            {" "}
                            An asset management company (AMC) or fund house establishes a mutual
                            fund. Each mutual fund has a specific investment objective and strategy
                            outlined in its offering document.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Fund Manager's Role</div>
                        <p>
                            {" "}
                            The mutual fund is managed by a professional fund manager or a team of
                            managers. Their responsibility is to make investment decisions on behalf
                            of the fund's investors.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">
                            Investor Participation
                        </div>
                        <p>
                            {" "}
                            Investors, like you, purchase shares or units of the mutual fund. These
                            shares represent your proportional ownership of the fund's holdings.
                            When you invest, you become a shareholder in the mutual fund.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Portfolio Creation</div>
                        <p>
                            {" "}
                            The fund manager uses the money pooled from investors to create a
                            diversified portfolio. This portfolio includes a mix of stocks, bonds,
                            money market instruments, or other securities, depending on the fund's
                            objectives.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Risk Mitigation</div>
                        <p>
                            Diversification is a key feature of mutual funds. It helps spread risk
                            because if one investment performs poorly, gains in other investments
                            can offset those losses.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">
                            Professional Management
                        </div>
                        <p>
                            Fund managers are responsible for researching, selecting, and managing
                            the assets in the portfolio. They make buy and sell decisions based on
                            market conditions and the fund's objectives.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Net Asset Value (NAV)</div>
                        <p>
                            {" "}
                            The NAV is the price at which investors can buy or sell mutual fund
                            units. It is calculated at the end of each trading day and is based on
                            the fund's total assets minus its liabilities. NAV is the per-unit price
                            of the fund.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Liquidity</div>
                        <p>
                            {" "}
                            Mutual fund shares are highly liquid. Investors can typically buy or
                            sell them on any business day at the NAV price, providing liquidity and
                            ease of entry or exit.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Distributions</div>
                        <p>
                            {" "}
                            Mutual funds may distribute dividends, interest income, and capital
                            gains to shareholders. These distributions can be reinvested in the fund
                            or taken as cash.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Fees and Expenses</div>
                        <p>
                            {" "}
                            Mutual funds charge fees, including management fees and operating
                            expenses. These fees are expressed as an expense ratio and can impact
                            your overall returns.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Regulation</div>
                        <p>
                            Mutual funds in India are regulated by the Securities and Exchange Board
                            of India (SEBI). Regulations ensure transparency and compliance with
                            industry standards.
                        </p>
                        <div className="mtop20" />
                        <p>
                            Investors can choose from various types of mutual funds, each with its
                            own investment focus and strategy. The type of mutual fund you select
                            should align with your financial goals, risk tolerance, and investment
                            horizon
                        </p>
                        <div className="mtop20" />
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSection16;

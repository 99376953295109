import Api from '.';
import { endpoints } from './endpoints';

export default class OfferApi extends Api {
    getOffersList(data) {
        let url = this.buildUrl(endpoints.offer.getOffersList)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    registerWalletOffer(data) {
        let url = this.buildUrl(endpoints.offer.registerWalletOffer)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initiateRedeem(data) {//
        let url = this.buildUrl(endpoints.offer.initiateRedeem)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initiateOffer(data) {//
        let url = this.buildUrl(endpoints.offer.initiateOffer)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initiateSkip(data) {//
        let url = this.buildUrl(endpoints.offer.initiateSkip)
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    offerpaymentStatus(data) {//terms and consition data
        let url = this.buildUrl(endpoints.offer.offerpaymentStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    downloadesignPdfForm(data) {//
        let url = this.buildUrl(endpoints.offer.downloadesignPdfForm)
        console.log('data', data);
        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getOfferDetails(data) {//get redeem offers details
        let url = this.buildUrl(endpoints.offer.getOfferDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getValuePackDetails(data) {
        let url = this.buildUrl(endpoints.offer.getValuePackDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    initiateValuepack(data) {
        let url = this.buildUrl(endpoints.offer.initiateValuepack)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updateValuePackDetails(data) {
        let url = this.buildUrl(endpoints.offer.updateValuePackDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
import React from 'react'
import Modal from '../../molecules/modal'
import ProceedButton from '../../molecules/proceedButton';
import './index.css';

const ErrorPopup = ({ isShow, closeModal, errorMsg }) => {

    console.log(isShow, 'isShow');
    return (
        <Modal
            open={isShow}

            onClose={closeModal}

            closeOnDocumentClick
            lockScroll
            modal

            className={'errorPopup-content'}
        >
            <div className="errorPopup-msg">{errorMsg}</div>
            <div className="popup-btn">
                <ProceedButton
                    handleProceed={closeModal}
                    label={'OK'}
                    className={'mt20'}
                />
            </div>
        </Modal>

    )
}
export default ErrorPopup;
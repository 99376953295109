export const GET_SCHEME_DETAILS_BRANCH = "GET_SCHEME_DETAILS_BRANCH";
export const GET_SCHEME_DETAILS_BRANCH_RESPONSE = "GET_SCHEME_DETAILS_BRANCH_RESPONSE";
export const GET_SCHEME_DETAILS_BRANCH_FAIL = "GET_SCHEME_DETAILS_BRANCH_FAIL";

export const ON_SCHEME_CHANGE_BRANCH = "ON_SCHEME_CHANGE_BRANCH";
export const ON_SCHEME_CHANGE_BRANCH_RESPONSE = "ON_SCHEME_CHANGE_BRANCH_RESPONSE";
export const ON_SCHEME_CHANGE_BRANCH_FAIL = "ON_SCHEME_CHANGE_BRANCH_FAIL";

export const SAVE_SCHEME_DETAILS_BRANCH = "SAVE_SCHEME_DETAILS_BRANCH";
export const SAVE_SCHEME_DETAILS_BRANCH_RESPONSE = "SAVE_SCHEME_DETAILS_BRANCH_RESPONSE";
export const SAVE_SCHEME_DETAILS_BRANCH_FAIL = "SAVE_SCHEME_DETAILS_BRANCH_FAIL";

export const GET_USER_PAYMENT_DETAILS = "GET_USER_PAYMENT_DETAILS";
export const GET_USER_PAYMENT_DETAILS_RESPONSE = "GET_USER_PAYMENT_DETAILS_RESPONSE";
export const GET_USER_PAYMENT_DETAILS_FAIL = "GET_USER_PAYMENT_DETAILS_FAIL";

export const GET_TRADER_DETAILS = "GET_TRADER_DETAILS";
export const GET_TRADER_DETAILS_RESPONSE = "GET_TRADER_DETAILS_RESPONSE";
export const GET_TRADER_DETAILS_FAIL = "GET_TRADER_DETAILS_FAIL";

export const INSERT_TRADER_DETAILS = "INSERT_TRADER_DETAILS";
export const INSERT_TRADER_DETAILS_RESPONSE = "INSERT_TRADER_DETAILS_RESPONSE";
export const INSERT_TRADER_DETAILS_FAIL = "INSERT_TRADER_DETAILS_FAIL";

export const SAVE_SCHEME_DETAILS_DIRECT = "SAVE_SCHEME_DETAILS_DIRECT";
import React, { Component, useState } from 'react';
import Slider from "react-slick";
import $ from "jquery";

const MFDSection17 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    const settings = {
        infinite: true,
        slidesToShow: 2,
        dots: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024, // 1024px or larger
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768, // 768px or larger
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480, // 480px or larger
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="youpayrightborder">
                    <div className="youpay video-sec">
                        <h2 className="textlabel text-center">
                            <span className="f32 line1 semibold lightgrey mobf24 text-capitalize">
                                Making Money Work&nbsp;
                            </span>
                            <div>
                                <span className="f32 font-semi-bold mobf24 text-capitalize">
                                    for India Since 1987
                                </span>
                            </div>
                        </h2>
                        <div className="mtop40" />
                        <div className="guideslider">
                            <Slider {...settings}>

                                <div className="guide-video">
                                    <video
                                        className="video custom-video"
                                        width="100%"
                                        height="100%"
                                        muted controls
                                    >
                                        <source
                                            src="https://dbs7qpzv4mcv.cloudfront.net/13_Rise_Testimonial_1.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                                <div className="guide-video">
                                    <video
                                        className="video custom-video"
                                        width="100%"
                                        height="100%"
                                        muted controls
                                    >
                                        <source
                                            src="https://dbs7qpzv4mcv.cloudfront.net/13_Rise_Testimonial_1.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}

export default MFDSection17;

import { produce } from 'immer';
import { SET_USER } from '../types/user';
import {
    SAVE_PROOF_LIST, UPDATE_AFTER_DELETE_PROOF, SAVE_BASE64_PROOF_CONTENT, SAVE_UPLOAD_PROOF_DATA, SHOW_PAN_TEXT_BOX,
    HIDE_PAN_TEXT_BOX,
    SAVE_VALIDATION_PAN,
    SAVE_VALIDATE_ACCOUNT_AND_IFSC,
    UPDATE_BANK_PROOF,
    UPDATE_IS_ADHAR_XML,
    SAVE_VALIDATE_ADHAR_NO,
    UPDATE_AFTER_PAN_SITE_REJECTION,
    SET_MICR
} from '../types/proof';
import { documentKeyForProof, userType } from '../../helper/constant';

const initialState = {
    proofList: [],
    // showPANTextBox: false,
    proofField: {
        panTextBox: {
            show: false,
            value: '',
            status: null
        },
        bankField: {
            accountNumber: '',
            ifscNumber: '',
            status: null
        }
    },
    micrOptions: []
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SAVE_PROOF_LIST:
            console.log('payload', payload)
            state.proofList = payload;
            return;

        case UPDATE_AFTER_DELETE_PROOF:
            let deletedProof;

            if (payload.applicationType === userType.alliance) {
                console.log('delete alliance');
                deletedProof = state.proofList.find(proof => proof.DOCUMENTKEY === payload.CallType);

            } else {
                console.log('delete normal');

                let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                let clientPhotoInChild = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === documentKeyForProof.CLIENTPHOTO);
                console.log("clientPhotoInChild=>", clientPhotoInChild);

                if (payload.CallType === documentKeyForProof.AADHARFRONT ||
                    payload.CallType === documentKeyForProof.AADHARBACK) {//AADHAR sub level documents
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    deletedProof = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.CallType);
                } else if (clientPhotoInChild && payload.CallType === documentKeyForProof.CLIENTPHOTO) {
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    deletedProof = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.CallType);
                } else {
                    deletedProof = state.proofList.find(proof => proof.DOCUMENTKEY === payload.CallType);
                }
            }
            deletedProof.FILEPATH = '';
            deletedProof.FILETYPE = '';
            deletedProof.PROOFSTATUS = '';
            deletedProof.VERIFIERREJECTREASON = '';
            deletedProof.imageData = '';// imageData is base64 data .clear base64 data if user click on view before.
            deletedProof.NAMEONDOC = '';

            if (payload.CallType === documentKeyForProof.PAN ||
                payload.CallType === documentKeyForProof.BANKPROOFEQ ||
                payload.CallType === documentKeyForProof.MARGINCHEQUEUPLOAD ||
                payload.CallType === documentKeyForProof.CLIENTPHOTO
            ) {
                deletedProof.CROPSIGNPATH = ''
                deletedProof.cropImage = ''
            }
            return;

        case SAVE_BASE64_PROOF_CONTENT: //response of view image 
            let proof64;

            if (payload.applicationType === userType.alliance) {
                console.log('view alliance');
                proof64 = state.proofList.find(proof => proof.DOCUMENTKEY === payload.CallType);
            } else {
                console.log('view normal');

                let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                let clientPhotoInChild = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === documentKeyForProof.CLIENTPHOTO);
                console.log("clientPhotoInChild=>", clientPhotoInChild);

                if (payload.CallType === documentKeyForProof.AADHARFRONT ||
                    payload.CallType === documentKeyForProof.AADHARBACK) {//AADHAR sub level documents
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    proof64 = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.CallType);
                } else if (clientPhotoInChild && payload.CallType === documentKeyForProof.CLIENTPHOTO) {
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    proof64 = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.CallType);
                } else if (payload.CallType === documentKeyForProof.CHEQUESIGN) {
                    let bankProofEQ64 = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.BANKPROOFEQ);
                    let marginChequeProof64 = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.MARGINCHEQUEUPLOAD);
                    if (bankProofEQ64) {
                        bankProofEQ64.cropImage = payload.FileData;
                    } else if (marginChequeProof64) {
                        marginChequeProof64.cropImage = payload.FileData;
                    }
                    return state;
                }
                else {
                    proof64 = state.proofList.find(proof => proof.DOCUMENTKEY === payload.CallType);
                }

            }

            proof64.imageData = payload.FileData;// imageData is base64 data. Base64 is way of showing image in form of string
            return state;

        case SAVE_UPLOAD_PROOF_DATA: //upload proof positive response
            let proof;

            if (payload.applicationType === userType.alliance) {
                console.log('upload alliance');
                proof = state.proofList.find(proof => proof.DOCUMENTKEY === payload.documentKey);

            } else {
                console.log('upload normal');

                let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                let clientPhotoInChild = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === documentKeyForProof.CLIENTPHOTO);
                console.log("clientPhotoInChild=>", clientPhotoInChild);

                if (payload.documentKey === documentKeyForProof.AADHARFRONT ||
                    payload.documentKey === documentKeyForProof.AADHARBACK) {//AADHAR sub level documents
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    aadhar.NAMEONDOC = payload.name;
                    proof = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.documentKey);
                } else if (clientPhotoInChild && payload.documentKey === documentKeyForProof.CLIENTPHOTO) {
                    let aadhar = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
                    aadhar.NAMEONDOC = payload.name;
                    proof = aadhar.SUBPROPERTIES.find(proof => proof.DOCUMENTKEY === payload.documentKey);
                } else {
                    proof = state.proofList.find(proof => proof.DOCUMENTKEY === payload.documentKey);
                }
            }
            // proof.imageData = payload.base64Data;// This is removed.because adhar mask. imageData is base64 data. Base64 is way of showing image in form of string
            proof.FILEPATH = payload.filePath;//uploaded image filePath
            proof.PROOFSTATUS = payload.documentStatus;
            proof.VERIFIERREJECTREASON = payload.rejectionReason;
            proof.NAMEONDOC = payload.name;

            if (
                payload.documentKey === documentKeyForProof.PAN ||
                payload.documentKey === documentKeyForProof.BANKPROOFEQ ||
                payload.documentKey === documentKeyForProof.MARGINCHEQUEUPLOAD

            ) {
                proof.CROPSIGNPATH = payload.cropSignpath
            }

            if (payload.documentKey === documentKeyForProof.PAN) {
                state.proofField.panTextBox.show = false;//remove pan txt box if ocr read pan correclty
            }
            return state;

        case SHOW_PAN_TEXT_BOX:
            state.proofField.panTextBox.show = true;
            return state;

        case HIDE_PAN_TEXT_BOX:
            state.proofField.panTextBox.show = false;
            return state;

        case SAVE_VALIDATION_PAN:
            state.proofField.panTextBox = {
                show: true,
                value: payload.panNumber,
                status: payload.status,
            }
            return state;

        case SAVE_VALIDATE_ACCOUNT_AND_IFSC:
            state.proofField.bankField = {
                accountNumber: payload.accountNumber,
                ifscNumber: payload.ifscNumber,
                status: payload.status
            }
            return state;

        case UPDATE_BANK_PROOF:
            let bankProof = state.proofList.find(proof => proof.DOCUMENTKEY === payload.callType);
            bankProof.IFSC = payload.ifscNumber;
            bankProof.ACCOUNTNUMBER = payload.accountNumber;
            break;

        case UPDATE_IS_ADHAR_XML:
            let adharProof = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
            adharProof.ISAADHAARXML = payload.isAdharXML;
            break;

        case UPDATE_AFTER_PAN_SITE_REJECTION:
            let panProof;

            panProof = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.PAN);

            panProof.PROOFSTATUS = '0';
            panProof.VERIFIERREJECTREASON = '';
            return;

        case SAVE_VALIDATE_ADHAR_NO: {
            let adharProof = state.proofList.find(proof => proof.DOCUMENTKEY === documentKeyForProof.AADHAR);
            adharProof.AADHAARNO = payload.adharNo;
        }
        case SET_MICR:
            state.micrOptions = payload;
            return;
            break;
        default:
            return state
    }

})

export default reducer;
import React, { Component } from 'react'
import { LinkConstant } from '../../../helper/constant'

class RegFooter extends Component {
    render() {
        return (
            <footer>
                <ul>
                    <li>
                        <a href={LinkConstant.DisclaimerLink} target="_blank">
                            Disclaimer
                        </a>
                    </li>
                    <li>
                        <a  href={LinkConstant.TermsAndConditionsLink} target="_blank">
                            Terms & Conditions
                        </a>
                    </li>
                </ul>
            </footer>
        )
    }
}

export default RegFooter;
import React from 'react'
import logo from '../../../assets/images/MO-PHYGITAL.png';
import Image from '../../atom/image';
import classNames from 'classnames'

export const RegLogo = (props) => {
    return (
        <div className={classNames("logo-block", { 'disable-mo-logo': props.diy })}>
            <a href="https://www.motilaloswal.com" className={"logo clearfix"}>
                <Image src={logo} alt="" title="" width="" height="" />
            </a>
        </div>
    )
}


import { DOWNLOAD_ESIGN_PDF_FORM, OFFER_REDEEM, INIT_OFFER, SAVE_OFFER_REDEEM, OFFER_PAYMENT_STATUS, SAVE_INIT_OFFER, INIT_SKIP, SAVE_INIT_SKIP, GET_OFFER_LIST, REGISTER_WALLET_OFFER, GET_VALUEPACK_DETAILS, INITIATE_VALUEPACK, UPDATE_VALUEPACK } from "../types/offer"

export const offerRedeem = (payload) => ({
    type: OFFER_REDEEM,
    payload
})

export const saveOfferRedeem = (payload) => ({
    type: SAVE_OFFER_REDEEM,
    payload
})

export const initOffer = (payload) => ({
    type: INIT_OFFER,
    payload
})

export const offerpaymentStatus = (payload) => ({
    type: OFFER_PAYMENT_STATUS,
    payload
})
export const saveInitOffer = (payload) => ({
    type: SAVE_INIT_OFFER,
    payload
})
export const initSkip = (payload) => ({
    type: INIT_SKIP,
    payload
})

export const saveInitSkip = (payload) => ({
    type: SAVE_INIT_SKIP,
    payload
})

export const downloadesignPdfForm = (payload) => ({
    type: DOWNLOAD_ESIGN_PDF_FORM,
    payload
})

export const getOfferList = (payload) => ({
    type: GET_OFFER_LIST,
    payload
})

export const registerWalletOffer = (payload) => ({
    type: REGISTER_WALLET_OFFER,
    payload
})

export const getValuePackDetails = (payload) => ({
    type: GET_VALUEPACK_DETAILS,
    payload
})

export const initiateValuepack = (payload) => ({
    type: INITIATE_VALUEPACK,
    payload
})

export const updateValuePackDetails = (payload) => ({
    type: UPDATE_VALUEPACK,
    payload
})

import React, { Component } from 'react'
import demat1 from '../../../assets/images/demat1.svg';
import demat2 from '../../../assets/images/demat2.svg';
import demat3 from '../../../assets/images/demat3.svg';
import demat4 from '../../../assets/images/demat4.svg';
import demat5 from '../../../assets/images/demat5.svg';
import demat6 from '../../../assets/images/demat6.svg';
import Image from '../../atom/image';

const feature1 = [
    { icon: demat1, description: '1 Million+ Happy Demat Account Holders' },
    { icon: demat2, description: 'High Tech 4-Star App for Traders & Investors' },
    { icon: demat3, description: '30+ Years of Research Expertise' },
];

const feature2 = [
    { icon: demat4, description: 'Personal Advisor / Advisory for Customers' },
    { icon: demat5, description: 'Customized Investment Plans with One Click Investment' },
    { icon: demat6, description: 'Wide Choice of Asset Classes to Invest in' },
];

function Feature({ icon, description }) {
    return (
        <div className="col-lg-3 col-md-4 text-center text-md-left">
            <div className="mb30">
                <Image src={icon} className="mb-4" alt={''} />
                <h2 className="font16">{description}</h2>
            </div>
        </div>
    )
}


export default class RegFeature extends Component {
    render() {
        return (
            <section className="section-spac ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="top-title text-center">
                                <h2 className="title text-center">WHY OPEN A DEMAT ACCOUNT ONLINE WITH MOTILAL OSWAL?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row justify-content-between align-items-center">
                                {
                                    feature1.map(({ icon, description }, index) =>
                                        <Feature key={index} icon={icon} description={description} />
                                    )
                                }
                            </div>

                            <div className="row justify-content-between align-items-center">
                                {
                                    feature2.map(({ icon, description }, index) =>
                                        <Feature key={index} icon={icon} description={description} />
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

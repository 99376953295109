import React, { Component, useState } from 'react';


const MFDSection12 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0 cms">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">What are</span>
                        <div>
                            <span className="f30 semibold mobf24">mutual funds?</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop30" />
                <p className="lightgrey">
                    Mutual fund is a type of investment that invests money pooled from multiple
                    investors in a diversified portfolio managed by a professional fund manager.
                    Mutual Funds invest in multiple assets like stocks, bonds,{" "}
                    <a
                        href="https://www.motilaloswal.com/invest-in-commodities-market"
                        className="seohreflink"
                        target="_blank"
                    >
                        commodities
                    </a>
                    , etc. and simplify investments for retail investors by investing on their
                    behalf.
                </p>
                <div className="mtop20" />
                <p>
                    Since retail investors do not hold the expertise or the time to continuously
                    study the market conditions, it is the fund manager who takes the
                    responsibility to manage risk and safeguard capital investments made through
                    Mutual Funds. The fund manager is a qualified finance professional -
                    certified by SEBI to undertake portfolio management. S/he takes into account
                    the risk tolerance level, investment horizon and market conditions before
                    making investment decisions. This is what makes Mutual Funds a safer
                    investment option for retail investors as compared to direct equity.
                </p>
                <div className="mtop20" />
                <p>
                    When you invest in a mutual fund, you own shares or units of the fund, not
                    the underlying assets. These units represent your proportional ownership of
                    the fund's holdings. Think of mutual fund units as shares in a collective
                    investment. For example, imagine four friends who want to buy a box of 12
                    chocolates, but they each have only ₹10. They pool their money, buying the
                    box for ₹40. Each friend now owns 3 chocolates or 3 units of the box, based
                    on their ₹10 contribution. The cost of one unit is calculated by dividing
                    the total cost (₹40) by the total number of units (12), resulting in ₹3.33
                    per unit. When you multiply the number of units (3) by the cost per unit
                    (₹3.33), you get the initial investment of ₹10. Each friend is now a unit
                    holder in the box of chocolates, collectively owned by all of them.
                </p>
                <div className="mtop20" />
                <p>
                    Similar to stocks which have a trading price, a mutual fund unit has a Net
                    Asset Value (NAV). The NAV represents the market value of all the assets
                    held by a fund on a specific day, adjusted for expenses and charges, plus
                    accrued income, divided by the total number of units in the scheme. Whenever
                    you make an investment in mutual funds, you are allocated the number of
                    units based on the NAV prevailing for that day. For example - If the{" "}
                    <a
                        href="https://www.motilaloswal.com/blog-details/what-is-nav-and-how-do-i-calculate-it-/20018"
                        className="seohreflink"
                        target="_blank"
                    >
                        NAV{" "}
                    </a>{" "}
                    of a mutual fund is Rs. 20 today and you invest Rs. 10,000 in the mutual
                    fund - you will receive 500 units of the fund. (Rs. 10,000 divided by 20)
                </p>
            </section>

        </>

    );
}

export default MFDSection12;

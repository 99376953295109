import { produce } from 'immer';
import { GET_FEEDBACK_QUESTIONS, GET_FEEDBACK_ANSWERS } from '../types/feedback';


const initialState = {
    getFeedbackQuestions: null,
    getFeedbackAnswers: null
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_FEEDBACK_QUESTIONS:
            state.getFeedbackQuestions = payload;
            return;
        case GET_FEEDBACK_ANSWERS:
            state.getFeedbackAnswers = payload;
            return;
        default:
            return state;
    }
})

export default reducer;
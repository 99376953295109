import { produce } from 'immer';
import { PAYMENT_STATUS_RESPONSE, SEND_CHEQUE_APPROVAL_RESPONSE, REQUEST_CHEQUE_STATUS_RESPONSE } from '../types/payment';

const initialState = {
    commodityPaymentStatus: "",
    equityPaymentStatus: "",
    sendChequeApproval: "",
    requestChequeStatus: ""
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PAYMENT_STATUS_RESPONSE:
            return { ...state, ...payload };
        case SEND_CHEQUE_APPROVAL_RESPONSE:
            return { ...state, ...payload };
        case REQUEST_CHEQUE_STATUS_RESPONSE:
            return { ...state, ...payload };
        default:
            return state
    }

})

export default reducer;
import React from 'react';

const SjetFAQs = () => {
  return (
    <>
      <div id="hometabs" >
        <div class="mtop40"></div>
        <h2 class="conbox">FAQS ON DEMAT ACCOUNT</h2>
        <div class="mtop20"></div>


        <div class="tabs">
          <div class="tab">
            <input type="radio" name="hometabs" id="chck1" />
            <label class="tab-label" for="chck1">


              <h3>What is a demat account and what are the types of demat account?</h3> </label>
            <div class="tab-content">
              <p>A Demat account is an account that holds financial securities in electronic form. In India,
                Demat accounts are maintained by two depository organisations, NSDL (National Securities
                  Depository Limited) and CDSL (Central Depository Services Limited).</p>

              <p>There are 3 types of Demat account:</p>

              <ul>
                <li>
                  <strong>Regular Demat Account:</strong> For the investors/traders who reside within India.
                  </li>
                <li>
                  <strong>Repatriable Demat Account: </strong> For the NRIs. Funds can be transferred abroad. It
                  requires an associated NRE bank account.
                  </li>
                <li>
                  <strong>Non-Repatriable Demat Account:</strong> For the NRIs. The fund cannot be transferred
                  abroad. It requires an associated NRO bank account.
                  </li>
              </ul>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck2" />
            <label class="tab-label" for="chck2">


              <h3>What are the benefits &amp; uses of a demat account?</h3> </label>
            <div class="tab-content">
              <p>A Demat account helps keep your equity investments, your <a className='tradAccLink' href="https://www.motilaloswal.com/mutual-fund-investments" target="_blank">mutual fund</a> units, bonds, and other
                financial assets safe and secure in an online electronic environment. With a Demat and trading
                account, you can not only <a className='tradAccLink' href="https://www.motilaloswal.com/invest-in-upcoming-ipo" target="_blank">invest in upcoming IPOs</a> of companies but also start a mutual fund,
                  digital gold and SIP as well.</p>

              <p>Here’s a quick look at some of the other uses of a Demat account:</p>

              <ul>
                <li>
                  Enables easy and quick share transfers.
                  </li>
                <li>
                  Highly secure storage with zero risk.
                  </li>
                <li>
                  Can be accessed at any time from anywhere through the internet.
                  </li>
                <li>
                  Very less paperwork involved.
                  </li>
              </ul>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck3" />
            <label class="tab-label" for="chck3">


              <h3>Who can open a demat account?</h3> </label>
            <div class="tab-content">
              <p>Any individual resident of India, HUF, NRI, proprietary firm, partnership firm or company can
                  open a Demat and trading account with Motilal Oswal.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck4" />
            <label class="tab-label" for="chck4">


              <h3>What documents are required to open a DEMAT Account?</h3> </label>
            <div class="tab-content">
              <p>To open a DEMAT and Trading Account with Motilal Oswal, individuals are required to hold the following documents handy:</p>
              <ul>
                <li>
                  PAN Card.
                  </li>
                <li>
                  Aadhaar Card (Linked with Mobile Number).
                  </li>
                <li>
                  Bank Account Statement or Cancelled Cheque.
                  </li>
                {/* <li>
                  A photocopy of your PAN card.
                  </li>
                <li>
                  A photocopy of your proof of identity &amp; address, which can be anyone of the following.
                  (Aadhaar Card, Voter’s ID, Passport or Driving license)
                  </li> */}
              </ul>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck5" />
            <label class="tab-label" for="chck5">


              <h3>What charges are applicable to open a DEMAT Account?</h3> </label>
            <div class="tab-content">
              {/* <p>With Motilal Oswal, you don’t have to pay any Demat account charges for opening an account.
                That’s not all. In addition to being able to open a Demat account for free, you also get to
                enjoy zero <a className='tradAccLink' href="https://www.motilaloswal.com/blog-details/account-maintenance-charge-for-demat-account-explained/20090" target="_blank">Account Maintenance Charges (AMC)</a> for life. What’s even more exciting is that you can
                do it online from the comfort of your own home.
                </p> */}

              <p>
                There are no charges applicable to open a DEMAT Account with Motilal Oswal. You can open a DEMAT Account online instantly at 0 cost.
                </p>

            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck6" />
            <label class="tab-label" for="chck6">


              <h3>What is the minimum amount required to open a DEMAT Account?</h3> </label>
            <div class="tab-content">
              <p>There is no minimum amount required to open a DEMAT Account with Motilal Oswal. You can open your DEMAT and Trading account with Motilal Oswal for Free.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck16" />
            <label class="tab-label" for="chck16">


              <h3>Can I open two/multiple demat accounts?</h3> </label>
            <div class="tab-content">
              <p>Of course. You can open multiple Demat accounts under your name as long as the Demat accounts
                are not with the same Depository Participant (DP). For instance, if you already have a Demat
                account with another DP and wish to open another one with Motilal Oswal, you can do so.


                </p>
            </div>
          </div>

          {/* <div class="tab">
            <input type="radio" name="hometabs" id="chck7" />
            <label class="tab-label" for="chck7">


              <h3>What is the minimum amount required to open a demat account?</h3> </label>
            <div class="tab-content">
              <p>With Motilal Oswal, there’s absolutely no requirement of having to put up a minimum amount for
                demat account opening. You can open a demat account with us for free!
                </p>
            </div>
          </div> */}


          <div class="tab">
            <input type="radio" name="hometabs" id="chck9" />
            <label class="tab-label" for="chck9">


              <h3>How to open an online/paperless Demat Account?</h3> </label>
            <div class="tab-content">
              <p>To open an online DEMAT Account with Motilal Oswal, you are required to follow the given below steps:</p>

              <ul>
                <li> Download and install the MO Investor application on your mobile phone.</li>
                <li>Register with your mobile number and verify it with an OTP.</li>
                <li>Register with your email id and verify it with an OTP.</li>
                <li>Add your PAN Card details.</li>
                <li>Add your Bank details.</li>
                <li>Add your Aadhaar Details.</li>
                <li>E-sign through Aadhaar-based OTP verification.</li>
                <li>Complete your DEMAT Account Activation.</li>

                {/* <li><a className='tradAccLink' href="https://www.motilaloswal.com/" target="_blank">Visit our website.</a></li>
                <li>Click on ‘Open Demat Account’.</li>
                <li>Enter the mobile number and submit the OTP you received.</li>
                <li>Enter the email ID and proceed.</li>
                <li>Complete the EKYC (Upload all necessary documents like PAN Card, Aadhar Card, Bank Details
                    etc.)</li>
                <li>Add personal details.</li>
                <li>After verification perform e-sign in.</li>
                <li>Demat account activated.</li> */}
              </ul>
              <p>Alternatively, you can also log in through web, by clicking on <a className='tradAccLink' href="https://www.motilaloswal.com/open-demat-account/" target="_blank">www.motilaloswal.com/open-demat-account/ </a>and performing the same steps as above.</p>
              <p>Your Demat Account credentials will be sent to your registered email id once the account is activated successfully.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck10" />
            <label class="tab-label" for="chck10">


              <h3>Can a demat account be opened in the name of Joint Holders?</h3> </label>
            <div class="tab-content">
              <p>Yes, a demat account can be opened in single or joint names. However, a demat account can have
                  a maximum of three account holders - 1 main holder and 2 joint holders.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck11" />
            <label class="tab-label" for="chck11">
              <h3>Can an NRI open a demat account in India?</h3> </label>
            <div class="tab-content">
              <p>Yes, a Demat account can be opened in single or joint names. However, a Demat account can have
                a maximum of three account holders - 1 main holder and 2 joint holders.Of course. It is
                certainly possible to open a Demat account for NRIs through Motilal Oswal. But to do so, you
                will have to possess either an NRO or NRE bank account and submit a Portfolio Investment Scheme
                letter of approval from the Reserve Bank of India along with your Demat account application form
                  and other supporting documents.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck12" />
            <label class="tab-label" for="chck12">
              <h3>Can I open a demat account without opening a trading account?</h3> </label>
            <div class="tab-content">
              <p>No. You cannot hold a Demat Account without a Trading Account. A Demat Account is only an account to hold your shares. If you intend on selling the shares, you will need a trading account.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck13" />
            <label class="tab-label" for="chck13">
              <h3>Can I link demat and trading account?</h3> </label>
            <div class="tab-content">
              <p>Yes. If you intend to sell the shares that you hold, you will need to link your Demat account with your <a class='tradAccLink' href='https://www.motilaloswal.com/open-free-trading-account-online'>Trading account</a>.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck14" />
            <label class="tab-label" for="chck14">
              <h3>What is a joint demat account?</h3> </label>
            <div class="tab-content">
              <p>Just like a Joint Bank Account, a <a class='tradAccLink' href="https://www.motilaloswal.com/blog-details/Joint-Demat-Account-Opening-Details-And-Benefits/20478" target="_blank">Joint Demat Account</a> is a shared Demat account in between two or three individuals. Only one demat account holder is registered as the primary account holder, while the other account holders become secondary account holders.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck15" />
            <label class="tab-label" for="chck15">
              <h3>How long does it take to open a demat account?</h3> </label>
            <div class="tab-content">
              <p>It takes only 15 minutes to open a Free* Demat account with Motilal Oswal.</p>
            </div>
          </div>
          <div class="tab">
            <input type="radio" name="hometabs" id="chck17" />
            <label class="tab-label" for="chck17">
              <h3>Is demat account compulsory to apply for an IPO in India?</h3> </label>
            <div class="tab-content">
              <p>Yes. If you intend on applying for an IPO in India, it is mandatory to hold a Demat account. However, the choice for a trading account is optional.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck18" />
            <label class="tab-label" for="chck18">
              <h3>Can I withdraw money from a demat account?</h3> </label>
            <div class="tab-content">
              <p>Yes. You can add and withdraw funds from your Demat account anytime.</p>
            </div>
          </div>

          <div class="tab">
            <input type="radio" name="hometabs" id="chck19" />
            <label class="tab-label" for="chck19">
              <h3>How to check Demat account balance?</h3> </label>
            <div class="tab-content">
              <p>
                <p>To check your funds on <a className='tradAccLink' href="https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en_IN&gl=US" target="_blank">MO INVESTOR</a>, follow the below given steps</p>
                <ul>
                  <li>Login to the app</li>
                  <li>Go to ‘Menu’</li>
                  <li>Select ‘Fund Transfer’</li>
                  <li>The screen will display ‘Available Margin’ which will be your Demat Account Balance</li>
                </ul>
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="radio" name="hometabs" id="chck20" />
            <label class="tab-label" for="chck20">
              <h3>Do I require a Demat account for SIP?</h3> </label>
            <div class="tab-content">
              <p>No, it is not mandatory to hold a DEMAT Account to <a className='tradAccLink' href="https://www.motilaloswal.com/invest-in-sip" target="_blank">invest in SIP</a> Plans. However, if you wish to start Stock SIP, you will need to have a DEMAT Account.</p>
            </div>
          </div>



        </div>
      </div>
    </>
  )
}
export default SjetFAQs;
import React, { Component } from 'react';
import Faq from '../../../assets/cms/faq.json';
import RegFAQ from '../../molecules/regFAQ';
import { connect } from 'react-redux';
import { regFaqClicked } from '../../../redux/actions/analytics';

class RegFAQs extends Component {
    constructor(props) {
        super();
        this.state = {
            expandedItemId: 0
        }
    }

    handleToggle = (id) => {
        this.props.regFaqClicked("Yes");
        this.setState(prevState => {
            // if (prevState.expandedItemId === id) {
            //     return ({ expandedItemId: null })
            // } else {
            return ({ expandedItemId: id })
            // }
        })
    }

    render() {
        console.log('Faq', Faq);
        return (
            <>
                <div id="hometabs">
                    <div class="mtop40"></div>
                    <div class="text-center conbox"><h2>FAQS ON DEMAT ACCOUNT</h2></div>
                    <div class="mtop20"></div>


                    <div class="tabs">
                        {
                            Faq.map(({ question, answer }, index) => {
                                return (
                                    <RegFAQ key={index}
                                        question={question}
                                        answer={answer}
                                        id={index}
                                        expandedItemId={this.state.expandedItemId}
                                        handleToggle={this.handleToggle}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    regFaqClicked: (data) => dispatch(regFaqClicked(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegFAQs);
import React, { Component } from 'react'
import { RegLogo } from '../../molecules/regLogo'
import RegHeaderContact from '../../molecules/regHeaderContact'
import { connect } from 'react-redux';
import { userType } from '../../../helper/constant'
import { regContactUsClicked } from '../../../redux/actions/analytics';

class RegHeader extends Component {

    contactClicked = () => {
        this.props.regContactUsClicked("Yes");
    }

    render() {
        return (
            <header class="container">
                <div class="row">
                    <nav class="navbar">

                        <RegLogo
                            diy={(this.props.user.diyId || this.props.user.diymass) !== null ? true : false}
                        />
                        <RegHeaderContact
                            diy={(this.props.user.diyId || this.props.user.diymass) !== null ? true : false}
                            isDirect={this.props.user.applicationType === userType.direct}
                            isCRM={this.props.user.applicationType === userType.crm}
                            isPP={this.props.user.applicationType === userType.pp}
                            contactClicked={this.contactClicked}
                        />
                    </nav>
                </div>
            </header>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    regContactUsClicked: (data) => dispatch(regContactUsClicked(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegHeader);

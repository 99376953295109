import React, { Component, useState } from 'react';


const MFDSection13 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">
                            How to invest in
                        </span>
                        <div>
                            <span className="f30 semibold mobf24">Mutual Funds?</span>
                        </div>
                    </h2>
                </div>
                <div className="cms">
                    <div className="mtop30" />
                    <p>
                        You can invest in Mutual Funds online as well as offline through your
                        Broker or by directly approaching the Asset Management Company (AMC). To
                        invest in Mutual Funds, all you need is a PAN Card, an Aadhaar Card, and a
                        Bank Account Proof.
                    </p>
                    <div className="mtop20" />
                    <p>
                        Investors can choose the method that aligns with their preferences,
                        investment goals, and level of involvement. Some of the most popular ways
                        to invest in mutual funds are:
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">Direct Investment</div>
                    <p>
                        Similar to the concept of buying chocolates collectively, you can directly
                        invest in mutual funds by approaching the fund house or asset management
                        company. In this case, you invest directly with the fund without involving
                        intermediaries like brokers or advisors. While direct investments
                        eliminate distribution commission, managing all your investments
                        separately with different AMCs might become a hassle for you.
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">
                        Online Broking Platforms
                    </div>
                    <p>
                        Many investors prefer the convenience of online platforms, much like
                        online shopping. You can choose to invest in mutual funds through various
                        online portals and mobile apps, which provide a user-friendly interface
                        and access to a wide range of funds. Online Broking platforms give you an
                        added advantage of expert research to select the right funds as per your
                        investment goals and risk profile along with the ease of managing all your
                        investments in one place
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">Financial Advisors</div>
                    <p>
                        {" "}
                        For those who prefer guidance and expert advice, financial advisors act as
                        consultants who help you select the right mutual funds based on your
                        financial goals and risk tolerance. They guide you through the investment
                        process. Many brokers like Motilal Oswal provide the service of financial
                        advisory for free and also offer personal financial planning sessions to
                        align your investments with your goals.
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">Robo-Advisors</div>
                    <p>
                        {" "}
                        Robo-advisors are automated investment platforms that use algorithms to
                        recommend and manage a portfolio of mutual funds based on your risk
                        profile and investment objectives. It's a technology-driven way to invest
                        with minimal human intervention. While robo advisory platforms remove
                        human bias and errors from investing, they might lack a human touch and
                        might not be right for every investor.
                    </p>
                    <div className="mtop20" />
                    <div className="mtop20" />
                    <p>
                        Based on the frequency of investments you choose, Mutual Fund Investments
                        can be classified into two categories - Systematic Investment Plans (SIP)
                        and{" "}
                        <a
                            href="https://www.motilaloswal.com/calculators/lumpsum-calculator"
                            className="seohreflink"
                            target="_blank"
                        >
                            Lumpsum
                        </a>
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">
                        1) Systematic Investment Plans (SIPs)
                    </div>
                    <p>
                        {" "}
                        A Systematic Investment Plan (SIP) is a practice of investing where you
                        regularly contribute a fixed amount every month to buy mutual fund units.
                        This disciplined approach enables investors to benefit from rupee cost
                        averaging and the power of compounding, as their investment buys more
                        units when prices are low and fewer units when prices are high. SIPs make
                        it easier for individuals to enter the stock market and create a habit of
                        consistent investing over time.
                    </p>
                    <div className="mtop20" />
                    <div className="lightblack font-semi-bold f16">2) Lumpsum Investments</div>
                    <p>
                        A lumpsum mutual fund investment refers to a one-time, single large
                        investment made by an individual into a mutual fund. Unlike SIP
                        (Systematic Investment Plan), where money is invested periodically, a lump
                        sum investment involves investing a substantial amount of capital into the
                        fund at a single go. This approach is often used by investors who have a
                        significant sum of money available for investment at a particular time,
                        and it can be influenced by market conditions or personal financial
                        situations.
                    </p>
                    <div className="mtop20" />
                </div>
            </section>

        </>

    );
}

export default MFDSection13;

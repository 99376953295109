import React from 'react'
import Modal from '../../molecules/modal'
import ProceedButton from '../../molecules/proceedButton';
import './index.css';
import { FaTimes } from 'react-icons/fa'

const partnerDetailsPopup = ({ open, closeModal, baDetails, onEdit }) => {

    console.log("BA details in popup=>", baDetails);

    let contactNo = baDetails.MOBILENO;
    let firmname = baDetails.FIRMNAME;
    let firmcode = baDetails.BRANCHCODE;
    let partnercode = baDetails.PARTNERCODE;
    let message = baDetails.MESSAGE;

    return (
        <Modal
            open={open}
            onClose={closeModal}
            closeOnDocumentClick
            lockScroll
            modal
            className={'errorPopup-content'}
        >
            <div className="popup_wrapper">
                {/* <i class="fa fa-times modal-close-icon" onClick={closeModal}></i> */}
                <FaTimes className="modal-close-icon" onClick={closeModal} />
                <div className="popup_title">
                    Partner Code Details
                        </div>
                <div className="horizantal-breack-line">
                    <div className="horizantal-breack-line-sub">
                    </div>
                </div>
                <div className="firm_details">
                    <div className="poupu_middle_text">Firm Name: {firmname}</div>
                    <div className="poupu_middle_text">Firm Code: {firmcode}</div>
                    <div className="poupu_middle_text">Partner1200 Code: {partnercode}</div>
                </div>
                <div className="popup_content">
                    <div className="poupup_desc">
                        {message}
                    </div>
                </div>
                <div className="popup-btn">
                    <ProceedButton
                        handleProceed={closeModal}
                        label={'OK'}
                    />
                </div>
                <div className="popup_link clickableLinkLabel" >
                    <span onClick={onEdit} className="edit-pencil">Edit Partner Code </span>
                </div>
            </div>

        </Modal>

    )
}
export default partnerDetailsPopup;
import React, { Component } from 'react';
import downarrow from '../../../assets/images/regscreen/down_arrow.svg'
import $ from "jquery";
import { isMobile } from 'react-device-detect';
import image1 from '../../../assets/images/regscreen/Talk1.png'
import image2 from '../../../assets/images/regscreen/Talk2.png'

class RegSection8 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  phoneNumber = "02240548383"; // Replace with the phone number you want


  handleButtonClick = () => {
    if (isMobile) {
      window.location.href = `tel:${this.phoneNumber}`;
    }
  };

  componentDidMount = () => {
    $(document).ready(function () {
      $(".accordion-head").click(function () {
        // Toggle the current section
        $('.accordion-head').removeClass('active');
        $(this).addClass("active").next(".accordion-content").slideToggle();

        // Toggle the rotate class on the image
        $(this).find("img").toggleClass("rotate");

        // Hide other sections and remove the rotate class from their images
        $(".accordion-content").not($(this).next()).slideUp();
        $(".accordion-head img").not($(this).find("img")).removeClass("rotate");
      });
    });
    $('.faqblockcom .whitebx').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $('.formbox').offset().top
      }, 1000);
      setTimeout(function () {
        $('.formbox .form-control.focus-field').focus();
      }, 1000);
    });
  }

  render() {
    return (<>
      <section className="sectionpad ptop0">
        <h2 className="f32 mobf24 font-semi-bold mobf20">Take your next step</h2>
        <div className="mtop40" />
        <div className="faqblockcom newsec ">
          <div className="dflex spacebetween">
            <div className="d48" style={{ cursor: "pointer" }}>
              <div className={isMobile ? "whitebx1" : "whitebx"} onClick={this.handleButtonClick}>
                <div className="stepbx">
                  <picture>
                    <source
                      src={image1}
                      type="image/webp"
                    />
                    <img
                      src={image1}
                      alt=""
                    />
                  </picture>
                </div>
                <div className="mtop10 faq-min-height">
                  <span className="f20 font-semi-bold mobf14">
                    Talk to our investment advisor for stock recommendations <br />
                  </span>
                </div>
                <div className="text-right mtop20">
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_4243_171381"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={28}
                      height={28}
                    >
                      <rect width={28} height={28} fill="#C4C4C4" />
                    </mask>
                    <g mask="url(#mask0_4243_171381)">
                      <path
                        d="M16.3332 21L14.6998 19.3083L18.8415 15.1667H4.6665V12.8333H18.8415L14.6998 8.69167L16.3332 7L23.3332 14L16.3332 21Z"
                        fill="#5B6471"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="d48" style={{ cursor: "pointer" }}>
              <div className="whitebx">
                <div className="stepbx">
                  <picture>
                    <source
                      src={image2}
                      type="image/webp"
                    />
                    <img
                      src={image2}
                      alt=""
                    />
                  </picture>
                </div>
                <div className="mtop10">
                  <span className="f20 font-semi-bold mobf14">
                    Get expert assistance for portfolio management
                  </span>
                </div>
                <div className="text-right mtop20">
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_4243_171381"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={28}
                      height={28}
                    >
                      <rect width={28} height={28} fill="#C4C4C4" />
                    </mask>
                    <g mask="url(#mask0_4243_171381)">
                      <path
                        d="M16.3332 21L14.6998 19.3083L18.8415 15.1667H4.6665V12.8333H18.8415L14.6998 8.69167L16.3332 7L23.3332 14L16.3332 21Z"
                        fill="#5B6471"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sectionpad ptop0">
      <h2 class="f32 mobf24 font-semi-bold mobf20">FAQs On Demat Account</h2>
        <div id="accordion">
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
            <h3> What is a Demat Account and what are the types of Demat Account?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                A Demat Account is an account that holds financial securities in electronic form. In India,
                Demat Accounts are maintained by two depository organisations, NSDL (National Securities
                Depository Limited) and CDSL (Central Depository Services Limited).
              </p>
              <div className="mtop10">
                There are 3 types of Demat Account:
              </div>
              <div className="mtop10">
                <ul className="ul1 pl0 calculator-buulet">
                  <li>
                    <strong> Regular Demat Account:</strong> For the investors/traders who reside within India.
                  </li>
                  <li>
                    <strong> Repatriable Demat Account: </strong> For the NRIs. Funds can be transferred abroad. It
                    requires an associated NRE bank account.
                  </li>
                  <li>
                    <strong> Non-Repatriable Demat Account:</strong> For the NRIs. The fund cannot be transferred
                    abroad. It requires an associated NRO bank account.
                  </li>
                </ul>
              </div>
              <p />
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              {" "}
              <h3>What are the benefits & uses of a Demat Account?</h3> <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                A Demat Account helps keep your equity investments, your <a className='tradAccLink' href="https://www.motilaloswal.com/invest-in-mutual-fund" target="_blank">Mutual fund</a> units, bonds, and other
                financial assets safe and secure in an online electronic environment. With a Demat and trading
                account, you can not only <a className='tradAccLink' href="https://www.motilaloswal.com/invest-in-upcoming-ipo" target="_blank">Invest in upcoming IPOs</a> of companies but also start a mutual fund,
                digital gold and SIP as well.
              </p>
              <div className="mtop10"></div>
              <p>Here’s a quick look at some of the other uses of a Demat Account:</p>
              <div className="mtop10"></div>
              <ul className="ul1 pl0 calculator-buulet">
                <li>
                  Enables easy and quick share transfers.
                </li>
                <li>
                  Highly secure storage with zero risk.
                </li>
                <li>
                  Can be accessed at any time from anywhere through the internet.
                </li>
                <li>
                  Very less paperwork involved.
                </li>
              </ul>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              {" "}
              <h3>Who can open a Demat Account?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                Any individual resident of India, HUF, NRI, proprietary firm, partnership firm or company can
                open a Demat and Trading Account with Motilal Oswal.
              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>What documents are required to open a Demat Account?</h3>{" "}
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                To open a Demat and Trading Account with Motilal Oswal,
                individuals are required to hold the following documents handy:
              </p>
              <div className="mtop10"></div>
              <ul className="ul1 pl0 calculator-buulet">
                <li>
                  PAN Card.
                </li>
                <li>
                  Aadhaar Card (Linked with Mobile Number).
                </li>
                {/* <li>
                  Bank Account Statement or Cancelled Cheque.
                </li> */}
                {/* <li>
                  A photocopy of your PAN card.
                  </li>
                <li>
                  A photocopy of your proof of identity &amp; address, which can be anyone of the following.
                  (Aadhaar Card, Voter’s ID, Passport or Driving license)
                  </li> */}
              </ul>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              {" "}
             <h3> What charges are applicable to open a Demat Account?</h3> <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                There are no charges applicable to open a Demat Account with Motilal Oswal.
                You can open a Demat Account online instantly at 0 cost.
              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>What is the minimum amount required to open a Demat Account?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                There is no minimum amount required to open a Demat Account with Motilal Oswal.
                You can open your Demat and Trading Account with Motilal Oswal for Free.
              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can I open two/multiple Demat Accounts?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                Of course. You can open multiple Demat Accounts under your name as long as the Demat Accounts
                are not with the same Depository Participant (DP). For instance, if you already have a Demat
                account with another DP and wish to open another one with Motilal Oswal, you can do so.
              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>How to open an online/paperless Demat Account?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                To open an online Demat Account with Motilal Oswal,
                you are required to follow the given below steps:</p>
              <div className="mtop10"></div>

              <ul className="ul1 pl0 calculator-buulet">
                <li> Download and install the MO Investor application on your mobile phone.</li>
                <li>Register with your mobile number and verify it with an OTP.</li>
                <li>Register with your email id and verify it with an OTP.</li>
                <li>Add your PAN Card details.</li>
                <li>Add your Bank details.</li>
                <li>Add your Aadhaar Details.</li>
                <li>E-sign through Aadhaar-based OTP verification.</li>
                <li>Complete your Demat Account Activation.</li>

                {/* <li><a className='tradAccLink' href="https://www.motilaloswal.com/" target="_blank">Visit our website.</a></li>
                <li>Click on ‘Open Demat Account’.</li>
                <li>Enter the mobile number and submit the OTP you received.</li>
                <li>Enter the email ID and proceed.</li>
                <li>Complete the EKYC (Upload all necessary documents like PAN Card, Aadhar Card, Bank Details
                    etc.)</li>
                <li>Add personal details.</li>
                <li>After verification perform e-sign in.</li>
                <li>Demat Account activated.</li> */}
              </ul>
              <div className="mtop10"></div>
              <p>Alternatively, you can also log in through web, by clicking on <a className='tradAccLink' href="https://www.motilaloswal.com/open-demat-account/" target="_blank">www.motilaloswal.com/open-demat-account/ </a>and performing the same steps as above.</p>
              <div className="mtop10"></div>
              <p>Your Demat Account credentials will be sent to your registered email id once the account is activated successfully.</p>

            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can a Demat Account be opened in the name of Joint Holders?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                Yes, a Demat Account can be opened in single or joint names. However, a Demat Account can have
                a maximum of three account holders - 1 main holder and 2 joint holders.

              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can an NRI open a Demat Account in India?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                Yes, a Demat Account can be opened in single or joint names. However, a Demat Account can have
                a maximum of three account holders - 1 main holder and 2 joint holders.Of course, It is
                certainly possible to open a Demat Account for NRIs through Motilal Oswal. But to do so, you
                will have to possess either an NRO or NRE bank account and submit a Portfolio Investment Scheme
                letter of approval from the Reserve Bank of India along with your Demat Account application form
                and other supporting documents
              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can I open a Demat Account without opening a Trading Account?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                No. You cannot hold a Demat Account without a Trading Account.
                A Demat Account is only an account to hold your shares. If you intend on
                selling the shares, you will need a Trading Account.

              </p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can I link demat and Trading Account?{" "}</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p className="seoptag">
                Yes. If you intend to sell the shares that you hold,you will need to link your Demat Account with your Trading account.</p>


            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
             <h3>What is a joint Demat Account?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>Just like a Joint Bank Account, a <a class='tradAccLink'
                href="https://www.motilaloswal.com/blog-details/Joint-Demat-Account-Opening-Details-And-Benefits/20478" target="_blank">Joint Demat Account</a> is a shared Demat Account in between two or three individuals. Only one Demat Account holder is registered as the primary account holder, while the other account holders
                become secondary account holders.</p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
             <h3> How long does it take to open a Demat Account?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>It takes only 15 minutes to open a Free* Demat Account with Motilal Oswal.</p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Is Demat Account compulsory to apply for an IPO in India?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>Yes. If you intend on applying for an IPO in India, it is mandatory to hold a Demat Account. However, the choice for a Trading Account is optional.</p>

            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Can I withdraw money from a Demat Account?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>Yes. You can add and withdraw funds from your Demat Account anytime.</p>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>How to check Demat Account balance?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>To check your funds on <a className='tradAccLink' href="https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en_IN&gl=US" target="_blank">MO INVESTOR</a>, follow the below given steps</p>
              <ul className="ul1 pl0 calculator-buulet">
                <li>Login to the app</li>
                <li>Go to ‘Menu’</li>
                <li>Select ‘Fund Transfer’</li>
                <li>The screen will display ‘Available Margin’ which will be your Demat Account Balance</li>
              </ul>
            </div>
          </div>
          <div className="accordion-head mtop20">
            <div className="dflex spacebetween">
              <h3>Do I require a Demat Account for SIP?</h3>
              <img src={downarrow} alt="" />
            </div>
          </div>
          <div className="accordion-content">
            <div>
              <p>No, it is not mandatory to hold a Demat Account to invest in SIP Plans. However, if you wish to start Stock SIP, you will need to have a Demat Account.</p>
            </div>
          </div>
        </div>
      </section>
    </>
    );
  }
}

export default RegSection8;
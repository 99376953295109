import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'

class RegSection11 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="whatisproduct-sec">
          <div className="cms">
            <h2>
              <div className="text-center line1">
                <span className="lightgrey f32 font-semi-bold mobf24">What documents are required to</span>
              </div>
              <div className="text-center line1">
                <span className="f32 font-semi-bold mobf24">open a Demat Account?
                </span>
              </div>
            </h2>
            <div className="mtop30" />
            <p>
              To open a Demat Account, an individual is required to carry the following documents
              <ul>
                <li>- PAN Card</li>
                <li>- Aadhaar Card</li>
                {/* <li>- Bank Account Statement.</li> */}
              </ul>
            </p>
            <div className="mtop20" />
            <p>
              In case, where an individual wants to trade in{" "}
              <a
                href="https://www.motilaloswal.com/invest-in-derivatives"
                className="seohreflink"
                target="_blank"
              >
                Futures and Options
              </a>{" "}
              and other assets like Commodities and Currencies,
              s/he is required to permit confirmation to fetch his/her bank account details
              through account aggregator -
              which automatically fetches 6 months statement from the mentioned account number and bank.</p>
          </div>
        </div>
      </section>
    );
  }
}

export default RegSection11;
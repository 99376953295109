import React, { Component } from 'react'

class RegInfo2 extends Component {
    render() {
        return (
            <>
                <div class="mtop20"></div>

                <div class="text-center">
                    <div class="conbox">
                        <h2>
                            BENEFITS TO OPEN DEMAT ACCOUNT IN MOTILAL OSWAL
                        </h2>
                    </div>
                </div>
                <div class="mtop20"></div>

                <p class="justify colorp">By opening an online DEMAT Account with Motilal Oswal, you get access to a wide range of products and investment solutions in the stock market.The MO Investor app, designed and developed exclusively for investors, helps you to get your hands on equity investments, investments in mutual funds, and other products such as expert designed portfolio services, robotic investment solutions and collated baskets of stocks handpicked by top financial experts. With MO Investor app you can invest seamlessly in the stock market in three simple steps - search your stock, enter the quantity you want to invest in and execute the orders. You can also assign a target price to your favourite stocks.
                </p>
                <div class="mtop20"></div>
                <p class="justify colorp">On the other hand, Motilal Oswal has also developed an exclusive app for stock market trading i.e the <a href="https://play.google.com/store/apps/details?id=mosl.powerapp.com" target="_blank" class="hyperlinkcol">MO Trader app</a> . With this app, you can place easy orders for intraday trading and FnO options. You can also use expert crafted trading tools to ace the trading practice. The app is specifically designed to help traders execute orders at real-time to maximise their returns on stock investments.
                </p>
                <p class="justify">So, if you are looking for an answer to the question - ‘How to open a demat account?’, then Motilal Oswal is your one-stop shop for all answers regarding your finances.
                </p>

            </>
        )
    }
}
export default RegInfo2;
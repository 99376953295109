export const SET_USER = 'SET_USER';

export const INIT_SET_USER = 'INIT_SET_USER'
export const INIT_SET_USER_RESPONSE = 'INIT_SET_USER_RESPONSE'
export const INIT_SET_USER_FAIL = 'INIT_SET_USER_RESPONSE_FAIL'

export const INIT_SET_USER_SUPERAPP = "INIT_SET_USER_SUPERAPP"

export const VERIFY_OTP_EMAIL = 'VERIFY_OTP_EMAIL';
export const GET_SCHEME_DETAILS = 'GET_SCHEME_DETAILS';
export const RESEND_OTP = 'RESEND_OTP';

export const GET_AURM_DETAILS = 'GET_AURM_DETAILS';
export const SAVE_AURM_DETAILS = 'SAVE_AURM_DETAILS';
export const GET_NOMINEE_RELATIONSHIP_DROPDOWN = "GET_NOMINEE_RELATIONSHIP_DROPDOWN";

export const SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN = "SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN";

export const SET_NOMINEE_GENDER_INFO_DROPDOWN = "SET_NOMINEE_GENDER_INFO_DROPDOWN";

export const GET_STATE_CITY_FROM_PINCODE = "GET_STATE_CITY_FROM_PINCODE";

export const SET_STATE_CITY_FROM_PINCODE = "SET_STATE_CITY_FROM_PINCODE";

export const BIND_STATE = "BIND_STATE";

export const SET_BIND_STATE = "SET_BIND_STATE"

export const BIND_CITY = "BIND_CITY"

export const SET_BIND_CITY = "SET_BIND_CITY"

export const UPDATE_NOMINEE_INFO = "UPDATE_NOMINEE_INFO"

export const SET_NOMINEEDETAILS = "SET_NOMINEEDETAILS"

export const PRIMARY_APPLICANT_ADDRESS = "PRIMARY_APPLICANT_ADDRESS"

export const SET_NOMINEEDETAILS4 = "SET_NOMINEEDETAILS4"

export const GET_NOMINEEDETAILS_BY_ID = "GET_NOMINEEDETAILS_BY_ID"
export const endpoints = {
    // baseUrl: process.env.REACT_APP_BASE_URL_JET,
    // baseUrl: process.env.REACT_APP_BASE_URL_SUPERJET,
    baseUrl: process.env.REACT_APP_BASE_URL_SUPERJET_WEB,
    app: {
        init: '/app/init',
        initSuperApp: '/app/initSuperApp'
        // init: '/app/initDirect'
    },
    user: {
        getUser: '/users'
    },
    registration: {
        getCities: '/registration/bindcity',
        openAccount: '/registration/oao',
        validatePartnerCode: '/registration/partnerValidation',
        resumeApplication: '/registration/resumeApplication',
        checkMobileNumberStatus: '/registration/checkMobileNumberStatus',
        getAuRmDetails: '/registration/getAuRmDetails',
        getBaDiyDetails: '/registration/getBaDiyDetails',
        getSchemeDetailsSTPMobile: '/registration/getSchemeDetailsSTPMobile',
        getTruecallerProfile: '/registration/getTruecallerProfile',
        validateReferralCode: '/registration/validateReferralCode',
        loadLeadTypes: '/registration/loadLeadTypes',
        generateRedirectionUrl: '/registration/generateRedirectionUrl',
        subBroker: '/registration/subBroker',
        exportToExcel: '/registration/exportToExcel',
        ekycApproveReject: '/registration/ekycApproveReject',
        getToken: '/proof/getJwtTokenId',
        getAuthForBlogs: '/registration/getAuthForBlogs',
        encryptionForBlogs: '/registration/encryptionForBlogs',
        getBlogsApi: '/registration/getBlogsApi',
    },
    verification: {
        otpEmail: '/verification/otpEmail',
        schemeDetails: '/verification/schemeDetails',
        getUserPaymentDetails: '/verification/getUserPaymentDetails',
        resendOtp: '/verification/resendOtp',
        onSchemeChange: '/verification/onSchemeChange',
        saveSchemeDetails: '/verification/saveSchemeDetails',
        insertTraderCode: '/verification/insertTraderCode',
        getTraderCode: '/verification/getTraderCode',
        saveSchemeDetailsDirect: '/verification/saveSchemeDetailsDirect',
    },
    esign: {
        getEsignDetails: '/esign/getEsignDetails',
        getEsignDetailsKra: '/esign/getEsignDetailsKra',
        getEsignDetailsDdpi: '/esign/getEsignDetailsDdpi',
        getAuthMode: '/esign/getAuthMode',
        getAuthModeKra: '/esign/getAuthModeKra',
        getAuthModeDdpi: '/esign/getAuthModeDdpi',
        performDigioEsignRequest: '/esign/performDigioEsignRequest',
        performDigioEsignResponse: '/esign/performDigioEsignResponse',
        performDigioEsignRequestKra: '/esign/performDigioEsignRequestKra',
        performDigioEsignResponseKra: '/esign/performDigioEsignResponseKra',
        getAvailableClientCode: '/esign/getAvailableClientCode',
        generateClientCode: '/esign/generateClientCode',
        pushDataInDrishti: '/esign/pushDataInDrishti',
        downloadPdfForm: '/esign/downloadPdfForm',
        pdfFileDownload: '/esign/pdfFileDownload',
        requestBiometric: '/esign/requestBiometric',
        emudhra: '/esign/emudhra',
        getEmudhra: '/esign/getEmudhra',
        emudhraKra: '/esign/emudhraKra',
        getEmudhraKra: '/esign/getEmudhraKra',
        emudhraDdpi: '/esign/emudhraDdpi',
        getEmudhraDdpi: '/esign/getEmudhraDdpi',
        performEmudhraEsignRequest: '/esign/performEmudhraEsignRequest',
        performEmudhraEsignRequestKra: '/esign/performEmudhraEsignRequestKra',
        performEmudhraEsignRequestDdpi: '/esign/performEmudhraEsignRequestDdpi',
        generateClientCodeMF: '/esign/generateClientCodeMF',
    },
    proof: {
        uploadProof: '/proof/upload',
        uploadProofUsEquity: '/proof/uploadProofUsEquity',
        getProofList: '/proof/getUploadedProof',
        deleteProof: '/proof/deleteProof',
        deleteProofDetailsUsEquity: '/proof/deleteProofDetailsUsEquity',
        viewProof: '/proof/viewUploadedImage',
        viewUploadedImageUsEquity: '/proof/viewUploadedImageUsEquity',
        getProofVerificationStatus: '/proof/getProofVerificationStatus',
        submitProof: '/proof/submitProofUpload',
        submitOCRProof: '/proof/submitOCRProofUpload',
        captureAdharXML: '/proof/captureAdharXML',
        fetchAdharXML: '/proof/fetchAdharXML',
        checkUncheckMobileAdharLink: '/proof/checkUncheckMobileAdharLink',
        gotoVerificationPageApi: '/proof/gotoVerificationPageApi',
        onSchemeChange: '/verification/onSchemeChange',
        saveSchemeDetails: '/verification/saveSchemeDetails',
        validatePAN: '/proof/validatePAN',
        validateAadhaarApi: '/proof/validateAadhaarApi',
        validateAccountAndIfsc: '/proof/validateAccountAndIfsc',
        validateEmailOTP: '/proof/validateEmailOTP',
        resendEmailOTP: '/proof/resendEmailOTP',
        pdfUpload: '/proof/uploadMultiPart',
        addRemovePOA: '/proof/addRemovePOA',
        uploadMultiPartMobile: '/proof/uploadMultiPartMobile',
        getMICR: '/proof/getMICR',
        deleteNominee: '/nominee/deleteNomineeDetailsById',
        validateNomineePAN: '/proof/validateNomineePAN',
        captureLivePhoto: '/proof/captureLivePhoto',
        saveLivePhoto: '/proof/saveLivePhoto',
        digiLockerRequest: '/proof/digiLockerRequest',
        fairXpayKycRequestRedirectionSuperApp: '/proof/fairXpayKycRequestRedirectionSuperApp',
        digiLockerResponse: '/proof/digiLockerResponse',
        shareLink: '/proof/shareLink',
        validatePanDob: '/proof/validatePanDob',
        verifyPanOnName: '/proof/verifyPanOnName',
        getPanDetails: '/proof/getPanDetails',
        getPANBankDetails: '/proof/getPANBankDetails',
        getCmrCopyDetails: '/proof/getCmrCopyDetails',
        getBankList: '/proof/getBankList',
        getBankListByIfsc: '/proof/getBankListByIfsc',
        getMicrBasedOnBankBranch: '/proof/getMicrBasedOnBankBranch',
        getBankDetails: '/proof/getBankDetails',
        getOneMoneyBankDetails: '/proof/getOneMoneyBankDetails',
        updateMobileNumber: '/proof/updateMobileNumber',
        OneMoneyRedirection: '/proof/OneMoneyRedirection',
        deleteBankDetails: '/proof/deleteBankDetails',
        getAadharDetails: '/proof/getAadhaarDetails',
        getClientPhoto: '/proof/getClientPhoto',
        updateProofStage: '/proof/updateProofStage',
        insertUpdateStageUsEquity: '/proof/insertUpdateStageUsEquity',
        getClientSignature: '/proof/getClientSignature',
        insertNriFlag: '/proof/insertNriFlag',
        validateDpIdApi: '/proof/validateDpIdApi',
        // moveBackwordStatus: '/proof/moveBackwordStatus',
        moveBackwordStatus: '/proof/moveBackwordStatus_direct',
        getAadhaarConsent: '/proof/getAadhaarConsent',
        hypervergeLivePhotoRequest: '/proof/hypervergeLivePhotoRequest',
        ainxtLivePhotoRequest: '/proof/ainxtLivePhotoRequest',
        ainxtLivePhotoResponse: '/proof/ainxtLivePhotoResponse',
        hypervergeLivePhotoResponse: '/proof/hypervergeLivePhotoResponse',
        insertUpdateSmallCasesConsent: '/proof/insertUpdateSmallCasesConsent',
        livePhotoLocationDetails: '/proof/livePhotoLocationDetails',
        getProofDetails: '/proof/getProofDetails',
        getProofDetailsUsEquity: '/proof/getProofDetailsUsEquity',
        getAadhaarDetailSuperApp: '/proof/getAadhaarDetailSuperApp',
        aadhaarLinksUsEquity: '/proof/aadhaarLinksUsEquity',
        ainxtDigiLockerRequest: '/proof/ainxtDigiLockerRequest',
        ainxtDigilockerResponse: '/proof/ainxtDigilockerResponse',
        validatePansign: '/proof/validatePansign',
        updateOneMoneyStatus: '/proof/updateOneMoneyStatus',
        updateMFDFlag: '/proof/updateMFDFlag',
        generateRevPennyToken: '/proof/generateRevPennyToken',
        saveRevPennyDetails: '/proof/saveRevPennyDetails',
        validateBankProofPDF: '/proof/validateBankProofPDF',
        validateBankProofImage: '/proof/validateBankProofImage',
        validateBankProofPdfBase64: '/proof/validateBankProofPdfBase64',
        uploadMultiPartBase64: '/proof/uploadMultiPartBase64',
    },
    payment: {
        init: '/payment/init',
        status: '/payment/status',
        sendChequeApproval: '/payment/sendChequeApproval',
        requestChequeStatus: '/payment/requestChequeStatus',
        skipOrChequePayment: '/payment/skipOrCheque',
        getFundTransferDetails: '/payment/getFundTransferDetails',
        getSchemeDetailsDirect: '/payment/getSchemeDetailsDirect',
        insertUpdateSelectedSegment: '/payment/insertUpdateSelectedSegment'
    },
    offer: {
        getOffersList: '/offer/getOffersList',
        registerWalletOffer: '/offer/registerWalletOffer',
        initiateRedeem: '/offer/initiateRedeem',
        initiateOffer: '/offer/initiateOffer',
        initiateSkip: '/offer/initiateSkip',
        offerpaymentStatus: '/offer/offerpaymentStatus',
        downloadesignPdfForm: '/offer/downloadesignPdfForm',
        getOfferDetails: '/offer/getOfferDetails',
        getValuePackDetails: '/offer/getValuePackDetails',
        initiateValuepack: '/offer/initiateValuepack',
        updateValuePackDetails: '/offer/updateValuePackDetails'
    },
    congratulation: {
        downloadPdfForm: '/congratulation/downloadPdfForm',
        addRefral: '/referral/add',
        validateDirectLogin: '/congratulation/validateDirectLogin'
    },
    personalInfo: {
        getPersonalInfo: '/personalDeatils/get',
        updateDetails: '/personalDeatils/update',
        updatePersonalOtherDetails: '/personalDeatils/updatePersonalOtherDetails',
        getIncomeProofDetails: '/proof/getIncomeProofDetails',
        getPersonalDetailsUsEquity: '/personalDeatils/getPersonalDetailsUsEquity',
        updatePersonalDetailsUsEquity: '/personalDeatils/updatePersonalDetailsUsEquity',

    },
    logout: {
        logout: '/logout'
    },
    helpUs: {
        getWorkDetails: '/helpUs/getWorkDetails',
        updateWorkDetails: '/helpUs/updateWorkDetails'
    },
    nominee: {
        getNomineeRelationshipDropdown: '/nominee/getNomineeRelationshipDropdown',
        getStateCityFromPincode: '/nominee/getStateCityFromPincode',
        bindState: '/nominee/bindState',
        bindCity: '/nominee/bindCity',
        updateNomineeInfo: '/nominee/insertUpdateNomineeDetailsById',
        getNomineeDetailsById: '/nominee/getNomineeDetailsById'
    },
    feedback: {
        getFeedback: '/feedback/getFeedback',
        updateFeedbackDetails: '/feedback/updateFeedbackDetails',
        getVideoLinkDetails: '/feedback/getVideoLinkDetails'
    }
}
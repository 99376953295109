import React, { Component, useState, useEffect } from 'react';
import List from '../../../assets/images/list.png';
import List1 from '../../../assets/images/list1.png';
import List2 from '../../../assets/images/list2.png';
import List3 from '../../../assets/images/list3.png';
import Image from '../../atom/image';
import bannerImg from '../../../assets/images/Mobile_Banner.png';
import { isMobile } from 'react-device-detect';
// import offerImg from '../../../assets/images/OAO_Banner1.png';
// import offerImg1 from '../../../assets/images/MTF_Banner_Image.png';
import offerImg1 from '../../../assets/images/crmBanner.jpg';
import { userType } from '../../../helper/constant';
import { connect } from 'react-redux';
import offerImg from '../../../assets/images/OAO_Banner2.png';
import franchiseBanner from '../../../assets/images/ppBanner.png';
import classNames from 'classnames';
import referralBanner from '../../../assets/images/referralBanner.jpg'

const RegSteps = (props) => {

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowBanner(true)
        }, 5000);
    }, []);

    return (
        <div id="newsteps">
            {/* {isMobile && */}
            <div className={classNames('mob', { 'banner-img': props.user.applicationType && props.user.applicationType.toUpperCase() == userType.direct })}>
                {(props.user.applicationType && props.user.applicationType !== "" && props.user.applicationType == userType.crm || props.user.applicationType == "crm") ?
                    <img src={offerImg1} alt="" className={classNames('img-width', { 'showBannerImg': showBanner }, { 'd-none': !showBanner })} width="100%" height="auto" />
                    : (props.user.applicationType && props.user.applicationType !== "" && props.user.applicationType == userType.pp || props.user.applicationType == "pp") ?
                        <img src={franchiseBanner} alt="" className={classNames('img-width', { 'showBannerImg': showBanner }, { 'd-none': !showBanner })} width="100%" height="auto" />
                        :
                        props.user.showReferralBanner ?
                            <img src={referralBanner} alt="" className={classNames('img-width', { 'showBannerImg': showBanner }, { 'd-none': !showBanner })} width="100%" height="auto" />
                            :
                            <img src={offerImg} alt="" className={classNames('img-width', { 'showBannerImg': showBanner }, { 'd-none': !showBanner })} width="100%" height="auto" />
                }
            </div>
            {/* } */}
            <div className="container">
                <div className="mtop20"></div>
                <div className="text-center">
                    <h2 className="conbox">
                        STEPS TO OPEN A DEMAT ACCOUNT WITH MOTILAL OSWAL
                    </h2>
                </div>
                <div id="stepsreg">
                    <div className="timeline-step">
                        <div className="timeline-content">
                            <div className="inner-circle"></div>
                        </div>
                        <p className="mtop10 stp">Step 1</p>
                        <div className="innersteps">
                            {/* <img src="images/list.png" alt=""/> */}
                            {/* <Image src={List} alt={''} width="" height="" /> */}
                            <div class='sprite list'></div>
                            <p>Fill the lead form from Motilal Oswal to Open Demat Account</p>
                        </div>
                    </div>

                    <div className="timeline-step">
                        <div className="timeline-content">
                            <div className="inner-circle"></div>
                        </div>
                        <p className="mtop10 stp">Step 2</p>
                        <div className="innersteps">
                            {/* <Image src={List1} alt={''} width="" height="" /> */}
                            <div class='sprite list1'></div>
                            <p>Enter OTP received on the registered Mobile Number</p>
                        </div>
                    </div>

                    <div className="timeline-step">
                        <div className="timeline-content">
                            <div className="inner-circle"></div>
                        </div>
                        <p className="mtop10 stp">Step 3</p>
                        <div className="innersteps">
                            {/* <Image src={List2} alt={''} width="" height="" /> */}
                            <div class='sprite list2'></div>
                            <p>Fill Date of Birth, PAN details, Email Address and Bank Account Details.</p>
                        </div>
                    </div>

                    <div className="timeline-step">
                        <div className="timeline-content">
                            <div className="inner-circle"></div>
                        </div>
                        <p className="mtop10 stp">Step 4</p>
                        <div className="innersteps">
                            {/* <Image src={List3} alt={''} width="" height="" /> */}
                            <div class='sprite list3'></div>
                            <p>Details of your demat account are sent to you on your registered mail address</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(RegSteps);
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";

export const UPDATE_PERSONAL_DETAIL = "UPDATE_PERSONAL_DETAIL";
export const SET_PERSONAL_INFO_DROPDOWN = "SET_PERSONAL_INFO_DROPDOWN";
export const UPDATE_PERSONAL_OTHER_DETAIL = "UPDATE_PERSONAL_OTHER_DETAIL";

//GETINCOMEPROOF
export const GET_INCOMEPROOF_DETAILS = "GET_INCOMEPROOF_DETAILS";
export const UPDATE_INCOMEPROOF_DETAIL = "UPDATE_INCOMEPROOF_DETAIL";
export const SET_INCOMEPROOF_INFO_DROPDOWN = "SET_INCOMEPROOF_INFO_DROPDOWN";

export const GET_NOMINEE_GENDER = "GET_NOMINEE_GENDER";
export const GET_NOMINEE_RELATIONSHIP = "GET_NOMINEE_RELATIONSHIP";

export const GET_NOMINEE_TYPE = "GET_NOMINEE_TYPE";

// GETPERSONALDETAILS Us Equity

export const GET_PERSONAL_DETAILS_USEQUITY = "GET_PERSONAL_DETAILS_USEQUITY";
export const UPDATE_PERSONAL_DETAILS_USEQUITY = "UPDATE_PERSONAL_DETAILS_USEQUITY";
export const UPDATE_PERSONAL_INFO_USEQUITY = "UPDATE_PERSONAL_INFO_USEQUITY";
export const GET_PERSONAL_INFO_USEQUITY = "GET_PERSONAL_INFO_USEQUITY";
export const SET_PERSONAL_INFO_USEQUITY_DROPDOWN = "SET_PERSONAL_INFO_USEQUITY_DROPDOWN";

import React, { Component } from 'react'

class RegReadyToInvest extends Component {
    render() {
        return (
            <section className="invest-with-wrapper d-none d-lg-block">
                <div className="custom-container">
                    <h2 className="section-title">Ready to invest with us?</h2>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <div className="form-wrap">
                        <ul className="clearfix">
                            <li>
                                <input type="text"  className="form-control" placeholder="Enter Full Name" />
                            </li>
                            <li>
                                <input type="number" className="form-control" placeholder="Enter Mobile Number" />
                                <span className="country-code">+91 <span className="verticla-bar">|</span></span>
                            </li>
                            <li>
                                <div className="btn-wrap ">
                                    <button className="btn btn-primary"><span className="overlay"></span>Open Account <span
                                        className="whitearrow"></span></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default RegReadyToInvest;
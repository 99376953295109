import Api from '.';
import { endpoints } from './endpoints';

export default class SchemeApi extends Api {

    paymentInit(data) {//terms and consition data
        let url = this.buildUrl(endpoints.payment.init)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    paymentStatus(data) {//terms and consition data
        let url = this.buildUrl(endpoints.payment.status)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    sendChequeApproval(data) {//terms and consition data
        let url = this.buildUrl(endpoints.payment.sendChequeApproval)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    requestChequeStatus(data) {//terms and consition data
        let url = this.buildUrl(endpoints.payment.requestChequeStatus)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    skipOrChequePayment(data) {//terms and consition data
        let url = this.buildUrl(endpoints.payment.skipOrChequePayment)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getFundTransferDetails(data){
        let url = this.buildUrl(endpoints.payment.getFundTransferDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    getSchemeDetailsDirect(data){
        let url = this.buildUrl(endpoints.payment.getSchemeDetailsDirect)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
    validateDirectLogin(data){
        let url = this.buildUrl(endpoints.congratulation.validateDirectLogin)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    insertUpdateSelectedSegment(data) {
        let url = this.buildUrl(endpoints.payment.insertUpdateSelectedSegment)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
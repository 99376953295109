/** Router component */
import React, { Component } from 'react';
import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom'
import { hot } from 'react-hot-loader/root';
// import "../css/global.css";
import Registration from './pages/registration';
import RegistrationStatic from './pages/registration.1';
import RegForm from './organism/regForm';
import RegHeader from './organism/regHeader';
import { MobileView } from 'react-device-detect';
import Loader from '../components/organism/loader'
import MobileRegistration from './pages/mobileRegistration';
import GlobalSnackbar from '../components/atom/globalSnackbar'
import TagManager from "react-gtm-module";
// import './bootstrap-grid.min.css';
import { isMobile } from 'react-device-detect';

const tagManagerArgs = {
    gtmId: "GTM-57GKC3W"
};

const NoMatch = loadable(() => import('./pages/nomatch'));
const DAD = loadable(() => import('./pages/dad'));
const Branch = loadable(() => import('./pages/branch'));
// const Alliance = loadable(() => import('./pages/alliance'));
const BA = loadable(() => import('./pages/ba'));
const FIREBASE = loadable(() => import('./pages/firebase'));
const FranchiseeDashboard = loadable(() => import('./pages/franchiseeDashboard'));
const USEQUITY = loadable(() => import('./pages/usEquity'))
const USEQUITYLOGIN = loadable(() => import('./pages/usEquityLogin'))

let GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE

class Router extends Component {

    componentDidMount() {
        this.initSlick();
        // this.initGa(); removed coz google tag manager in use
        // TagManager.initialize(tagManagerArgs);
    }

    //#region hook up slick css files after application load
    initSlick = () => {
        // Reference : https://www.html5rocks.com/en/tutorials/webcomponents/imports/
        // var link = document.createElement('link');
        // link.rel = 'stylesheet';
        // link.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
        // document.head.appendChild(link); // browser requests styles.css


        // var link2 = document.createElement('link');
        // link2.rel = 'stylesheet';
        // link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
        // document.head.appendChild(link2); // browser requests styles.css
    }
    //#endregion

    //#region Init GA
    initGa = () => {

        // if (GA_TRACKING_CODE && 'function' === typeof window.ga) {
        //     if ('object' === typeof document && document.location) {
        //         const location = document.location;
        //         const page = [location.pathname, location.search, location.hash].join('');
        //         window.ga('create', GA_TRACKING_CODE, 'auto');
        //         window.ga('set', 'page', page);
        //         window.ga('set', 'location', page);
        //         window.ga('send', 'pageview');
        //     }
        // }
    }

    //#endregion


    render() {
        return (
            <>
                <Loader />
                <GlobalSnackbar />
                <Switch>
                    <Route exact path="/">
                        <Registration showMFDReg={false} />
                    </Route>

                    <Route path="/mobileOTP">
                        <Registration 
                        showOTP={true}
                        showMFDOtp={false}
                         />
                    </Route>

                    <Route path="/MFD">
                        <Registration showMFDReg={true} />
                    </Route>

                    <Route path="/MFDOtp">
                        <Registration showMFDOtp={true} />
                    </Route>

                    {/* <Route path="/firebaseData">
                        <FIREBASE />
                    </Route> */}

                    <Route path="/franchiseeDashboard">
                        <FranchiseeDashboard />
                    </Route>

                    <Route path="/usEquity">
                        <USEQUITY />
                    </Route>

                    <Route path="/usEquityLogin">
                        <USEQUITYLOGIN />
                    </Route>

                    {/* for mobile view */}
                    <Route exact path="/registration">
                        <MobileRegistration />
                    </Route>

                    <Route path={'/dad'}>
                        <DAD />
                    </Route>

                    <Route path={'/branch'}>
                        <Branch />
                    </Route>

                    {/* <Route path={'/alliance'}>
                        <Alliance />
                    </Route> */}

                    <Route path={'/ba'}>
                        <BA />
                    </Route>

                    {/* {!isMobile && */}
                    <Route>
                        {/* <NoMatch /> */}
                        <RegistrationStatic />
                    </Route>
                    {/* } */}

                </Switch>
            </>
        )
    }
}

export default process.env.NODE_ENV === "development" ? hot(Router) : Router;
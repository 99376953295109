import React from 'react'
import classNames from 'classnames';
import Button from '../../atom/button';

const RegButton = (props) => {
    const { outerContainerClassName, buttonClassName, ...otherProps } = props;
    return (
        <div onClick={props.onClick} className={classNames("text-center", { [outerContainerClassName]: outerContainerClassName })}>
            <a class="cta dark">
                {props.label}
            </a>
        </div>
    )
}
export default RegButton;
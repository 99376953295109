import { produce } from 'immer';
import {
    SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN, SET_STATE_CITY_FROM_PINCODE,
    SET_BIND_STATE, SET_BIND_CITY, SET_NOMINEEDETAILS, PRIMARY_APPLICANT_ADDRESS, SET_NOMINEE_GENDER_INFO_DROPDOWN, SET_NOMINEEDETAILS4
} from '../types/nominee';


const initialState = {
    getNomineeDetails: null,
    getNomineeRelationshipDropDownValues: null,
    stateCityFromPincode: null,
    bindStateList: null,
    bindCityList: null,
    primaryApplicantAddress: null,
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_NOMINEE_RELATIONSHIP_INFO_DROPDOWN:
            state.getNomineeRelationshipDropDownValues = payload;
            return;
        case SET_STATE_CITY_FROM_PINCODE:
            state.stateCityFromPincode = payload;
            return;
        case SET_BIND_STATE:
            state.bindStateList = payload;
            return;
        case SET_BIND_CITY:
            state.bindCityList = payload;
            return;
        case SET_NOMINEEDETAILS:
            state.getNomineeDetails = payload;
            return;
        case PRIMARY_APPLICANT_ADDRESS:
            state.primaryApplicantAddress = payload;
            return;
        case SET_NOMINEE_GENDER_INFO_DROPDOWN:
            state.genderDropDownValues = payload;
            return;
        case SET_NOMINEEDETAILS4:
            state.getNomineeDetails4 = payload;
            return;
        default:
            return state;
    }
})

export default reducer;
import React, { Component } from 'react';
import banner from '../../../assets/images/regscreen/OAO-Page-Banner.png'
import $ from "jquery";

class RegSection4 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount = () => {
        $('.youpay .w100').on('click', function (e) {
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $('.formbox').offset().top
          }, 1000);
          setTimeout(function () {
            $('.formbox .form-control.focus-field').focus();
          }, 1000);
        });
      }

    render() {
        return (
            <section className="ptop0">
                <div className="youpay sectionpad ptop30">
                    <div className="text-center">
                        <h2 className="textlabel">
                            <span className="f32 line1 mobf24 font-regular">
                                Unlock special
                            </span>
                            <div>
                                <span className="f30 semibold mobf24">{" "}offers on the go!</span>
                            </div>
                        </h2>
                    </div>
                    <div className="dflex mtop40 mobwrap mobmtop0">
                        <div className="d33">
                            <div className="priceblock text-center bright">
                                <div>
                                    <span className="f40 mtop10 font-semi-bold">FREE</span>
                                </div>
                                <div className="dflex flex-direction-column aligncenter">
                                    {/* <span className="lightgrey">Options Trading&nbsp;</span>
                                    <span className="lightgrey">A/C Opening Charges</span> */}
                                    <span className="">Account Opening</span> 
                                </div>
                            </div>
                        </div>
                        <div className="d33">
                            <div className="priceblock text-center bright">
                                <div>
                                    <span className="f40 mtop10 font-semi-bold">FREE</span>
                                </div>
                                <div className="dflex flex-direction-column aligncenter">
                                    {/* <span className="lightgrey">AMC &amp; Admin Charges </span>
                                    <span className="lightgrey">&nbsp;for 1st Year</span> */}
                                    <span className="">AMC & Admin for 1st Year</span>
                                </div>

                            </div>
                        </div>
                        <div className="d33">
                            <div className="priceblock text-center">
                                <div>
                                    <span className="f40 mtop10 font-semi-bold">FREE</span>
                                </div>
                                <div className="dflex flex-direction-column aligncenter">
                                    {/* <span className="lightgrey">Charges for Research </span>
                                    <span className="lightgrey">
                                        &nbsp;& Dedicated Personal Advisor
                                    </span> */}
                                    <span className="">Stock Recommendations</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="text-center mtop20">
                        <span className="f16 font-semi-bold">This month’s offer</span>
                    </div>
                    <div className="mtop10" />
                    <div class="">
                    <img className='w100 maximg' src={banner} style={{cursor:'pointer'}}/>
                    </div>
                    
                </div>
            </section>

        );
    }
}

export default RegSection4;
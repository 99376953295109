import { RESPONSE_ERROR_SNACK_OPEN, RESPONSE_ERROR_SNACK_CLOSE, RESPONSE_ERROR_POPUP } from "../types/responseError";

export const setResponseErrorSnack = (payload) => ({
    type: RESPONSE_ERROR_SNACK_OPEN,
    payload
})

export const closeResponseErrorSnack = (payload) => ({
    type: RESPONSE_ERROR_SNACK_CLOSE,
    payload
})

export const setResponseErrorPopup = () => ({
    type: RESPONSE_ERROR_POPUP
})

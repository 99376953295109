import { produce } from 'immer';
import { GET_INCOME_CHOICE_CHIPS } from '../types/income';

const initialState = {
    IncomeProofChoiceChips: null
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_INCOME_CHOICE_CHIPS:
            state.IncomeProofChoiceChips = payload;
            return;
        default:
            return state;
    }
})

export default reducer;
import { produce } from 'immer';
import { SAVE_AURM_DETAILS } from '../types/user';

const initialState = {
    auRm: []
}

const reducer = produce((state = initialState, action) => {
    switch (action.type) {
        case SAVE_AURM_DETAILS:
            state.auRm = action.payload;
            return state;

        default:
            return state
    }
})

export default reducer;
import React, { Component, useState } from 'react';
import equity1 from '../../../assets/images/mfdregscreen/equity1.gif'
import equity2 from '../../../assets/images/mfdregscreen/equity2.gif'
import equity3 from '../../../assets/images/mfdregscreen/equity3.gif'
import equity4 from '../../../assets/images/mfdregscreen/equity4.gif'
import equity5 from '../../../assets/images/mfdregscreen/equity5.gif'
import equity6 from '../../../assets/images/mfdregscreen/equity6.gif'

const MFDSection7 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">
                            Equity and Debt
                        </span>
                        <div>
                            <span className="f30 semibold mobf24">MF Collections</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop10" />
                <div className="dflex wrap spacebetween gap15">
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity1}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Small Cap
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity2}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">Mid Caps</span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity3}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Large Cap
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity4}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Large &amp; Mid Cap
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity5}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Flexi Cap
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20  mtop20">
                        <div className="dflex mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src={equity6}
                                    alt=""

                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Tax Saver
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSection7;

import { REG_CONTACT_US_CLICKED, REG_FAQ_CLICKED } from "../types/analytics";

export const regContactUsClicked = (payload) => ({
    type: REG_CONTACT_US_CLICKED,
    payload
})

export const regFaqClicked = (payload) => ({
    type: REG_FAQ_CLICKED,
    payload
})
import Api from '.';
import { endpoints } from './endpoints';

export default class Logout extends Api {

    logout(data) {
        let url = this.buildUrl(endpoints.logout.logout)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
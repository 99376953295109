import React from 'react';
import IPO from '../../../assets/images/IPO.png';
import MutualFunds from '../../../assets/images/Mutual-Funds.png';
import Equity from '../../../assets/images/Equity.png';
import Commodity from '../../../assets/images/Commodity.png';
import Currency from '../../../assets/images/Currency.png';
import FnO from '../../../assets/images/F&o.png';
import IAP from '../../../assets/images/IAP.png';
import SIP from '../../../assets/images/SIP.png';
import Image from '../../atom/image';

const ShowInvestment = () => {
    return (
        <>
            <div class="mtop40"></div>
            <div class="text-center">
                <h2 class="conbox">
                    MAKE BETTER INVESTMENTS FOR LIFE WITH MOTILAL OSWAL
                </h2>
                <div> <small>It is one demat account with multiple benefits. <br />
                    Investors can invest in a variety of asset classes through Motilal Oswal Demat Account</small>
                </div>
                <div class="mtop40"></div>

            </div>
            <div id="betterinvest">
                <div className="mobileswiper">
                    <div class="fourblock">
                        <div class="blocks">

                            <a href="https://www.motilaloswal.com/invest-in-upcoming-ipo" target="_blank">
                                <div class="borderbox">
                                    {/* <Image src={IPO} alt={''} width="" height="" /> */}
                                    <div class='sprite IPO'></div>
                                    <h3 class="head3">Upcoming IPO
                                </h3>
                                    <p>Apply with Motilal Oswal’s pre-IPO application instantly. Seamless UPI application now available on MO INVESTOR.</p>
                                </div>
                            </a>

                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/mutual-fund-investments" target="_blank">
                                <div class="borderbox">
                                    {/* <Image src={MutualFunds} alt={''} width="" height="" /> */}
                                    <div class='sprite Mutual-Funds'></div>
                                    <h3 class="head3">Mutual Fund
                                </h3>
                                    <p>Planning to buy a house? Or want to take a Europe tour? Turn your dreams into reality with Mutual Funds.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/equity-investments-and-financial-derivatives" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite Equity'></div>
                                    {/* <Image src={Equity} alt={''} width="" height="" /> */}
                                    <h3 class="head3">Equity
                                </h3>
                                    <p>Invest in some of the best companies of the country at a click. Invest in Equities with Motilal Oswal.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/invest-in-commodities-market" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite Commodity'></div>
                                    {/* <Image src={Commodity} alt={''} width="" height="" /> */}
                                    <h3 class="head3">Commodity
                                </h3>
                                    <p>A must-have hedge against inflation because prices of commodities tend to rise during high inflation.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/currency-and-forex-trading" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite Currency'></div>
                                    {/* <Image src={Currency} alt={''} width="" height="" /> */}
                                    <h3 class="head3">Currency
                               </h3>
                                    <p>Bring flexibility in your investment portfolio by trading across multiple currencies 24x7, at ease with expert advice by your side.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/futures-and-options-tradin-online" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite Fo'></div>
                                    {/* <Image src={FnO} alt={''} width="" height="" /> */}
                                    <h3 class="head3">F&O
                                </h3>
                                    <p>Trade at a lightning speed powered by expert minds and AI-fuelled algorithms.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/intelligent-advisory-portfolio" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite IAP'></div>
                                    {/* <Image src={IAP} alt={''} width="" height="" /> */}
                                    <h3 class="head3">IAP
                                </h3>
                                    <p>Expert curated, AI-powered portfolio of stocks for better returns.</p>
                                </div>
                            </a>
                        </div>
                        <div class="blocks">
                            <a href="https://www.motilaloswal.com/invest-in-sip" target="_blank">
                                <div class="borderbox">
                                    <div class='sprite SIP'></div>
                                    {/* <Image src={SIP} alt={''} width="" height="" /> */}
                                    <h3 class="head3">Start SIP
                                </h3>
                                    <p>Start small & grow with your progress. Treat yourself with a good life ahead. Start a SIP.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mtop40"></div>
        </>
    )
}
export default ShowInvestment;
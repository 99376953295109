import React, { useState, useEffect } from 'react'
import Modal from '../../molecules/modal'
import './index.css';
import loaderGif from '../../../assets/images/motilal-loader-new.gif';
// import loaderGif from '../../../assets/images/motilal-sjet-loader.gif';
import { connect } from 'react-redux';
import { userType } from '../../../helper/constant'

let roller = 0;
let rollerID = null;

const Loader = ({ isLoading, message, quotes, showOffers, additionalMessage, applicationType, additionalMessageTimeout }) => {

    const [showQuote, setShowQuote] = useState(false);
    const [showAddMessage, setShowAddMessage] = useState(false);

    useEffect(() => {
        rollQuotes();
        triggerAddMessageTimeout(); // trigger additional message timeout.
        if (!additionalMessageTimeout) { // hide additional msg when loader is cleared, so when next time loader comes addtional msg is hidden.
            setShowAddMessage(false);
        }
        if (!quotes) {
            setShowQuote(false);
            clearTimeout(rollerID);
        }
    }, [quotes, additionalMessageTimeout])

    function rollQuotes() {
        if (quotes) {
            setShowQuote(quotes[roller]);
            if (++roller <= quotes.length) {
                rollerID = setTimeout(rollQuotes, 10000);
            }
        }
    }

    function triggerAddMessageTimeout() {
        if (additionalMessageTimeout) {
            setTimeout(() => {
                setShowAddMessage(true)
            }, additionalMessageTimeout)
        }
    }

    if (isLoading)
        console.log(showAddMessage, 'loader showAddMessage');
    console.log(showQuote, 'loader quotes');
    return (
        <Modal
            open={isLoading}

            closeOnDocumentClick={false}
            lockScroll={true}
            modal={true}
            closeOnEscape={false}

            className={'loader'}
        >
            {/* <div>Loading...</div> */}
            {/* <img src={loaderGif} alt={'loading'} alt=""
                // width="80px"
                width=""
                height="" /> */}

            <span class="loader"></span>

            <div className={'loaderMessage'}>{message && message}</div>

            {showAddMessage &&
                <div className={'additionalLoaderMessage'}>{additionalMessage && additionalMessage}</div>
            }

            {showQuote &&
                <div className={'billionaireQuotes'}>
                    <div className={'quote'}>{`"${showQuote.quote}"`}</div>
                    <div className={'billionaireQuotes_Author'}>{showQuote.author}</div>
                </div>
            }

            {showOffers && (applicationType.toLowerCase() === userType.direct.toLowerCase()) &&
                <div className={'engageOffers'}>
                    <div className={'heading'}>We have some exciting offers for you</div>
                    <div className={'row'}>
                        <div className={'col-lg-4 col-md-4 col-sm-12'}>
                            <div className={'offerBox'}>
                                <p>Pay ₹499 and get Brokerage Credits worth</p>
                                <h6>₹999</h6>
                            </div>
                        </div>
                        <div className={'col-lg-4 col-md-4 col-sm-12'}>
                            <div className={'offerBox'}>
                                <p>Pay ₹999 and get Brokerage Credits worth</p>
                                <h6>₹1,999</h6>
                            </div>
                        </div>
                        <div className={'col-lg-4 col-md-4 col-sm-12'}>
                            <div className={'offerBox'}>
                                <p>Pay ₹1,999 and get Brokerage Credits worth</p>
                                <h6>₹4,999</h6>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal>

    )
    return null;
}

const mapStateToProps = (state) => ({
    isLoading: state.loader.loading,
    message: state.loader.message,
    quotes: state.loader.quotes,
    showOffers: state.loader.showOffers,
    additionalMessage: state.loader.additionalMessage,
    additionalMessageTimeout: state.loader.additionalMessageTimeout,
    applicationType: state.user.applicationType
})

export default connect(mapStateToProps)(Loader);
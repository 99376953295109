import { takeEvery, all, takeLatest, take } from 'redux-saga/effects';
import { VERIFY_OTP_EMAIL, GET_SCHEME_DETAILS, RESEND_OTP, INIT_SET_USER, INIT_SET_USER_SUPERAPP, GET_AURM_DETAILS } from '../redux/types/user';
import { verifyOtpEmail, getSchemeDetails, resendOtp, initSetUser, initSetUserSuperApp } from './user';
import { GET_ESIGN_DETAILS, GET_ESIGN_DETAILS_KRA, GET_ESIGN_DETAILS_DDPI, GET_AUTH_MODE, GET_AUTH_MODE_KRA, GET_AUTH_MODE_DDPI, GET_AVAILABLE_CLIENTCODE, PUSH_DATA_IN_DRISHTI, GENERATE_CLIENTCODE, EMUDRA, GET_EMUDRA, EMUDRA_KRA, GET_EMUDRA_KRA, EMUDRA_DDPI, GET_EMUDRA_DDPI, GENERATECLIENTCODE_MF  } from '../redux/types/esign';
import { getEsignDetails, getEsignDetailsKra, getEsignDetailsDdpi, getAuthMode, getAuthModeKra, getAuthModeDdpi, getAvailableClientCode, pushDataInDrishti, generateClientCode, emudhra, getEmudhra, emudhraKra, getEmudhraKra, emudhraDdpi, getEmudhraDdpi, generateClientCodeMF } from './esign';
import {
  UPLOAD_PROOF, UPLOAD_PROOF_USEQUITY, DELETE_PROOF, DELETE_PROOF_USEQUITY, GET_PROOF_LIST, VIEW_PROOF, VIEW_UPLOAD_IMAGE_USEQUITY,
  GET_PROOF_VERIFICATION_STATUS, SUBMIT_PROOF, SUBMIT_OCR_PROOF,
  CAPTURE_ADHAR_XML, CHECK_UNCHECK_MOBILE_ADHAR_LINK, FETCH_ADHAR_XML, GOTO_VERIFICATION_PAGE, VALIDATE_PAN, VALIDATE_PAN_DOB, VERIFY_PAN_ON_NAME, VALIDATE_AADHAAR, VALIDATE_ACCOUNT_AND_IFSC,
  VALIDATE_EMAIL_OTP, RESEND_EMAIL_OTP, UPLOAD_MULTI_PART, ADD_REMOVE_POA, GET_MICR, DELETE_NOMINEE, VALIDATE_NOMINEE_PAN, CAPTURE_LIVE_PHOTO, SAVE_LIVE_PHOTO,
  DIGI_LOCKER_REQUEST, FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP, FAIR_X_PAY_RESPONSE, DIGI_LOCKER_RESPONSE, SHARE_LINK, GET_PAN_DETAILS, GET_MICR_BASED_ON_BANK_BRANCH, GET_BANK_LIST, GET_CMR_COPY_DETAILS, GET_PAN_BANK_DETAILS, GET_AADHAR_DETAILS, GET_CLIENT_PHOTO, UPDATE_PROOF_STAGE, GET_CLIENT_SIGNATURE, GET_BANK_DETAILS, DELETE_BANK_DETAILS,
  INSERT_NRI_FLAG, MOVE_BACKWORD_STATUS, GET_AADHAAR_CONSENT, GET_PROOF_DETAILS_USEQUITY, AADHAARLINKUSEQUITY,
  HYPERVERGE_LIVE_PHOTO_REQUEST, AINXT_LIVE_PHOTO_REQUEST, AINXT_LIVE_PHOTO_RESPONSE, HYPERVERGE_LIVE_PHOTO_RESPONSE, VALIDATE_DPID, INSERT_UPDATE_SMALL_CASES_CONSENT, GET_AADHAR_DETAILS_SUPERAPP, LIVE_PHOTO_LOCATION_DETAILS, GET_PROOF_DETAILS, SAVE_UPLOAD_PROOF_DATA, UPDATE_PROOF_STAGE_USEQUITY, GET_BANK_LIST_BY_IFSC,
  AINXT_DIGILOCKER_REQUEST, AINXT_DIGILOCKER_RESPONSE, VALIDATE_PANSIGN, GET_ONEMONEY_BANK_DETAILS, UPDATE_MOBILE_NUMBER, ONE_MONEY_WEB_REDIRECTION, UPDATE_ONE_MONEY_STATUS, SAVE_REV_PENNY_DETAILS, UPDATE_MFD_FLAG
} from '../redux/types/proof';

import {
  uploadProof, uploadProofUsEquity, getProofList, deleteProof, deleteProofDetailsUsEquity, viewProof, viewUploadedImageUsEquity,
  getProofVerificationStatus, submitProof,
  captureAdharXML, checkUncheckMobileAdharLink, fetchAdharXML, gotoVerificationPageApi, validatePAN, validatePanDob, verifyPanOnName,
  validateAadhaarApi, validateAccountAndIfsc, validateEmailOTP, resendEmailOTP, uploadMultiPart, aadhaarLinksUsEquity,
  submitOCRProof, addRemovePOA, getMICR, deleteNominee, validateNomineePAN, captureLivePhoto, saveLivePhoto,
  digiLockerRequest, fairXpayKycRequestRedirectionSuperApp, digiLockerResponse, shareLink, getPanDetails, getPANBankDetails, getCmrCopyDetails, getBankList, getMicrBasedOnBankBranch, getAadharDetails, getClientPhoto, updateProofStage, insertUpdateStageUsEquity, getClientSignature, getBankDetails, deleteBankDetails, insertNriFlag, moveBackwordStatus, getAadhaarConsent,
  hypervergeLivePhotoRequest, ainxtLivePhotoRequest, ainxtLivePhotoResponse, validateDpIdApi, hypervergeLivePhotoResponse, insertUpdateSmallCasesConsent, getProofDetailsUsEquity, getAadhaarDetailSuperApp, livePhotoLocationDetails, getProofDetails, getBankListByIfsc,
  ainxtDigiLockerRequest, ainxtDigilockerResponse, validatePansign, getOneMoneyBankDetails, updateMobileNumber, OneMoneyRedirection, updateOneMoneyStatus, saveRevPennyDetails, updateMFDFlag
} from './proof';
import { GET_SCHEME_DETAILS_BRANCH, GET_USER_PAYMENT_DETAILS, ON_SCHEME_CHANGE_BRANCH, SAVE_SCHEME_DETAILS_BRANCH, GET_TRADER_DETAILS, INSERT_TRADER_DETAILS, SAVE_SCHEME_DETAILS_DIRECT } from '../redux/types/scheme';
import { getSchemeDetailsBranch, getUserPaymentDetails, onSchemeChangeBranch, saveSchemeDetailsBranch, getTraderDetails, insertTraderDetails, saveSchemeDetailsDirect } from './scheme';
import { downloadesignPdfForm, offerRedeem, initOffer, offerpaymentStatus, initSkip, getOffersList, registerWalletOffer, getValuePackDetails, initiateValuepack, updateValuePackDetails } from './offer';
import { downloadPdfForm, addRefral } from './congratulation';
import { DOWNLOAD_ESIGN_PDF_FORM, OFFER_REDEEM, INIT_OFFER, INIT_SKIP, OFFER_PAYMENT_STATUS, GET_OFFER_LIST, REGISTER_WALLET_OFFER, GET_VALUEPACK_DETAILS, INITIATE_VALUEPACK, UPDATE_VALUEPACK } from '../redux/types/offer';
import { DOWNLOAD_PDF_FORM, ADD_REFRAL } from '../redux/types/congratulation';
import { PAYMENT_INIT, PAYMENT_STATUS, SEND_CHEQUE_APPROVAL, REQUEST_CHEQUE_STATUS, SKIP_OR_CHEQUE_PAYMENT, GET_FUND_TRANSFER_DETAILS, GET_SCHEME_DETAILS_DIRECT, VALIDATE_DIRECT_LOGIN, INSERT_UPDATE_SELECTED_SEGMENT } from '../redux/types/payment';
import { paymentInit, paymentStatus, sendChequeApproval, requestChequeStatus, skipOrChequePayment, getFundTransferDetails, getSchemeDetailsDirect, validateDirectLogin, insertUpdateSelectedSegment } from './payment';
import { getAuRmDetails } from './registration';
import { GET_PERSONAL_INFO, UPDATE_PERSONAL_INFO, UPDATE_PERSONAL_OTHER_DETAIL, GET_INCOMEPROOF_DETAILS, GET_PERSONAL_DETAILS_USEQUITY, UPDATE_PERSONAL_DETAILS_USEQUITY } from '../redux/types/personal';
import { getPersonalInfo, updatePersonalInfo, updatePersonalOtherDetails, getIncomeProofDetails, getPersonalDetailsUsEquity, updatePersonalDetailsUsEquity } from './personal';

// import { get } from 'immer/dist/internal';
import { getWorkDetails, updateWorkDetails } from './helpUs'
import { GET_WORK_DETAILS, UPDATE_WORK_DETAILS } from '../redux/types/helpUs'
import { GET_NOMINEE_RELATIONSHIP_DROPDOWN, GET_STATE_CITY_FROM_PINCODE, BIND_STATE, BIND_CITY, UPDATE_NOMINEE_INFO, GET_NOMINEEDETAILS_BY_ID } from '../redux/types/nominee'
import { getNomineeRelationshipDropdown, getStateCityFromPincode, bindState, bindCity, updateNomineeInfo, getNomineeDetailsById } from './nominee'
import { GET_FEEDBACK, UPDATE_FEEDBACK_DETAILS, GET_VIDEO_LINK_DETAILS } from '../redux/types/feedback'
import { getFeedback, updateFeedbackDetails, getVideoLinkDetails } from './feedback'

function* rootSaga() {
  yield all([
    takeLatest(INIT_SET_USER, initSetUser),
    takeLatest(INIT_SET_USER_SUPERAPP, initSetUserSuperApp),
    takeLatest(GET_AURM_DETAILS, getAuRmDetails),
    takeLatest(GET_USER_PAYMENT_DETAILS, getUserPaymentDetails),
    takeLatest(VERIFY_OTP_EMAIL, verifyOtpEmail),
    takeLatest(RESEND_OTP, resendOtp),
    takeLatest(GET_SCHEME_DETAILS, getSchemeDetails),
    takeLatest(GET_SCHEME_DETAILS_BRANCH, getSchemeDetailsBranch),
    takeLatest(ON_SCHEME_CHANGE_BRANCH, onSchemeChangeBranch),
    takeLatest(SAVE_SCHEME_DETAILS_BRANCH, saveSchemeDetailsBranch),
    takeLatest(PAYMENT_INIT, paymentInit),
    takeLatest(PAYMENT_STATUS, paymentStatus),
    takeLatest(GET_ESIGN_DETAILS, getEsignDetails),
    takeLatest(GET_ESIGN_DETAILS_KRA, getEsignDetailsKra),
    takeLatest(GET_ESIGN_DETAILS_DDPI, getEsignDetailsDdpi),
    takeLatest(GET_AUTH_MODE, getAuthMode),
    takeLatest(GET_AUTH_MODE_KRA, getAuthModeKra),
    takeLatest(GET_AUTH_MODE_DDPI, getAuthModeDdpi),
    takeLatest(GET_AVAILABLE_CLIENTCODE, getAvailableClientCode),
    takeLatest(EMUDRA, emudhra),
    takeLatest(GET_EMUDRA, getEmudhra),
    takeLatest(EMUDRA_KRA, emudhraKra),
    takeLatest(GET_EMUDRA_KRA, getEmudhraKra),
    takeLatest(EMUDRA_DDPI, emudhraDdpi),
    takeLatest(GET_EMUDRA_DDPI, getEmudhraDdpi),
    takeLatest(GENERATE_CLIENTCODE, generateClientCode),
    takeLatest(UPLOAD_PROOF, uploadProof),
    takeLatest(UPLOAD_PROOF_USEQUITY, uploadProofUsEquity),
    takeLatest(VALIDATE_EMAIL_OTP, validateEmailOTP),
    takeLatest(RESEND_EMAIL_OTP, resendEmailOTP),
    takeLatest(UPLOAD_MULTI_PART, uploadMultiPart),
    takeLatest(PUSH_DATA_IN_DRISHTI, pushDataInDrishti),
    takeLatest(GET_PROOF_LIST, getProofList),
    takeLatest(DELETE_PROOF, deleteProof),
    takeLatest(DELETE_PROOF_USEQUITY, deleteProofDetailsUsEquity),
    takeLatest(VIEW_PROOF, viewProof),
    takeLatest(VIEW_UPLOAD_IMAGE_USEQUITY, viewUploadedImageUsEquity),
    // takeLatest(GET_PROOF_VERIFICATION_STATUS, getProofVerificationStatus),
    takeLatest(SUBMIT_PROOF, submitProof),
    takeLatest(SUBMIT_OCR_PROOF, submitOCRProof),
    takeLatest(CAPTURE_ADHAR_XML, captureAdharXML),
    takeLatest(FETCH_ADHAR_XML, fetchAdharXML),
    takeLatest(CHECK_UNCHECK_MOBILE_ADHAR_LINK, checkUncheckMobileAdharLink),
    takeLatest(GOTO_VERIFICATION_PAGE, gotoVerificationPageApi),
    takeLatest(OFFER_REDEEM, offerRedeem),
    takeLatest(INIT_OFFER, initOffer),
    takeLatest(INIT_SKIP, initSkip),
    takeLatest(OFFER_PAYMENT_STATUS, offerpaymentStatus),
    takeLatest(DOWNLOAD_ESIGN_PDF_FORM, downloadesignPdfForm),
    takeLatest(GET_TRADER_DETAILS, getTraderDetails),
    takeLatest(INSERT_TRADER_DETAILS, insertTraderDetails),
    takeLatest(VALIDATE_DPID, validateDpIdApi),
    takeLatest(GENERATECLIENTCODE_MF, generateClientCodeMF),

    takeLatest(SEND_CHEQUE_APPROVAL, sendChequeApproval),
    takeLatest(REQUEST_CHEQUE_STATUS, requestChequeStatus),
    takeLatest(SKIP_OR_CHEQUE_PAYMENT, skipOrChequePayment),
    takeLatest(VALIDATE_ACCOUNT_AND_IFSC, validateAccountAndIfsc),
    takeLatest(GET_PAN_DETAILS, getPanDetails),
    takeLatest(GET_PAN_BANK_DETAILS, getPANBankDetails),
    takeLatest(GET_CMR_COPY_DETAILS, getCmrCopyDetails),
    takeLatest(GET_BANK_LIST, getBankList),
    takeLatest(GET_BANK_LIST_BY_IFSC, getBankListByIfsc),
    takeLatest(GET_MICR_BASED_ON_BANK_BRANCH, getMicrBasedOnBankBranch),
    takeLatest(GET_BANK_DETAILS, getBankDetails),
    takeLatest(GET_ONEMONEY_BANK_DETAILS, getOneMoneyBankDetails),
    takeLatest(UPDATE_MOBILE_NUMBER, updateMobileNumber),
    takeLatest(ONE_MONEY_WEB_REDIRECTION, OneMoneyRedirection),
    takeLatest(DELETE_BANK_DETAILS, deleteBankDetails),
    takeLatest(VALIDATE_PAN, validatePAN),
    takeLatest(VALIDATE_PAN_DOB, validatePanDob),
    takeLatest(VERIFY_PAN_ON_NAME, verifyPanOnName),
    takeLatest(VALIDATE_AADHAAR, validateAadhaarApi),
    takeLatest(DOWNLOAD_PDF_FORM, downloadPdfForm),
    takeLatest(ADD_REFRAL, addRefral),

    takeLatest(GET_PERSONAL_INFO, getPersonalInfo),
    takeLatest(GET_INCOMEPROOF_DETAILS, getIncomeProofDetails),
    takeLatest(UPDATE_PERSONAL_INFO, updatePersonalInfo),
    takeLatest(UPDATE_PERSONAL_OTHER_DETAIL, updatePersonalOtherDetails),
    takeLatest(GET_CLIENT_PHOTO, getClientPhoto),
    takeLatest(UPDATE_PROOF_STAGE, updateProofStage),
    takeLatest(UPDATE_PROOF_STAGE_USEQUITY, insertUpdateStageUsEquity),
    takeLatest(GET_CLIENT_SIGNATURE, getClientSignature),
    takeLatest(INSERT_NRI_FLAG, insertNriFlag),
    takeLatest(MOVE_BACKWORD_STATUS, moveBackwordStatus),

    takeLatest(GET_AADHAR_DETAILS, getAadharDetails),
    // offer 
    takeLatest(GET_OFFER_LIST, getOffersList),
    takeLatest(REGISTER_WALLET_OFFER, registerWalletOffer),
    takeLatest(ADD_REMOVE_POA, addRemovePOA),

    //helpUs for AU3in1
    takeLatest(GET_WORK_DETAILS, getWorkDetails),
    takeLatest(UPDATE_WORK_DETAILS, updateWorkDetails),
    takeLatest(GET_MICR, getMICR),

    // nominee
    takeLatest(GET_NOMINEE_RELATIONSHIP_DROPDOWN, getNomineeRelationshipDropdown),
    takeLatest(GET_STATE_CITY_FROM_PINCODE, getStateCityFromPincode),
    takeLatest(BIND_STATE, bindState),
    takeLatest(BIND_CITY, bindCity),
    takeLatest(UPDATE_NOMINEE_INFO, updateNomineeInfo),

    takeLatest(DELETE_NOMINEE, deleteNominee),
    takeLatest(VALIDATE_NOMINEE_PAN, validateNomineePAN),
    takeLatest(GET_NOMINEEDETAILS_BY_ID, getNomineeDetailsById),

    //feedback
    takeLatest(GET_FEEDBACK, getFeedback),
    takeLatest(UPDATE_FEEDBACK_DETAILS, updateFeedbackDetails),
    takeLatest(GET_VIDEO_LINK_DETAILS, getVideoLinkDetails),

    //valuepack
    takeLatest(GET_VALUEPACK_DETAILS, getValuePackDetails),
    takeLatest(INITIATE_VALUEPACK, initiateValuepack),
    takeLatest(UPDATE_VALUEPACK, updateValuePackDetails),
    takeLatest(CAPTURE_LIVE_PHOTO, captureLivePhoto),
    takeLatest(SAVE_LIVE_PHOTO, saveLivePhoto),
    takeLatest(DIGI_LOCKER_REQUEST, digiLockerRequest),
    takeLatest(FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP, fairXpayKycRequestRedirectionSuperApp),
    takeLatest(DIGI_LOCKER_RESPONSE, digiLockerResponse),
    takeLatest(SHARE_LINK, shareLink),
    takeLatest(LIVE_PHOTO_LOCATION_DETAILS, livePhotoLocationDetails),
    takeLatest(GET_PROOF_DETAILS, getProofDetails),
    takeLatest(GET_PROOF_DETAILS_USEQUITY, getProofDetailsUsEquity),
    takeLatest(GET_AADHAR_DETAILS_SUPERAPP, getAadhaarDetailSuperApp),
    takeLatest(AADHAARLINKUSEQUITY, aadhaarLinksUsEquity),
    takeLatest(GET_FUND_TRANSFER_DETAILS, getFundTransferDetails),
    takeLatest(GET_AADHAAR_CONSENT, getAadhaarConsent),
    takeLatest(HYPERVERGE_LIVE_PHOTO_REQUEST, hypervergeLivePhotoRequest),
    takeLatest(AINXT_LIVE_PHOTO_REQUEST, ainxtLivePhotoRequest),
    takeLatest(AINXT_LIVE_PHOTO_RESPONSE, ainxtLivePhotoResponse),
    takeLatest(HYPERVERGE_LIVE_PHOTO_RESPONSE, hypervergeLivePhotoResponse),
    takeLatest(INSERT_UPDATE_SMALL_CASES_CONSENT, insertUpdateSmallCasesConsent),
    takeLatest(GET_SCHEME_DETAILS_DIRECT, getSchemeDetailsDirect),
    takeLatest(VALIDATE_DIRECT_LOGIN, validateDirectLogin),
    takeLatest(SAVE_SCHEME_DETAILS_DIRECT, saveSchemeDetailsDirect),
    takeLatest(INSERT_UPDATE_SELECTED_SEGMENT, insertUpdateSelectedSegment),

    //Personal details Us Equity
    takeLatest(GET_PERSONAL_DETAILS_USEQUITY, getPersonalDetailsUsEquity),
    takeLatest(UPDATE_PERSONAL_DETAILS_USEQUITY, updatePersonalDetailsUsEquity),

    takeLatest(AINXT_DIGILOCKER_REQUEST, ainxtDigiLockerRequest),
    takeLatest(AINXT_DIGILOCKER_RESPONSE, ainxtDigilockerResponse),
    takeLatest(VALIDATE_PANSIGN, validatePansign),
    takeLatest(UPDATE_ONE_MONEY_STATUS, updateOneMoneyStatus),
    takeLatest(SAVE_REV_PENNY_DETAILS, saveRevPennyDetails),
    takeLatest(UPDATE_MFD_FLAG, updateMFDFlag),
  ])
}
export default rootSaga;
import { RESPONSE_ERROR_SNACK_OPEN, RESPONSE_ERROR_SNACK_CLOSE, RESPONSE_ERROR_POPUP } from "../types/responseError";

const initialState = {
    responseErrorSnack: {
        type: "",
        message: "",
        hideAfterSec: "",
    },
    responseErrorPopup: {
        open: false,
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RESPONSE_ERROR_SNACK_OPEN:
            return { ...state, responseErrorSnack: action.payload };
        case RESPONSE_ERROR_SNACK_CLOSE:
            return { ...state, responseErrorSnack: action.payload };
        case RESPONSE_ERROR_POPUP:
            return { ...state, responseErrorPopup: action.payload };
        default:
            return state
    }
}
import Api from '.';
import { endpoints } from './endpoints';

export default class HelpApi extends Api {

    getWorkDetails(data) {//terms and consition data
        let url = this.buildUrl(endpoints.helpUs.getWorkDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    updateWorkDetails(data) {//terms and consition data
        let url = this.buildUrl(endpoints.helpUs.updateWorkDetails)

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }
}
import React, { Component, useState, useEffect } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'
import $ from "jquery";


const MFDSection9 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    useEffect(() => {
        $('.inputholder .getstartedcta1').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('.formbox').offset().top
            }, 1000);
            setTimeout(function () {
                $('.formbox .form-control.focus-field').focus();
            }, 1000);
        });
    }, [])

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey font-regular">
                            Market Leaders
                        </span>
                        <div>
                            <span className="f30 semibold mobf24"> for a Reason!</span>
                        </div>
                        <div className="mtop10" />
                    </h2>
                </div>
                <div className="mtop40" />
                <div className="pad20">
                    <div className="dflex aligncenter">
                        <div className="imblock">
                            <picture>
                                {/* WebP source */}
                                <source
                                    src={image1}
                                    type="image/webp"
                                    loading="lazy"
                                />
                                {/* Fallback for browsers that do not support WebP */}
                                <img
                                    src={image4}
                                    alt=""
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <div className="textblock ml20">
                            <h3 className="f16 lightgrey font-regular">
                                Trusted by 40L+ Customers
                            </h3>
                        </div>
                    </div>
                    <div className="dflex aligncenter mtop10 justifyend">
                        <div className="imblock">
                            <picture>
                                {/* WebP source */}
                                <source
                                    src={image2}
                                    type="image/webp"
                                    loading="lazy"
                                />
                                {/* Fallback for browsers that do not support WebP */}
                                <img
                                    src={image2}
                                    alt=""
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <div className="textblock ml20">
                            <h3 className="f16 lightgrey font-regular">
                                35+ Years of Robust Research
                            </h3>
                        </div>
                    </div>
                    <div className="dflex aligncenter mtop10 ">
                        <div className="imblock">
                            <picture>
                                {/* WebP source */}
                                <source
                                    src={image3}
                                    type="image/webp"
                                    loading="lazy"
                                />
                                {/* Fallback for browsers that do not support WebP */}
                                <img
                                    src={image3}
                                    alt=""
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <div className="textblock ml20">
                            <h3 className="f16 lightgrey font-regular">
                                2,500+ Branches
                            </h3>
                        </div>
                    </div>
                    <div className="dflex aligncenter mtop10 justifyend">
                        <div className="imblock">
                            <picture>
                                {/* WebP source */}
                                <source
                                    src={image4}
                                    type="image/webp"
                                    loading="lazy"
                                />
                                {/* Fallback for browsers that do not support WebP */}
                                <img
                                    src={image4}
                                    alt=""
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <div className="textblock ml20">
                            <h3 className="f16 lightgrey font-regular">
                                1,100+ Investment Advisors
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="text-center mtop20">
                    <div className="inputholder">
                        <a href=""></a>
                        <div className="Button getstartedcta1">
                            <a href=""></a>
                            <div className="dflex aligncenter spacebetween">
                                {/* <a href=""> */}
                                <span className="f14 semibold">KNOW MORE</span>
                                <span className="ml10">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_61_142"
                                            style={{ maskType: "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                        >
                                            <rect width={24} height={24} fill="#C4C4C4" />
                                        </mask>
                                        <g mask="url(#mask0_61_142)">
                                            <path
                                                d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z"
                                                fill="#0E0F11"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default MFDSection9;

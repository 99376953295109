import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class Cookie {

    setCookie(name, value) {
        cookies.set(name, value, { path: '/', secure: true });
    }

    getCookie(name) {
        return cookies.get(name);
    }

    removeCookie(name) {
        return cookies.remove(name, { path: '/' });
    }

}

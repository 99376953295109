import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'
import $ from "jquery";



class RegSection7 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  componentDidMount = () => {
    $('.inputholder .getstartedcta').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $('.formbox').offset().top
      }, 1000);
      setTimeout(function () {
        $('.formbox .form-control.focus-field').focus();
      }, 1000);
    });
  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="text-center">
          <h2 className="textlabel">
            <span className="f32 line1 mobf24 lightgrey font-regular">
              Market Leaders
            </span>
            <div>
              <span className="f30 semibold mobf24"> for a Reason!</span>
            </div>
            <div className="mtop10" />
          </h2>
        </div>
        <div className="mtop20" />
        <div className="pad20">
          <div className="dflex aligncenter mobjustifycenter mob-flex-col">
            <div className="imblock">
              <picture>
                {/* WebP source */}
                <source
                  src={image1}
                  type="image/webp"
                  loading="lazy"
                />
                {/* Fallback for browsers that do not support WebP */}
                <img
                  src={image4}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="textblock ml20 mobtxtcenter">
              <span className="f16 lightgrey font-regular">
                Trusted by 40L+ Customers
              </span>
            </div>
          </div>
          <div className="dflex aligncenter mobjustifycenter mob-flex-col mtop10 justifyend">
            <div className="imblock">
              <picture>
                {/* WebP source */}
                <source
                  src={image2}
                  type="image/webp"
                  loading="lazy"
                />
                {/* Fallback for browsers that do not support WebP */}
                <img
                  src={image2}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="textblock ml20 mobtxtcenter">
              <span className="f16 lightgrey font-regular">
                35+ Years of Robust Research
              </span>
            </div>
          </div>
          <div className="dflex aligncenter mobjustifycenter mob-flex-col mtop10 ">
            <div className="imblock">
              <picture>
                {/* WebP source */}
                <source
                  src={image3}
                  type="image/webp"
                  loading="lazy"
                />
                {/* Fallback for browsers that do not support WebP */}
                <img
                  src={image3}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="textblock ml20 mobtxtcenter">
              <span className="f16 lightgrey font-regular">
                2,500+ Branches
              </span>
            </div>
          </div>
          <div className="dflex aligncenter mobjustifycenter mob-flex-col mtop10 justifyend">
            <div className="imblock">
              <picture>
                {/* WebP source */}
                <source
                  src={image4}
                  type="image/webp"
                  loading="lazy"
                />
                {/* Fallback for browsers that do not support WebP */}
                <img
                  src={image4}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="textblock ml20 mobtxtcenter">
              <span className="f16 lightgrey font-regular">
                1,100+ Investment Advisors
              </span>
            </div>
          </div>
        </div>
        <div className="text-center mtop20">
          <div className="inputholder">
            <div className="Button getstartedcta">
              <div className="dflex aligncenter spacebetween">
                <span className="f14 semibold">Know More</span>
                <span className="ml10">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_61_142"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={24}
                      height={24}
                    >
                      <rect width={24} height={24} fill="#C4C4C4" />
                    </mask>
                    <g mask="url(#mask0_61_142)">
                      <path
                        d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z"
                        fill="#0E0F11"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default RegSection7;
import React, { Component } from 'react'
import basicDetails from '../../../assets/images/basic-details.svg';
import uploadProofs from '../../../assets/images/upload-proofs.svg';
import postVerification from '../../../assets/images/post-verification.svg';
import Image from '../../atom/image';
import LazyIframe from '../../molecules/lazyIframe'
import videoImg from '../../../assets/images/openDemat/vedio-img.jpg'

const procedureList = [
    { icon: basicDetails, description: 'Fill in your Basic Personal Details' },
    { icon: uploadProofs, description: 'Upload ONLY 4 documents (PAN, Aadhaar, Bank Proof, Signature) to Open Demat Account' },
    { icon: postVerification, description: 'Demat Account Activated Post Verification' },
]

function Procedure({ icon, description }) {
    return (
        <div className="col-sm-4">
            <div className="">
                <Image src={icon} className="mb-3" />
                <h2 className="font16">{description}</h2>
            </div>
        </div>
    )
}

class RegProcedure extends Component {
    render() {
        return (

            <div id="videosection">
                <div class="container">
                    <div class="msection">
                        <div class="left">
                            <div class="videobox">
                                <div class="text-center">
                                    <div class="head headcommon">
                                        <h2>
                                            What is a Demat Account?
                                        </h2>
                                    </div>
                                </div>

                                <div class="qcbloxck">
                                    <p className="video-text"> A <strong>Demat account</strong> is a dematerialised account for your financial securities. By opening a Demat account, you can trade and invest in the <strong>stock market</strong> online, without any hassle. Demat Account Opening requires a Broker, who acts as a channel between the investor and the exchange. Know more about getting a <strong>Free Demat Account</strong> in this video!
                                     <br />
                                        Before registering for a Free* Demat Account, remember to keep the following documents handy:-
                                        <br />

                                        <div className="mtop10 video-text">
                                            •	PAN Card<br />
                                            •	AADHAR Card<br />
                                            •	Signed & cancelled blank bank cheque<br />
                                            •	BANK Account Statement (for past 6 months)<br />
                                        </div>
                                    </p>


                                    <iframe width="355" height="250" src="https://www.youtube.com/embed/7Q9YbR8TLs4" title="Open Demat Account" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="video-text">
                                    <div className="mtop10"> </div>
                                    <p class="f16"> To open a DEMAT Account, follow the following given steps:-</p>


                                    <div className="mtop10"> </div>

                                    <p>
                                        <b>Step 1 - Filling out Personal Details:</b> To begin with, you will have to register with your mobile number and email address, and verify the same via OTP.
                                                </p>
                                    <p>
                                        <b>Step 2 - Submitting necessary documents:</b> To open a DEMAT Account, you will have to verify your PAN Number, Aadhar Card details, Bank Account Number & IFSC Code with the Broker.
                                                </p>
                                    <p>
                                        <b>Step 3 - Completing E-KYC:</b> To complete your E-KYC, you will have to fill in your personal details such as your income, your marital status, address, nominee, etc.
                                                </p>
                                    <p>
                                        <b>Step 4 - E-sign Aadhar Validation:</b> For the last step, you will have to e-verify your Aadhar. This can be done by receiving an OTP to your Aadhar registered mobile number or by uploading the ‘front’ and ‘back’ side photographs of your Aadhar card.
                                                </p>
                                    <div class="mtop10"></div>
                                    <p className="f16">
                                        On successful account opening, you will be communicated your User Id and Password via email.
                                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegProcedure;
import Api from '.';
import { endpoints } from './endpoints';

export default class CongratulationApi extends Api {

    downloadPdfForm(data) {
        let url = this.buildUrl(endpoints.congratulation.downloadPdfForm)
        console.log('data', data);

        return this.fetch(url, "POST", JSON.stringify(data)).then(response => response);
    }

    addRefral(data) {
        let url = this.buildUrl(endpoints.congratulation.addRefral)
        console.log('data', data);
        return this.fetch(url, "POST", data).then(response => response);
    }
}
import React, { Component } from 'react';
import classNames from 'classnames';
import RegResumeApplication from '../regResumeApplication';
import RegOpenAccountForm from '../regOpenAccountForm';
import RegOpenAccountFormWithOTP from '../regOprnAccountFormWithOTP'
import { regFormType } from '../../../helper/constant';
import Cookies from '../../../lib/cookies';
import { setUser } from '../../../redux/actions/user';
import { connect } from 'react-redux';

class RegForm extends Component {
    constructor(props) {
        super();
        this.state = {
            // 3 types of form(Open new, resume application, track). Will maintain state for currentForm
            currentForm: regFormType.openAccount,
            RegisteredmobileNo: "",
            isUserExisted: false,
            uniqueId: "",
            showLeftArrowBtn: false,//after clicking on resume application will be visible,
            regFullName: '',
            regMobileNo: '',
            isSouthIndianSelected: false,
            showPartner: false,
            partnerCode: '',
            referralCode: '',
            selectedLanguage: '',
            referralCodeMsg: '',
            city: '',
        }
        this.cookie = new Cookies();
    }

    handleCurrentForm = (value, mobileNo, isUserExisted, uniqueId, showLeftArrowBtn) => {
        this.setState({
            currentForm: value,
            RegisteredmobileNo: mobileNo,
            isUserExisted: isUserExisted,
            uniqueId: uniqueId,
            showLeftArrowBtn: showLeftArrowBtn
        })
    }

    handleCurrentOTPForm = (fullName, mobileNo, isSouthIndianSelected, showPartner, partnerCode, selectedLanguage, referralCode, referralCodeMsg, city) => {
        this.setState({ regFullName: fullName, regMobileNo: mobileNo, isSouthIndianSelected: isSouthIndianSelected, showPartner: showPartner, partnerCode: partnerCode, referralCode: referralCode, selectedLanguage: selectedLanguage, referralCodeMsg: referralCodeMsg, city: city })
        this.props.handleCurrentOTPForm(fullName, mobileNo, isSouthIndianSelected, showPartner, partnerCode, selectedLanguage, referralCode, referralCodeMsg, city)
    }

    componentDidMount() {
        this.setState({
            currentForm: this.props.formTypeCommingFromParams
        })

        let client_name = this.cookie.getCookie('client_name');
        let client_mobile = this.cookie.getCookie('client_mobile');
        let client_applicationType = this.cookie.getCookie('client_applicationType');
        let isAlliance = this.cookie.getCookie('isAlliance');
        let IsBRBALogin = this.cookie.getCookie('IsBRBALogin');
        let BranchEmpCodeFromRedirect = this.cookie.getCookie('BranchEmpCodeFromRedirect');
        let AppLoginIdFromRedirect = this.cookie.getCookie('AppLoginIdFromRedirect');
        let UserId = this.cookie.getCookie('UserId');
        let LSQLeadId = this.cookie.getCookie('LSQLeadId');
        let OpportunityId = this.cookie.getCookie('OpportunityId');
        let uniqueId = this.cookie.getCookie('uniqueId');

        console.log("client details=>", client_name, client_mobile);

        if (client_name) {
            sessionStorage.setItem('client_name', client_name);
            this.cookie.removeCookie('client_name');
        }
        if (client_mobile) {
            sessionStorage.setItem('client_mobile', client_mobile);
            this.cookie.removeCookie('client_mobile');
        }
        if (client_applicationType) {
            sessionStorage.setItem('client_applicationType', client_applicationType);
            this.cookie.removeCookie('client_applicationType');
        }
        if (isAlliance) {
            sessionStorage.setItem('isAlliance', isAlliance);
            this.cookie.removeCookie('isAlliance');
        }
        if (IsBRBALogin) {
            sessionStorage.setItem('IsBRBALogin', IsBRBALogin);
            this.cookie.removeCookie('IsBRBALogin');
        }
        if (BranchEmpCodeFromRedirect) {
            sessionStorage.setItem('BranchEmpCodeFromRedirect', BranchEmpCodeFromRedirect);
            this.cookie.removeCookie('BranchEmpCodeFromRedirect');
        }
        if (AppLoginIdFromRedirect) {
            sessionStorage.setItem('AppLoginIdFromRedirect', AppLoginIdFromRedirect);
            this.cookie.removeCookie('AppLoginIdFromRedirect');
        }
        if (UserId) {
            sessionStorage.setItem('UserId', UserId);
            this.cookie.removeCookie('UserId');
        }
        if (LSQLeadId) {
            sessionStorage.setItem('LSQLeadId', LSQLeadId);
            this.cookie.removeCookie('LSQLeadId');
        }
        if (OpportunityId) {
            sessionStorage.setItem('OpportunityId', OpportunityId);
            this.cookie.removeCookie('OpportunityId');
        }
        if (uniqueId) {
            sessionStorage.setItem('uniqueId', uniqueId);
            this.cookie.removeCookie('uniqueId');
        }

        if (sessionStorage.client_name || sessionStorage.client_mobile) {
            this.setState({
                regFullName: sessionStorage.client_name,
                regMobileNo: sessionStorage.client_mobile
            })
            this.props.setUser({
                mobileNumber: sessionStorage.client_mobile,
            })
        }

        if (sessionStorage.client_applicationType) {
            this.props.setUser({
                applicationType: sessionStorage.client_applicationType,
            })
        }

        if (sessionStorage.isAlliance) {
            this.props.setUser({
                isAlliance: sessionStorage.isAlliance,
            })
        }

        if (sessionStorage.IsBRBALogin) {
            this.props.setUser({
                isBRBALogin: sessionStorage.IsBRBALogin,
            })
        }

        if (sessionStorage.BranchEmpCodeFromRedirect) {
            this.props.setUser({
                branchEmpCodeFromRedirect: sessionStorage.BranchEmpCodeFromRedirect,
            })
        }

        if (sessionStorage.AppLoginIdFromRedirect) {
            this.props.setUser({
                appLoginIdFromRedirect: sessionStorage.AppLoginIdFromRedirect,
            })
        }

        if (sessionStorage.UserId) {
            this.props.setUser({
                id: sessionStorage.UserId,
            })
        }
        if (sessionStorage.LSQLeadId) {
            this.props.setUser({
                lsqId: sessionStorage.LSQLeadId,
            })
        }
        if (sessionStorage.OpportunityId) {
            this.props.setUser({
                opportunityId: sessionStorage.OpportunityId,
            })
        }
        if (sessionStorage.uniqueId) {
            this.props.setUser({
                uniqueId: sessionStorage.uniqueId,
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        console.log('prevProps', prevProps);
        console.log('this.props', this.props);
        if (this.props.formTypeCommingFromParams && (prevProps.formTypeCommingFromParams !== this.props.formTypeCommingFromParams)) {
            this.setState({
                currentForm: this.props.formTypeCommingFromParams
            })
        }
    }

    render() {
        console.log('render regform');
        return (
            <>
                {
                    (this.props.showOTP || this.props.showMFDOtp) ?
                        <div className={classNames("hide", { "show": this.state.currentForm === regFormType.openAccount })}>
                            <RegOpenAccountFormWithOTP
                                handleCurrentForm={this.handleCurrentForm}
                                showFullName={this.props.showFullName}
                                showMobile={this.props.showMobile}
                                showCity={this.props.showCity}
                                showOTP={this.props.showOTP}
                                regFullName={this.state.regFullName}
                                regMobileNo={this.state.regMobileNo}
                                isSouthIndianSelected={this.state.isSouthIndianSelected}
                                showPartner={this.state.showPartner}
                                partnerCode={this.state.partnerCode}
                                referralCode={this.state.referralCode}
                                selectedLanguage={this.state.selectedLanguage}
                                disableFullName={this.state.regFullName != "" && this.state.regFullName != undefined ? true : false}
                                disableMobileNo={this.state.regMobileNo != "" && this.state.regMobileNo != undefined ? true : false}
                                disableReferralCode={this.state.referralCode != "" && this.state.referralCode != undefined ? true : false}
                                disableSelectedLanguage={this.state.selectedLanguage != "" && this.state.selectedLanguage != undefined ? true : false}
                                referralCodeMsg={this.state.referralCodeMsg}
                                middleFormFullName={this.props.middleFormFullName}
                                middleFormMobile={this.props.middleFormMobile}
                                city={this.state.city}
                                disabledCity={this.state.city != "" && this.state.city != undefined ? true : false}
                                showMFDReg={this.props.showMFDReg}
                                showMFDOtp={this.props.showMFDOtp}
                            />
                        </div>
                        :
                        <div className={classNames("hide", { "show": this.state.currentForm === regFormType.openAccount })}>
                            <RegOpenAccountForm
                                handleCurrentForm={this.handleCurrentForm}
                                handleCurrentOTPForm={this.handleCurrentOTPForm}
                                showFullName={this.props.showFullName}
                                showMobile={this.props.showMobile}
                                showCity={this.props.showCity}
                                showMFDReg={this.props.showMFDReg}
                                showMFDOtp={this.props.showMFDOtp}
                            />
                        </div>
                }

                {/* <div className={classNames("hide", { "show": this.state.currentForm === regFormType.resumeApp })}>
                    <RegResumeApplication
                        // mobileNoCommingFromParams={this.props.mobileNoCommingFromParams}
                        formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                        RegisteredmobileNo={this.state.RegisteredmobileNo}
                        uniqueId={this.state.uniqueId}
                        isUserExisted={this.state.isUserExisted}

                        showLeftArrowBtn={this.state.showLeftArrowBtn}

                        handleCurrentForm={this.handleCurrentForm}
                    />
                </div> */}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
})

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(RegForm);
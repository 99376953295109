import { produce } from 'immer';
import { UPDATE_PERSONAL_DETAIL, SET_PERSONAL_INFO_DROPDOWN, UPDATE_INCOMEPROOF_DETAIL, SET_INCOMEPROOF_INFO_DROPDOWN, GET_NOMINEE_GENDER, GET_NOMINEE_RELATIONSHIP, GET_NOMINEE_TYPE, GET_PERSONAL_DETAILS_USEQUITY, SET_PERSONAL_INFO_USEQUITY_DROPDOWN, GET_PERSONAL_INFO_USEQUITY } from '../types/personal';


const initialState = {
    personalDetails: null,
    dropDownValues: null,
    incomeProofDetails: null,
    incomeDropDownValues: null,
    getNomineeType: null,
    // personalDetailsUsEquity: null,
    getPersonalDetailsUsEquityDropdown: null,
    getPersonalInfoUsEquity: null,
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_PERSONAL_DETAIL:
            state.personalDetails = payload;
            return;
        case SET_PERSONAL_INFO_DROPDOWN:
            state.dropDownValues = payload;
            return;
        case UPDATE_INCOMEPROOF_DETAIL:
            state.incomeProofDetails = payload;
            return;
        case SET_INCOMEPROOF_INFO_DROPDOWN:
            state.incomeDropDownValues = payload;
            return;
        case GET_NOMINEE_GENDER:
            state.getNomineeGender = payload;
            return;
        case GET_NOMINEE_RELATIONSHIP:
            state.getNomineeRelationship = payload;
            return;
        case GET_NOMINEE_TYPE:
            state.getNomineeType = payload;
            return;
        // case GET_PERSONAL_DETAILS_USEQUITY:
        //     state.personalDetailsUsEquity = payload;
        //     return;
        case SET_PERSONAL_INFO_USEQUITY_DROPDOWN:
            state.getPersonalDetailsUsEquityDropdown = payload;
            return;
        case GET_PERSONAL_INFO_USEQUITY:
            state.getPersonalInfoUsEquity = payload;
            return;
        default:
            return state;
    }
})

export default reducer;
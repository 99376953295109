import React, { Component, useState } from 'react';
import explore1 from '../../../assets/images/mfdregscreen/explore1.gif'
import explore2 from '../../../assets/images/mfdregscreen/explore2.gif'
import explore3 from '../../../assets/images/mfdregscreen/explore3.gif'
import explore4 from '../../../assets/images/mfdregscreen/explore4.gif'
import explore5 from '../../../assets/images/mfdregscreen/explore5.gif'
import explore6 from '../../../assets/images/mfdregscreen/explore6.gif'
import explore7 from '../../../assets/images/mfdregscreen/explore7.gif'
import explore8 from '../../../assets/images/mfdregscreen/explore8.gif'
import explore9 from '../../../assets/images/mfdregscreen/explore9.gif'




const MFDSection6 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey semibold">Explore </span>
                        <div>
                            <span className="f30 semibold mobf24">Mutual Funds Collections</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop10" />
                <div className="dflex wrap spacebetween gap15">
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    src = {explore1}
                                    alt=""
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    High Returns
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore2}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    SIP with Rs 5000
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore3}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Tax Saver
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore4}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    MO Recommends
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore5}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Highest Rated Funds
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore6}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    International Funds
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore7}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Wealth Builder
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore8}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    Index Funds
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="whitegradientbox d32 mobflexd48 mobmtop20 mtop20 ">
                        <div className="dflex align-flex-start mwrap">
                            <div className="text-right hd icon">
                                <img
                                    alt=""
                                    src = {explore9}
                                />
                            </div>
                            <div className="txthold ml10">
                                <span className="f18 lightblack font-semi-bold mobf14">
                                    New Fund Offers
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSection6;

import React from 'react';
import Stats from '../../../assets/images/stats.jpg';
import MobileStats from '../../../assets/images/mobileStats.jpg';
import Image from '../../atom/image';


const SjetBlogs = (props) => {
  console.log("SjetBlogs props=", props.getRegBlogsDetails, props.getRegBlogsDetails && props.getRegBlogsDetails[0] && props.getRegBlogsDetails[0].blogtitle);

  let imgBase = process.env.REACT_APP_BLOG_IMG_BASEURL;

  let img = props.getRegBlogsDetails && props.getRegBlogsDetails[0] && props.getRegBlogsDetails[0].filekey;
  let img1 = props.getRegBlogsDetails && props.getRegBlogsDetails[1] && props.getRegBlogsDetails[1].filekey;
  let img2 = props.getRegBlogsDetails && props.getRegBlogsDetails[2] && props.getRegBlogsDetails[2].filekey;
  let img3 = props.getRegBlogsDetails && props.getRegBlogsDetails[3] && props.getRegBlogsDetails[3].filekey;

  let readMoreBase = process.env.REACT_APP_READMORE_BASEURL;

  let readMore = props.getRegBlogsDetails && props.getRegBlogsDetails[0] && props.getRegBlogsDetails[0].blogxmlurl.replace(/\'/g, '-')
    .replace(/\?/g, '-')
    .replace(/\//g, '-')
    .replace(/;/g, '-')
    .replace(/  /g, '-')
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .replace(/'/g, '')
    .replace(/\//g, '')
    .toLowerCase();
  let readMoreId = props.getRegBlogsDetails && props.getRegBlogsDetails[0] && props.getRegBlogsDetails[0].id

  let readMore1 = props.getRegBlogsDetails && props.getRegBlogsDetails[1] && props.getRegBlogsDetails[1].blogxmlurl.replace(/\'/g, '-')
    .replace(/\?/g, '-')
    .replace(/\//g, '-')
    .replace(/;/g, '-')
    .replace(/  /g, '-')
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .replace(/'/g, '')
    .replace(/\//g, '')
    .toLowerCase();
  let readMoreId1 = props.getRegBlogsDetails && props.getRegBlogsDetails[1] && props.getRegBlogsDetails[1].id

  let readMore2 = props.getRegBlogsDetails && props.getRegBlogsDetails[2] && props.getRegBlogsDetails[2].blogxmlurl.replace(/\'/g, '-')
    .replace(/\?/g, '-')
    .replace(/\//g, '-')
    .replace(/;/g, '-')
    .replace(/  /g, '-')
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .replace(/'/g, '')
    .replace(/\//g, '')
    .toLowerCase();
  let readMoreId2 = props.getRegBlogsDetails && props.getRegBlogsDetails[2] && props.getRegBlogsDetails[2].id

  let readMore3 = props.getRegBlogsDetails && props.getRegBlogsDetails[3] && props.getRegBlogsDetails[3].blogxmlurl.replace(/\'/g, '-')
    .replace(/\?/g, '-')
    .replace(/\//g, '-')
    .replace(/;/g, '-')
    .replace(/  /g, '-')
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .replace(/'/g, '')
    .replace(/\//g, '')
    .toLowerCase();
  let readMoreId3 = props.getRegBlogsDetails && props.getRegBlogsDetails[3] && props.getRegBlogsDetails[3].id

  return (

    <>
      <div id="blog" >
        <div class="container">
          <div class="text-center">
            <h2 class="conbox">BLOGS</h2>
          </div>
          <div class="mtop40"></div>
        </div>
        <div class="mb">
          <div class="mainblog">
            <div class="borderbox">
              <Image data-src={imgBase + img} class="lazyload" alt={''} width="100%" height="auto" />
              <div class="bl_box">
                <h3 class="bloghead">{props.getRegBlogsDetails && props.getRegBlogsDetails[0] && props.getRegBlogsDetails[0].blogtitle}</h3>

                <div class="text-right">
                  <a href={readMoreBase + readMore + "/" + readMoreId} target="_blank" class="readmore">Read More</a>
                </div>
              </div>

            </div>
          </div>
          <div class="subblog">
            <div class="borderbox">
              <div class="imgbx">
                <Image data-src={imgBase + img1} class="lazyload" alt={''} width="100%" height="auto" />
              </div>
              <div class="bl_box">
                <h3 class="bloghead">{props.getRegBlogsDetails && props.getRegBlogsDetails[1] && props.getRegBlogsDetails[1].blogtitle}</h3>
                <div class="text-right">
                  <a href={readMoreBase + readMore1 + "/" + readMoreId1} target="_blank" class="readmore">Read More</a>
                </div>
              </div>
            </div>

            <div class="borderbox">
              <div class="imgbx">
                <Image data-src={imgBase + img2} class="lazyload" alt={''} width="100%" height="auto" />
              </div>
              <div class="bl_box">
                <h3 class="bloghead">{props.getRegBlogsDetails && props.getRegBlogsDetails[2] && props.getRegBlogsDetails[2].blogtitle}</h3>
                <div class="text-right">
                  <a href={readMoreBase + readMore2 + "/" + readMoreId2} target="_blank" class="readmore">Read More</a>
                </div>
              </div>
            </div>

            <div class="borderbox">
              <div class="imgbx">
                <Image data-src={imgBase + img3} class="lazyload" alt={''} width="100%" height="auto" />
              </div>
              <div class="bl_box">
                <h3 class="bloghead">{props.getRegBlogsDetails && props.getRegBlogsDetails[3] && props.getRegBlogsDetails[3].blogtitle}</h3>
                <div class="text-right">
                  <a href={readMoreBase + readMore3 + "/" + readMoreId3} target="_blank" class="readmore">Read More</a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}
export default SjetBlogs;
import React, { Component } from 'react';
import multi1 from '../../../assets/images/regscreen/multi1.png'
import multi2 from '../../../assets/images/regscreen/multi2.png'
import multi3 from '../../../assets/images/regscreen/multi3.svg'
import multi4 from '../../../assets/images/regscreen/multi4.jpg'

class RegSection5 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (
            <section className="sectionpad ptop0" >
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey font-regular">
                            Multiple Ways To
                        </span>
                        <div>
                            <span className="f30 semibold mobf24">Grow Your Money</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop20" />
                <div className="dflex wrap spacebetween mtop10">
                    <a className="whitegradientbox d48 mtop30 mobflex100 mobmtop20 textfit">
                        <div>
                            <div>
                                <div className="rec-sec-img">
                                    <img
                                        alt=""
                                        src={multi1}
                                    />
                                </div>
                            </div>
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">
                                    INTRADAY CALLS
                                </h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Free recommendations to make your day - everyday
                                </span>
                            </div>
                        </div>
                    </a>
                    <a className="whitegradientbox d48 mtop30 mobflex100 mobmtop20 textfit">
                        <div>
                            <div>
                                <div className="rec-sec-img">
                                    <picture>
                                        <img
                                            src={multi2}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">
                                    OPTIONS STRATEGIES
                                </h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Ready-made strategies to simplify options trading
                                </span>
                            </div>
                        </div>
                    </a>
                    <a className="whitegradientbox d48 mtop30 mobflex100 mobmtop20 textfit">
                        <div>
                            <div>
                                <div className="rec-sec-img">
                                    <picture>
                                        <img
                                            src={multi3}
                                            alt=""
                                            className="stockimg-setheight"
                                            loading="lazy"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">
                                    LONG TERM PLAYS
                                </h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Identify multibagger opportunities with QGLP philosophy
                                </span>
                            </div>
                        </div>
                    </a>
                    <a className="whitegradientbox d48 mtop30 mobflex100 mobmtop20 textfit">
                        <div>
                            <div>
                                <div className="rec-sec-img">
                                    <picture>
                                        {/* WebP source */}
                                        <source
                                            src={multi4}
                                            type="image/webp"
                                            className="stockimg-setheight"
                                            loading="lazy"
                                        />
                                        {/* Fallback for browsers that do not support WebP */}
                                        <img
                                            src={multi4}
                                            alt=""
                                            className="stockimg-setheight"
                                            loading="lazy"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div className="dflex spacebetween aligncenter">
                                <h3 className="font-semi-bold f18 mobf16 lightblack">
                                    MARGIN TRADING FACILITY
                                </h3>
                            </div>
                            <div className="mtop10">
                                <span className="lightgrey">
                                    Boost your trading with 4x leverage
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </section>



        );
    }
}

export default RegSection5;
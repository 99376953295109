import React from 'react'
import Image from '../../atom/image';
import getInTouch from '../../../assets/images/get-in-touch.svg';
import { LinkConstant } from '../../../helper/constant'
import { isMobile } from 'react-device-detect';

const RegHeaderContact = (props) => {
    // <div class="nav-block">
    //                 <ul class="contact-link flex-shrink-0">
    //                     <li class="contact-num-li">
    //                         <a href="" class="mr10">REFER &amp; EARN</a>
    //                         <span class="icons"><img src="assets/images/get-in-touch.svg" alt="" title="" width="" height=""></span>
    //                         <span class="lbl">Get in touch</span>
    //                         <a href="tel:0224054-8383" class="login-link"><span class="num">022 4054-8383</span></a>
    //                     </li>
    //                 </ul>
    //             </div>

    return (
        <div className="nav-block custom-div">
            {/* <div> */}
             {/* className="bottom-nav d-flex align-items-center justify-content-end justify-md-content-between" */}
                {isMobile && props.isDirect && <a href={LinkConstant.Refer_N_Earn} className="mr10 referNearn">REFER & EARN</a>}  {/*displayed in center for mobile */}
                {isMobile && (props.isCRM || props.isPP) && <a href={LinkConstant.DownloadPOA} target="_blank" className="mr10 downloadPOA">Download POA</a>} {/*displayed in center for mobile */}
                {!props.diy &&
                    <ul className="contact-link flex-shrink-0">
                        <li className="contact-num-li">
                            {!isMobile && props.isDirect && <a href={LinkConstant.Refer_N_Earn} className="mr10">REFER & EARN</a>} {/*displayed in bottom for desktop */}
                            {!isMobile && (props.isCRM || props.isPP) && <a href={LinkConstant.DownloadPOA} target="_blank" className="mr10">Download POA</a>} {/*displayed in bottom for desktop */}
                            <span className="icons">
                                <Image src={getInTouch} alt="" title="" width="" height=""/>
                            </span>
                            <span className="lbl">Get in touch</span>
                            <a href="tel:0224054-8383" className="login-link">
                                {/* <Image src={getInTouch} alt="" title="" /> */}
                                <span className="num" onClick={props.contactClicked}>022 4054-8383</span>
                            </a>
                        </li>
                    </ul>}
                {props.diy &&
                    <ul className="contact-link flex-shrink-0">
                        <li className="contact-num-li">
                            {!isMobile && props.isDirect && <a href={LinkConstant.Refer_N_Earn} className="mr10">REFER & EARN</a>} {/*displayed in bottom for desktop */}
                            {!isMobile && (props.isCRM || props.isPP) && <a href={LinkConstant.DownloadPOA} target="_blank" className="mr10">Download POA</a>} {/*displayed in bottom for desktop */}
                        </li>
                    </ul>}
            {/* </div> */}
        </div>
    )
}
export default RegHeaderContact;
import { produce } from 'immer';
import { SET_LOADING, CLEAR_LOADING } from '../types/loader';

const initialState = {
    loading: false,
    message: "",
    quotes: "",
    count: 0,
    showOffers: false,
    additionalMessage: "",
    additionalMessageTimeout: 0
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_LOADING:
            state.loading = true;
            state.message = payload && payload.message;
            state.quotes = payload && payload.quotes;
            state.showOffers = payload && payload.showOffers;
            state.additionalMessage = payload && payload.additionalMessage;
            state.additionalMessageTimeout = (payload && payload.additionalMessageTimeout) ? payload.additionalMessageTimeout : 1;
            state.count = state.count + 1;
            return state;
        case CLEAR_LOADING:
            state.loading = false;
            state.message = "";
            state.quotes = "";
            state.showOffers = false;
            state.additionalMessage = "";
            state.additionalMessageTimeout = 0;
            return state;
        default:
            return state;

    }
})

export default reducer;

import {
    UPLOAD_PROOF, UPLOAD_PROOF_USEQUITY, GET_PROOF_LIST, DELETE_PROOF, DELETE_PROOF_USEQUITY, VIEW_PROOF, VIEW_UPLOAD_IMAGE_USEQUITY, GET_MICR_BASED_ON_BANK_BRANCH,
    GET_PROOF_VERIFICATION_STATUS, SUBMIT_PROOF, SAVE_PROOF_LIST, UPDATE_AFTER_DELETE_PROOF,
    CAPTURE_ADHAR_XML, CHECK_UNCHECK_MOBILE_ADHAR_LINK, SAVE_BASE64_PROOF_CONTENT, SAVE_UPLOAD_PROOF_DATA,
    FETCH_ADHAR_XML, SHOW_PAN_TEXT_BOX, GET_CMR_COPY_DETAILS, GOTO_VERIFICATION_PAGE, VALIDATE_ACCOUNT_AND_IFSC, VALIDATE_PAN,
    HIDE_PAN_TEXT_BOX, SAVE_VALIDATION_PAN, SAVE_VALIDATE_ACCOUNT_AND_IFSC, UPDATE_BANK_PROOF, UPDATE_IS_ADHAR_XML,
    VALIDATE_EMAIL_OTP, RESEND_EMAIL_OTP, VERIFY_OTP_EMAIL, UPLOAD_MULTI_PART, VALIDATE_AADHAAR, UPDATE_AFTER_PAN_SITE_REJECTION, SAVE_VALIDATE_ADHAR_NO, SUBMIT_OCR_PROOF,
    ADD_REMOVE_POA, GET_MICR, SET_MICR, DELETE_NOMINEE, VALIDATE_NOMINEE_PAN, CAPTURE_LIVE_PHOTO, SAVE_LIVE_PHOTO,
    DIGI_LOCKER_REQUEST, FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP, GET_PROOF_DETAILS_USEQUITY, GET_AADHAR_DETAILS_SUPERAPP, AADHAARLINKUSEQUITY, DIGI_LOCKER_RESPONSE, SHARE_LINK, VALIDATE_PAN_DOB, VERIFY_PAN_ON_NAME, GET_PAN_DETAILS, GET_BANK_LIST, GET_PAN_BANK_DETAILS, GET_AADHAR_DETAILS,
    GET_CLIENT_PHOTO, UPDATE_PROOF_STAGE, VALIDATE_DPID, GET_CLIENT_SIGNATURE, GET_BANK_DETAILS, DELETE_BANK_DETAILS, INSERT_NRI_FLAG, MOVE_BACKWORD_STATUS, GET_AADHAAR_CONSENT,
    HYPERVERGE_LIVE_PHOTO_REQUEST, AINXT_LIVE_PHOTO_REQUEST, AINXT_LIVE_PHOTO_RESPONSE, UPDATE_PROOF_STAGE_USEQUITY, HYPERVERGE_LIVE_PHOTO_RESPONSE, INSERT_UPDATE_SMALL_CASES_CONSENT, LIVE_PHOTO_LOCATION_DETAILS, GET_PROOF_DETAILS, GET_BANK_LIST_BY_IFSC,
    AINXT_DIGILOCKER_REQUEST, AINXT_DIGILOCKER_RESPONSE, VALIDATE_PANSIGN, GET_ONEMONEY_BANK_DETAILS, UPDATE_MOBILE_NUMBER, ONE_MONEY_WEB_REDIRECTION, UPDATE_ONE_MONEY_STATUS, SAVE_REV_PENNY_DETAILS ,UPDATE_MFD_FLAG
} from "../types/proof";

export const uploadProof = (payload) => ({
    type: UPLOAD_PROOF,
    payload
})

export const uploadProofUsEquity = (payload) => ({
    type: UPLOAD_PROOF_USEQUITY,
    payload
})

export const saveUploadProofData = (payload) => ({
    type: SAVE_UPLOAD_PROOF_DATA,
    payload
})
export const validateDpIdApi = (payload) => ({
    type: VALIDATE_DPID,
    payload
})

export const uploadMultiPart = (payload) => ({
    type: UPLOAD_MULTI_PART,
    payload
})

export const getProofList = (payload) => ({
    type: GET_PROOF_LIST,
    payload
})
export const saveProofList = (payload) => ({
    type: SAVE_PROOF_LIST,
    payload
})


export const deleteProof = (payload) => ({
    type: DELETE_PROOF,
    payload
})

export const deleteProofDetailsUsEquity = (payload) => ({
    type: DELETE_PROOF_USEQUITY,
    payload
})

export const updateAfterDeleteProof = (payload) => ({
    type: UPDATE_AFTER_DELETE_PROOF,
    payload
})


export const viewProof = (payload) => ({
    type: VIEW_PROOF,
    payload
})

export const viewUploadedImageUsEquity = (payload) => ({
    type: VIEW_UPLOAD_IMAGE_USEQUITY,
    payload
})
export const saveBase64ProofContent = (payload) => ({
    type: SAVE_BASE64_PROOF_CONTENT,
    payload
})

export const getProofVerificationStatus = (payload) => ({
    type: GET_PROOF_VERIFICATION_STATUS,
    payload
})

export const submitProof = (payload) => ({
    type: SUBMIT_PROOF,
    payload
})

export const submitOCRProof = (payload) => ({
    type: SUBMIT_OCR_PROOF,
    payload
})

export const captureAdharXML = (payload) => ({
    type: CAPTURE_ADHAR_XML,
    payload
})

export const fetchfAdharXML = (payload) => ({
    type: FETCH_ADHAR_XML,
    payload
})

export const checkUncheckMobileAdharLink = (payload) => ({
    type: CHECK_UNCHECK_MOBILE_ADHAR_LINK,
    payload
})

export const gotoVerificationPage = (payload) => ({
    type: GOTO_VERIFICATION_PAGE,
    payload
})

export const showPanTextBox = () => ({
    type: SHOW_PAN_TEXT_BOX
})

export const clearPanTextBox = () => ({
    type: HIDE_PAN_TEXT_BOX
})


export const validateAccountAndIfsc = (payload) => ({
    type: VALIDATE_ACCOUNT_AND_IFSC,
    payload
})

export const getPanDetails = (payload) => ({
    type: GET_PAN_DETAILS,
    payload
})
export const getPANBankDetails = (payload) => ({
    type: GET_PAN_BANK_DETAILS,
    payload
})
export const getCmrCopyDetails = (payload) => ({
    type: GET_CMR_COPY_DETAILS,
    payload
})

export const getBankList = (payload) => ({
    type: GET_BANK_LIST,
    payload
})

export const getBankListByIfsc = (payload) => ({
    type: GET_BANK_LIST_BY_IFSC,
    payload
})
export const getMicrBasedOnBankBranch = (payload) => ({
    type: GET_MICR_BASED_ON_BANK_BRANCH,
    payload
})


export const getBankDetails = (payload) => ({
    type: GET_BANK_DETAILS,
    payload
})

export const getOneMoneyBankDetails = (payload) => ({
    type: GET_ONEMONEY_BANK_DETAILS,
    payload
})

export const updateMobileNumber = (payload) => ({
    type: UPDATE_MOBILE_NUMBER,
    payload
})

export const OneMoneyRedirection = (payload) => ({
    type: ONE_MONEY_WEB_REDIRECTION,
    payload
})


export const deleteBankDetails = (payload) => ({
    type: DELETE_BANK_DETAILS,
    payload
})

export const validatePAN = (payload) => ({
    type: VALIDATE_PAN,
    payload
})

export const validatePanDob = (payload) => ({
    type: VALIDATE_PAN_DOB,
    payload
})

export const verifyPanOnName = (payload) => ({
    type: VERIFY_PAN_ON_NAME,
    payload
})

export const validateNomineePAN = (payload) => ({
    type: VALIDATE_NOMINEE_PAN,
    payload
})

export const validateAadhaarApi = (payload) => ({
    type: VALIDATE_AADHAAR,
    payload
})

export const saveValidateAdharNo = (payload) => ({
    type: SAVE_VALIDATE_ADHAR_NO,
    payload
})

export const saveValidationPan = (payload) => ({
    type: SAVE_VALIDATION_PAN,
    payload
})

export const saveValidateAccountAndIfsc = (payload) => ({
    type: SAVE_VALIDATE_ACCOUNT_AND_IFSC,
    payload
})

export const updateBankProof = (payload) => ({
    type: UPDATE_BANK_PROOF,
    payload
})

export const updateIsAdharXML = (payload) => ({
    type: UPDATE_IS_ADHAR_XML,
    payload
})

export const validateEmailOTP = (payload) => ({
    type: VALIDATE_EMAIL_OTP,
    payload
})

export const verifyOtpEmail = (payload) => ({
    type: VERIFY_OTP_EMAIL,
    payload
})

export const resendEmailOTP = (payload) => ({
    type: RESEND_EMAIL_OTP,
    payload
})

export const updateAfterPanSiteRejection = (payload) => ({
    type: UPDATE_AFTER_PAN_SITE_REJECTION,
    payload
})

export const addRemovePOA = (payload) => ({
    type: ADD_REMOVE_POA,
    payload
})

export const getMICR = (payload) => ({
    type: GET_MICR,
    payload
})

export const setMICR = (payload) => ({
    type: SET_MICR,
    payload
})

export const deleteNominee = (payload) => ({
    type: DELETE_NOMINEE,
    payload
})

export const captureLivePhoto = (payload) => ({
    type: CAPTURE_LIVE_PHOTO,
    payload
})

export const saveLivePhoto = (payload) => ({
    type: SAVE_LIVE_PHOTO,
    payload
})

export const digiLockerRequest = (payload) => ({
    type: DIGI_LOCKER_REQUEST,
    payload
})

export const fairXpayKycRequestRedirectionSuperApp = (payload) => ({
    type: FAIR_X_PAY_REQUEST_REDIRECTION_SUPERAPP,
    payload
})

export const digiLockerResponse = (payload) => ({
    type: DIGI_LOCKER_RESPONSE,
    payload
})

export const shareLink = (payload) => ({
    type: SHARE_LINK,
    payload
})


export const getAadharDetails = (payload) => ({
    type: GET_AADHAR_DETAILS,
    payload
})

export const getClientPhoto = (payload) => ({
    type: GET_CLIENT_PHOTO,
    payload
})

export const updateProofStage = (payload) => ({
    type: UPDATE_PROOF_STAGE,
    payload
})

export const insertUpdateStageUsEquity = (payload) => ({
    type: UPDATE_PROOF_STAGE_USEQUITY,
    payload
})

export const getClientSignature = (payload) => ({
    type: GET_CLIENT_SIGNATURE,
    payload
})

export const insertNriFlag = (payload) => ({
    type: INSERT_NRI_FLAG,
    payload
})

export const moveBackwordStatus = (payload) => ({
    type: MOVE_BACKWORD_STATUS,
    payload
})

export const getAadhaarConsent = (payload) => ({
    type: GET_AADHAAR_CONSENT,
    payload
})

export const hypervergeLivePhotoRequest = (payload) => ({
    type: HYPERVERGE_LIVE_PHOTO_REQUEST,
    payload
})

export const ainxtLivePhotoRequest = (payload) => ({
    type: AINXT_LIVE_PHOTO_REQUEST,
    payload
})

export const ainxtLivePhotoResponse = (payload) => ({
    type: AINXT_LIVE_PHOTO_RESPONSE,
    payload
})

export const hypervergeLivePhotoResponse = (payload) => ({
    type: HYPERVERGE_LIVE_PHOTO_RESPONSE,
    payload
})

export const insertUpdateSmallCasesConsent = (payload) => ({
    type: INSERT_UPDATE_SMALL_CASES_CONSENT,
    payload
})

export const livePhotoLocationDetails = (payload) => ({
    type: LIVE_PHOTO_LOCATION_DETAILS,
    payload
})

export const getProofDetails = (payload) => ({
    type: GET_PROOF_DETAILS,
    payload
})

export const getProofDetailsUsEquity = (payload) => ({
    type: GET_PROOF_DETAILS_USEQUITY,
    payload
})

export const getAadhaarDetailSuperApp = (payload) => ({
    type: GET_AADHAR_DETAILS_SUPERAPP,
    payload
})

export const aadhaarLinksUsEquity = (payload) => ({
    type: AADHAARLINKUSEQUITY,
    payload
})

export const ainxtDigiLockerRequest = (payload) => ({
    type: AINXT_DIGILOCKER_REQUEST,
    payload
})

export const ainxtDigilockerResponse = (payload) => ({
    type: AINXT_DIGILOCKER_RESPONSE,
    payload
})

export const validatePansign = (payload) => ({
    type: VALIDATE_PANSIGN,
    payload
})

export const updateOneMoneyStatus = (payload) => ({
    type: UPDATE_ONE_MONEY_STATUS,
    payload
})

export const saveRevPennyDetails = (payload) => ({
    type: SAVE_REV_PENNY_DETAILS,
    payload
})


export const updateMFDFlag = (payload) => ({
    type: UPDATE_MFD_FLAG,
    payload
})


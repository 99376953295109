import React from 'react'
import Input from '../../atom/input';
import classNames from 'classnames';

const RegInput = (props) => {
    // const { className, }
    return (
        <div className={classNames("form-group", { [props.className]: props.className })}>
            <label for="exampleFormControlInput1" className="desk">{props.Label}</label>
            <Input type={props.type} showOtp={props.showOtp} otpTimer={props.otpTimer} {...props} className="form-control" />
            {<div className={classNames('reg-error disappear text-left', { 'appear': props.error })}>{props.error}</div>}
            {props.value === "" && <div className={classNames('reg-message disappear', { 'appear': props.message })}>{props.message}</div>}

        </div>
    )
}
export default RegInput;
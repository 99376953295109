import React, { Component } from 'react';
import image1 from '../../../assets/images/regscreen/new_icon_01.png'
import image2 from '../../../assets/images/regscreen/robotresearch35l.png'
import image3 from '../../../assets/images/regscreen/robotresearch35l (1).png'
import image4 from '../../../assets/images/regscreen/investmentadvison.png'

class RegSection13 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0">
        <div className="whatisproduct-sec">
          <div className="cms">
            <h2>
              <div className="text-center line1">
                <span className="lightgrey f32 font-semi-bold mobf24">What are the benefits of</span>
              </div>
              <div className="text-center line1">
                <span className="f32 font-semi-bold mobf24">  opening a Demat Account?
                </span>
              </div>
            </h2>
            <div className="mtop30" />
            <p>
              <b className='boldfaq'>By opening a Demat Account online, you get access to benefits like:-  </b>
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>1. Safe and Convenient: </b>Eliminates the risk of theft, loss, or damage associated with physical share certificates.
              Securities are held electronically, making transactions and tracking investments convenient.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>2. Easy Access: </b>Provides easy access to your investment portfolio,
              allowing you to track holdings, view transactions, and receive updates conveniently online.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>3. Quick and Efficient Transactions: </b>Enables quick and efficient
              buying and selling of securities, with transactions settled in a few days.

            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'> 4. Lower Costs: </b>
              Reduces paperwork and associated costs,
              such as stamp duty, compared to physical share transactions.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>5. Portfolio Diversification: </b>Allows you to invest in a wide range of securities,
              including stocks, bonds, mutual funds, and ETFs, helping you diversify your investment portfolio.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>6. Nomination Facility: </b> Allows you to nominate a person to manage your Demat Account in the event of your death.
            </p>
            <div className="mtop20" />
            <p>
              <b className='boldfaq'>7. Loan Against Securities: </b>Provides the option to avail loans against
              securities held in your Demat Account, offering liquidity without selling your investments.

            </p>
          </div>
        </div>
      </section >
    );
  }
}

export default RegSection13;
import React, { Component } from 'react'
import Image from '../../atom/image';
import rightArrow from '../../../assets/images/right-arrow-outline.svg'
import RegButton from '../../molecules/regButton';
import RegHeading from '../../molecules/regHeading';
import RegMobileNo from '../../molecules/regMobileNo';
import produce from 'immer';
import { regFormType, userType, apiErrorResponse } from '../../../helper/constant';
import { isValidMobileNo, fetchWithWait } from '../../../helper/method';
import RegistrationApi from '../../../services/registrationApi';
import { connect } from 'react-redux';
import { resendOtp, setUser } from '../../../redux/actions/user';
import { setLoading, clearLoading } from '../../../redux/actions/loader';
import RegInput from '../../molecules/regInput';
import history from '../../../lib/history'
import Loader from '../loader';
import { gotoVerificationPage } from '../../../redux/actions/proof';
import classNames from 'classnames'
import { isAndroid, isIOS, isMobile } from "react-device-detect";

let api = new RegistrationApi();
const otpLength = 4;
let TRACK_URL = process.env.REACT_APP_TRACK_URL;
let TRADE_URL = process.env.REACT_APP_FINISH_URL;

class RegResumeApplication extends Component {
    constructor(props) {
        super();
        this.state = {
            values: {
                regMobileNo: '',
                otp: ''
            },
            error: {
                // regMobileNo: ''
                // otp: '',
                // apiResponse: apiErrorResponse
            },
            showOtpField: false,
            uniqueId: '',
            disableResendButton: false,
            timer: 60,
            showTimer: false,
            IsTrueCallerUser: false,
            trueCallertimer: 60,
            showTrueCallerPopup: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.RegisteredmobileNo !== this.props.RegisteredmobileNo) {
            this.setState(prevState => produce(prevState, draft => {
                draft.values.regMobileNo = this.props.RegisteredmobileNo;
            }))
        }
    }

    redirect = (applicationType, page, isDiy) => {
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push(`/dad/${page}`);
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push(`/branch/${page}`);
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            if (isDiy) {
                page = page === "verifyMobileNumber" ? "verifyMobileNumberDiy" : page;
                history.push(`/ba/${page}`);
            }
            history.push(`/ba/${page}`);
        }
    }

    //#region resume Application
    resumeApplication = (data) => {
        this.props.setLoading();
        api.resumeApplication(data).then(response => {
            this.props.clearLoading();
            if (response.data && response.data.Status) {
                if (response.data.Data) {
                    if (response.data.Data.Status === "1") {

                        if (response.data.Data.ISSUPERJET !== "1") {
                            window.location = response.data.Data._ReturnURL;
                            return;
                        }

                        this.props.setUser({
                            id: response.data.Data.UserId,
                            uniqueId: response.data.Data._uniqueId,
                            mobileNo: this.state.values.regMobileNo,
                            isOtpRequired: false,
                            applicationType: response.data.Data.ApplicationType
                        })

                        let isDiy = response.data.Data.IsDIY === "1" ? true : false;

                        let page = this.getPage(response.data.Data.PageName); // get page logic
                        console.log('page', page)
                        if (page === 'verification') {
                            this.callResumeForm(response.data.Data.ApplicationType);
                        } else if (page === 'tracker') {
                            window.location = TRACK_URL;
                            return
                        } else if (page === 'trade') {
                            window.location = TRADE_URL;
                            return
                        }

                        this.redirect(response.data.Data.ApplicationType, page);//redirect logic on positive response

                    } else if (response.data.Status === "0") {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.apiResponse = response.data.Data.Message;
                        }))
                    }
                }
            }
            else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.data.Message;
                }))
            }

        }).catch(error => {
            console.log('resumeApplication error', error);
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
            }))
        })
    }
    //#endregion

    //#region resume form api call
    callResumeForm = (applicationType) => {
        let type;
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            type = 'dad';
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            type = 'branch';
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            type = 'ba';
        }

        let currentUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/verifyEmail';
        let successUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/esign';

        let data = {
            ReturnURLSUCCESS: successUrl,
            ReturnURLFAILURE: currentUrl,
            Type: 'CLIENTLOGIN'
        }
        this.props.resumeForm(data).then(res => {
            window.location.href = res[0].RESUMEFORM_URL;
        })
    }
    //#endregion

    //#region 
    getPage = (pageName) => {
        switch (pageName) {

            case 'VERIFICATION':
                return 'verification'
            case 'TRACKER':
                return 'tracker'
            case 'TRADE':
                return 'trade'
            case 'OTPEMAIL':
                return 'verifyEmail'
            case 'ESIGN':
                return 'esign'
            case 'KRAESIGN':
                return 'esignKra'
            case 'DDPIESIGN':
                return 'esignDdpi'
            case 'PERSONALDETAIL':
                return 'personalAndNominee'
            case 'FUNDTRANSFER':
                return 'fundTransferScheme'
            case 'PANBANK':
                return 'panBankUpload'
            case 'PAN':
                return 'pan'
            case 'BANK':
                return 'bank'
            case 'PROOFUPLOAD':
                return 'adhaarPhotoIncomeProof'
            case 'CONGRATULATION':
                return 'congratulation'
            default:
                break;
        }
    }

    //#endregion

    clearError = (name) => {
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error[name]
        }))
        this.setMessage({ name, message: '' });
    }

    clearApiResponse = () => {
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error["apiResponse"]
        }))
    }

    //#region change in any input field is handled here
    handleInputChange = ({ name, value }) => {
        console.log('name,value', name, value)
        if (name === "regMobileNo" && value.length != 0) {
            let validMob = isValidMobileNo(value);
            if (!validMob) {
                return false;
            }
        }
        this.clearError(name);
        this.clearApiResponse();

        if (!(name === 'otp' && value.length > otpLength)) {
            this.setState(prevState => produce(prevState, draft => {
                draft.values[name] = value;
            }))
        }

    }
    //#endregion

    //#region any input field blur is handled here.
    handleInputBlur = ({ name, value, isRequired }) => {
        this.validateField({ name, value, isRequired });
    }
    //#endregion

    //#region validation of field 
    validateField = ({ name, value, isRequired, callback = () => { } }) => {
        if (isRequired && !value) {// value is reqd but left blank
            callback();
            this.setState(prevState => produce(prevState, draft => {
                if (name === "regMobileNo") {
                    draft.error[name] = 'Please enter Mobile No.';
                } else if (name === "otp") {
                    draft.error[name] = 'Please enter OTP';
                }
            }))
        } else if (name === "regMobileNo") {
            this.validateMobileNumber({ name, value, callback })
        } else if (name === "otp") {
            this.validateOtp({ name, value, callback })
        }
    }
    //#endregion

    validateMobileNumber = ({ name, value, callback }) => {
        let vailid = isValidMobileNo(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 10) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Mobile Number';
            }))
        }
    }

    validateOtp = ({ name, value, callback }) => {
        if (value.length < otpLength) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = `OTP must be of ${otpLength} digits`;
            }))
        }
    }

    //#region back button is clicked. Go to Open Account Form
    handleBack = () => {
        this.setState({ showOtpField: false, error: {} })
        this.props.handleCurrentForm(regFormType.openAccount);
    }
    //#endregion

    //#region next button is clicked. Send req to backed
    handleSendOtp = () => {
        if (this.validateForm()) {
            let data = {
                _mobile: this.state.values.regMobileNo
            }
            if (this.state.IsTrueCallerUser) {
                let data = {
                    UserId: this.state.values.regMobileNo,
                    _password: this.state.values.otp,
                    _uniqueId: this.state.uniqueId || this.props.uniqueId,
                    BranchEmpCode: this.props.user.branchEmpCode,
                    AppLoginId: this.props.user.appLoginId,
                    IsDIY: this.props.user.isDiy,
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                }
                this.resumeApplication(data)
            } else {
                this.sendOtp(data)
            }
        }
    }
    //#endregion

    //#region handle resend 
    handleResendOtp = () => {

        if (this.validateForm()) {
            let data = {
                _mobile: this.state.values.regMobileNo
            }
            this.props.setLoading();
            this.props.resendOtp(data).then(response => {
                this.props.clearLoading();

                if (response.Status === "1") {

                    // otp is successfully resend.
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.otp = '';
                    }))
                    this.setState({
                        uniqueId: response._uniqueId,
                        message: response.Message + " " + this.state.values.regMobileNo,
                        resetvalue: '',
                        disableResendButton: true,
                        timer: 60,
                        showTimer: true
                    })

                    this.myInterval = setInterval(() => {
                        if (this.state.timer > 1) {
                            this.setState(({ timer }) => ({
                                timer: timer - 1
                            }))
                        } else {
                            clearInterval(this.myInterval);
                            this.setState({ showTimer: false })
                        }
                        console.log("Timer1=>", this.state.timer);
                    }, 1000);

                    setTimeout(() => {
                        this.setState({ disableResendButton: false })
                    }, 60000)   // 60 sec

                } else if (response.Status === "0") {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.apiResponse = response.Message
                    }))
                }
            }).catch(error => {
                this.props.clearLoading();

                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = apiErrorResponse;
                }))
            })
        }
    }

    // clearError = (name) => {

    //     this.setMessage({ name, message: '' });
    // }

    //#region sets error based on field otp and email
    setMessage = ({ name, message }) => {
        if (name === 'otp') {
            this.setState({
                message: ""
            })
        }
    }
    //#endregion

    //#region next button is clicked. Send req to backed
    handleResumeApplication = () => {
        let decision = true;
        let makeDecision = () => {
            decision = false
        }

        this.validateField({ name: 'otp', value: this.state.values.otp, isRequired: true, callback: makeDecision });

        if (decision) {
            let data = {
                UserId: this.state.values.regMobileNo,
                _password: this.state.values.otp,
                _uniqueId: this.state.uniqueId || this.props.uniqueId,
                BranchEmpCode: this.props.user.branchEmpCode,
                AppLoginId: this.props.user.appLoginId,
                IsDIY: this.props.user.isDiy
            }
            this.resumeApplication(data)
        }
    }
    //#endregion

    //#region send otp
    sendOtp = (data) => {
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {
                // otp is successfully send.
                this.setState({ showOtpField: true, uniqueId: response._uniqueId })
            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.Message
                }))
            }
        }).catch(error => {
            this.props.clearLoading();

            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = error.message;
            }))
        })
    }
    //#endregion

    //#region validation of whole form
    validateForm = () => {
        const { regMobileNo } = this.state.values
        let decision = true;

        const makeDecision = () => {
            decision = false;
        }

        const fieldsToValidate = [
            { name: 'regMobileNo', value: regMobileNo, isRequired: true },
        ]

        fieldsToValidate.forEach(field => this.validateField({ ...field, callback: makeDecision }))
        return decision;
    }
    //#endregion
    handleMobileClick = () => {
        let req_nonce = Math.floor((Math.random() * 899999) + 100000000);
        window.location = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${req_nonce}&partnerKey=${process.env.REACT_APP_TRUECALLER_APPKEY}&partnerName=motilaloswalekyc&lang=en&loginPrefix=getstarted&loginSuffix=verifymobile&ctaPrefix=continuewith&ctaColor=%23EB6400&ctaTextColor=%23ffffff&btnShape=round&skipOption=useanothernum`;

        var self = this;

        var isUcBrowser = false;
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        var isOpera = navigator.userAgent.indexOf(' OPR/') > -1;
        var isMiBrowser = navigator.userAgent.indexOf('MiuiBrowser') > -1;

        if (navigator.userAgent.includes('UCBrowser')) {
            isUcBrowser = true;
        }

        if (isUcBrowser || isFirefox || isOpera || isMiBrowser) {
            try {
                let callTrueCallerInterval = setInterval(() => {
                    let data = {
                        requestId: req_nonce
                    }
                    api.getTruecallerProfile(data).then(response => {
                        console.log(' getTruecallerProfile response', response);
                        self.setState(({ timer }) => ({
                            timer: timer - 5
                        }))
                        if (response.data.Status && self.state.timer > 1) {
                            if (response.data.Data[0]) {
                                self.callSetValues(response.data.Data[0].phoneNumbers);
                                clearInterval(callTrueCallerInterval);
                            } else {
                                self.setState({ showTrueCallerPopup: false })
                            }
                        } else {
                            clearInterval(callTrueCallerInterval);
                        }
                    })
                }, 5000);
            } catch (error) {
                this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
            }
        } else if (!isFirefox && !isUcBrowser && !isOpera && !isMiBrowser) {
            setTimeout(function () {
                if (document.hasFocus()) {
                    // alert('Truecaller app not present')
                    // Truecaller app not present on the device and you redirect the user 
                    // to your alternate verification page
                } else {
                    // alert('Truecaller app present')
                    // Truecaller app present on the device and the profile overlay opens
                    // The user clicks on verify & you'll receive the user's access token to fetch the profile on your 
                    // callback URL - post which, you can refresh the session at your frontend and complete the user  verification  
                    try {
                        let callTrueCallerInterval = setInterval(() => {
                            let data = {
                                requestId: req_nonce
                            }
                            api.getTruecallerProfile(data).then(response => {
                                console.log(' getTruecallerProfile response', response);
                                self.setState(({ timer }) => ({
                                    timer: timer - 5
                                }))
                                if (response.data.Status && self.state.timer > 1) {
                                    if (response.data.Data[0]) {
                                        self.callSetValues(response.data.Data[0].phoneNumbers);
                                        clearInterval(callTrueCallerInterval);
                                    } else {
                                        self.setState({ showTrueCallerPopup: false })
                                    }
                                } else {
                                    clearInterval(callTrueCallerInterval);
                                }
                            })
                        }, 5000);
                    } catch (error) {
                        this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
                    }
                }
            }, 600);
        }
    }

    callSetValues = (num) => {
        this.setState(prevState => produce(prevState, draft => {
            draft.values.regMobileNo = num;
            draft.IsTrueCallerUser = true;
            delete draft.error['regMobileNo'];
        }))
    }

    render() {
        let message = this.state.message;
        console.log("Message", message)
        return (
            <div className="resume-application-wrapper">
                <div className="text-center mb20">

                    {
                        this.props.showLeftArrowBtn &&
                        <Image className={'pull-left pointer'} src={rightArrow} onClick={this.handleBack} />
                    }

                    <RegHeading
                        className={"text-transform-upper mt-4"}
                        label={'Resume Application!'}
                    />

                </div>
                {
                    this.props.isUserExisted &&
                    <div className="text-center mb20 resumeMessage">
                        You have already registered with us. Please enter the OTP sent on your registered mobile number to resume your application.
                    </div>
                }

                {/* <form> */}
                {
                    this.state.showOtpField
                        ?
                        <RegInput
                            name={"otp"}
                            type={"number"}
                            value={this.state.values.otp}
                            error={this.state.error.otp}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            message={message}
                            placeholder={"Enter OTP"}
                            className={"resume-otp-field"}
                        />
                        :
                        <RegMobileNo
                            name={"regMobileNo"}
                            value={this.state.values.regMobileNo}
                            error={this.state.error.regMobileNo}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            placeholder={"Registered Mobile No."}
                            onClick={isAndroid && this.state.showTrueCallerPopup && this.handleMobileClick}
                            disabled={this.state.IsTrueCallerUser}
                        />
                }

                {
                    this.props.isUserExisted &&
                    <RegInput
                        name={"otp"}
                        type={"number"}
                        value={this.state.values.otp}
                        error={this.state.error.otp}
                        message={message}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}

                        placeholder={"Enter OTP"}
                        className={"resume-otp-field-existed"}
                    />
                }

                {
                    (this.props.isUserExisted || this.state.showOtpField) &&
                    <div className={classNames("resendOTP", { "disableResendButton": this.state.disableResendButton })} onClick={this.handleResendOtp}>
                        Resend OTP
                    </div>
                }

                {this.state.showTimer && <div className='reg-otpmessage'>Resend OTP in {this.state.timer} seconds</div>}

                {this.state.error.apiResponse && <div className={'reg-api-response-error'}>{this.state.error.apiResponse}</div>}

                {(this.state.showOtpField || this.props.isUserExisted)
                    ?
                    <RegButton
                        name={"proceed"}

                        onClick={this.handleResumeApplication}

                        label={'Proceed'}
                        outerContainerClassName={"mt30"}
                    />
                    :
                    <RegButton
                        name={"next"}

                        onClick={this.handleSendOtp}

                        label={'Next'}
                        outerContainerClassName={"mt30"}
                    />
                }
                {/* </form> */}
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})


const mapDispatchToProps = (dispatch) => ({
    resendOtp: (data) => fetchWithWait({ dispatch, action: resendOtp(data) }),
    resumeForm: (data) => fetchWithWait({ dispatch, action: gotoVerificationPage(data) }),
    setUser: (data) => dispatch(setUser(data)),
    setLoading: () => dispatch(setLoading()),
    clearLoading: () => dispatch(clearLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegResumeApplication);
import React, { Component } from 'react';
import classNames from 'classnames'
import RegInput from '../../molecules/regInput';
import RegMobileNo from '../../molecules/regMobileNo';
import RegButton from '../../molecules/regButton';
import { RegCheckbox } from '../../molecules/regCheckbox';
import RegOrangeText from '../../atom/regOrangeText';
import produce from 'immer';
import RegSelect from '../../molecules/regSelect';
import RegHeading from '../../molecules/regHeading';
import ErrorPopup from '../../organism/errorPopup';
import PartnerDetailsPopup from '../../organism/partnerDetailsPopup'
import { regFormType, userType, regFormLinks, apiErrorResponse, mobileExistMessage } from '../../../helper/constant';
import { isValidMobileNo, isValidName, fetchWithWait, isValidAlphaNumeric, getUserMachinePublicIp, getUserMachineLocalIp, getUserCity, getCityFromLatLong, isValidOtp } from '../../../helper/method';
import RegistrationApi from '../../../services/registrationApi';
import history from "../../../lib/history";
import { connect } from 'react-redux';
import { resendOtp, setUser } from '../../../redux/actions/user';
import { setResponseErrorSnack } from '../../../redux/actions/responseError';
import Loader from '../loader';
import { setLoading, clearLoading } from '../../../redux/actions/loader';
import { capitalCase } from 'change-case';
import firebase from "../../molecules/firebase";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import RegLanguageSelection from "../../molecules/regLanguageSelection"
import $ from "jquery";
import RegMobileOtpBottomSheet from '../../organism/regMobileOtpBottomSheet';

let api = new RegistrationApi();
let TRACK_URL = process.env.REACT_APP_TRACK_URL;
let TRADE_URL = process.env.REACT_APP_FINISH_URL;
const otpLength = 4;

class RegOpenAccountForm extends Component {

    constructor(props) {
        super();
        this.state = {
            values: {
                fullName: '',
                mobileNo: '',
                city: '',
                partnerCode: '',
                showPartner: false,
                isPolicyAccepted: true, // checkbox state
                auRm: '',
                fetchedCity: '',
                fetchedState: '',
                isSouthIndian: false,
                referralCode: '',
                otp: '',
                referedVia: "",
                TnC: true,
                refereeClientCode: "",
                refereeName: "",
                refereeMobileNo: "",
                refereeEmail: "",
            },
            error: {
                // fullName: '',
                // mobileNo: '',
                // city: '',
                // partnerCode: '',
                // isPolicyAccepted: '' // terms and condition (policy)
                // apiResponse: 'erhhr slskjjs'
            },
            availableCities: [],
            isShowErrorPopup: false,
            baDetails: {},
            openPartnerDetailDialog: false,
            isBADetailShow: true,
            isDIY: false,
            schemPlanId: null,
            disableFullNameForDiy: true,
            disableMobileForDiy: true,
            disablePartnerCodeForDiy: true,
            showCity: false,
            IsTrueCallerUser: false,
            timer: 60,
            showTrueCallerPopup: false,
            selectedLanguage: "English",
            // showMobileOtpPopup: false,
            otpTimer: 60,
            showTimer: false,
            disableResendButton: false,
            isValidReferralCode: false,
            disableReferralCode: false,
            showCheckBoxes: true,
            referralCodeMsg: "",
            latitude: "",
            longitude: "",
            locationStatus: "",
        }
    }

    componentDidMount = async () => {
        sessionStorage.removeItem('client_applicationType');
        sessionStorage.removeItem('isAlliance');
        sessionStorage.removeItem('IsBRBALogin');
        sessionStorage.removeItem('BranchEmpCodeFromRedirect');
        sessionStorage.removeItem('AppLoginIdFromRedirect');
        sessionStorage.removeItem('client_name');
        sessionStorage.removeItem('client_mobile');
        sessionStorage.removeItem('UserId');
        sessionStorage.removeItem('LSQLeadId');
        sessionStorage.removeItem('OpportunityId');
        sessionStorage.removeItem('uniqueId');
        //  this.getCities();
        // let ipLocalAddress = await getUserMachineLocalIp();
        // console.log("IP local =", ipLocalAddress);
        this.setState({ showCity: this.props.showCity })

        let latlongRes = await getUserMachinePublicIp();
        console.log("IP public=", latlongRes);
        if (typeof latlongRes === "object") {
            this.setState({ latitude: latlongRes.latitude, longitude: latlongRes.longitude })
        } else {
            this.setState({ locationStatus: latlongRes })
        }

        // if (latlongRes.city === null || latlongRes.state === null) {
        //     let cityFromLL = await getCityFromLatLong(latlongRes.latitude, latlongRes.longitude);
        //     console.log("getCityFromLatLong=", cityFromLL);

        //     let city = cityFromLL.results[0].components.city ? cityFromLL.results[0].components.city : cityFromLL.results[0].components.state_district ? cityFromLL.results[0].components.state_district : "Others";
        //     let state = cityFromLL.results[0].components.state ? cityFromLL.results[0].components.state : "Others";

        //     this.setState(prevState => produce(prevState, draft => {
        //         if (latlongRes.city !== null) { // 1st preference given to geolocation city
        //             draft.values.fetchedCity = latlongRes.city;  // fetchedCity used only for SRC="Website" user
        //             draft.values.fetchedState = latlongRes.state;
        //         } else {
        //             draft.values.fetchedCity = city; // if geolocation captures city as null then get city from lat long
        //             draft.values.fetchedState = state;
        //             // this.setState({ showCity: true })            //show City dropdown when api fetch city as null
        //         }
        //     }))
        // } else {
        //     this.setState(prevState => produce(prevState, draft => {
        //         draft.values.fetchedCity = latlongRes.city;
        //         draft.values.fetchedState = latlongRes.state;
        //     }))
        // }

        // let data = latlongRes.IPv4;
        // let userCity = await getUserCity(data);  // calling xavier api (ip-api)
        // console.log("userCity=", userCity, "CITY=>", userCity.city);

        // this.setState(prevState => produce(prevState, draft => {
        //     if (userCity.city !== null) {
        //         draft.values.fetchedCity = userCity.city;  // fetchedCity used only for SRC="Website" user
        //     } else {
        //         draft.values.fetchedCity = "Others"
        //         // this.setState({ showCity: true })            //show City dropdown when api fetch city as null
        //     }
        // }))

        this.initScript();

        // let screenTimer = 10;
        // this.myInterval = setInterval(() => {
        //     if (screenTimer > 0) {
        //         screenTimer = screenTimer - 1;
        //         sessionStorage.setItem('screenTimer', screenTimer);
        //     } else {
        //         clearInterval(this.myInterval);
        //     }
        //     console.log("screenTimer=>", screenTimer);
        // }, 1000);
    }

    initScript = () => {
        var self = this;
        console.log("initScript=", $('.cta.tc'));

        // $('.cta.tc').click(function () {
        //     $('body').addClass('open tc');
        // });
        // $('.close , .otherno').click(function () {
        //     $('body#home').removeClass('open').removeClass('mobileotp ').removeClass('noscroll').removeClass('tc');;
        // });
        // $('.cta.mobileotp').click(function () {
        //     $('body').addClass('open mobileotp noscroll');
        //     $('body').removeClass('tc');
        // });


        $('.close , .otherno').click(function () {
            $('body#home').removeClass('open').removeClass('mobileotp ').removeClass('noscroll').removeClass('tc');;
        });
        // $('.cta.proceed').click(function () {
        //     $('body').addClass('open mobileotp noscroll');
        // });

        var charLimit = 1;
        $(".inputs").keydown(function (e) {

            var keys = [8, 9, /*16, 17, 18,*/ 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 144, 145];

            if (e.which == 8 && this.value.length == 0) {
                $(this).prev('.inputs').focus();
            } else if ($.inArray(e.which, keys) >= 0) {
                return true;
            } else if (this.value.length >= charLimit) {
                $(this).next('.inputs').focus();
                return false;
            } else if (e.shiftKey || e.which <= 48 || e.which >= 58) {
                return false;
            }
        }).keyup(function () {
            if (this.value.length >= charLimit) {
                $(this).next('.inputs').focus();
                return false;
            }
        });
        var first = '', two = '', three = '', four = '';
        $('.mobotp .one').keyup(function () {
            first = $(this).val();
            self.setState(prevState => produce(prevState, draft => {
                draft.values.otp = first + two + three + four;
            }))
        });
        $('.mobotp .two').keyup(function () {
            two = $(this).val();
            self.setState(prevState => produce(prevState, draft => {
                draft.values.otp = first + two + three + four;
            }))
        });
        $('.mobotp .three').keyup(function () {
            three = $(this).val();
            self.setState(prevState => produce(prevState, draft => {
                draft.values.otp = first + two + three + four;
            }))
        });
        $('.mobotp .four').keyup(function () {
            four = $(this).val();
            self.setState(prevState => produce(prevState, draft => {
                draft.values.otp = first + two + three + four;
            }))
        });

        $("html, body").animate({ scrollTop: 0 }, "slow");  // to auto scroll up
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.source !== this.props.user.source || prevProps.user.sourceDetail !== this.props.user.sourceDetail) {
            if (this.props.user.source == "Stockpe" || this.props.user.sourceDetail == "Stockpe") {
                this.setState({ showTrueCallerPopup: false })
            }
        }

        if (prevProps.user.referralCode !== this.props.user.referralCode) {
            let data = {
                Referral_Code: this.props.user.referralCode
            }
            api.validateReferralCode(data).then(response => {
                console.log('validateReferralCode response', response);

                if (response.data.Table[0].IsValid !== true) {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.referralCode = 'Please enter valid Referral Code';
                        draft.isValidReferralCode = false;
                    }))
                } else if (response.data.Table[0].IsValid === true) {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.isValidReferralCode = true;
                        draft.Table = response.data.Table[0];
                        delete draft.error['referralCode'];
                        draft.values.refereeCode = response.data.Table[0].Referral_Code;
                        draft.values.referralCode = response.data.Table[0].Referral_Code;
                        draft.disableReferralCode = true;
                        draft.values.refereeName = response.data.Table[0].Referral_By;
                        draft.values.IsValid = response.data.Table[0].IsValid;
                        draft.referralCodeMsg = 'Referral by ' + response.data.Table[0].Referral_By;
                        draft.values.referedVia = "SOCIAL MEDIA";
                        draft.values.refereeClientCode = response.data.Table[0].Client_Code
                    }))
                    if (response.data.Table[0].ApplicationFrom.toUpperCase() == userType.crm || response.data.Table[0].ApplicationFrom.toUpperCase() == userType.pp) {
                        this.props.setUser({ showReferralBanner: true })
                    }
                }
            }).catch(error => {
                console.log("my error=", error);
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.referralCode = apiErrorResponse
                }))
            })
        }

        if (prevProps.user.diyId !== this.props.user.diyId) {
            console.log(this.props.user.diyId, 'diyId on update');
            this.checkDiyBa();
        }
        if (prevProps.showCity !== this.props.showCity) {
            this.setState({ showCity: this.props.showCity })
        }
        // if (prevState.values.isSouthIndian !== this.state.values.isSouthIndian) {
        //     if (this.state.values.isSouthIndian) {
        //         this.setState(prevState => produce(prevState, draft => {
        //             draft.values.fetchedCity = "Hyderabad"
        //         }))
        //     }
        // }

        if (prevProps.user.applicationType !== this.props.user.applicationType) {
            if (this.props.user.applicationType === "CRM") {
                this.getCities();
            }
        }

        if (prevState.error.apiResponse !== this.state.error.apiResponse) {
            var elmnt = document.getElementsByClassName("reg-error");
            console.log("reg-error element", elmnt);
            elmnt[1].scrollIntoView();
        }
    }

    //#region diy user logic.
    checkDiyBa = () => {

        if (!this.props.user.diyId) {
            return;
        }

        let data = {
            _guid: this.props.user.diyId
        }

        // this.props.setLoading({ message: "We are fetching your details..." })
        this.props.setLoading({ message: "" });

        api.getBaDiyDetails(data).then(response => {
            console.log('diy ba response', response);
            this.props.clearLoading()

            if (response.data) { //set user field for diy

                this.props.setUser({
                    applicationType: response.data[0].APPLICATIONTYPE,
                    strPSource: response.data[0].PSOURCE
                })

                this.setState(prevState => produce(prevState, draft => {
                    draft.values.fullName = response.data[0].NAME;
                    draft.values.mobileNo = response.data[0].MOBILE;
                    draft.values.partnerCode = response.data[0].PARTNERCODE;
                    draft.schemPlanId = response.data[0].SCHEMEPLANID;
                    draft.isDIY = true;
                }))

                if (response.data[0].NAME === null || response.data[0].NAME === '') {
                    this.setState({ disableFullNameForDiy: false })
                }

                if (response.data[0].MOBILE === null || response.data[0].MOBILE === '') {
                    this.setState({ disableMobileForDiy: false })
                }

                if (response.data[0].PARTNERCODE === null || response.data[0].PARTNERCODE === '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = false;
                        draft.disablePartnerCodeForDiy = false;
                    }))
                } else if (response.data[0].PARTNERCODE !== null || response.data[0].PARTNERCODE !== '') {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.values.showPartner = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.data.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
                // draft.isShowErrorPopup = true;
            }))
        })

    }
    //#endregion

    //#region Open Account Request data
    getOpenAccountReqData = () => {
        let data;
        let applicationType = this.props.user.applicationType;
        if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {// PP user = BA user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _strSource: 'Online',
                // _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                _interestedin: this.props.user.intrestedIN,
                ApplicationType: 'PP',
                AppLoginId: this.state.values.partnerCode || this.props.user.appLoginId,
                _applicationFrom: 'Web',
                isOcrUser: '1',
                BranchEmpCode: this.props.user.branchEmpCode,
                IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                Token_send: "1",
                Latitude: this.state.latitude,
                Longitude: this.state.longitude,
                LocationErrorMSG: this.state.locationStatus
            }
            if (this.state.schemPlanId) {
                data.SCHEMEPLANID = this.state.schemPlanId
                data.BACODE = this.state.values.partnerCode
            }
        } else if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {// direct user
            this.state.showCity ?
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    // _city: this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME,//'MUMBAI',
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    // _strPSource: this.props.user.source ? this.props.user.source : '',
                    // _strPSource: isMobile ? "Mobilesite" : "Website",
                    _strPSource: this.props.user.source ? this.props.user.source : isMobile ? "Mobilesite" : "Website",
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                } :
                data = {
                    _name: this.state.values.fullName,
                    _mobile: this.state.values.mobileNo,
                    _city: this.state.values.fetchedCity,//'MUMBAI',
                    _state: this.state.values.fetchedState,
                    IsSouthCitySelected: this.state.values.isSouthIndian ? "1" : "0",
                    _strSource: 'Online',
                    _interestedin: 'All',
                    ApplicationType: 'direct',
                    _applicationFrom: 'Web',
                    isOcrUser: '1',
                    // _strPSource: this.props.user.source ? this.props.user.source : '',
                    // _strPSource: isMobile ? "Mobilesite" : "Website",
                    _strPSource: this.props.user.source ? this.props.user.source : isMobile ? "Mobilesite" : "Website",
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                    IsNewReferral: (this.state.values.IsValid === true && this.state.values.refereeNamee !== "") ? "1" : "0",
                    Referee_Code: this.state.values.refereeCode,
                    Referee_Name: this.state.values.refereeName,
                    Referral_via: this.state.values.referedVia,
                    Referee_clientCode: this.state.values.refereeClientCode,
                    Token_send: "1",
                    Latitude: this.state.latitude,
                    Longitude: this.state.longitude,
                    LocationErrorMSG: this.state.locationStatus
                }
            if (this.state.values.showPartner) {//direct user with partner code entered, make it pp
                this.props.setUser({ applicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct })
                data = {
                    ...data,
                    BACODE: this.state.values.partnerCode,
                    ApplicationType: this.state.values.partnerCode != "" ? userType.pp : userType.direct,
                    _emailid: "",
                    _state: "",
                    // _strPSource: this.props.user.source ? this.props.user.source : '',
                    // _strPSource: isMobile ? "Mobilesite" : "Website",
                    _strPSource: this.props.user.source ? this.props.user.source : isMobile ? "Mobilesite" : "Website",
                    _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                    IPAddress: "",
                    _Otp: "",
                    _uniqueId: "",
                    AppLoginId: "",//"RETAILDOM",
                    _ROLEMAPPINGID: "",
                    _pincode: "",
                    BranchEmpCode: "",//"RETAILDOM",
                    _FamilyDeclaration: "",
                    IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                    Token_send: "1",
                    Latitude: this.state.latitude,
                    Longitude: this.state.longitude,
                    LocationErrorMSG: this.state.locationStatus
                }
            }

        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {// crm user = branch user
            data = {
                _name: this.state.values.fullName,
                _mobile: this.state.values.mobileNo,
                _city: this.state.showCity ? this.state.availableCities.find(({ CITY_CODE }) => CITY_CODE === this.state.values.city).CITY_NAME : "",//'MUMBAI',
                _strSource: 'Online',
                _interestedin: this.props.user.intrestedIN,
                ApplicationType: 'CRM',
                AppLoginId: this.props.user.appLoginId || this.state.values.partnerCode,
                _applicationFrom: 'Web',
                BranchEmpCode: this.props.auRm.length > 0 ? this.state.values.auRm : this.props.user.branchEmpCode,
                isOcrUser: '1',
                SHADOWUSERID: this.props.user.branchEmpCode,
                IsDIY: this.props.user.isDiy,
                // _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSource: this.props.user.source ? this.props.user.source : '',
                _strPSourceDetails: this.props.user.sourceDetail ? this.props.user.sourceDetail : '',
                IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
                BranchCode: this.props.user.branchCode,
                Token_send: "1",
                Latitude: this.state.latitude,
                Longitude: this.state.longitude,
                LocationErrorMSG: this.state.locationStatus
            }
        }
        return data;
    }
    //#endregion

    //#region bindcity api calling
    getCities = (params) => {
        try {
            api.getCities({ _stateid: '0' }).then(response => {
                console.log(' getCities response', response);
                if (response.data && response.data.Status) {
                    this.setState({
                        availableCities: response.data.Data.CityDetails
                    })
                }
                else {
                    this.props.setResponseErrorSnack({ type: 'error', message: response.data.Message });
                }
            })
        } catch (error) {
            this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
        }
    }
    //#endregion

    //#region resume api calling
    resumeApplication = () => {
        let applicationType = this.props.user.applicationType;
        let data = {
            UserId: this.state.values.mobileNo,
            _password: this.state.values.otp,
            _uniqueId: this.props.user.uniqueId,
            BranchEmpCode: this.props.user.branchEmpCode,
            AppLoginId: this.props.user.appLoginId,
            IsDIY: this.props.user.isDiy,
            IsTrueCaller: this.state.IsTrueCallerUser ? "1" : "0",
        }
        this.props.setLoading();
        api.resumeApplication(data).then(response => {
            this.props.clearLoading();
            if (response.data.Status) {
                $('body').removeClass('open mobileotp noscroll');

                if (this.props.user.applicationType === userType.direct || this.props.user.applicationType === 'direct') {
                    window.dataLayer.push({
                        event: 'lead_verified',
                        Parameters: [{
                            'mobile_number': this.state.values.mobileNo,
                            'truecaller_verified': "no",
                            'ekycuserid': response.data.Data.UserId,
                            'lsqid': response.data.Data.LSQLeadId,
                            'ProspectOpportunityId': response.data.Data.OpportunityId
                        }]
                    });
                    console.log("pushed event on reg page => ", window.dataLayer);

                    window.clevertap.event.push(
                        "lead_verified",
                        {
                            "mobile_number": this.state.values.mobileNo,
                            "truecaller_verified": "no",
                            "ekycuserid": response.data.Data.UserId,
                            "lsqid": response.data.Data.LSQLeadId,
                            "ProspectOpportunityId": response.data.Data.OpportunityId
                        }
                    );
                    console.log("pushed clevertap event => ", window.clevertap);
                }

                if (response.data.Data.ISSUPERJET !== "1") {
                    window.location = response.data.Data._ReturnURL;
                    return;
                }

                if (response.data.Data && response.data.Data.Status === "1") {//new user

                    if (this.props.user.uniqueId !== response.data.Data._uniqueId) {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.otp = "OTP entered is incorrect";
                        }))
                        return;
                    }

                    this.props.setUser({
                        // type: this.state.values.showPartner ? userType.pp : userType.direct,
                        id: response.data.Data.UserId,
                        tokenKey: response.data.Data.TokenKey,
                        uniqueId: response.data.Data._uniqueId,
                        fullName: capitalCase(this.state.values.fullName),
                        mobileNumber: this.state.values.mobileNo,
                        isOtpRequired: true,
                        lsqId: response.data.Data.LSQLeadId,
                        opportunityId: response.data.Data.OpportunityId
                    })

                    let page = this.getPage(response.data.Data.PageName); // get page logic
                    console.log('page', page)
                    if (page === 'verification') {
                        this.callResumeForm(response.data.Data.ApplicationType);
                    } else if (page === 'tracker') {
                        window.location = TRACK_URL;
                        return
                    } else if (page === 'trade') {
                        window.location = TRADE_URL;
                        return
                    }

                    this.redirectPage(response.data.Data.ApplicationType, page);//redirect logic on positive response

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && (applicationType.toLowerCase() === userType.direct.toLowerCase())) {//already existed user

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && this.props.user.strPSource == "LIVEPHOTO") {//already existed user crm/ba

                    let data = {
                        _mobile: this.state.values.mobileNo
                    }
                    this.sendOtp(data)

                } else if (response.data.Data && response.data.Data.IsPrivilege) {
                    window.location = TRADE_URL;
                } else {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.otp = response.data.Data.Message;
                        // draft.isShowErrorPopup = true;
                    }))
                }
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.otp = response.data.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }

        }).catch(error => {
            console.log('resumeApplication error', error);
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
            }))
        })
    }
    //#endregion

    getPage = (pageName) => {
        switch (pageName) {
            case 'OTPEMAIL':
                return 'verifyEmail'
            case 'ESIGN':
                return 'esign'
            case 'KRAESIGN':
                return 'esignKra'
            case 'DDPIESIGN':
                return 'esignDdpi'
            case 'PERSONALDETAIL':
                return 'personalAndNominee'
            case 'FUNDTRANSFER':
                return 'fundTransferScheme'
            case 'PANBANK':
                return 'panBankUpload'
            case 'PAN':
                return 'pan'
            case 'BANK':
                return 'bank'
            case 'PROOFUPLOAD':
                return 'adhaarPhotoIncomeProof'
            case 'CONGRATULATION':
                return 'congratulation'
            case 'VERIFICATION':
                return 'verification'
            case 'TRACKER':
                return 'tracker'
            case 'TRADE':
                return 'trade'
            default:
                break;
        }
    }

    //#region resume form api call
    callResumeForm = (applicationType) => {
        let type;
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            type = 'dad';
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            type = 'branch';
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            type = 'ba';
        }

        let currentUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/verifyEmail';
        let successUrl = window.location.origin + process.env.REACT_APP_PUBLIC_URL + '/' + type + '/esign';

        let data = {
            ReturnURLSUCCESS: successUrl,
            ReturnURLFAILURE: currentUrl,
            Type: 'CLIENTLOGIN'
        }
        this.props.resumeForm(data).then(res => {
            window.location.href = res[0].RESUMEFORM_URL;
        })
    }
    //#endregion

    redirectPage = (applicationType, page, isDiy) => {
        if (applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push(`/dad/${page}`);
        } else if (applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push(`/branch/${page}`);
        } else if (applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            history.push(`/ba/${page}`);
        }
    }

    //#region open account api calling
    openAccount = () => {
        // this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode);
        // history.push('/mobileOTP');
        // return;

        let data = this.getOpenAccountReqData();
        let applicationType = this.props.user.applicationType;
        let TRADE_URL = process.env.REACT_APP_FINISH_URL;
        this.props.setLoading()
        console.log('openAccount request=', data);
        api.openAccount(data).then(response => {
            console.log('openAccount response', response);
            this.props.clearLoading()
            if (response.data.code == "200") {
                if (response.data.Status) {
                    // sessionStorage.removeItem('webCam');
                    // sessionStorage.removeItem('webCamChecked');

                    if (response.data.Data.ISSUPERJET !== "1" && response.data.Data.ISJET !== "1") {
                        window.location = response.data.Data._ReturnURL;
                        return;
                    }

                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.apiResponse = "";
                    }))

                    if (response.data.Data && response.data.Data.Status === "4") {//new user

                        if (this.props.user.applicationType === userType.direct || this.props.user.applicationType === 'direct') {
                            // let screenTimer = sessionStorage.getItem('screenTimer');
                            // if (screenTimer <= 0) {
                            window.dataLayer.push({
                                event: 'lead_filled',
                                Parameters: [{
                                    'language': this.state.selectedLanguage,
                                    'mobile_number': this.state.values.mobileNo,
                                    'referral_code': this.state.values.referralCode,
                                    'south_indian_language': this.state.values.isSouthIndian ? "yes" : "no",
                                    'ekycuserid': response.data.Data.UserId,
                                    'lsqid': response.data.Data.LSQLeadId,
                                    'ProspectOpportunityId': response.data.Data.OpportunityId
                                }]
                            });
                            console.log("pushed event on reg page => ", window.dataLayer);


                            window.clevertap.event.push(
                                "lead_filled",
                                {
                                    "language": this.state.selectedLanguage,
                                    "mobile_number": this.state.values.mobileNo,
                                    "referral_code": this.state.values.referralCode,
                                    "south_indian_language": this.state.values.isSouthIndian ? "yes" : "no",
                                    "ekycuserid": response.data.Data.UserId,
                                    "lsqid": response.data.Data.LSQLeadId,
                                    'ProspectOpportunityId': response.data.Data.OpportunityId
                                }
                            );
                            console.log("pushed clevertap event => ", window.clevertap);

                            // window.clevertap.event.push(
                            //     "lead_verified",
                            //     {
                            //         "full_Name": this.state.values.fullName,
                            //         "language": this.state.selectedLanguage,
                            //         "mobile_number": this.state.values.mobileNo,
                            //         "referral_code": this.state.values.referralCode,
                            //         "south_indian_language": this.state.values.isSouthIndian ? "yes" : "no",
                            //         "ekycuserid": response.data.Data.UserId,
                            //         "lsqid": response.data.Data.LSQLeadId,
                            //         'ProspectOpportunityId': response.data.Data.OpportunityId
                            //     }
                            // );
                            // console.log("pushed clevertap event => ", window.clevertap);

                            var presentDate = new Date().toLocaleString().slice(0, 10);

                            window.clevertap.onUserLogin.push({
                                "Site": {
                                    "Name": this.state.values.fullName,
                                    "Phone": "+91" + this.state.values.mobileNo, // Phone (with the country code)
                                    "Identity": "",
                                    "Gender": "", // Can be either M or F
                                    DOB: new Date(), // Date of Birth. Date object
                                    // optional fields. controls whether the user will be sent email, push etc.
                                    "MSG-email": false, // Disable email notifications
                                    "MSG-push": true, // Enable push notifications
                                    "MSG-sms": true, // Enable sms notifications
                                    "MSG-whatsapp": true, // Enable WhatsApp notifications
                                    "customer_status": "Lead",
                                    "lead_filled_date": presentDate,
                                    "ekycuserid": this.props.user.id,
                                }
                            })
                            // }

                            // window.clevertap.profile.push({
                            //     "Site": {
                            //         "customer_status": "Lead",
                            //         "lead_filled_date": presentDate,
                            //         "ekycuserid": this.props.user.id,
                            //     }
                            // })
                        }

                        this.props.setUser({
                            // type: this.state.values.showPartner ? userType.pp : userType.direct,
                            id: response.data.Data.UserId,
                            tokenKey: response.data.Data.TokenKey,
                            uniqueId: response.data.Data._uniqueId,
                            fullName: capitalCase(this.state.values.fullName),
                            mobileNumber: this.state.values.mobileNo,
                            isOtpRequired: true,
                            lsqId: response.data.Data.LSQLeadId,
                            opportunityId: response.data.Data.OpportunityId
                        })

                        if (this.state.IsTrueCallerUser) {
                            this.redirect();
                        } else {
                            if (isMobile) {
                                // for mobile ( to open mobile otp popup )
                                $('body').addClass('open mobileotp noscroll');
                                this.setState({ showMobileOtpPopup: true, otpTimer: 60, showTimer: true, disableResendButton: true, })

                                this.myInterval = setInterval(() => {
                                    if (this.state.otpTimer > 1) {
                                        this.setState(({ otpTimer }) => ({
                                            otpTimer: otpTimer - 1
                                        }))
                                    } else {
                                        clearInterval(this.myInterval);
                                        this.setState({ showTimer: false })
                                    }
                                    // console.log("Timer1=>", this.state.otpTimer);
                                }, 1000);

                                setTimeout(() => {
                                    this.setState({ disableResendButton: false })
                                }, 60000)   // 60 sec

                                setTimeout(() => {
                                    this.setState({ showCheckBoxes: false })
                                }, 1000)   // 1 sec

                            } else {
                                // for desk
                                this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode, this.state.referralCodeMsg, this.state.values.city);
                                if (this.props.showMFDReg) {
                                    history.push('/MFDOtp');

                                } else {
                                    history.push('/mobileOTP');
                                }
                            }
                        }

                    } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists) {//already existed user
                        if (this.state.IsTrueCallerUser) {
                            // window.location = response.data.Data._ReturnURL;
                            // return;
                            this.resumeApplication();
                        } else {
                            let data = {
                                _mobile: this.state.values.mobileNo
                            }
                            this.sendOtp(data)

                            this.setState({ otpTimer: 60, showTimer: true, disableResendButton: true, })

                            this.myInterval = setInterval(() => {
                                if (this.state.otpTimer > 1) {
                                    this.setState(({ otpTimer }) => ({
                                        otpTimer: otpTimer - 1
                                    }))
                                } else {
                                    clearInterval(this.myInterval);
                                    this.setState({ showTimer: false })
                                }
                                // console.log("Timer1=>", this.state.otpTimer);
                            }, 1000);

                            setTimeout(() => {
                                this.setState({ disableResendButton: false })
                            }, 60000)   // 60 sec
                        }
                    } else if (response.data.Data && response.data.Data.IsMobileNoAlreadyExists && this.props.user.strPSource == "LIVEPHOTO") {//already existed user crm/ba

                        let data = {
                            _mobile: this.state.values.mobileNo
                        }
                        this.sendOtp(data)

                    } else if (response.data.Data && response.data.Data.IsPrivilege) {
                        window.location = TRADE_URL;
                    } else {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.apiResponse = response.data.Data.Message;
                            // draft.isShowErrorPopup = true;
                        }))
                    }
                } else {
                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.apiResponse = response.data.Message;
                        // draft.isShowErrorPopup = true;
                    }))
                }
            } else {
                //added by sushil
                const EkycApiFailRef = firebase.database().ref(process.env.REACT_APP_FIREBASE_OBJECT_NAME);
                const EkycApiFailData = {
                    ApplicationType: data.ApplicationType,
                    IsSouthCitySelected: data.IsSouthCitySelected,
                    isOcrUser: data.isOcrUser,
                    _applicationFrom: data._applicationFrom,
                    _city: data._city,
                    _interestedin: data._interestedin,
                    _mobile: data._mobile,
                    _name: data._name,
                    _state: data._state,
                    _strPSource: data._strPSource,
                    _strPSourceDetails: data._strPSourceDetails,
                    _strSource: data._strSource,
                    IsNewReferral: data.IsNewReferral,
                    Referee_Code: data.Referee_Code,
                    Referee_Name: data.Referee_Name,
                    Referral_via: data.Referral_via,
                };
                EkycApiFailRef.push(EkycApiFailData);
            }

        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = apiErrorResponse;
                // draft.isShowErrorPopup = true;
            }))
            // alert("called")
            console.log(' openAccount error', error);
        })
    }
    //#endregion

    //#region send otp
    sendOtp = (data) => {
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {
                this.props.setUser({ uniqueId: response._Uniqueid })
                // otp is successfully resend.

                // let jumpToResume = regFormType.resumeApp;
                // this.handleCurrentForm(jumpToResume, true, response._uniqueId)

                if (isMobile) {
                    // for mobile ( to open mobile otp popup )
                    this.setState({ showMobileOtpPopup: true })

                    setTimeout(() => {
                        this.setState({ showCheckBoxes: false })
                    }, 1000)   // 1 sec

                    $('body').addClass('open mobileotp noscroll');
                    this.setState(prevState => produce(prevState, draft => {
                        delete draft.error.otp
                        draft.values.otp = "";
                        draft.showMobileOtpPopup = true;
                    }))
                } else {
                    // for desk
                    this.props.handleCurrentOTPForm(this.state.values.fullName, this.state.values.mobileNo, this.state.values.isSouthIndian, this.state.values.showPartner, this.state.values.partnerCode, this.state.selectedLanguage, this.state.values.referralCode, this.state.referralCodeMsg, this.state.values.city);
                    if (this.props.showMFDReg) {
                        history.push('/MFDOtp');

                    } else {
                        history.push('/mobileOTP');
                    }
                }

            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.apiResponse = error.message;
            }))
        })
    }
    //#endregion

    //#region validate partner code api calling
    validatePartnerCode = (partner) => {
        api.validatePartnerCode(partner).then(response => {
            console.log('validatePartnerCode response.data.Data', response.data.Data)
            console.log('validatePartnerCode response.data.Data', response.data)
            if (response.data.Status) {
                if (response.data.Data && response.data.Data.BADETAILS) {
                    if (response.data.Data.BADETAILS[0].STATUS === 0) {//Invalid partner code

                        this.setState(prevState => produce(prevState, draft => {
                            draft.error.partnerCode = 'Invalid Partner Code'
                        }))

                    }
                    else if (response.data.Data.BADETAILS[0].STATUS === 1) {
                        this.setState(prevState => produce(prevState, draft => {
                            draft.baDetails = response.data.Data.BADETAILS[0];
                            // openPartnerDetailDialog: isEkycUpperMost ? false : true,
                            draft.openPartnerDetailDialog = true
                        }))
                        console.log("BA Details=>", this.state.baDetails);

                    }
                } else {

                    this.setState(prevState => produce(prevState, draft => {
                        draft.error.partnerCode = apiErrorResponse
                    }))

                }
            }
            else {

                this.setState(prevState => produce(prevState, draft => {
                    draft.error.partnerCode = apiErrorResponse
                }))
            }

        }).catch(error => {

            this.setState(prevState => produce(prevState, draft => {
                draft.error.partnerCode = apiErrorResponse
            }))

        })

    }
    //#endregion

    validateReferralCode = (callback) => {
        let data = {
            Mobile: this.state.values.mobileNo,
            Referral_Code: this.state.values.referralCode,

        }
        api.validateReferralCode(data).then(response => {
            console.log('validateReferralCode response', response);

            if (response.data.Table[0].IsValid !== true) {
                this.setState(prevState => produce(prevState, draft => {

                    draft.error.referralCode = 'Please enter valid Referral Code';
                    draft.isValidReferralCode = false;
                }))
            } else if (response.data.Table[0].IsValid === true) {
                if (response.data.Table[0].ApplicationFrom.toUpperCase() == userType.crm || response.data.Table[0].ApplicationFrom.toUpperCase() == userType.pp) {
                    this.props.setUser({ showReferralBanner: true })
                }
                this.setState(prevState => produce(prevState, draft => {
                    draft.isValidReferralCode = true;
                    draft.Table = response.data.Table[0];
                    delete draft.error['referralCode'];
                    draft.values.refereeCode = response.data.Table[0].Referral_Code;
                    draft.values.refereeName = response.data.Table[0].Referral_By;
                    draft.values.IsValid = response.data.Table[0].IsValid;
                    draft.referralCodeMsg = 'Referral by ' + response.data.Table[0].Referral_By;
                    draft.values.referedVia = this.state.values.referedVia != "" ? this.state.values.referedVia : "EKYC";
                    draft.values.refereeClientCode = response.data.Table[0].Client_Code;
                    draft.values.refereeMobileNo = response.data.Table[0].Referral_Mobile_No;
                    draft.values.refereeEmail = response.data.Table[0].Referral_Email;
                }), () => {
                    // this.handleOpenAccount();

                    let screenTimer = sessionStorage.getItem('screenTimer');
                    if (screenTimer <= 0) {

                        if (this.props.user.applicationType.toLowerCase() === userType.direct.toLowerCase()) {
                            window.dataLayer.push({
                                event: 'Referral_AO',
                                Parameters: [{
                                    'Referral Code': this.state.values.referralCode,
                                    'Referral Name': this.state.values.refereeName,
                                    'Referral Client Code': this.state.values.refereeClientCode,
                                    'Referral Email address': this.state.values.refereeEmail,
                                    'Referral Contact Number': this.state.values.refereeMobileNo,
                                }]
                            });
                            console.log("pushed event on reg page => ", window.dataLayer);

                            window.clevertap.event.push(
                                "Referral_AO",
                                {
                                    'Referral Code': this.state.values.referralCode,
                                    'Referral Name': this.state.values.refereeName,
                                    'Referral Client Code': this.state.values.refereeClientCode,
                                    'Referral Email address': this.state.values.refereeEmail,
                                    'Referral Contact Number': this.state.values.refereeMobileNo,
                                }
                            );
                            console.log("pushed clevertap event => ", window.clevertap);
                        }
                    }
                    let valid = this.validateForm();
                    if (valid) {
                        this.openAccount();
                    }
                })
            }
            callback();
            console.log("sszz", this.state.values.refereeClientCode)
        }).catch(error => {
            console.log("my error=", error);

            this.setState(prevState => produce(prevState, draft => {
                draft.error.referralCode = apiErrorResponse
            }))

        })


    }

    //#region open account button clicked. Send req to backend
    handleOpenAccount = () => {
        if (this.state.values.referralCode !== '' && !this.state.values.isValidReferralCode) {
            this.validateReferralCode(this.validateForm);
            return;
        }
        let valid = this.validateForm();
        console.log('handleOpenAccount valid', valid);
        if (valid && this.state.isBADetailShow) {
            console.log('send req')
            this.openAccount();
        }
    }
    //#endregion

    clearError = (name) => {
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error[name]
        }), () => {
            console.log('error cleared');
        })
    }

    //#region  show partner code field on clicking on 'Got a partner code?'
    showPartnerCode = () => {
        this.setState(prevState => produce(prevState, draft => {
            draft.values.showPartner = true;
        }))
    }
    //#endregion

    handleLabelClick = () => {
        window.open("https://ekyc.motilaloswal.com/EKYC_Icons/TermsAndConditions.pdf", '_blank');//new tab
    }

    //#region change in any input field is handled here
    handleInputChange = ({ name, value }) => {
        console.log('handleInputChange name,value', name, value)
        if (name === "mobileNo" && value.length != 0) {
            let validMob = isValidMobileNo(value);

            if (!validMob) {
                return false;
            }
        } else if (name === "fullName" && value.length != 0) {
            if (value.length > 60) {    // for mobile, not to accept 60+ chars
                return false;
            }
            // if (value[0] == ".") {  // dont allow . in first position
            //     return false;
            // }
            let validName = isValidName(value);
            if (!validName) {
                return false;
            }
            if (value.includes('.')) {
                // value = value.replace('..', '.')        // allow only single dot(.)
                // value = value.replace(/ +/g, ' ', '');  //Remove extra white spaces
                return false;   // dont allow .
            }
            else {
                value = value.replace(/ +(?= )/g, '');  //allow only single space after every one word
            }
        } else if (name === "partnerCode") {
            if (!isValidAlphaNumeric(value)) {
                return;
            }
            if (value.length == 0) {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = true
                }))
            } else {
                this.setState(prevState => produce(prevState, draft => {
                    draft.isBADetailShow = false
                }))
            }
            value = value.toUpperCase();
        }
        else if (name === "referralCode") {
            if (!isValidAlphaNumeric(value) || value.length > 8) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === 'otp') {
            if (!isValidOtp(value)) {
                return;
            }
        }
        this.clearError(name);
        if (name === 'otp' && value.length > otpLength) {
            return false;
        }
        this.setState(prevState => produce(prevState, draft => {
            draft.values[name] = value;
        }), () => {
            if (this.state.values.city) {// this is work around for city to unset city error in mobile
                this.clearError(name);
            }
        })
    }
    //#endregion

    //#region set current form to display. 3 form types. open account, resume Application and track App.
    handleCurrentForm = (value, isUserExisted, uniqueId, showLeftArrowBtn) => {
        // if (this.props.user.applicationType.toLowerCase() === userType.direct.toLowerCase() || this.props.user.strPSource == "LIVEPHOTO") {
        this.props.handleCurrentForm(value, this.state.values.mobileNo, isUserExisted, uniqueId, showLeftArrowBtn)
        // }
    }
    //#endregion

    //#region any input field blur is handled here.
    handleInputBlur = ({ name, value, isRequired }) => {
        console.log('Input blur', name, value, isRequired);
        this.validateField({ name, value, isRequired });
        if (name === 'partnerCode' && value) {
            let data = {
                _BACODE: value,
                ApplicationType: 'PP'
            }
            this.validatePartnerCode(data);
        }
    }
    //#endregion

    //#region validation of field 
    validateField = ({ name, value, isRequired, callback = () => { } }) => {
        console.log('validate', name, value, isRequired)
        if (isRequired && !value) {// value is reqd but left blank
            callback();
            this.setState(prevState => produce(prevState, draft => {
                if (name === "fullName") {
                    draft.error[name] = 'Please enter your Full Name';
                } else if (name === "mobileNo") {
                    draft.error[name] = 'Please enter your Mobile Number';
                } else if (name === "city") {
                    draft.error[name] = 'Please select city';
                } else if (name === "isPolicyAccepted") {
                    draft.error[name] = true;
                } else if (name === "otp") {
                    draft.error[name] = 'Please enter OTP';
                }
                else if (this.state.isDIY && name === "partnerCode" && this.props.user.strPSource !== "LIVEPHOTO") {
                    draft.error[name] = 'Please enter partner code';
                    draft.values.showPartner = true;
                }
            }), () => {
                console.log('error set')
            })
        } else if (name === "mobileNo") {
            this.validateMobileNumber({ name, value, callback })
        } else if (name === "fullName") {
            this.validateFullName({ name, value, callback })
        } else if (name === "partnerCode") {
            if (this.state.error.partnerCode) {//if field error is set on blue of partner code.
                this.setState(prevState => produce(prevState, draft => {
                    draft.values.showPartner = true;
                }))
                callback();
            }
        }
        // else if (name === "partnerCode") {
        //     if (this.state.error.partnerCode) {//if field error is set on blue of partner code.
        //         callback();
        //         this.setState(prevState => produce(prevState, draft => {
        //             draft.error[name] = 'Please enter Code';
        //         }))
        //     }
        // }
        else if (name === "otp") {
            this.validateOtp({ name, value, callback })
        }
    }
    //#endregion

    validateOtp = ({ name, value, callback }) => {
        if (value.length < otpLength) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = `OTP must be of ${otpLength} digits`;
            }))
        }
    }

    validateMobileNumber = ({ name, value, callback }) => {
        let vailid = isValidMobileNo(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 10) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Mobile Number';
            }))
        }
    }

    validateFullName = ({ name, value, callback }) => {
        let vailid = isValidName(value);
        console.log('vailid', vailid)
        if (!vailid || value.length < 2) {
            callback();
            this.setState(prevState => produce(prevState, draft => {
                draft.error[name] = 'Please enter valid Full Name';
            }))
        }
    }

    //#region validation of whole form
    validateForm = () => {
        const { fullName, mobileNo, city, partnerCode, isPolicyAccepted, auRm, referralCode } = this.state.values
        let decision = true;

        const makeDecision = () => {
            decision = false;
        }

        let fieldsToValidate = [
            (this.props.showFullName) && { name: 'fullName', value: fullName, isRequired: true },
            (this.props.showMobile) && { name: 'mobileNo', value: mobileNo, isRequired: true },

            (this.state.showCity) && (this.props.user.applicationType === userType.direct || this.props.user.applicationType === userType.crm)
            && { name: 'city', value: city, isRequired: true },

            (this.props.user.applicationType === userType.direct && this.state.values.showPartner && this.state.values.partnerCode != "") &&
            { name: 'partnerCode', value: partnerCode, isRequired: true },

            (this.props.user.applicationType === userType.direct && this.state.values.showPartner && this.state.values.partnerCode === "") &&
            { name: 'partnerCode', value: partnerCode, isRequired: false },

            (this.props.user.applicationType === userType.pp && this.state.isDIY && this.props.user.strPSource !== "LIVEPHOTO") &&
            { name: 'partnerCode', value: partnerCode, isRequired: true },

            (this.state.values.referralCode && this.state.values.referralCode !== "") &&
            { name: 'referralCode', value: referralCode, isRequired: true, },

            { name: 'isPolicyAccepted', value: isPolicyAccepted, isRequired: true },

            (this.props.user.applicationType === userType.crm && this.props.auRm.length > 0)
            && { name: 'auRm', value: auRm, isRequired: true },
        ]
        fieldsToValidate = fieldsToValidate.filter(field => field);
        fieldsToValidate.forEach(field => this.validateField({ ...field, callback: makeDecision }))
        console.log('fieldsToValidate', fieldsToValidate);
        return decision;
    }
    //#endregion

    redirect = () => {
        if (this.props.user.applicationType.toLowerCase() === userType.direct.toLowerCase()) {
            history.push('/dad/verifyEmail');
        } else if (this.props.user.applicationType.toLowerCase() === userType.crm.toLowerCase()) {
            history.push('/branch/verifyEmail');
        } else if (this.props.user.applicationType.toLowerCase() === userType.pp.toLowerCase()) {
            if (this.state.isDIY) {
                history.push('/ba/verifyEmail');
            } else {
                history.push('/ba/verifyEmail');
            }
        }
    }

    closeErrorPopup = () => {
        this.setState({ isShowErrorPopup: false })
        if (this.state.error.apiResponse == mobileExistMessage) {
            let jumpToResume = regFormType.resumeApp;
            this.handleCurrentForm(jumpToResume)
        }
    }

    handleBADetailsEdit = () => {
        this.setState({
            openPartnerDetailDialog: false
        },
            () => {
                var element = document.getElementsByName("partnerCode")
                console.log("Element=>", element);
                element[0].focus();
            }
        );
    }

    handleBADetailsClose = () => {
        this.setState({ openPartnerDetailDialog: false, isBADetailShow: true })
    }

    handleTrackApplication = () => {
        let trackUrl = process.env.REACT_APP_TRACK_URL;
        window.open(trackUrl);
        console.log("TrackURL", trackUrl);
    }

    handleMobileClick = () => {
        let req_nonce = Math.floor((Math.random() * 899999) + 100000000);
        window.location = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${req_nonce}&partnerKey=${process.env.REACT_APP_TRUECALLER_APPKEY}&partnerName=motilaloswalekyc&lang=en&loginPrefix=getstarted&loginSuffix=verifymobile&ctaPrefix=continuewith&ctaColor=%23EB6400&ctaTextColor=%23ffffff&btnShape=round&skipOption=useanothernum`;

        var self = this;

        var isUcBrowser = false;
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        var isOpera = navigator.userAgent.indexOf(' OPR/') > -1;
        var isMiBrowser = navigator.userAgent.indexOf('MiuiBrowser') > -1;

        if (navigator.userAgent.includes('UCBrowser')) {
            isUcBrowser = true;
        }

        if (isUcBrowser || isFirefox || isOpera || isMiBrowser) {
            try {
                let callTrueCallerInterval = setInterval(() => {
                    let data = {
                        requestId: req_nonce
                    }
                    api.getTruecallerProfile(data).then(response => {
                        console.log(' getTruecallerProfile response', response);
                        self.setState(({ timer }) => ({
                            timer: timer - 5
                        }))
                        if (response.data.Status && self.state.timer > 1) {
                            if (response.data.Data[0]) {
                                if (this.props.user.applicationType === userType.direct || this.props.user.applicationType === 'direct') {
                                    window.dataLayer.push({
                                        event: 'lead_verified',
                                        Parameters: [{
                                            'mobile_number': this.state.values.mobileNo,
                                            'truecaller_verified': "yes",
                                            'ekycuserid': this.props.user.id,
                                            'lsqid': this.props.user.lsqId,
                                        }]
                                    });
                                    console.log("pushed event on reg page => ", window.dataLayer);
                                    window.clevertap.event.push(
                                        "lead_verified",
                                        {
                                            "mobile_number": this.state.values.mobileNo,
                                            "truecaller_verified": "yes",
                                            "ekycuserid": this.props.user.id,
                                            "lsqid": this.props.user.lsqId,
                                            'ProspectOpportunityId': this.props.user.opportunityId
                                        }
                                    );
                                    console.log("pushed clevertap event => ", window.clevertap);
                                }

                                self.callSetValues(response.data.Data[0].phoneNumbers);
                                clearInterval(callTrueCallerInterval);
                            } else {
                                self.setState({ showTrueCallerPopup: false })
                            }
                        } else {
                            clearInterval(callTrueCallerInterval);
                        }
                    })
                }, 5000);
            } catch (error) {
                this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
            }
        } else if (!isFirefox && !isUcBrowser && !isOpera && !isMiBrowser) {
            setTimeout(function () {
                if (document.hasFocus()) {
                    // alert('Truecaller app not present')
                    // Truecaller app not present on the device and you redirect the user 
                    // to your alternate verification page
                } else {
                    // alert('Truecaller app present')
                    // Truecaller app present on the device and the profile overlay opens
                    // The user clicks on verify & you'll receive the user's access token to fetch the profile on your 
                    // callback URL - post which, you can refresh the session at your frontend and complete the user  verification  
                    try {
                        let callTrueCallerInterval = setInterval(() => {
                            let data = {
                                requestId: req_nonce
                            }
                            api.getTruecallerProfile(data).then(response => {
                                console.log(' getTruecallerProfile response', response);
                                self.setState(({ timer }) => ({
                                    timer: timer - 5
                                }))
                                if (response.data.Status && self.state.timer > 1) {
                                    if (response.data.Data[0]) {
                                        self.callSetValues(response.data.Data[0].phoneNumbers);
                                        clearInterval(callTrueCallerInterval);
                                    } else {
                                        self.setState({ showTrueCallerPopup: false })
                                    }
                                } else {
                                    clearInterval(callTrueCallerInterval);
                                }
                            })
                        }, 5000);
                    } catch (error) {
                        this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
                    }
                }
            }, 600);
        }
    }

    callSetValues = (num) => {
        this.setState(prevState => produce(prevState, draft => {
            draft.values.mobileNo = num;
            draft.IsTrueCallerUser = true;
            delete draft.error['mobileNo'];
        }))
    }

    handleLanguageSelection = (lang) => {
        this.setState({ selectedLanguage: lang })
    }

    handlePopupClose = () => {
        // this.setState({ showMobileOtpPopup: false })
        $('body').removeClass('open mobileotp noscroll');
        this.setState(prevState => produce(prevState, draft => {
            delete draft.error.otp
            draft.values.otp = "";
            draft.otpTimer = 0;
            draft.showTimer = false;
            draft.disableResendButton = false;
            draft.showCheckBoxes = true;
        }))
    }

    handleMobileResendOTP = () => {
        let data = {
            _mobile: this.state.values.mobileNo,
            ISRESENDOTP: "1"
        }
        this.props.setLoading();
        this.props.resendOtp(data).then(response => {
            this.props.clearLoading();

            if (response.Status === "1") {

                this.setState(prevState => produce(prevState, draft => {
                    delete draft.error.otp
                    draft.values.otp = "";
                    draft.otpTimer = 60;
                    draft.showTimer = true;
                    draft.disableResendButton = true;
                    draft.error.otp = '';
                }))

                this.props.setUser({
                    uniqueId: response._Uniqueid,
                })

                this.myInterval = setInterval(() => {
                    if (this.state.otpTimer > 1) {
                        this.setState(({ otpTimer }) => ({
                            otpTimer: otpTimer - 1
                        }))
                    } else {
                        clearInterval(this.myInterval);
                        this.setState({ showTimer: false })
                    }
                    // console.log("Timer1=>", this.state.otpTimer);
                }, 1000);

                setTimeout(() => {
                    this.setState({ disableResendButton: false })
                }, 60000)   // 60 sec
            } else if (response.Status === "0") {
                this.setState(prevState => produce(prevState, draft => {
                    draft.error.apiResponse = response.Message;
                    // draft.isShowErrorPopup = true;
                }))
            }
        }).catch(error => {
            this.props.clearLoading();
            this.setState(prevState => produce(prevState, draft => {
                draft.error.otp = error.message;
            }))
        })
    }

    handleResumeApplication = () => {
        if (this.state.values.otp == "") {
            this.setState(prevState => produce(prevState, draft => {
                draft.error["otp"] = 'Please enter OTP';
            }))
        } else if (this.state.values.otp.length < otpLength) {
            this.setState(prevState => produce(prevState, draft => {
                draft.error["otp"] = `OTP must be of ${otpLength} digits`;
            }))
        } else {
            this.resumeApplication();
        }
    }

    render() {

        console.log("City=", this.state.values.city, "Available city=", this.state.availableCities, "fetchedCity=", this.state.values.fetchedCity);

        let cities = this.state.availableCities.map(({ CITY_CODE, CITY_NAME }) => ({ label: CITY_NAME, value: CITY_CODE }));
        console.log('cities', cities);

        let auRm = this.props.auRm.map(auRmObj => ({
            label: auRmObj.BankEmployeeName,
            value: auRmObj.BankEmployeeCode
        }));


        // BA user partner code logic
        let baDiyUser = (this.props.user.applicationType === userType.pp) && this.state.isDIY;


        return (
            <>
                {/* {this.state.showMobileOtpPopup && */}
                <RegMobileOtpBottomSheet
                    handlePopupClose={this.handlePopupClose}
                    handleResendOTP={this.handleMobileResendOTP}
                    handleResumeApplication={this.handleResumeApplication}
                    otpValue={this.state.values.otp}
                    otpError={this.state.error.otp}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputBlur}
                    showOtpTimer={this.state.showTimer}
                    otpTimer={this.state.otpTimer}
                    disableResendButton={this.state.disableResendButton}
                />
                {/* } */}

                {/* <div class="nformbox"> */}

                <div className="opendtext f22 semibold mobf18">
                    {this.props.user.intrestedIN == "MFD" ? "Open Mutual Fund Account" : this.props.user.intrestedIN === "EQUITY_MFD" ? "Open Instant Demat Account" : (this.props.showMFDReg || this.props.showMFDOtp) ? "Open Mutual Fund Account" : "Open Instant Demat Account"}
                </div>
                <div className="mtop20" />

                {/* <div class="langtxt">Language</div>

                    <RegLanguageSelection
                        onLanguageSelection={this.handleLanguageSelection}
                        selectedLanguage={this.state.selectedLanguage}
                    /> */}

                <div class="mtop20"></div>
                <form action="#">

                    {this.props.showFullName && <RegInput
                        name={"fullName"}
                        value={this.state.values.fullName}
                        error={this.state.error.fullName}
                        maxLength={60}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                        type={"text"}
                        className={"mt-2"}
                        customDobClass={"focus-field"}
                        placeholder={"Enter your Full Name as per PAN"}
                        disabled={this.state.isDIY && this.state.disableFullNameForDiy ? true : false}
                    // Label={"Full Name"}
                    />}

                    {this.props.showMobile && <RegMobileNo
                        name={"mobileNo"}
                        value={this.state.values.mobileNo}
                        error={this.state.error.mobileNo}

                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}

                        placeholder={"Enter your Mobile Number"}
                        disabled={(this.state.isDIY && this.state.disableMobileForDiy) || this.state.IsTrueCallerUser ? true : false}
                        onClick={isAndroid && this.state.showTrueCallerPopup && this.handleMobileClick}
                    // Label={"Mobile Number"}
                    />}

                    {this.props.user.applicationType === userType.direct &&
                        <>
                            <RegInput
                                name={"referralCode"}
                                value={this.state.values.referralCode}
                                error={this.state.error.referralCode}
                                maxLength={8}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                type={"text"}
                                className={"mt-2"}
                                placeholder={"Enter Referral Code (Optional)"}
                                disabled={this.state.disableReferralCode}
                            // Label={"Referral Code (Optional)"}
                            />
                            <div className="reg-message">{this.state.referralCodeMsg}</div>
                        </>
                    }

                    {
                        (this.props.user.applicationType === userType.direct || this.props.user.applicationType === userType.crm) &&
                        (this.state.showCity) &&
                        <RegSelect
                            name={'city'}
                            value={this.state.values.city}
                            error={this.state.error.city}
                            options={cities}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            placeholder={'Select city'}
                        />
                    }

                    {
                        auRm.length > 0 &&
                        <RegSelect
                            name={'auRm'}
                            value={this.state.values.auRm}
                            error={this.state.error.auRm}
                            options={auRm}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            placeholder={'Select employee code'}
                        />
                    }

                    {
                        ((this.props.user.applicationType === userType.pp) || baDiyUser)
                        &&
                        // (

                        this.state.values.showPartner &&
                        // ?
                        <RegInput
                            name={"partnerCode"}
                            value={this.state.values.partnerCode}
                            error={this.state.error.partnerCode}

                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                            type="text"
                            maxLength={20}
                            placeholder="Enter Partner Code"
                            disabled={this.state.isDIY && this.state.disablePartnerCodeForDiy}
                        // disabled={true}
                        />
                        // :
                        // <div>
                        //     <RegOrangeText
                        //         className={'font14 partner-text pointer'}
                        //         label={"Got a partner code?"}
                        //         onClick={this.showPartnerCode}
                        //     />
                        // </div>)
                    }

                    {/* <RegButton
                        name={"openAccount"}
                        onClick={this.handleOpenAccount}
                        label={this.props.user.strPSource == "LIVEPHOTO" ? 'PROCEED' : 'Proceed'}
                        outerContainerClassName={"desk"}
                    /> */}

                    {/* <div class="text-center desk semibold">
                        <div class="cta dark proceed" onClick={this.handleOpenAccount}>
                            Proceed
                        </div>
                    </div> */}
                    <p className="f14 mobf12 greycolor">
                        By proceeding you’re agree to our{" "}
                        <a href="https://www.motilaloswal.com/terms-and-conditions" target="_blank" className="orangecolor f14 mobf12 ">
                            T&amp;C
                        </a>
                    </p>

                    <div className="text-center semibold">
                        <a className={this.props.user.intrestedIN == "MFD" && isMobile ? "cta newcta f14 mtop15 fontsize13" : (this.props.showMFDReg || this.props.showMFDOtp) && isMobile ? "cta newcta f14 mtop15 fontsize13" : "cta newcta f14 mtop15"} onClick={this.handleOpenAccount}>
                            {this.props.user.intrestedIN == "MFD" ? "Open Mutual Fund Account" : this.props.user.intrestedIN === "EQUITY_MFD" ? "OPEN DEMAT ACCOUNT" : (this.props.showMFDReg || this.props.showMFDOtp) ? "Open Mutual Fund Account" : "OPEN DEMAT ACCOUNT"}
                            <svg
                                width={15}
                                height={10}
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.16667 10L8 8.79167L10.9583 5.83333H0.833336V4.16667H10.9583L8 1.20833L9.16667 0L14.1667 5L9.16667 10Z"
                                    fill="#0E0F11"
                                />
                            </svg>
                        </a>
                    </div>

                    {this.state.showCheckBoxes &&
                        <>
                            <div class="mtop20"></div>
                            {
                                !this.props.user.showReferralBanner &&
                                this.props.user.applicationType === userType.direct &&
                                <RegCheckbox
                                    name={"isSouthIndian"}
                                    checked={this.state.values.isSouthIndian}
                                    onChange={this.handleInputChange}
                                    // Label={"I prefer South Indian language"}
                                    Label={"I wish to talk in South Indian language"}
                                />
                            }
                            <div class="mtop10"></div>
                            <RegCheckbox
                                name={"isPolicyAccepted"}
                                checked={this.state.values.isPolicyAccepted}
                                // onChange={this.handleInputChange}    // no change
                                Label={this.props.user.intrestedIN == "MFD" ? "I would like to open Mutual Fund Investment Account with Motilal Oswal Financial Services Ltd(MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications for any offerings on my registered mobile number through Call, email, SMS, Whatsapp or any other medium even if I'm registered under DND." : this.props.user.intrestedIN === "EQUITY_MFD" ? "I would like to open Trading and Demat Account with Motilal Oswal Financial Services Ltd (MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications for any offerings on my registered mobile number through Call, email, SMS, Whatsapp or any other medium even if I'm registered under DND."
                                    : (this.props.showMFDReg || this.props.showMFDOtp) ? "I would like to open Mutual Fund Investment Account with Motilal Oswal Financial Services Ltd(MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications for any offerings on my registered mobile number through Call, email, SMS, Whatsapp or any other medium even if I'm registered under DND." : "I would like to open Trading and Demat Account with Motilal Oswal Financial Services Ltd (MOFSL) and give my consent to open the same through online mode. I'm ok with MOFSL sending me promotional communications for any offerings on my registered mobile number through Call, email, SMS, Whatsapp or any other medium even if I'm registered under DND."}
                            />
                        </>
                    }

                    {/* <div class="mtop10"></div>

                        <RegCheckbox
                            name={"TnC"}
                            checked={this.state.values.TnC}
                            // onChange={this.handleInputChange}    // no change
                            Label={"I hereby agree to enclosed Terms & Condition for opening of Trading / Demat Account"}
                            handleLabelClick={this.handleLabelClick}
                        /> */}

                    {this.state.error.apiResponse !== "" &&
                        <>
                            <div class="mtop10"></div>
                            <div className={'reg-error'}>{this.state.error.apiResponse}</div>
                        </>
                    }

                    <div class="mtop10"></div>

                    {/* Mobile Proceed */}
                </form>

                {this.state.error.apiResponse &&
                    <ErrorPopup
                        isShow={this.state.isShowErrorPopup}
                        errorMsg={this.state.error.apiResponse}
                        closeModal={this.closeErrorPopup}
                    />
                }
                {this.state.openPartnerDetailDialog &&
                    <PartnerDetailsPopup
                        open={this.state.openPartnerDetailDialog}
                        onEdit={this.handleBADetailsEdit}
                        closeModal={this.handleBADetailsClose}
                        baDetails={this.state.baDetails}
                    />
                }

                {/* </div> */}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
    resendOtp: (data) => fetchWithWait({ dispatch, action: resendOtp(data) }),
    setLoading: (data) => dispatch(setLoading(data)),
    clearLoading: () => dispatch(clearLoading()),
    setResponseErrorSnack: (payload) => dispatch(setResponseErrorSnack(payload))
})

const mapStateToProps = (state) => ({
    user: state.user,
    auRm: state.auRmDetails.auRm,
    analytics: state.analytics
})

export default connect(mapStateToProps, mapDispatchToProps)(RegOpenAccountForm);
import React, { Component } from 'react';

class RegInfo extends Component {
    render() {
        return (

            <>
                <div class="mtop20"></div>
                <div class="text-center">
                    <div class="conbox">
                        <h2>
                            WHAT IS A DEMAT ACCOUNT?
                        </h2>
                    </div>
                </div>
                <div class="mtop20"></div>


                <p class="justify colorp">Just like a bank account that safeguards all your liquid cash, an online Demat Account holds all your financial securities in safe custody. When you open a Demat Account with any SEBI registered stock broker and do any transaction such as buying a share, the shares bought are held in the depositories. There are two functional depositories in India namely - the Central Depository Services Limited aka CDSL and the National Depository Services Limited or NDSL. These depositories hold shares in an electronic format, in your dematerialised account. You can hold equities, <a href="https://www.motilaloswal.com/mutual-fund-investments" target="_blank" class="hyperlinkcol">mutual funds,</a> <a href="https://www.motilaloswal.com/invest-in-bonds" target="_blank" class="hyperlinkcol">bonds</a>, exchange traded funds (ETFs) and <a href="https://www.motilaloswal.com/invest-in-upcoming-ipo" target="_blank" class="hyperlinkcol">invest in IPOs</a> (initial public offerings) seamlessly in one-click through your <a href="#">Demat Account</a>.</p><div class="mtop20"></div>

                <p class="justify colorp">One of the most highlighting benefits of having an online demat account is - paperless receipts. The dematerialisation of receipts has made it easy for investors and traders to trade at real-time. There is no stress of keeping hold of thousands of receipts and producing them each time you want to trade and invest in the stock market. You can buy shares and hold them for a long period of time in electronic format in your demat account. The dashboard helps you to track your positions 24 by 7. Intraday trading has now become a profession thanks to real-time execution of the buy and sell orders. By opening an online Demat Account you can scale your investments and diversify your portfolio by investing in a variety of stock market trading products ranging from equities to non-convertible debentures (NCDs).
                    </p>
            </>
        )
    }
}
export default RegInfo;
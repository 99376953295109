import PaymentApi from "../services/paymentApi";
import { put } from 'redux-saga/effects';
import { PAYMENT_STATUS_RESPONSE, PAYMENT_STATUS_FAIL, SEND_CHEQUE_APPROVAL_RESPONSE, SEND_CHEQUE_APPROVAL_FAIL, REQUEST_CHEQUE_STATUS_RESPONSE, REQUEST_CHEQUE_STATUS_FAIL } from '../redux/types/payment';
import Cookie from '../lib/cookies';
import { apiErrorResponse } from '../helper/constant'
import { RESPONSE_ERROR_SNACK_OPEN } from '../redux/types/responseError'
import { setLoading, clearLoading } from "../redux/actions/loader";

let api = new PaymentApi();
let cookie = new Cookie();

export function* paymentInit({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        yield put(setLoading());
        let response = yield api.paymentInit(payload)
        yield put(clearLoading());
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* paymentStatus({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.paymentStatus(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                responsePayload.commodityPaymentStatus = response.data.Data.PAYMENTSTATUS[0].COMMPAYMENTSTATUS ? response.data.Data.PAYMENTSTATUS[0].COMMPAYMENTSTATUS : "";
                responsePayload.equityPaymentStatus = response.data.Data.PAYMENTSTATUS[0].EQPAYMENTSTATUS ? response.data.Data.PAYMENTSTATUS[0].EQPAYMENTSTATUS : "";

                cookie.removeCookie('pInit');

                yield put({ type: PAYMENT_STATUS_RESPONSE, payload: responsePayload })

                resolve && resolve(responsePayload);

            } else {

                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

            }
        }
        else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {

        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })

    }
}
export function* sendChequeApproval({ type, payload, resolve, reject }) {
    let responsePayload = {};
    try {
        let response = yield api.sendChequeApproval(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                responsePayload.sendChequeApproval = response.data.Data.FUNDTRANSFER[0].Response ? response.data.Data.FUNDTRANSFER[0].Response : "";
                yield put({ type: SEND_CHEQUE_APPROVAL_RESPONSE, payload: responsePayload })
                resolve && resolve();
            }
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* requestChequeStatus({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.requestChequeStatus(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                responsePayload.requestChequeStatus = response.data.Data.FUNDTRANSFER[0].Response ? response.data.Data.FUNDTRANSFER[0].Response : "";
                yield put({ type: REQUEST_CHEQUE_STATUS_RESPONSE, payload: responsePayload })
                resolve && resolve();
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* skipOrChequePayment({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.skipOrChequePayment(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getFundTransferDetails({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.getFundTransferDetails(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data.FUNDTRANSFER[0]);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getSchemeDetailsDirect({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.getSchemeDetailsDirect(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* validateDirectLogin({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.validateDirectLogin(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* insertUpdateSelectedSegment({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.insertUpdateSelectedSegment(payload)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                responsePayload = { type: "error", message: response.data.Data.Message }
                yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
            }
        } else {
            yield put(clearLoading());
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put(clearLoading());
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

import React from 'react'
import classNames from "classnames";

function RegFAQ({ question, answer, id, expandedItemId, handleToggle }) {
    console.log("expandedItemId===", id, expandedItemId, typeof (id), typeof (expandedItemId));
    // let htmlAnswer = function createMarkup() {
    //     return { __html: answer };
    // }
    // console.log("htmlAnswer==",htmlAnswer);
    return (
        <>
            <div class="tab">
                <input name="hometabs" id={id} checked={id === expandedItemId ? true : false} type="radio" onClick={() => handleToggle(id)} />
                {/* <label class="tab-label" for={id} onClick={() => handleToggle(id)}>{question}</label > */}
                <h3 class="tab-label" for={id} onClick={() => handleToggle(id)}>{question}</h3 >
                <div class={"tab-content"} dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
        </>
    )
}
export default RegFAQ;
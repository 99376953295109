import React from 'react';
import { showSnackBar } from '../../../lib/snackBar';
import { connect } from 'react-redux';
import { setResponseErrorSnack, closeResponseErrorSnack } from '../../../redux/actions/responseError';

const GlobalSnackbar = (props) => {
    console.log('props in global snackbar', props)

    function closeSnackbar() {
        props.closeResponseErrorSnack({ type: "", message: "", hideAfterSec: "" })
    }


    return (
        <div>
            {props.snackbarMessage &&
                showSnackBar({
                    type: props.snackbarType,
                    message: props.snackbarMessage,
                    hideAfterSec: props.snackbarHideAfter,
                    closeSnackbar: () => closeSnackbar()
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    snackbarType: state.responseError.responseErrorSnack.type,
    snackbarMessage: state.responseError.responseErrorSnack.message,
    snackbarHideAfter: state.responseError.responseErrorSnack.hideAfterSec
})

const mapDispatchToProps = (dispatch) => ({
    setResponseErrorSnack: (payload) => dispatch(setResponseErrorSnack(payload)),
    closeResponseErrorSnack: (payload) => dispatch(closeResponseErrorSnack(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSnackbar);
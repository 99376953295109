import React, { Component } from 'react';

class RegSection6 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <section className="sectionpad ptop0 oyo-multiplewyas-outer">
        <div className="text-center">
          <h2 className="textlabel">
            <span className="f32 line1 mobf24 lightgrey font-regular">
              One Demat Account
            </span>
            <div>
              <span className="f30 semibold mobf24"> {" "}Multiple Benefits {" "}</span>
            </div>
            <div className="mtop10" />
            <p className="semibold mobf14">
              Choose from a diverse range of products to invest in, starting from
              ₹500* only!
            </p>
          </h2>
        </div>
        <div className="mtop40" />
        <div className="whitebbox">
          <div>
            <div className="col12">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Equity/Stocks:- Discover the ease of investing in top companies
                    with expert research
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    F&amp;O:- Trade at a lightning speed powered by expert minds and
                    AI-fueled algorithms&nbsp;
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Mutual Fund:- Invest in your future, one fund at a time, with{" "}
                    <a
                      href="https://www.motilaloswal.com/invest-in-mutual-fund"
                      className="seohreflink f14"
                      target="_blank"
                    >
                      Mutual Funds
                    </a>{" "}that matter
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Upcoming IPO:- Invest early, invest smart with Pre-apply 
                    {" "}
                    <a
                      href="https://www.motilaloswal.com/ipo/upcoming-ipo"
                      className="seohreflink f14"
                      target="_blank"
                    >
                     IPO Application
                    </a>{" "}
                    <br />
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    IAP:- Expert curated, AI-powered stock portfolio for better
                    returns
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Start a SIP :- Start small SIPs to treat yourself with a good life ahead
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Commodity:-  Trade across multiple
                    {" "}
                    <a
                      href="https://www.motilaloswal.com/invest-in-commodities-market"
                      className="seohreflink f14"
                      target="_blank"
                    >
                   Commodities
                    </a>{" "}(Agri, Bullion, Metal, Energy)
                    <br />
                  </span>
                </div>
              </div>
            </div>
            <div className="col12 mtop20">
              <div className="content">
                <div className="mtop4">
                  <span className="f14 color8A93A0">
                    Currency:-  Trade in 8+ 
                    {" "}
                    <a
                      href="https://www.motilaloswal.com/invest-in-currency-and-forex-trading"
                      className="seohreflink f14"
                      target="_blank"
                    >
                   Currencies
                    </a>{" "} and explore the global economy strategically&nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}

export default RegSection6;
import React from 'react'
import Input from '../../atom/input'

export const RegCheckbox = (props) => {
    return (
        <div class="control-group customCheckBox">
            <label class="control control-checkbox">
                {/* {props.Label} */}
                <Input type="checkbox" {...props} />
                <div class="control_indicator"></div>
            </label>
            <span onClick={props.handleLabelClick}> {props.Label}</span>
        </div>
    )
}

import React, { Component, useState } from 'react';


const MFDSection14 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <section className="sectionpad ptop0">
                <div className="whatisproduct-sec">
                    <div className="cms">
                        <h2>
                            <div className="text-center line1">
                                <span className="lightgrey f32 font-semi-bold mobf24">
                                    What are different types of
                                </span>
                            </div>
                            <div className="text-center line1">
                                <span className="f32 font-semi-bold mobf24">Mutual Funds?</span>
                            </div>
                        </h2>
                        <div className="mtop30" />
                        <p>
                            Different types of Mutual funds include equity funds, debt funds, hybrid
                            funds, theme-based (sectoral) funds, index funds, Tax-saver (ELSS) funds
                            etc. Different types of Mutual Funds make it possible for everyone to
                            invest as per their risk appetite and return expectations.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Equity Funds</div>
                        <p>
                            {" "}
                            Equity funds are those which primarily invest in stocks or equities. The
                            goal is to achieve capital appreciation over the long term. They offer
                            the potential for higher returns but also come with higher risk due to
                            stock market volatility.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Debt Funds</div>
                        <p>
                            {" "}
                            Debt funds are those which invest in fixed-income securities such as
                            government bonds, corporate bonds, and municipal bonds. They are known
                            for providing regular interest income. They are typically considered to
                            have lower risk compared to equity funds.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Money Market Funds</div>
                        <p>
                            {" "}
                            Money market funds are the type of mutual funds which invest in
                            short-term, highly liquid, and low-risk securities like Treasury bills
                            and commercial paper. These funds focus on preserving capital and
                            providing safety and liquidity. They are a low-risk option for parking
                            funds.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Hybrid Funds</div>
                        <p>
                            {" "}
                            Hybrid funds, also known as balanced funds, are the type of mutual funds
                            which combine investments in both stocks (equities) and bonds (fixed
                            income). They aim to strike a balance between growth and income, making
                            them suitable for investors seeking a middle-ground approach.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">Index Funds</div>
                        <p>
                            Index funds are those funds which aim to replicate the performance of a
                            specific market index, like the BSE S&amp;P 500 or the Nifty 50. These
                            funds typically hold the same stocks in the same proportions as the
                            index they track. They are known for their low expenses and aim to match
                            the returns of the underlying index.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">
                            Sector/Theme-based Funds
                        </div>
                        <p>
                            Sector funds, also known as theme-based funds, are those types of Mutual
                            Funds which invest in a specific industry or sector of the economy, such
                            as technology, healthcare, or energy. They provide an opportunity to
                            focus on a particular area of interest or expertise, but they can be
                            riskier due to their lack of diversification.
                        </p>
                        <div className="mtop20" />
                        <div className="lightblack font-semi-bold f16">ELSS Funds</div>
                        <p>
                            {" "}
                            Equity Linked Savings Schemes (ELSS) are a category of mutual funds in
                            India that offer tax benefits under Section 80C of the Income Tax Act.
                            ELSS funds primarily invest in equities, providing the potential for
                            capital appreciation. They have a lock-in period of three years, the
                            shortest among all tax-saving instruments. ELSS funds are popular for
                            tax planning as they allow investors to save on taxes while
                            participating in the stock market, offering the dual benefit of
                            potential returns and tax deductions.
                        </p>
                        <div className="mtop20" />
                    </div>
                </div>
            </section>

        </>

    );
}

export default MFDSection14;

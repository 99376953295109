import React, { Component, useState } from 'react';
import image1 from '../../../assets/images/mfdregscreen/handimg-1.jpg'
import image2 from '../../../assets/images/mfdregscreen/handimg-2.jpg'
import image3 from '../../../assets/images/mfdregscreen/handimg-3.jpg'
import image4 from '../../../assets/images/mfdregscreen/handimg-4.jpg'



const MFDSection1 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    return (
        <>
            <h1 className="f50 mobf28 semibold">
                <span className="lightgrey f50 mobf28">Handpicked Mutual Funds</span>
                 {""} for Everyone!
            </h1>
            {/* <small class="lightgrey mobf12">(Winner of Best Domestic Brokers 2023 - Asia Money)
                     </small> */}
            <div className="mtop30 inholder">
                <div className="dflex spacebetween mobwrap">
                    <div className="dflex aligncenter d48 mobflex100">
                        <div className="iconbx">
                            <picture>
                                <source
                                    srcSet="https://dbs7qpzv4mcv.cloudfront.net/img-2.webp"
                                    type="image/webp"
                                />
                                <img src={image1} alt="" />
                            </picture>
                        </div>
                        <div>
                            <h3 className="f16 lightgrey mobf14 font-regular">
                                40L+ Investors&nbsp;
                            </h3>
                        </div>
                    </div>
                    <div className="dflex aligncenter d48 mobflex100 mobmtop10">
                        <div className="iconbx">
                            <picture>
                                <source
                                    srcSet="https://dbs7qpzv4mcv.cloudfront.net/img04.webp"
                                    type="image/webp"
                                />
                                <img src={image2} alt="" />
                            </picture>
                        </div>
                        <div>
                            <h3 className="f16 lightgrey mobf14 font-regular">
                                Simplified SIPs through UPI Mandate
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="dflex spacebetween mtop20 mobwrap mobmtop10">
                    <div className="dflex aligncenter d48 mobflex100">
                        <div className="iconbx">
                            <picture>
                                <source
                                    srcSet="https://dbs7qpzv4mcv.cloudfront.net/img-3.webp"
                                    type="image/webp"
                                />
                                <img src={image3} alt="" />
                            </picture>
                        </div>
                        <div>
                            <h3 className="f16 lightgrey mobf14 font-regular">
                                Single-click Lumpsum Investments
                            </h3>
                        </div>
                    </div>
                    <div className="dflex aligncenter d48 mobflex100 mobmtop10">
                        <div className="iconbx">
                            <picture>
                                <source
                                    srcSet="https://dbs7qpzv4mcv.cloudfront.net/Industry.webp"
                                    type="image/webp"
                                />
                                <img
                                    src={image4}
                                    alt=""
                                />
                            </picture>
                        </div>
                        <div>
                            <h3 className="f16 lightgrey mobf14 font-regular">
                                Over 250 top rated mutual funds to choose from
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default MFDSection1;

import React, { Component } from 'react';
import Slider from "react-slick";
import $ from "jquery";
import appstore from '../../../assets/images/regscreen/appstore.jpg'
import playstore from '../../../assets/images/regscreen/playstore.jpg'
import investor from '../../../assets/images/regscreen/investor.png'
import trader from '../../../assets/images/regscreen/MOTrader.png'
import research from '../../../assets/images/regscreen/Research360.png'
import options from '../../../assets/images/regscreen/OptionsStore.png'
import options1 from '../../../assets/images/regscreen/option-store-png.png'
import edumo from '../../../assets/images/regscreen/edumo-2.png'

class RegSection3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        const settings = {
            infinite: true,
            slidesToShow: 2,
            dots: true,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 1024, // 1024px or larger
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 768, // 768px or larger
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480, // 480px or larger
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
    
        return (
            <section className="sectionpad">
                <div className="text-center">
                    <h2 className="textlabel">
                        <span className="f32 line1 mobf24 lightgrey font-regular">
                            Complete Digital Process
                        </span>
                        <div>
                            <span className="f30 semibold mobf22">Steps to start investing</span>
                        </div>
                    </h2>
                </div>
                <div className="mtop60" />
                <div className="slider">
                <Slider {...settings}>
                    <div>
                        <div className="whiteslide text-center">
                            <div>
                                <div>
                                    <span className="f18 font-semi-bold">Rise Investor</span>
                                </div>
                                <div>
                                    Smart Investing powered by expert research and fastest order
                                    placement
                                </div>
                            </div>
                            <div className="appimageholder">
                                <div className="home-turbocharge-img">
                                    <a href="https://apps.apple.com/in/app/mo-trader-share-trading-app/id1257014690">
                                        <picture>
                                            {/* WebP source */}
                                            <source
                                               src={investor}
                                                type="image/webp"
                                            />
                                            {/* Fallback for browsers that do not support WebP */}
                                            <img
                                                src={investor}
                                                alt=""
                                                className="tns-complete"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="greyslide">
                            <div className="dflex spacebetween applogoholder">
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/motilal-oswal-demat-stocks/id1055577451"
                                >
                                    <img
                                        alt=""
                                        src={appstore}
                                        className="tns-complete"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile"
                                >
                                    <img
                                        alt=""
                                        src={playstore}
                                        className="tns-complete"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="whiteslide text-center">
                            <div>
                                <div>
                                    <span className="f18 font-semi-bold">Rise Trader</span>
                                </div>
                                <div>
                                    Lightning fast trading experience with advanced technical tools
                                </div>
                            </div>
                            <div className="appimageholder">
                                <div className="home-turbocharge-img">
                                    <a href="https://play.google.com/store/apps/details?id=mosl.powerapp.com">
                                        <picture>
                                            {/* WebP source */}
                                            <source  src={trader} />
                                            {/* Fallback for browsers that do not support WebP */}
                                            <img
                                                src={trader}
                                                alt=""
                                                className="tns-complete"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="greyslide">
                            <div className="dflex spacebetween applogoholder">
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/motilal-oswal-demat-stocks/id1055577451"
                                >
                                    <img
                                        alt=""
                                        src={appstore}
                                        className="tns-complete"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile"
                                >
                                    <img
                                        alt=""
                                        src={playstore}
                                        className="tns-complete"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="whiteslide text-center">
                            <div>
                                <div>
                                    <span className="f18 font-semi-bold">Research 360</span>
                                </div>
                                <div>Real time market data &amp; research tools</div>
                            </div>
                            <div className="appimageholder">
                                <div className="home-turbocharge-img">
                                    <a href="https://play.google.com/store/apps/details?id=mosl.powerapp.com">
                                        <picture>
                                            {/* WebP source */}
                                            <source src={research} />
                                            {/* Fallback for browsers that do not support WebP */}
                                            <img
                                                src={research}
                                                alt=""
                                                className="tns-complete"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="greyslide">
                            <div className="dflex spacebetween applogoholder">
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/research-360-screener-ipo/id1633206960"
                                >
                                    <img
                                        alt=""
                                        src={appstore}
                                        className="tns-complete"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.mosl.research360app"
                                >
                                    <img
                                        alt=""
                                        src={playstore}
                                        className="tns-complete"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="whiteslide text-center">
                            <div>
                                <div>
                                    <span className="f18 font-semi-bold">Trading View Platform</span>
                                </div>
                                <div>
                                    Upgrade your chart analysis with 150+ indicators, Stock Screeners,
                                    Community Access
                                </div>
                            </div>
                            <div className="appimageholder">
                                <div>
                                    <div className="home-turbocharge-img tradingview-img-outer">
                                        <div style={{ display: "none" }}>
                                            <a href="https://apps.apple.com/in/app/mo-investor/id1055577451">
                                                <picture>
                                                    {/* WebP source */}
                                                    <source src={options} />
                                                    {/* Fallback for browsers that do not support WebP */}
                                                    <img
                                                        src={options}
                                                        alt=""
                                                        className="tns-complete"
                                                    />
                                                </picture>
                                            </a>
                                        </div>
                                        <div className="risetrader-imgouter">
                                            <a href="https://apps.apple.com/in/app/mo-investor/id1055577451">
                                                <picture>
                                                    {/* WebP source */}
                                                    <source  src={options1} />
                                                    {/* Fallback for browsers that do not support WebP */}
                                                    <img
                                                        src={options1}
                                                        alt=""
                                                        className="tns-complete"
                                                    />
                                                </picture>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="greyslide explorernow-outerdiv">
                            <div
                                className="dflex spacebetween applogoholder "
                                style={{ display: "none" }}
                            >
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/motilal-oswal-demat-stocks/id1055577451"
                                >
                                    <img
                                        alt=""
                                        src={appstore}
                                        className="tns-complete"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile"
                                >
                                    <img
                                        alt=""
                                        target="target"
                                        src={playstore}
                                        className="tns-complete"
                                    />
                                </a>
                            </div>
                            <div className="dflex applogoholder traplatform-outer">
                                <a
                                    target="_blank"
                                    href="https://invest.motilaloswal.com/TradingView/TradingView.html"
                                >
                                    <span className="text-uppercase">EXPLORE</span>
                                </a>
                                <img
                                    className="ThemeGrid_MarginGutter tns-complete"
                                    alt=""
                                    src="https://monextdev.motilaloswal.com/MO_Website_CW/img/arrow_right_alt_Sharp_Filled.png?127253"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="whiteslide text-center">
                            <div>
                                <div>
                                    <span className="f18 font-semi-bold">EduMO</span>
                                </div>
                                <div>
                                    Stock market education portal by Motilal Oswal
                                    <br />
                                </div>
                            </div>
                            <div className="appimageholder">
                                <div className="home-turbocharge-img">
                                    <a href="https://apps.apple.com/in/app/mo-trader-share-trading-app/id1257014690">
                                        <picture>
                                            {/* WebP source */}
                                            <source
                                                srcSet={edumo}
                                                type="image/webp"
                                            />
                                            {/* Fallback for browsers that do not support WebP */}
                                            <img
                                                srcSet={edumo}
                                                alt=""
                                                className="tns-complete"
                                            />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="greyslide">
                            <div className="dflex spacebetween applogoholder">
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/motilal-oswal-demat-stocks/id1055577451"
                                >
                                    <img
                                        alt=""
                                        src={appstore}
                                        className="tns-complete"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.mosl.mobile"
                                >
                                    <img
                                        alt=""
                                        src={playstore}
                                        className="tns-complete"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    </Slider>
                </div>
            </section>

        );
    }
}

export default RegSection3;

import React from 'react'
import Image from '../../atom/image';
import star from '../../../assets/images/star-icon.svg';
import halfStar from '../../../assets/images/half-star-icon.svg';

const RegReviewCard = ({ image, name, title, description, stars }) => {
    return (
         <>
         <div className="customer-review-card">
             <div className="customer-review-card-wrapper">
                <div className="customer-review-identity">
                    <Image className="customer-review-img" src={image} alt="" />
                    <div className="customer-review-stars-container">
                        <span>{name}</span>
                        <div className="rating">
                            <a href="javascript:void(0);" className="star"><Image className="customer-review-star" src={star} title="" alt="" /></a>
                            <a href="javascript:void(0);" className="star"><Image className="customer-review-star" src={star} title="" alt="" /></a>
                            <a href="javascript:void(0);" className="star"><Image className="customer-review-star" src={star} title="" alt="" /></a>
                            <a href="javascript:void(0);" className="star"><Image className="customer-review-star" src={star} title="" alt="" /></a>
                            <a href="javascript:void(0);" className="star"><Image className="customer-review-star" src={halfStar} title="" alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className="customer-review-container">
                    <h4 className="customer-review-title">{title}</h4>
                    <p className="customer-review-description">{description}</p>
                </div>
             </div>
         </div>
</>
    )
}
export default RegReviewCard;
import React, { Component, useEffect, useState } from 'react';
import $ from "jquery";
import staricon from '../../../assets/images/mfdregscreen/Framelogo.svg'


const MFDSection3 = (props) => {
    const [state, setState] = useState({
        // initial state properties
    });

    const [check, setCheck] = useState("Popular");



    useEffect(() => {

    }, [])


    const openCity = (evt) => {
        setCheck(evt)

    }


    return (
        <>
            <section className="sectionpad ptop0">
                <div className="">
                    <div className="text-center">
                        <h2 className="textlabel">
                            <span className="f32 line1 mobf24 lightgrey semibold">
                                Top Mutual Funds
                            </span>
                            <div>
                                <span className="f32 semibold mobf24">for you</span>
                            </div>
                        </h2>
                    </div>
                    <div className="mtop30" />
                    <div className="top-funds-sec">
                        <div className="tab">
                            <button
                                className={check == "Popular" ? "tablinks active" : "tablinks"}
                                onClick={() => openCity('Popular')}
                            >
                                Most Popular
                            </button>
                            <button className={check == "recommend" ? "tablinks active" : "tablinks"}

                                onClick={() => openCity('recommend')}>
                                MO Recommended
                            </button>
                            <button className={check == "high-fund" ? "tablinks active" : "tablinks"}
                                onClick={() => openCity('high-fund')}>
                                Highest Rated Funds
                            </button>
                            <button className={check == "equity" ? "tablinks active" : "tablinks"}
                                onClick={() => openCity('equity')}>
                                Equity Based
                            </button>
                        </div>


                        <div id="Popular" className={check == "Popular" ? "tabcontentshow" : "tabcontent"}>
                            <div className="dflex wrap spacebetween gap30">
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Nippon India Multi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Multi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">31.67%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 286.5578</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Flexi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Flexi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    26.49%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 1805.753
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Kotak Emerging Equity Fund Regular-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">25%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 126.165</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                SBI Small Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Small Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    24.07%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 174.2407
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Balanced Advantage Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Dynamic Asset Allocation</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">23.92%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 492.334</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                UTI Nifty 50 Index Fund Regular Plan-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Large Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    15.78%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 163.4981
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="recommend" className={check == "recommend" ? "tabcontentshow" : "tabcontent"}>
                            <div className="dflex wrap spacebetween gap30">
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                    HDFC Mid-Cap Opportunities Fund-Growth
                                                </span>
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">29.48%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 178.366</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Flexi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Flexi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    26.49%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 1805.753
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Kotak Emerging Equity Fund Regular-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">25%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 126.165</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                SBI Small Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Small Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    24.07%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 174.2407
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Balanced Advantage Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Dynamic Asset Allocation</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">23.92%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 492.334</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                UTI Nifty 50 Index Fund Regular Plan-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Large Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    15.78%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 163.4981
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="high-fund" className={check == "high-fund" ? "tabcontentshow" : "tabcontent"}>
                            <div className="dflex wrap spacebetween gap30">
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Nippon India Multi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Multi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">31.67%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 286.5578</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Flexi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Flexi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    26.49%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 1805.753
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Kotak Emerging Equity Fund Regular-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">25%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 126.165</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                SBI Small Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Small Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    24.07%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 174.2407
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Balanced Advantage Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Dynamic Asset Allocation</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">23.92%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 492.334</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                UTI Nifty 50 Index Fund Regular Plan-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Large Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    15.78%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 163.4981
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="equity" className={check == "equity" ? "tabcontentshow" : "tabcontent"}>
                            <div className="dflex wrap spacebetween gap30">
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                    HDFC Mid-Cap Opportunities Fund-Growth
                                                </span>
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">29.48%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 178.366</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Flexi Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Flexi Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    26.49%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 1805.753
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                Kotak Emerging Equity Fund Regular-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Mid Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">25%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 126.165</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                SBI Small Cap Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Small Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    24.07%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 174.2407
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="semibold f18 two-text-ellipses hei60 mobf16">
                                                HDFC Balanced Advantage Fund-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">-</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Dynamic Asset Allocation</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor semibold  text-align-left">
                                                <span className="semibold f20 greencolor mobf20">23.92%</span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="semibold f16 mobf14">NAV - 492.334</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="whitegradientbox d48">
                                    <a href="">
                                        <div className="dflex spacebetween aligncenter align-flex-start">
                                            <span className="font-semi-bold f18 two-text-ellipses hei60 mobf16">
                                                UTI Nifty 50 Index Fund Regular Plan-Growth
                                            </span>
                                            <div className="dflex ml20 aligncenter">
                                                <div className="display-contents custimgwrap">
                                                    <img
                                                        alt=""
                                                        src={staricon}
                                                    />
                                                </div>
                                                <div className="f18 mobf14 semibold">4</div>
                                            </div>
                                        </div>
                                        <div className="greylabel  text-uppercase mtop10 inlineblock">
                                            <span className="f12">Large Cap</span>
                                        </div>
                                        <div className="text-align-left lightgrey mtop10">
                                            <span className="f14 ">3 Yr CAGR</span>
                                        </div>
                                        <div className="dflex spacebetween  aligncenter">
                                            <div className="greencolor font-semi-bold  text-align-left">
                                                <span className="font-semi-bold f20 greencolor mobf20">
                                                    15.78%
                                                </span>
                                            </div>
                                            <div className="text-align-left">
                                                <span className="font-semi-bold f16 mobf14">
                                                    NAV - 163.4981
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mtop20">
                        <div className="inputholder">
                            <a href=""></a>
                            <div className="Button getstartedcta1">
                                <a href=""></a>
                                <div className="dflex aligncenter spacebetween">
                                    <a href="">
                                        <span className="f14 semibold">View More</span>
                                        <span className="ml10">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <mask
                                                    id="mask0_61_142"
                                                    style={{ maskType: "alpha" }}
                                                    maskUnits="userSpaceOnUse"
                                                    x={0}
                                                    y={0}
                                                    width={24}
                                                    height={24}
                                                >
                                                    <rect width={24} height={24} fill="#C4C4C4" />
                                                </mask>
                                                <g mask="url(#mask0_61_142)">
                                                    <path
                                                        d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z"
                                                        fill="#0E0F11"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="dflex mtop40 mobwrap mobmtop0">
                                  <div class="d33">
                                      <div class="priceblock text-center bright">
                                          <div class="dflex flex-direction-column aligncenter"><span
                                                  class="lightgrey">Options Trading&nbsp;</span><span
                                                  class="lightgrey">Opening Charges</span></div>
                                          <div><span class="f56 mtop10 semibold">₹0</span></div>
                                      </div>
                                  </div>
                                  <div class="d33">
                                      <div class="priceblock text-center bright">
                                          <div class="dflex flex-direction-column aligncenter"><span
                                                  class="lightgrey">AMC &amp; Admin</span><span
                                                  class="lightgrey">&nbsp;Charges for 1st Year</span></div>
                                          <div><span class="f56 mtop10 semibold">₹0</span></div>
                                      </div>
                                  </div>
                                  <div class="d33">
                                      <div class="priceblock text-center">
                                          <div class="dflex flex-direction-column aligncenter"><span
                                                  class="lightgrey">Charges for research</span><span
                                                  class="lightgrey">&nbsp;and dedicated personal advisor</span>
                                          </div>
                                          <div><span class="f56 mtop10 semibold">₹0</span></div>
                                      </div>
                                  </div>
                                  </div> */}
                    {/* <div class="text-center mtop20"><span class="f16 semibold">This month’s
                                  offer</span></div>
                                  <div class="mtop10"></div>
                                  <div class="offerblock mtop20">
                                  <div class="dflex spacebetween offerimg gap15">
                                  <div class="flex3"><span class="f28 font-bold mobf18">Zero Brokerage for 30
                                          Days</span>
                                      <div class="mobf14">Get discount upto ₹1000 on your first month’s
                                          brokerage</div>
                                  </div>
                                  <div class="flex1 text-right"><img class="mobml15" alt=""
                                          src="images/Localoffer2.png"></div>
                                  </div>
                                  <div class="mtop50 mobmtop20" style="display:none">
                                  <div><span class="text-uppercase semibold mr10">Details</span><span
                                          class="fa fa-fw fa-long-arrow-right"></span></div>
                                  </div>
                                  </div> */}
                </div>
            </section>

        </>

    );
}

export default MFDSection3;

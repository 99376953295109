import React from 'react';
import starIcon from '../../../assets/images/star-icon.svg';
import Download from '../../../assets/images/download.jpg';
import Image from '../../atom/image';

const CustomerReviewSjet = () => {
    return (
        <>
        <h2 class="conbox">WHAT OUR CUSTOMERS SAY</h2>
        <div><small>Reviews, comments from MO's customers and community</small></div>
        <div class="reviewbox">
          <div class="threeblock">
            <div class="blocks">
              <div class="borderbox">
                <h3 class="top head">Paperless Online Process</h3>
                <p>Opening a Demat Account with Motilal Oswal is easy. You don’t have to fill any forms and courier it. Online, hassle-free process!
  
                </p>
  
                <div class="nimbox">
                  {/* <div>
                      <Image src={Download} alt={''} width="" height="" />
                  </div> */}
                   <div><div class='sprite download'></div></div>
                  <div>
                    <div class="top">
                      Gagandeep Singh</div>
                    <div class="botm">
                    {/* <Image src={starIcon} alt={''} width="120px" height="25px" /> */}
                    <svg height="" width="">
<path class="st0" d="M17,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8L9.3,0.2C9.2,0,9,0,8.9,0c-0.1,0-0.1,0.1-0.1,0.1L6.4,5.1L1.2,5.8
	C1.1,5.9,1,5.9,1,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8L4,15.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0L9,13.3l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C17,6.3,17,6.2,17,6z"/>
<path class="st0" d="M35,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C27.2,0,27,0,26.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C19.1,5.9,19,5.9,19,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C35,6.3,35,6.2,35,6z"/>
<path class="st0" d="M53,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C45.2,0,45,0,44.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C37.1,5.9,37,5.9,37,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C53,6.3,53,6.2,53,6z"/>
<path class="st0" d="M71,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C63.2,0,63,0,62.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C55.1,5.9,55,5.9,55,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C71,6.3,71,6.2,71,6z"/>
<path class="st0" d="M89,6.2c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9
	L73.2,6C73.1,6,73,6.1,73,6.2c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C89,6.4,89,6.3,89,6.2z"/>
</svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <h3 class="top head">Quick Opening an Account</h3>
                <p> If you want to open an instant Demat Account, Motilal Oswal is the right choice. All process is online and it only takes 5 minutes to process.
                </p>
  
                <div class="nimbox">
                  {/* <div><Image src={Download} alt={''} width="" height="" /></div> */}
                  <div><div class='sprite download'></div></div>
                 <div>
                    <div class="top">
                      Rakesh Kumar</div>
                    <div class="botm">
                    {/* <Image src={starIcon} alt={''} width="120px" height="25px" /> */}
                    <svg height="" width="">
<path class="st0" d="M17,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8L9.3,0.2C9.2,0,9,0,8.9,0c-0.1,0-0.1,0.1-0.1,0.1L6.4,5.1L1.2,5.8
	C1.1,5.9,1,5.9,1,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8L4,15.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0L9,13.3l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C17,6.3,17,6.2,17,6z"/>
<path class="st0" d="M35,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C27.2,0,27,0,26.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C19.1,5.9,19,5.9,19,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C35,6.3,35,6.2,35,6z"/>
<path class="st0" d="M53,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C45.2,0,45,0,44.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C37.1,5.9,37,5.9,37,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C53,6.3,53,6.2,53,6z"/>
<path class="st0" d="M71,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C63.2,0,63,0,62.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C55.1,5.9,55,5.9,55,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C71,6.3,71,6.2,71,6z"/>
<path class="st0" d="M89,6.2c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9
	L73.2,6C73.1,6,73,6.1,73,6.2c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C89,6.4,89,6.3,89,6.2z"/>
</svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="blocks">
              <div class="borderbox">
                <h3 class="top head">Good Guided Assistance</h3>
                <p>Employees of Motilal Oswal guided me so well during the entire account opening process that it took me less than 20 minutes to finish.
                </p>
  
                <div class="nimbox">
                  {/* <div><Image src={Download} alt={''} width="" height="" /></div> */}
                  <div><div class='sprite download'></div></div>
                  <div>
                    <div class="top">
                    Vivek Saraswat</div>
                    <div class="botm">
                    {/* <Image src={starIcon} alt={''} width="120px" height="25px" /> */}
                    <svg height="" width="">
<path class="st0" d="M17,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8L9.3,0.2C9.2,0,9,0,8.9,0c-0.1,0-0.1,0.1-0.1,0.1L6.4,5.1L1.2,5.8
	C1.1,5.9,1,5.9,1,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8L4,15.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0L9,13.3l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C17,6.3,17,6.2,17,6z"/>
<path class="st0" d="M35,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C27.2,0,27,0,26.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C19.1,5.9,19,5.9,19,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C35,6.3,35,6.2,35,6z"/>
<path class="st0" d="M53,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C45.2,0,45,0,44.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C37.1,5.9,37,5.9,37,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C53,6.3,53,6.2,53,6z"/>
<path class="st0" d="M71,6c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9C63.2,0,63,0,62.9,0c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9l-5.2,0.8
	C55.1,5.9,55,5.9,55,6c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C71,6.3,71,6.2,71,6z"/>
<path class="st0" d="M89,6.2c0-0.1-0.1-0.2-0.2-0.2l-5.2-0.8l-2.3-4.9c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.1,0.1-0.1,0.1l-2.3,4.9
	L73.2,6C73.1,6,73,6.1,73,6.2c0,0.1,0,0.2,0.1,0.3l3.7,3.8l-0.9,5.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0l4.6-2.5l4.6,2.5
	c0.1,0.1,0.2,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3l-0.9-5.4l3.7-3.8C89,6.4,89,6.3,89,6.2z"/>
</svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

         
        </div>
        <div class="mtop40"></div>
        </>
    )
}
export default CustomerReviewSjet;
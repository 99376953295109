import { SET_USER, VERIFY_OTP_EMAIL, GET_SCHEME_DETAILS, RESEND_OTP, INIT_SET_USER,INIT_SET_USER_SUPERAPP, GET_AURM_DETAILS, SAVE_AURM_DETAILS } from "../types/user";

export const setUser = (payload) => ({
    type: SET_USER,
    payload
})

export const initSetUser = (payload) => ({
    type: INIT_SET_USER,
    payload
})

export const initSetUserSuperApp = (payload) => ({
    type: INIT_SET_USER_SUPERAPP,
    payload
})

export const verifyOtpEmail = (payload) => ({
    type: VERIFY_OTP_EMAIL,
    payload
})

export const resendOtp = (payload) => ({
    type: RESEND_OTP,
    payload
})

export const getSchemeDetails = (payload) => ({
    type: GET_SCHEME_DETAILS,
    payload
})

export const getAuRmDetails = (payload) => ({
    type: GET_AURM_DETAILS,
    payload
})

export const saveAuRmDetails = (payload) => ({
    type: SAVE_AURM_DETAILS,
    payload
})

import { produce } from 'immer';
import { REG_CONTACT_US_CLICKED, REG_FAQ_CLICKED } from '../types/analytics';

const initialState = {
    registrationContactUsClicked: "No",
    registrationFaqClicked: "No",
}

const reducer = produce((state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case REG_CONTACT_US_CLICKED:
            state.registrationContactUsClicked = payload;
            return;

        case REG_FAQ_CLICKED:
            state.registrationFaqClicked = payload;
            return;

        default:
            return state
    }
})

export default reducer;
import React, { Component } from 'react'
import starIcon from '../../../assets/images/star-icon.svg'
import img1 from '../../../assets/images/img1.jpeg'

class CustomerReview extends Component {
    render() {
        return (
            <>
                <div class="mtop20"></div>
                <div class="text-center">
                    <div class="conbox"><h2>WHAT OUR CUSTOMERS SAY</h2></div>
                    <div><small>Reviews, comments from MO's customers and community</small></div>

                    <div class="reviewbox">
                        <div class="bl">
                            <div class="borderbox">
                                <div class="top head headcommon"><h3>Opening an Account</h3></div>
                                <p>Hassle-free account opening. Advisor has good knowledge of trading. Tailor made brokerage plans and easy fund transfer.                        </p>

                                <div class="nimbox">
                                    <div><img src={img1} alt="" width="" height=""/></div>
                                    <div>
                                        <div class="top">
                                            Gagandeep Singh</div>
                                        <div class="botm">
                                            <img src={starIcon} alt="" width="" height="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="bl">
                            <div class="borderbox">
                                <div class="top head headcommon"><h3>MO Investor App</h3></div>
                                <p>If you are a rookie in trading and want to know more about Invesment. I personally recommend you to download it.</p>

                                <div class="nimbox">
                                    <div><img src={img1} alt="" width="" height="" /></div>
                                    <div>
                                        <div class="top">
                                            Rakesh Kumar</div>
                                        <div class="botm">
                                            <img src={starIcon} alt="" width="" height=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </>
        )
    }
}
export default CustomerReview